<div
  class="composer-min-top-bar"
  [ngStyle]="{ width: minimized ? '400px ' : '700px' }"
>
  <div>Message</div>
  <div class="composer-min-top-bar__actions">
    <mat-icon
      *ngIf="!minimized"
      class="composer-min-top-bar__toggle-icon"
      (click)="onToggleMinimize()"
      >{{ minimized ? 'maximize' : 'minimize' }}</mat-icon
    >
    <mat-icon
      class="composer-min-top-bar__toggle-icon"
      mat-icon-button
      (click)="minimized ? onToggleMinimize() : onExpandDialog()"
      >open_in_full</mat-icon
    >
    <mat-icon
      class="composer-min-top-bar__close-icon"
      (click)="onCloseDialog()"
      >close</mat-icon
    >
  </div>
</div>

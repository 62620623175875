import { Attachment } from './Attachment';
import { ComposeEmailCategory } from './ComposeEmailCategory';

interface IEmailTemplate {
  id: number;
  title: string;
  type_id: number;
}
export class ComposeEmail {
  category: ComposeEmailCategory;
  from: string;
  to: string[];
  cc: string[];
  bcc: string[];
  bodyHtml: string;
  bodyPlain: string;
  subject: string;
  attachments: Attachment[] = [];
  sourceStorageId: string;
  references: any; // Original headers
  in_reply_to: any; // Original headers
  messageId: string;
  currentEdition: string;
  includeChainInEditor = true;
  sender: string;
  fromSdrId: string;
  restored = false;
  template: IEmailTemplate;
  salesRepresentative: string;

  constructor(data?: any) {
    if (!data) return;
    this.from = data.from;
    this.to = data.to;
    this.cc = data.cc;
    this.bcc = data.bcc || '';
    this.bodyHtml = data.bodyHtml;
    this.bodyPlain = data.bodyPlain;
    this.subject = data.subject;
    this.attachments = data.attachments;
    this.category = data.category;
    this.sourceStorageId = data.sourceStorageId;
    this.references = data.references;
    this.in_reply_to = data.in_reply_to;
    this.messageId = data.messageId;
    this.currentEdition = data.currentEdition ? data.currentEdition : '';
    this.sender = data.sender;
    this.fromSdrId = data.fromSdrId;
    this.template = data.template;
  }

  static clone(emailToClone: ComposeEmail) {
    return new ComposeEmail({
      from: emailToClone.from,
      to: emailToClone.to,
      cc: emailToClone.cc,
      bcc: emailToClone.bcc,
      subject: emailToClone.subject,
      bodyHtml: `${emailToClone.bodyHtml}`,
      bodyPlain: emailToClone.bodyPlain,
      attachments: emailToClone.attachments,
      category: emailToClone.category,
      sourceStorageId: emailToClone.sourceStorageId,
      references: emailToClone.references,
      in_reply_to: emailToClone.in_reply_to,
      messageId: emailToClone.messageId,
      sender: emailToClone.sender,
      fromSdrId: emailToClone.fromSdrId,
      template: emailToClone.template,
    });
  }
}

<mat-progress-bar
  mode="indeterminate"
  *ngIf="isLoadingSdrsConversations"
></mat-progress-bar>

<mat-drawer-container
  class="drawer-container"
  autosize
>
  <mat-drawer
    #drawer
    mode="over"
    position="end"
    [ngClass]="'extra-info-sidenav'"
    ><div
      class="sdr-conversations__extra-info"
      *ngIf="selectedConversation && showExtraInfoSidenav"
    >
      <app-classification></app-classification>
      <app-extra-info></app-extra-info>
      <div class="sdr-conversations__interactions">
        <app-interactions></app-interactions>
      </div>
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <div class="sdr-conversations">
      <div class="sdr-conversations__container">
        <div class="sdr-conversations__toolbar">
          <div class="sdr-conversations__refresh-container">
            <span class="sdr-conversations__title">LinkedIn conversations</span>
            <button
              matTooltip="Refresh"
              (click)="onRefreshConversations()"
              class="sdr-conversations__refresh-btn"
            >
              <mat-icon class="sdr-conversations__refresh-icon">sync</mat-icon>
            </button>
          </div>
          <div class="sdr-conversations__selectable-container">
            <mat-form-field
              class="sdr-conversations__folder-picker form-field-5"
              appearance="outline"
              id="folder-mat-form-field"
              subscriptSizing="dynamic"
            >
              <mat-label>Folder</mat-label>
              <mat-select
                id="folder-mat-select"
                [value]="selectedFolder"
                (selectionChange)="onSelectFolder($event.value)"
              >
                <mat-option
                  *ngFor="let folder of folders; let i = index"
                  [value]="folder.value"
                  [id]="'folder-mat-option-' + i"
                >
                  {{ folder.title | titlecase }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field
              class="sdr-conversations__conversation-type-picker form-field-5"
              appearance="outline"
              id="conversation-type-mat-form-field"
              subscriptSizing="dynamic"
            >
              <mat-label> Conversation Type </mat-label>
              <mat-select
                id="conversation-type-mat-select"
                [value]="selectedConversationType"
                (selectionChange)="onSelectConversationType($event.value)"
              >
                <mat-option
                  *ngFor="let conversationType of conversationTypes; let i = index"
                  [value]="conversationType.value"
                  [id]="'conversation-type-mat-option-' + i"
                >
                  {{ conversationType.title | titlecase }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field
              class="sdr-conversations__sdr-picker form-field-5"
              appearance="outline"
              id="sdr-mat-form-field"
              subscriptSizing="dynamic"
            >
              <mat-label>SDR</mat-label>
              <mat-select
                id="sdr-mat-select"
                [value]="selectedSdrId"
                canSelectNullableOptions
                (selectionChange)="onSelectSdr($event.value)"
              >
                @for (sdr of sdrs; track sdr; let i = $index) {
                  <mat-option
                    [value]="sdr.value"
                    [id]="'sdr-mat-option-' + i"
                    >{{ sdr.title | titlecase }}</mat-option
                  >
                }
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div
          *ngIf="filteredSdrConversations?.length"
          class="sdr-conversations__conversations-list"
        >
          <mat-accordion>
            <ng-container *ngFor="let sdrConversation of filteredSdrConversations; let i = index">
              <mat-expansion-panel
                [expanded]="i === 0"
                [togglePosition]="'before'"
                class="sdr-conversations__expansion-panel"
              >
                <mat-expansion-panel-header class="sdr-conversations__expansion-panel-header">
                  <div class="sdr-conversations__header-content">
                    <div class="sdr-conversations__sdr-name">
                      {{ sdrConversation?.sdrName }}
                    </div>
                    <mat-icon
                      *ngIf="sdrConversation.isReal"
                      class="sdr-conversations__sdr-icon"
                      >person</mat-icon
                    >
                  </div>
                </mat-expansion-panel-header>
                <app-conversation
                  [conversations]="sdrConversation.conversations"
                  [selectedConversation]="selectedConversation"
                ></app-conversation>
              </mat-expansion-panel>
            </ng-container>
          </mat-accordion>
          <div class="sdr-conversations__load-more">
            <button
              mat-icon-button
              matTooltip="Load more conversations"
              color="primary"
              [disabled]="loadMoreDisabled"
              (click)="loadMoreConversations()"
            >
              <mat-icon>more_horiz</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <div
        class="sdr-conversations__messages"
        *ngIf="filteredSdrConversations?.length && selectedConversation"
      >
        <app-message></app-message>
      </div>
      <div
        class="sdr-conversations__extra-info"
        [ngClass]="{ 'sdr-conversations__extra-info--with-interactions': hasInteractions }"
        *ngIf="filteredSdrConversations?.length && selectedConversation! && !showExtraInfoSidenav"
      >
        <app-classification></app-classification>
        <app-extra-info></app-extra-info>
        <app-interactions></app-interactions>
      </div>
      <div
        class="sdr-conversations__sidenav-action-info"
        *ngIf="showExtraInfoSidenav && selectedConversation"
      >
        <mat-icon
          color="primary"
          (click)="drawer.toggle()"
          >chevron_left</mat-icon
        >
      </div>

      <div
        class="sdr-conversations__not-results"
        *ngIf="filteredSdrConversations?.length && !selectedConversation"
      >
        <mat-icon
          class="sdr-conversations__info-icon"
          color="primary"
          >info</mat-icon
        >
        Please select a conversation
      </div>
      <div
        class="sdr-conversations__not-results"
        *ngIf="!filteredSdrConversations?.length"
      >
        <mat-icon
          class="sdr-conversations__info-icon"
          color="warn"
          >info</mat-icon
        >
        No conversations found
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>

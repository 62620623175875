import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { GetLocationDto } from '../model/catalog/get-location.dto';
import { Location } from '../model/catalog/location.model';
import { GenericResponse } from '../model/common/generic-response';

@Injectable({
  providedIn: 'root',
})
export class CatalogService {
  constructor(private http: HttpClient) {}

  getLocations(getLocationDto: GetLocationDto): Observable<Location[]> {
    const url = `${environment.newApiBaseUrl}/catalog/get-locations`;

    return this.http
      .post(url, getLocationDto)
      .pipe(map((response: GenericResponse<any>) => response.data?.locations as Location[]));
  }
}

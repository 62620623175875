<div class="contact-dialog">
  <div
    class="contact-dialog__top-bar"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
  >
    <span class="contact-dialog__title">{{
      isCreatingContact
        ? 'Create Contact'
        : isShowingAlias
          ? 'Alias'
          : parentData?.contactId
            ? 'View Contact'
            : 'Find Contact'
    }}</span>
    <mat-icon
      mat-dialog-close
      class="contact-dialog__close-btn"
      >close</mat-icon
    >
  </div>
  <div class="contact-dialog__content">
    <div
      class="contact-dialog__search-actions-container"
      [ngClass]="{ 'contact-dialog__search-actions-container--end': isCreatingContact || isShowingAlias }"
    >
      <app-contact-search
        class="contact-dialog__search"
        [ngClass]="{ 'contact-dialog__search--hide': isCreatingContact || isShowingAlias }"
        [initialEmailAddress]="emailAddress"
        [customerId]="customerId"
        (updateContactIdFromCtrl)="setContactId($event)"
      ></app-contact-search>
      <app-contact-toolbar></app-contact-toolbar>
    </div>
    <div
      class="contact-dialog__form-container"
      *ngIf="isCreatingContact"
    >
      <app-contact-form
        class="contact-dialog__form"
        (addContact)="createContact($event)"
      ></app-contact-form>
    </div>
    <div
      class="contact-dialog__alias-container"
      *ngIf="isShowingAlias"
    >
      <app-alias-dialog class="contact-dialog__alias"></app-alias-dialog>
    </div>
    <app-contact-detail *ngIf="!isCreatingContact && !isShowingAlias"></app-contact-detail>
  </div>
</div>

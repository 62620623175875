<div
  class="classifier-body"
  id="emailColumn"
>
  <app-mail-classifier-details-toolbar></app-mail-classifier-details-toolbar>
  <div class="classifier-body__iframe-container">
    <iframe
      class="classifier-body__iframe"
      #iframe
      id="emailIframe"
      frameborder="0"
    ></iframe>

    <div
      class="classifier-body__not-found"
      *ngIf="!currentEmail"
    >
      <span *ngIf="!loading">Message not found</span>
    </div>
  </div>
  <div class="details-toolbar">
    <div
    *ngIf="currentEmail?.attachments?.length"
    class="details-toolbar__attachments-container"
    id="readAttachmentsDiv"
  >
    <div
      *ngFor="let attachment of currentEmail.attachments"
      class="details-toolbar__attachments"
    >
      <app-attachment
        [attachment]="attachment"
        (clickEvt)="messageAttachmentService.downloadRemoteFile(attachment.filename, attachment.isSafe)"
      ></app-attachment>
    </div>
  </div>
  </div>
</div>


import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[appAllowOnlyNumbers]',
    standalone: false
})
export class AllowOnlyNumbersDirective {
  private regex: RegExp = /^[0-9]+$/;

  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent): void {
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

    if (!allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData || window['clipboardData'];
    const pastedText = clipboardData.getData('text');

    if (!this.regex.test(pastedText)) {
      event.preventDefault();
    }
  }
}

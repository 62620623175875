import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ClassifierMessage } from '../../../../model/mail-classifier/classifier-message.model';
import { LoadingStateService } from '../../../../services/base/loading-state.service';
import { MailReviewService } from '../../../../services/mail-review.service';

@Component({
    selector: 'app-mail-classifier-log',
    templateUrl: './mail-classifier-log.component.html',
    styleUrls: ['./mail-classifier-log.component.scss'],
    standalone: false
})
export class MailClassifierLogComponent implements OnInit, OnDestroy {
  @Input() message: ClassifierMessage;
  @Output() completeCase: EventEmitter<null> = new EventEmitter();
  @Output() emailClicked: EventEmitter<string> = new EventEmitter();
  isLoading = false;
  private destroy$ = new Subject<boolean>();

  constructor(
    private mailReviewService: MailReviewService,
    private loadingStateService: LoadingStateService,
  ) {}

  ngOnInit(): void {
    this.loadingStateService.isLoading.pipe(takeUntil(this.destroy$)).subscribe((isLoading) => {
      this.isLoading = isLoading;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  onComplete() {
    this.completeCase.emit();
  }

  onClickEmail(email: string) {
    this.emailClicked.emit(email);
  }

  onClickProspectId(prospectId: number): void {
    this.mailReviewService.setClickedProspectId(prospectId);
  }
}

import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Subject, fromEvent, pipe } from 'rxjs';
import { Subscription } from 'rxjs-compat';
import { filter, finalize, take, takeUntil } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../../../components/confirm-dialog/confirm-dialog.component';
import { MailStatus } from '../../../model/common/mail-status.enum';
import { ComposeEmail } from '../../../model/ComposeEmail';
import { ComposeEmailCategory } from '../../../model/ComposeEmailCategory';
import { FOLDERS } from '../../../model/Email';
import { LeadProfile } from '../../../model/lead-profile.model';
import { Sdr } from '../../../model/Sdr';
import { AppConstants } from '../../../resources/app-constants';
import { SdApiService } from '../../../services/base/sd-api.service';
import { ComposerMailService } from '../../../services/composer-mail.service';
import { FeedService } from '../../../services/feed.service';
import { InquiryService } from '../../../services/inquiry.service';
import { InteractionService } from '../../../services/interaction.service';
import { MessageAttachmentService } from '../../../services/message-attachment.service';
import { SnackBarService } from '../../../services/snackbar/snackbar.service';
import { MAX_BODY_SIZE } from '../../../utils/constants';
import { MailComposerComponent } from '../mail-composer/mail-composer.component';
import { RecipientFieldsComponent } from '../mail-composer/recipient-fields/recipient-fields.component';
import { SelectSalesRepresentativeComponent } from '../mail-composer/select-sales-representative/select-sales-representative.component';
import { SelectTemplateComponent } from '../mail-composer/select-template/select-template.component';

declare var $: any;

interface IDialogData {
  currentFolder: string;
  lead: string;
}

@Component({
  selector: 'app-mail-composer-min',
  templateUrl: './mail-composer-min.component.html',
  styleUrls: ['./mail-composer-min.component.scss'],
})
export class MailComposerMinComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(RecipientFieldsComponent) recipientFields: RecipientFieldsComponent;
  @ViewChild('inputFileControl', { read: ElementRef }) inputFileControl: ElementRef;
  public Editor = ClassicEditor;
  public minimized = false;
  currentComposedEmail: ComposeEmail;
  sdrs: Sdr[];
  renderedBody: string;
  initialized: Boolean;
  subscriptions: Subscription = new Subscription();
  blockedSdrs: string[];
  destroy$ = new Subject<boolean>();
  selectedTemplate;
  selectedSalesRep;
  categoryType = ComposeEmailCategory;
  leadCopied = false;
  isLoadingLead = false;
  isReplyReady = true;
  isIntroductionReady = true;

  constructor(
    private matDialog: MatDialog,
    public dialogRef: MatDialogRef<MailComposerMinComponent>,
    private composerMailService: ComposerMailService,
    private sdApiService: SdApiService,
    public messageAttachmentService: MessageAttachmentService,
    @Inject(MAT_DIALOG_DATA) public dialogData: IDialogData,
    public dialog: MatDialog,
    private feedService: FeedService,
    private snackBarService: SnackBarService,
    private inquiryService: InquiryService,
    private interactionService: InteractionService,
  ) {}

  ngOnInit() {
    this.isReplyReady = true;
    this.isIntroductionReady = true;
    this.composerMailService.setIsComposerOpen(true);
    this.subscriptions.add(
      this.composerMailService.composedEmail.subscribe((composedEmail) => {
        if (composedEmail) {
          this.currentComposedEmail = composedEmail;
          this.currentComposedEmail.currentEdition = composedEmail.currentEdition;
          if (this.isSdrBlocked || !this.isSendConfigured) {
            $('#minComposerId').next().find('.note-editable').attr('contenteditable', false);
          } else {
            $('#minComposerId').next().find('.note-editable').attr('contenteditable', true);
          }
        } else {
          this.currentComposedEmail.currentEdition = '';
        }
        if (composedEmail?.template) {
          this.selectedTemplate = composedEmail.template;
          if (this.selectedTemplate.type_id === 1) {
            this.checkInteractionExist(MailStatus.Reply, false);
          }
          if (this.selectedTemplate.type_id === 2) {
            this.checkInteractionExist(MailStatus.Introduction, false);
          }
        }
      }),
    );
    this.loadSdrs();
    this.subscriptions.add(
      this.sdApiService.blockedSdrs.subscribe((blockedSdrs) => {
        this.blockedSdrs = blockedSdrs;
      }),
    );

    fromEvent<KeyboardEvent>(document, 'keydown')
      .pipe(
        takeUntil(this.destroy$),
        filter((event) => event.key === 'Escape'),
      )
      .subscribe(() => {
        this.updateBody();
        if (!this.minimized) {
          this.toggleMinimize();
        }
      });
  }

  loadSdrs() {
    this.feedService.selectedFolder.pipe(take(1)).subscribe((folder) => {
      const obs =
        folder === FOLDERS.REVIEW
          ? this.sdApiService.userSdrsReview
          : folder === FOLDERS.SENT
            ? this.sdApiService.userSdrsSent
            : folder === FOLDERS.SALE_OPERATORS
              ? this.sdApiService.userSdrsSales
              : this.sdApiService.userSDRs;

      obs.pipe(take(1)).subscribe((sdrs) => {
        this.sdrs = sdrs;
      });
    });
  }

  ngAfterViewInit() {
    this.composerMailService.isMailComposerMinimized
      .pipe(takeUntil(this.destroy$))
      .subscribe((isMailComposerMinimized) => {
        if (isMailComposerMinimized) {
          this.minimized = isMailComposerMinimized;
        }
      });

    this.subscriptions.add(
      this.composerMailService.composedEmail.subscribe((composedEmail) => {
        if (composedEmail) {
          this.renderedBody = this.currentComposedEmail.currentEdition;
          $('#minComposerId').summernote({
            toolbar: [
              ['style', ['style']],
              ['font', ['bold', 'underline', 'italic', 'clear']],
              ['fontname', ['fontname']],
              ['fontsize', ['fontsize']],
              ['fontsizeunit', ['fontsizeunit']],
              ['color', ['color']],
              ['para', ['ul', 'ol', 'paragraph']],
            ],
            fontNames: [
              'Arial',
              'Calibri',
              'Roboto',
              'Comic Sans MS',
              'Courier New',
              'Helvetica',
              'Impact',
              'sans-serif',
              'Tahoma',
              'Times New Roman',
              'Verdana',
            ],
            fontSizes: ['8', '9', '10', '11', '12', '14', '15', '16', '18', '24', '36'],
            fontSizeUnits: ['pt'],
            fontNamesIgnoreCheck: ['Roboto'],
            dialogsInBody: true,
            disableResizeEditor: true,
            callbacks: {
              onChange(contents, $editable) {
                const bodySize = new Blob([contents]).size;
                if (MAX_BODY_SIZE < bodySize) {
                  $('#minComposerId').summernote('code', (window as any).editorHtml);
                  alert('Alert! The size of the body exceeds the allowed limit.');
                } else {
                  (window as any).editorHtml = contents;
                }
              },
            },
          });

          $('.note-editable').css('font-size', AppConstants.GMAIL_FONT_SIZE + AppConstants.GMAIL_FONT_UNIT);
          $('.note-editable').css('font-family', AppConstants.GMAIL_FONT_FAMILY);
          $('.note-editable').css('min-height', '25vh');

          if (this.renderedBody) {
            $('#minComposerId').summernote('code', this.renderedBody);
          }

          if (!this.initialized) {
            const service = this.composerMailService;
            $('#minComposerId').on('summernote.paste', function (we, e) {
              for (let i = 0; i < e.originalEvent.clipboardData.items.length; i += 1) {
                if (
                  e.originalEvent.clipboardData.items[i].kind == 'string' &&
                  e.originalEvent.clipboardData.items[i].type.match('^text/html')
                ) {
                  e.preventDefault();

                  e.originalEvent.clipboardData.items[i].getAsString(function (s) {
                    s = service.fixPastedHtml(s);
                    $('#minComposerId').summernote('pasteHTML', '<p id="temp-paragraph" style="display:none"></p>');
                    $('#minComposerId').summernote('pasteHTML', s);
                    $('.note-editable').find('p#temp-paragraph').remove();
                  });
                }
              }
            });
          }
          (window as any).editorHtml = this.renderedBody;
          this.initialized = true;
        }
        setTimeout(function () {
          if (!composedEmail?.to?.length) $('#to').focus();
        }, 500);
      }),
    );
  }

  selectTemplate() {
    const selectedSdr = this.sdrs.find((sdr) => sdr.email === this.recipientFields.fromControl.value);
    const dialogRef = this.dialog.open(SelectTemplateComponent, {
      panelClass: 'my-custom-dialog-class',
      minWidth: '1150px',
      data: {
        sdr: this.recipientFields.fromControl.value,
        client: {
          name: selectedSdr.client,
          id: selectedSdr.clientId,
        },
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.selectedTemplate = result;
        if (this.selectedTemplate.attachment && this.selectedTemplate.attachment.length > 0) {
          if (this.currentComposedEmail.attachments === undefined) {
            this.currentComposedEmail.attachments = [];
          }
          this.selectedTemplate.attachment.forEach((item: any) => {
            this.currentComposedEmail.attachments.push({
              filename: item.name,
              fileId: item.key,
              key: item.key,
              isSafe: item.is_safe,
              contentType: item.content_type,
              size: item.size,
            });
          });
        }
        if (this.selectedTemplate.isInternalUse) {
          this.currentComposedEmail.cc = [];
          this.recipientFields.ccEmailList = [];
          this.currentComposedEmail.to = [];
          this.recipientFields.toEmailList = [];
          this.currentComposedEmail.bcc = [];
          this.recipientFields.bccEmailList = [];
        }
        if (this.selectedTemplate.clientContactEmail && this.selectedTemplate.clientContactEmail !== '') {
          if (this.selectedTemplate.isInternalUse) {
            this.currentComposedEmail.to.push(this.selectedTemplate.clientContactEmail);
            this.recipientFields.toEmailList.push({ value: this.selectedTemplate.clientContactEmail, invalid: false });
          } else {
            this.currentComposedEmail.cc.push(this.selectedTemplate.clientContactEmail);
            this.recipientFields.ccEmailList.push({ value: this.selectedTemplate.clientContactEmail, invalid: false });
          }
        }
        if (this.selectedTemplate.cc && this.selectedTemplate.cc !== '') {
          const ccList = this.selectedTemplate.cc.split(';');
          ccList.forEach((item: string) => {
            this.currentComposedEmail.cc.push(item.trim());
            this.recipientFields.ccEmailList.push({ value: item, invalid: false });
          });
        }
        if (this.selectedTemplate.bcc && this.selectedTemplate.bcc !== '') {
          const bccList = this.selectedTemplate.bcc.split(';');
          bccList.forEach((item: string) => {
            this.currentComposedEmail.bcc.push(item.trim());
            this.recipientFields.bccEmailList.push({ value: item, invalid: false });
          });
        }

        this.currentComposedEmail.template = {
          id: this.selectedTemplate.id,
          title: this.selectedTemplate.title,
          type_id: this.selectedTemplate.templateTypeId,
        };

        if (this.selectedTemplate.templateTypeId === 1) {
          this.checkInteractionExist(MailStatus.Reply);
        }
        if (this.selectedTemplate.templateTypeId === 2) {
          this.checkInteractionExist(MailStatus.Introduction);
        }

        $('#minComposerId').summernote('code', this.selectedTemplate.templateText + this.renderedBody);
      }
    });
  }

  checkInteractionExist(mailStatus: number, showNotification = true) {
    mailStatus === MailStatus.Reply ? (this.isReplyReady = false) : (this.isIntroductionReady = false);
    this.interactionService
      .checkInteractionExist({
        messageId: this.currentComposedEmail.messageId,
        sdrId: Number(this.currentComposedEmail.fromSdrId),
        statusId: mailStatus,
      })
      .subscribe({
        next: (response) => {
          if (!response) {
            this.invalidForm;
            if (showNotification) {
              var message = '';
              if (mailStatus === MailStatus.Reply)
                message = 'You must create a Reply interaction with this message before Send the reply';
              else
                message = 'You must create a Introduction interaction with this message before Send the introduction';
              this.snackBarService.showWarning(message, 'Close', { duration: 10000 });
            }
          } else {
            mailStatus === MailStatus.Reply ? (this.isReplyReady = true) : (this.isIntroductionReady = true);
          }
        },
        error: () =>
          mailStatus === MailStatus.Reply ? (this.isReplyReady = false) : (this.isIntroductionReady = false),
      });
  }

  selectSalesRep() {
    const selectedSdr = this.sdrs.find((sdr) => sdr.email === this.recipientFields.fromControl.value);
    const dialogRef = this.dialog.open(SelectSalesRepresentativeComponent, {
      panelClass: 'my-custom-dialog-class',
      minWidth: '500px',
      data: selectedSdr.id,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result !== '') {
        this.selectedSalesRep = result;
        this.currentComposedEmail.salesRepresentative = result;
        if (this.selectedSalesRep && this.selectedTemplate !== '') {
          const ccList = this.selectedSalesRep.split(';');
          this.currentComposedEmail.to = [];
          this.recipientFields.toEmailList = [];

          ccList.forEach((item: string) => {
            this.currentComposedEmail.to.push(item);
            this.recipientFields.toEmailList.push({ value: item, invalid: false });
          });
        }
      }
    });
  }

  get displayCopyLeadBtn(): boolean {
    return (
      this.dialogData?.lead &&
      (this.dialogData?.currentFolder === 'review' ||
        this.dialogData?.currentFolder === 'discrepancies' ||
        this.dialogData?.currentFolder === 'converted') &&
      this.currentComposedEmail.category === this.categoryType.Forward
    );
  }

  onCopyLeadProfile() {
    this.isLoadingLead = true;
    const { messageId, fromSdrId } = this.currentComposedEmail;
    const lead = this.dialogData.lead;

    if (messageId && fromSdrId) {
      this.inquiryService
        .getLeadsData(messageId, fromSdrId)
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => (this.isLoadingLead = false)),
        )
        .subscribe({
          next: (response: LeadProfile) => {
            response
              ? this.copyLeadInfo(response)
              : this.snackBarService.showError(`No data found for lead with email ${lead}`);
          },
          error: () => this.snackBarService.showError(`No data found for lead with email ${lead}`),
        });
    }
  }

  private copyLeadInfo(lead: LeadProfile): void {
    let textToCopy =
      `<div><p style="font-family: 'Courier New'">*** INTERNAL USE ONLY ***</p>` +
      `<p style="font-family: 'Courier New'">FYI, Lead profile details below...</p><br>`;

    textToCopy =
      textToCopy +
      [
        { key: 'Original Email', value: lead.emailAddress },
        { key: 'Name', value: `${lead.firstName} ${lead.lastName}` },
        { key: 'Title', value: lead.title || '' },
        { key: 'Responsibility', value: lead.jobResponsibility || '' },
        { key: 'Department', value: lead.jobDepartment || '' },
        { key: 'LinkedIn URL', value: lead.linkedInUrlGen ? `https://linkedin.com${lead.linkedInUrlGen}` : '' },
        { key: 'Region', value: lead.region || '' },
        { key: 'Country', value: lead.country || '' },
        { key: 'State', value: lead.state || '' },
        { key: 'City', value: lead.city || '' },
        { key: 'Phone', value: lead.phone || '' },
        { key: 'Company', value: lead.company.companyName || '' },
        { key: 'LinkedIn URL', value: lead.company.linkedInUrl || '' },
        { key: 'Industry', value: lead.company.industry || '' },
        { key: 'Size', value: lead.company.size || '' },
        { key: 'Employees Qty', value: lead.company.employeeCount || '' },
      ]
        .map((field) => {
          return `<p style="font-family: 'Courier New'"><span style="margin-right: 20px; width: 140px; display:inline-flex;">${field.key}:</span>${field.value}</p>`;
        })
        .join('');

    if (lead.comments) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          message: `Is the lead the same as ${lead.comments}?`,
          confirmBtnText: 'Yes',
          cancelBtnText: 'No',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (!result) {
          textToCopy +=
            `<p style="font-family: 'Courier New'">-------------------------------------------</p>` +
            `<p style="font-family: 'Courier New'">Original Lead contacted</p>`;
          textToCopy =
            textToCopy +
            [
              { key: 'Email', value: lead.originalLeadContactedEmail },
              { key: 'Name', value: lead.originalLeadContactedName },
              { key: 'Title', value: lead.originalLeadContactedTitle },
              {
                key: 'LinkedIn URL',
                value: lead.originalLeadContactedLinkedInUrl
                  ? `https://linkedin.com${lead.originalLeadContactedLinkedInUrl}`
                  : '',
              },
            ]
              .map((field) => {
                return `<p style="font-family: 'Courier New'"><span style="margin-right: 20px; width: 140px; display:inline-flex;">${field.key}:</span>${field.value}</p>`;
              })
              .join('');

          textToCopy += '</div>';
          this.pasteToEditor(textToCopy);
        } else {
          textToCopy =
            `<div><p style="font-family: 'Courier New'">*** INTERNAL USE ONLY ***</p>` +
            `<p style="font-family: 'Courier New'">FYI, Lead profile details below...</p><br>`;
          textToCopy =
            textToCopy +
            [
              { key: 'Original Email', value: lead.emailAddress },
              { key: 'Response Email', value: lead.comments },
              { key: 'Name', value: `${lead.firstName} ${lead.lastName}` },
              { key: 'Title', value: lead.title || '' },
              { key: 'Responsibility', value: lead.jobResponsibility || '' },
              { key: 'Department', value: lead.jobDepartment || '' },
              { key: 'LinkedIn URL', value: lead.linkedInUrlGen ? `https://linkedin.com${lead.linkedInUrlGen}` : '' },
              { key: 'Region', value: lead.region || '' },
              { key: 'Country', value: lead.country || '' },
              { key: 'State', value: lead.state || '' },
              { key: 'City', value: lead.city || '' },
              { key: 'Phone', value: lead.phone || '' },
              { key: 'Company', value: lead.company.companyName || '' },
              { key: 'LinkedIn URL', value: lead.company.linkedInUrl || '' },
              { key: 'Industry', value: lead.company.industry || '' },
              { key: 'Size', value: lead.company.size || '' },
              { key: 'Employees Qty', value: lead.company.employeeCount || '' },
            ]
              .map((field) => {
                return `<p style="font-family: 'Courier New'"><span style="margin-right: 20px; width: 140px; display:inline-flex;">${field.key}:</span>${field.value}</p>`;
              })
              .join('');

          textToCopy += '</div>';
          this.pasteToEditor(textToCopy);
        }
      });
    } else {
      textToCopy += '</div>';
      this.pasteToEditor(textToCopy);
    }
  }
  private async pasteToEditor(textToCopy: string) {
    $('#minComposerId').summernote('pasteHTML', textToCopy);
    this.leadCopied = true;
  }

  updateBody() {
    this.currentComposedEmail.currentEdition = (window as any).editorHtml;
    this.composerMailService.setComposedEmail(this.currentComposedEmail);
  }

  send() {
    if (
      this.sdrs.some((sdr) => sdr.email === this.currentComposedEmail.from) &&
      this.currentComposedEmail.to.length > 0
    ) {
      this.currentComposedEmail.currentEdition = (window as any).editorHtml;
      this.composerMailService.setLastComposedEmail(this.currentComposedEmail);
      this.composerMailService.sendEmail(this.currentComposedEmail);
      this.dialogRef.close();
    }
  }

  toggleMinimize() {
    this.minimized = !this.minimized;
    const newHeight = this.minimized ? '36px' : 'auto';
    this.dialogRef.updateSize(undefined, newHeight);
    this.dialogRef.updatePosition({ bottom: '0', right: '20px' });

    if (this.minimized === true) {
      this.composerMailService.setIsMailComposerMinimized(false);
    }
  }

  expand() {
    this.updateBody();
    this.dialogRef.close();
    const dialogData = JSON.parse(window.localStorage.getItem('fwdEmailData') || '{}');
    const matDialogRef = this.matDialog.open(MailComposerComponent, {
      minWidth: '98vw',
      minHeight: '98vh',
      maxHeight: '98vh',
      panelClass: 'mail-composer-container',
      data: dialogData,
      autoFocus: false,
    });

    matDialogRef.keydownEvents().subscribe((event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        this.updateBody();
        this.composerMailService.setIsMailComposerMinimized(true);
      }
    });

    matDialogRef.backdropClick().subscribe(() => {
      this.updateBody();
      this.composerMailService.setIsMailComposerMinimized(true);
    });
  }

  close() {
    this.composerMailService.setComposedEmail(null);
    this.composerMailService.composeNew();
    this.dialogRef.close();
  }

  get invalidForm(): boolean {
    if (this.currentComposedEmail.attachments.some((attachment) => attachment.isUploading)) {
      return true;
    }

    if (this.recipientFields) {
      return (
        !this.sdrs.some((sdr) => sdr.email === this.currentComposedEmail.from) ||
        this.currentComposedEmail.to.length == 0 ||
        !this.recipientFields ||
        this.recipientFields.toEmailList.some((e) => e.invalid) ||
        this.recipientFields.ccEmailList.some((e) => e.invalid) ||
        this.recipientFields.bccEmailList.some((e) => e.invalid)
      );
    }

    if (!this.isReplyReady) return true;

    if (!this.isIntroductionReady) return true;

    return false;
  }

  get isSdrBlocked(): boolean {
    if (this.recipientFields) {
      return this.blockedSdrs.some((sdr) => sdr === this.recipientFields.fromControl.value);
    }
    return false;
  }

  get isSendConfigured(): boolean {
    if (this.recipientFields) {
      const selectedSdr = this.sdrs.find((sdr) => sdr.email === this.recipientFields.fromControl.value);
      if (selectedSdr) {
        return selectedSdr.isSendConfigured;
      }
      return true;
    }
    return true;
  }

  get sendButtonText(): string {
    if (!this.isSendConfigured) {
      return 'SDR not configured to send emails';
    }
    if (this.isSdrBlocked) {
      return 'SDR currently blocked';
    }
    return 'Send';
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.composerMailService.setIsComposerOpen(false);
    this.composerMailService.setIsMailComposerMinimized(false);
    this.composerMailService.setIsMailComposerMediumOpen(false);
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  async addAttachment(files: FileList) {
    if (!this.currentComposedEmail.attachments) {
      this.currentComposedEmail.attachments = [];
    }
    await this.messageAttachmentService.attach(
      files,
      this.currentComposedEmail.attachments,
      this.inputFileControl,
      this.matDialog,
      this.currentComposedEmail.sender,
    );
    this.currentComposedEmail.currentEdition = (window as any).editorHtml;
    this.composerMailService.setComposedEmail(this.currentComposedEmail);
  }
}

<div class="interaction-data">
  <div class="interaction-data__contact">
    <table class="interaction-data__table">
      <ng-container *ngFor="let contact of contactRows">
        <tr *ngIf="contact.title !== 'Phones'; else phones">
          <td>{{ contact.title }}</td>
          <ng-container *ngIf="contact?.type === valueType.Url; else plainText">
            <td>
              <a
                [href]="contact.value"
                target="_blank"
                >{{ contact.value }}</a
              >
            </td>
          </ng-container>
          <ng-template #plainText>
            <td>
              {{ contact.value }}
            </td>
          </ng-template>
        </tr>
        <ng-template #phones>
          <tr>
            <td>{{ contact.title }}</td>
            <td>
              <mat-form-field class="interaction-data__input-phone form-field-5">
                <input
                  matInput
                  placeholder="{Phone} x{Extension}, {Phone}"
                  [formControl]="phoneCtrl"
                  (blur)="handleUpdateLead()"
                />
                <mat-error *ngIf="phoneCtrl.hasError('pattern') && phoneCtrl.touched">
                  * Format {{ '{' }}Phone{{ '}' }} x {{ '{' }}Extension{{ '}' }}, {{ '{' }}Phone{{ '}' }}
                </mat-error>
              </mat-form-field>
            </td>
          </tr>
        </ng-template>
      </ng-container>
    </table>
  </div>

  <div class="interaction-data__interaction">
    <table class="interaction-data__table">
      <tr *ngFor="let interaction of interactionRows">
        <td>{{ interaction.title }}</td>
        <ng-container *ngIf="interaction?.type === valueType.Url; else plainTextInteraction">
          <td>
            <a
              [href]="interaction.value"
              target="_blank"
              >{{ interaction.value }}</a
            >
          </td>
        </ng-container>
        <ng-template #plainTextInteraction>
          <td>{{ interaction.value }}</td>
        </ng-template>
      </tr>
    </table>
  </div>
</div>

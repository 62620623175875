<div
  fxLayout.gt-lg="row"
  fxLayout.lt-lg="column"
  fxLayoutAlign="space-between"
  class="mail-recipients-details"
>
  <div
    fxLayout="row"
    class="recipients-details-wrapper"
    fxFlex="60%"
    fxFlex.lt-lg="100%"
  >
    <div class="recipients-list">
      <div>
        <span
          *ngIf="email.from"
          class="primary-recipients-text"
          >{{ email.from_.name }}</span
        >
        <span class="secondary-text">{{ email.from_.email }}</span>
      </div>
      <div class="to-info">
        <span class="secondary-text"> to {{ sliceRecipients(email.to) }} </span>
        <button
          mat-icon-button
          [matMenuTriggerFor]="menu"
        >
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
        <mat-menu
          #menu="matMenu"
          class="more-info-panel"
        >
          <ul class="email-more-info">
            <li (click)="$event.stopPropagation()">
              <span class="section-name">from: </span>
              <span class="email-info">{{ email.from_.email }}</span>
            </li>
            <li (click)="$event.stopPropagation()">
              <span class="section-name">to: </span>
              <span class="email-info">{{ formatRecipients(email.to) }}</span>
            </li>
            <li
              (click)="$event.stopPropagation()"
              *ngIf="email.cc"
            >
              <span class="section-name">cc: </span>
              <span class="email-info">{{ formatRecipients(email.cc) }}</span>
            </li>
            <li (click)="$event.stopPropagation()">
              <span class="section-name">date: </span>
              <span class="email-info">{{ email.date | date: 'E, dd LLL yyyy hh:mm aaa' }}</span>
            </li>
            <li (click)="$event.stopPropagation()">
              <span class="section-name">ART date: </span>
              <span class="email-info">{{ getArgentinianDate() | date: 'E, dd LLL yyyy hh:mm aaa' }}</span>
            </li>
            <li (click)="$event.stopPropagation()">
              <span class="section-name">subject: </span>
              <span class="email-info">{{ email.subject }}</span>
            </li>
          </ul>
        </mat-menu>
      </div>
      <span
        *ngIf="email.cc"
        class="secondary-text"
      >
        cc: {{ sliceRecipients(email.cc) }}
      </span>
    </div>
  </div>
  <div
    class="date-wrapper"
    fxFlex="40%"
    fxFlex.lt-lg="100"
  >
    <span
      *ngIf="email?.attachments?.length"
      class="secondary-text attachment-icon"
      [fxFlexOrder.lt-lg]="2"
    >
      <mat-icon>attachment</mat-icon>
    </span>
    <div [fxFlexOrder.lt-lg]="1">
      <p class="secondary-text">Local: {{ transform() | date: 'MM/dd/yyyy, hh:mm:ss a' }}</p>
      <p class="secondary-text">ART: {{ getArgentinianDate() | date: 'E, dd LLL yyyy hh:mm aaa' }}</p>
    </div>
  </div>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  Discrepancy,
  ILeadSearchParams,
  ILeadsToBulkSearchParams,
  ILoadDiscrepanciesResponse,
} from '../model/Discrepancy';
import {
  ContactInteraction,
  DiscrepancyLead,
  DiscrepancyLeadToBulk,
  IEmailStatus,
  IInteraction,
  ILead,
  ILeadInteractionsData,
  ISelectsData,
} from '../model/DiscrepancyLeadToBulk';
import { AppConstants } from '../resources/app-constants';
import dayjs from 'dayjs';

@Injectable({
  providedIn: 'root',
})
export class DiscrepanciesDataService {
  private selectedSearchIteam = new BehaviorSubject<ILeadInteractionsData>(null);
  private refreshInteractions = new BehaviorSubject<boolean>(false);
  public EDITABLE_CUSTOMERS_IDS = [4, 31, 42];
  public CONDITIONAL_QUALIFICATION_IDS = [4, 5, 6];
  public newSearch = new BehaviorSubject<ILeadSearchParams>(null);
  private baseUrl = environment.apiBaseURL;
  constructor(private httpClient: HttpClient) {}
  private selectedInteractionItem = new BehaviorSubject<IInteraction>(null);

  setSearchParams(params: ILeadSearchParams) {
    this.newSearch.next(params);
  }

  get newSearchParams() {
    return this.newSearch.asObservable();
  }

  get selectedSearchResult() {
    return this.selectedSearchIteam.asObservable();
  }

  selectSearchItem(lead: ILeadInteractionsData) {
    this.selectedSearchIteam.next(lead);
  }

  get selectedInteraction() {
    return this.selectedInteractionItem.asObservable();
  }

  selectInteractionItem(interaction: IInteraction) {
    this.selectedInteractionItem.next(interaction);
  }

  get interactionsUpdated() {
    return this.refreshInteractions.asObservable();
  }

  setInteractionsUpdated(created: boolean) {
    this.refreshInteractions.next(created);
  }

  loadDiscrepancies(
    page = 0,
    limit = AppConstants.DISCREPANCIES_LIMIT,
    inReview = false,
  ): Observable<ILoadDiscrepanciesResponse> {
    const offset = page * limit;
    return this.httpClient
      .get(`${this.baseUrl}/app/discrepancies?pending=true&limit=${limit}&offset=${offset}&is_review=${inReview}`)
      .pipe(
        map((response: any) => {
          response.data = response.data.map((element) => new Discrepancy(element));
          return response;
        }),
      );
  }

  completeDiscrepancy(id: number): Observable<any> {
    return this.httpClient
      .patch(`${this.baseUrl}/app/discrepancies/${id}`, {
        pending: false,
      })
      .pipe(map((response: any) => response));
  }

  searchLeadsToBulk(filters: ILeadsToBulkSearchParams): Observable<DiscrepancyLeadToBulk[]> {
    let url = `${environment.apiBaseURL}/app/leads_to_bulk?sdr=${filters.sdr}`;

    if (filters.email) {
      url = filters.isPartialEmail
        ? `${url}&partial_email_address=${filters.email}`
        : `${url}&email_address=${filters.email}`;
    }

    if (filters.searchDate) {
      const formattedDate = this.formatSearchStartDate(filters.searchDate);
      url = `${url}&search_date=${formattedDate}`;
    } else if (filters.fromDate && filters.toDate) {
      const formattedFromDate = this.formatSearchStartDate(filters.fromDate);
      const formattedToDate = this.formatSearchEndDate(filters.toDate);
      url = `${url}&from_date=${formattedFromDate}&to_date=${formattedToDate}`;
    }

    if (filters.firstName) {
      url = `${url}&first_name=${filters.firstName}`;
    }

    if (filters.lastName) {
      url = `${url}&last_name=${filters.lastName}`;
    }

    if (filters.matchAnyCondition) {
      url = `${url}&or_condition=true`;
    }

    return this.httpClient.get(url).pipe(
      map((response: any) => {
        return response?.data?.map((lead) => new DiscrepancyLeadToBulk(lead)) || [];
      }),
    );
  }

  searchLeadInteractions(sdr: number, email: string): Observable<ILeadInteractionsData> {
    const url = `${environment.apiBaseURL}/app/interactions/?id_user=${sdr}&email=${email}`;
    return this.httpClient.get<ILeadInteractionsData>(url).pipe(
      map((response: any) => {
        let lead = null;
        if (!response?.data) {
          return null;
        }
        lead = new ContactInteraction(response.data);
        this.selectedSearchIteam.next(lead);
        if (lead?.interactions?.length) {
          this.selectInteractionItem(lead.interactions[0]);
        }
        return lead;
      }),
    );
  }

  createInteraction(body: any) {
    const url = `${environment.apiBaseURL}/app/interactions/`;
    return this.httpClient.post(url, body).pipe(map((response) => response));
  }

  getSelectsData(): Observable<ISelectsData> {
    const bvMatrixUrl = `${environment.apiBaseURL}/app/bv_matrix/filter=all`;

    return this.httpClient.get(bvMatrixUrl).pipe(
      map((response: any) => {
        const options: ISelectsData = {
          matrix: [],
          country: [],
          delay: [],
          industry: [],
          qualifications: [],
        };

        options.matrix = response.data.filter((opt) => opt.type === 'Matrix');
        options.country = response.data.filter((opt) => opt.type === 'Country');
        options.delay = response.data.filter((opt) => opt.type === 'Delay');
        options.industry = response.data.filter((opt) => opt.type === 'Industry');

        return options;
      }),
    );
  }

  getQualifications(customerId: number): Observable<any> {
    if (!customerId || !this.EDITABLE_CUSTOMERS_IDS.includes(customerId)) {
      return of([]);
    }
    const url = `${environment.apiBaseURL}/app/lead_gen_sources?customer_id=${customerId}`;
    return this.httpClient.get(url).pipe(
      map((response: any) => {
        return response?.data?.map((item) => {
          return {
            id: item.source_id,
            name: item.name,
            factor: item.factor,
          };
        });
      }),
    );
  }

  getLeadDefaultValues(inputs): Observable<any> {
    const url =
      `${environment.apiBaseURL}/app/default_values` +
      `?source_id=${inputs.source_id}` +
      `&date_response=${inputs.date_response}` +
      `&campaign_bulk_date=${inputs.campaign_bulk_date}` +
      `&industry_lead=${inputs.industry_lead}` +
      `&country_lead=${inputs.country_lead}`;

    return this.httpClient.get(url).pipe(
      map((response: any) => {
        return response.data || {};
      }),
    );
  }

  getLeadScore(inputs): Observable<any> {
    let url =
      `${environment.apiBaseURL}/app/score` +
      `?country_value=${inputs.country_value}` +
      `&industry_value=${inputs.industry_value}` +
      `&matrix_value=${inputs.matrix_value}` +
      `&source_value=${inputs.source_value}`;

    if (inputs.delay_value) {
      url = `${url}&delay_value=${inputs.delay_value}`;
    }

    return this.httpClient.get(url).pipe(
      map((response: any) => {
        return response.data;
      }),
    );
  }

  getLTBEmailStatuses(): Observable<IEmailStatus[]> {
    const url = `${environment.apiBaseURL}/app/email_status`;

    return this.httpClient.get(url).pipe(map((response: any) => response.data as IEmailStatus[]));
  }

  getLeadsData(id: number): Observable<ILead> {
    const url = `${environment.apiBaseURL}/app/leads/${id}`;

    return this.httpClient.get(url).pipe(map((response: any) => new DiscrepancyLead(response.data)));
  }

  getLeadsDataByEmail(email: string): Observable<ILead> {
    const url = `${environment.apiBaseURL}/app/leads?email_address=${email}`;

    return this.httpClient.get(url).pipe(map((response: any) => new DiscrepancyLead(response.data)));
  }

  getStatesByCountry(country: string): Observable<any> {
    const url = `${environment.apiBaseURL}/app/countries_states/states_for_country/${country}`;
    return this.httpClient.get(url).pipe(map((response: any) => response));
  }

  updateLeadStatus(id: number, attrs: any) {
    const url = `${environment.apiBaseURL}/app/leads/${id}`;

    return this.httpClient.patch(url, attrs).pipe(map((response: any) => response));
  }

  getNewLeadSelectsData(): Observable<any> {
    const country = `${environment.apiBaseURL}/app/countries_states`;
    const size = `${environment.apiBaseURL}/app/sizes`;
    const industry = `${environment.apiBaseURL}/app/industries`;
    // const pattern = `${environment.apiBaseURL}/app/patterns`;

    let data: any = {
      countries: [],
      states: [],
      sizes: [],
      industries: [],
      patterns: [],
    };
    forkJoin([this.httpClient.get(country), this.httpClient.get(size), this.httpClient.get(industry)]).subscribe(
      ([countries, sizes, industries]: any[]) => {
        data.countries = countries?.data || [];
        data.sizes = sizes.data || [];
        (data.industries = industries?.data || []), (data.patterns = []);
      },
    );

    return of(data);
  }

  createLead(lead: ILead): Observable<any> {
    const url = `${environment.apiBaseURL}/app/leads/`;

    return this.httpClient.post(url, { ...lead }).pipe(map((response) => response));
  }

  addLeadToBulk(attr: { id_lead: number; campaign_bulk_id }): Observable<any> {
    const url = `${environment.apiBaseURL}/app/leads_to_bulk`;

    return this.httpClient.post(url, attr).pipe(map((response) => response));
  }

  private formatSearchStartDate(date: string | Date) {
    return `${dayjs(date).format('YYYY-MM-DD')}T00:00:00.000Z`;
  }

  private formatSearchEndDate(date: string | Date) {
    return `${dayjs(date).format('YYYY-MM-DD')}T23:59:00.000Z`;
  }
}

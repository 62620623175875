<h1 mat-dialog-title>Forwarded Interaction</h1>
<mat-dialog-content>
  <mat-form-field
    appearance="outline"
    subscriptSizing="dynamic"
    class="forwarded-interaction__details-form-field form-field-5"
  >
    <mat-label>Forwarded Id</mat-label>
    <input
      matInput
      [formControl]="forwardedControlIdCtrl"
      id="forwarded-interaction-details-input"
    />
    <mat-icon
      matSuffix
      aria-label="Clear"
      *ngIf="forwardedControlIdCtrl.value"
      (click)="onClearDetails()"
      class="forwarded-interaction__clear-details"
      id="unforwardeddo-interaction-details-mat-icon-clear"
    >
      close
    </mat-icon>
    <mat-error *ngIf="forwardedControlIdCtrl?.errors?.required">This field is required</mat-error>
  </mat-form-field>

  <table
    class="forwarded-interaction__table"
    *ngIf="!isSearching && (forwardedContactName || forwardedContactEmail)"
  >
    <tr>
      <td><b>Contact Name:</b></td>
      <td>
        {{ forwardedContactName }}
      </td>
    </tr>
    <tr>
      <td><b>Contact Email:</b></td>
      <td>{{ forwardedContactEmail }}</td>
    </tr>
  </table>

  <ngx-skeleton-loader
    *ngIf="isSearching"
    count="2"
    appearance="line"
  />
</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-button
    mat-dialog-close
    [mat-dialog-close]="false"
    cdkFocusInitial
  >
    CANCEL
  </button>
  <button
    mat-button
    color="primary"
    (click)="onForwardedInteraction()"
    [disabled]="isForwarding || forwardedControlIdCtrl.invalid || !validForm"
  >
    <span *ngIf="!isForwarding">SEND</span>
    <mat-spinner
      *ngIf="isForwarding"
      diameter="20"
    ></mat-spinner>
  </button>
</mat-dialog-actions>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StatusScreen } from '../../model/common/status-screen.enum';
import { ClientsDataContext } from '../../model/Sdr';
import { SdApiService } from '../../services/base/sd-api.service';
import { MailReviewService } from '../../services/mail-review.service';

@Component({
    selector: 'app-mail-review',
    templateUrl: './mail-review.component.html',
    styleUrls: ['./mail-review.component.scss'],
    standalone: false
})
export class MailReviewComponent implements OnInit {
  currentStatusScreen: StatusScreen = null;
  statusScreen = StatusScreen;

  constructor(
    private route: ActivatedRoute,
    private sdApiService: SdApiService,
    private mailReviewService: MailReviewService,
  ) {}

  ngOnInit() {
    this.sdApiService.setClientsContext(ClientsDataContext.DISCREPANCIES);

    this.route.url.subscribe((segments) => {
      const lastSegment = segments[segments.length - 1]?.path;

      this.currentStatusScreen = lastSegment === 'mail-review' ? StatusScreen.Review : StatusScreen.Manual;
      this.mailReviewService.setCurrentStatusScreen(this.currentStatusScreen);
    });
  }
}

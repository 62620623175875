import { AfterViewChecked, Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { interval, map, Subject, Subscription, switchMap, takeUntil } from "rxjs";
import { SdApiService } from "src/app/services/base/sd-api.service";
import { SnackBarService } from "src/app/services/snackbar/snackbar.service";


export interface IMetabaseResourceFromUser {
    name: string;
    id: number;
  }

  export enum ThemeType {
    Light = 'light',
    Night = 'night',
  }
  
const IFRAME_START_HEIGHT = 850;
const MIN_DASHBOARD_HEIGHT_ALLOWED = 350;
const HEIGHT_STEP_CHECK = 50;

export interface IMetabaseUrl {
    url: string;
    minutesToExpire: number;
  }

@Component({
    selector: 'app-metabase-dashboard',
    templateUrl: './metabase-dashboard.component.html',
    styleUrls: ['./metabase-dashboard.component.scss'],
    standalone: false
  })
  export class MetabaseDashboardComponent implements OnInit, OnDestroy, AfterViewChecked {

    constructor(
        private service: SdApiService,
        private route: ActivatedRoute,
        private snackBarService: SnackBarService,
        private sanitizer: DomSanitizer){}

    operatorId: number;  
    private destroy$: Subject<boolean> = new Subject();
    public metabaseResources: IMetabaseResourceFromUser[] = [];
    public selectDashboard = new FormControl<any>('');
    public iframeHeight: number = IFRAME_START_HEIGHT;
    public selectedDashboardNotAvailable: boolean = false;
    public iFrameLoading: boolean = true;  
    public intervalSubs$ = new Subscription();
    public iframeUrl!: SafeResourceUrl;
    userEmail: string | null;    

    ngOnInit(): void {
        this.service
        .getLoggedUserMetabaseResource(null)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
            next: (response) => {
            this.metabaseResources = response;
           if (this.metabaseResources?.length > 0) {
                // There're no default dashboard.
                this.selectedDashboardNotAvailable = true;
                this.iFrameLoading = false;
                
            } else {
                this.iFrameLoading = false;
            }
            },
        });
    }
    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.complete();
    }
    ngAfterViewChecked(): void {
        this.checkIframeLoaded();
    }

    /**
   * loadDashboard
   *
   * @param   {number}  id
   *
   * @return  {void}
   */
  public loadDashboard(id: number, resourceType: string): void {
    this.selectedDashboardNotAvailable = false;
    this.iFrameLoading = true;
    const theme = ThemeType.Light;
    this.intervalSubs$.unsubscribe();

    this.service
      .getDashboardUrl(id, resourceType)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (metabaseResource: IMetabaseUrl) => {
          const { url } = metabaseResource;
          const unsafeUrl = url ? `${url}#theme=${theme}&bordered=true&titled=true` : '';
          this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeUrl);
          this.setRefreshDashboard(id, resourceType, metabaseResource.minutesToExpire, theme);
        },
        error: () => {
          this.snackBarService.showError('An error occurred while getting the dashboard');
          this.iframeUrl = '';
        },
      });
  }

   /**
   * setRefreshDashboard
   *
   * @param   {number}  id
   * @param   {number}  minutesToExpire
   * @param   {string}  theme
   *
   * @return  {void}
   */
   public setRefreshDashboard(id: number, resourceType: string, minutesToExpire: number, theme: string): void {
    const minutes = minutesToExpire * 60 * 1000;
    const timePercent = 0.95;
    this.intervalSubs$ = interval(minutes * timePercent)
      .pipe(
        takeUntil(this.destroy$),
        switchMap(() => this.service.getDashboardUrl(id, resourceType)),
        map((metabaseResource: IMetabaseUrl) => metabaseResource.url || '')
      )
      .subscribe({
        next: (url: string) => {
          this.iframeUrl = url ? `${url}#theme=${theme}&bordered=true&titled=true` : '';
        },
        error: () => {
          this.snackBarService.showError('An error occurred while refresh the Dashboard');
          this.iframeUrl = '';
        },
      });
  }

  public checkIframeLoaded(): void {
    const iframe: any = document.getElementById('dashboardFrame');
    iframe?.addEventListener('load', () => {
      iframe.height = this.iframeHeight;
      const windowInnerHeight = window.innerHeight;
      for (let i = windowInnerHeight; i >= MIN_DASHBOARD_HEIGHT_ALLOWED; i -= HEIGHT_STEP_CHECK) {
        if (!this._isElementInViewport(iframe)) {
          iframe.height -= HEIGHT_STEP_CHECK;
        }
      }
      this.iFrameLoading = false;
    });
  }

  private _isElementInViewport(el: HTMLElement): boolean {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  /**
   * getOptionText
   *
   * @param   {any}     option
   *
   * @return  {string}
   */
  public getOptionText(option: any): string {
    return option.name;
  }

  }
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

export const TOKEN_KEY = 'idtoken';

@Injectable({
  providedIn: 'root',
})
export class SDAuthService {
  constructor(
    private router: Router,
    private http: HttpClient,
  ) {}

  async login(email: string, password: string) {
    try {
      const user = await Auth.signIn(email, password);
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        await Auth.completeNewPassword(user, password, {});
      }
      const token = user.signInUserSession.idToken.jwtToken;
      window.localStorage.setItem(TOKEN_KEY, token);
      return user;
    } catch (err) {
      return err;
    }
  }

  logOut() {
    Auth.signOut({ global: true })
      .then(() => {
        this.router.navigate(['/login']);
        localStorage.removeItem(TOKEN_KEY);
      })
      .catch((err) => console.log(err));
  }

  isUserAuthenticatedGuard() {
    return new Promise<boolean | UrlTree>((resolve) => {
      Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then((user) => {
          if (user) {
            resolve(true);
          }
        })
        .catch(() => {
          resolve(this.router.createUrlTree(['/login']));
        });
    });
  }

  autoLogin() {
    if (localStorage.getItem(TOKEN_KEY)) this.router.navigate(['/home']);
  }

  async refreshToken() {
    try {
      const user = await Auth.currentSession();
      localStorage.setItem(TOKEN_KEY, user.getIdToken().getJwtToken());
    } catch (e) {
      console.log(e);
      localStorage.removeItem(TOKEN_KEY);
      await this.router.navigate(['/']);
    }
  }

  refreshSessionToken() {
    return from(Auth.currentSession()).pipe(map((user: CognitoUserSession) => user.getIdToken().getJwtToken()));
  }

  getCurrentUserEmail() {
    return this.getCurrentAuthenticatedUser().then((value) => {
      return value.attributes.email;
    });
  }

  sendRecoveryAccountCode(email: string): Observable<any> {
    const url = `${environment.apiBaseURL}/management/password-public-change-start`;
    return this.http.post(url, { username: email }).pipe(map((response) => response));
  }

  updatePassword(attrs: { username: string; password: string; code: string }): Observable<any> {
    const url = `${environment.apiBaseURL}/management/password-public-change-close`;
    return this.http.post(url, attrs).pipe(map((response) => response));
  }

  private async getCurrentAuthenticatedUser() {
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: false,
      });
      return user;
    } catch (err) {
      return err;
    }
  }
}

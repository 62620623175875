<div class="interaction-data">
  <div class="interaction-data__contact">
    <table class="interaction-data__table">
      <ng-container *ngFor="let contact of contactRows">
        <tr>
          <td>{{ contact.title }}</td>
          <ng-container *ngIf="contact?.type === valueType.Url; else plainText">
            <td>
              <a
                [href]="contact.value"
                target="_blank"
                >{{ contact.value }}</a
              >
            </td>
          </ng-container>
          <ng-template #plainText>
            <td>
              {{ contact.value }}
            </td>
          </ng-template>
        </tr>
      </ng-container>
    </table>
  </div>

  <div class="interaction-data__interaction">
    <table class="interaction-data__table">
      <tr *ngFor="let interaction of interactionRows">
        <td>{{ interaction.title }}</td>
        <ng-container *ngIf="interaction?.type === valueType.Url; else plainTextInteraction">
          <td>
            <a
              [href]="interaction.value"
              target="_blank"
              >{{ interaction.value }}</a
            >
          </td>
        </ng-container>
        <ng-template #plainTextInteraction>
          <td>{{ interaction.value }}</td>
        </ng-template>
      </tr>
    </table>
  </div>
</div>

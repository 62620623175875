<div class="modal-header">
  <h2>Assign Operator</h2>
</div>
<div class="modal-content">
  <mat-form-field *ngIf="!data.selectedSdr">
    <mat-select
      placeholder="SDR"
      (selectionChange)="handleSdrChange($event.value)"
    >
      <mat-option
        *ngFor="let sdr of data.sdrs"
        [value]="sdr"
      >
        {{ sdr.user_name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="data.selectedSdr">
    <input
      matInput
      type="text"
      [value]="selectedSdr?.user_name"
      disabled
    />
  </mat-form-field>
  <app-table-actions
    [showSelectAllAction]="true"
    [selectAllActionIcon]="'add'"
    [selectAllActionLabel]="'Assign Operator to SDR'"
    [allItensSelected]="selectedAllOperators$ | async"
    (selectAllEvent)="handleSelectAllEvent($event)"
    (selectAllActionEvent)="handleSelectAllActionEvent()"
    (filterUserInputEvent)="handleOperatorFilterUserInputEvent($event)"
  ></app-table-actions>
  <div class="mat-elevation-z1">
    <mat-table
      [dataSource]="operatorDataSource"
      matSort
    >
      <ng-container matColumnDef="select">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        ></mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          class="checkbox-cell"
        >
          <mat-checkbox
            [checked]="checkIfOperatorIsSelected(element.operator_id)"
            (change)="handleSelect($event.checked, element.operator_id)"
            [disabled]="!checkIfOperatorHasLevel(element.operator_id)"
          ></mat-checkbox>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="operator_name">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="operator-name-cell"
          >Operator Name</mat-header-cell
        >
        <mat-cell
          *matCellDef="let element"
          class="operator-name-cell"
          >{{ element.operator_name }}</mat-cell
        >
      </ng-container>
      <ng-container matColumnDef="client_level">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="client-level-cell"
          >Operator Level</mat-header-cell
        >
        <mat-cell
          *matCellDef="let element"
          class="client-level-cell"
        >
          <mat-form-field>
            <mat-select
              placeholder="Select Level"
              (selectionChange)="setOperatorLevel($event.value, element.operator_id)"
              [value]="getOperatorLevel(element.operator_id)"
            >
              <mat-option
                *ngFor="let level of data.levels"
                [value]="level.id"
              >
                {{ level.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="assigned">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="assigned-cell"
          >Assigned</mat-header-cell
        >
        <mat-cell
          *matCellDef="let element"
          class="assigned-cell"
        >
          {{ element.assigned }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="noData">
        <mat-footer-cell *matNoDataRow>
          {{ operatorDataSource.data || hasError ? 'No data.' : 'Select a SDR.' }}
        </mat-footer-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedOperatorColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedOperatorColumns"></mat-row>
    </mat-table>
    <mat-paginator
      [pageSizeOptions]="[25, 50, 100]"
      (page)="handlePaginatorChange()"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>
<div class="modal-actions">
  <button
    mat-button
    (click)="onDismiss()"
  >
    Close
  </button>
  <button
    mat-button
    color="primary"
    (click)="onConfirm()"
    cdkFocusInitial
    [disabled]="!selectedOperatorIds.length"
  >
    Assign ({{ selectedOperatorIds.length }})
  </button>
</div>

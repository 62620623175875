import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-composer-window-bar',
    templateUrl: './composer-window-bar.component.html',
    styleUrls: ['./composer-window-bar.component.scss'],
    standalone: false
})
export class ComposerWindowBarComponent implements OnInit {
  @Output() closeDialog = new EventEmitter();
  @Output() minimizeDialog = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onMinimizeDialog() {
    this.minimizeDialog.emit(true);
  }

  onCloseDialog() {
    this.closeDialog.emit();
  }
}

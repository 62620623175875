<div class="inquiry-detail">
  <div class="inquiry-detail__section-container">
    <div class="inquiry-detail__section">
      <div class="inquiry-detail__section-title">Details</div>
      <div class="inquiry-detail__section-body">
        <p class="inquiry-detail__row">
          <span class="inquiry-detail__label">ID Lead</span>
          <span class="inquiry-detail__value">{{ lead?.leadId }}</span>
        </p>
        <p class="inquiry-detail__row">
          <span class="inquiry-detail__label">First Name</span>
          <span class="inquiry-detail__value">{{ lead?.firstName }}</span>
        </p>
        <p class="inquiry-detail__row">
          <span class="inquiry-detail__label">Middle Name</span>
          <span class="inquiry-detail__value">{{ lead?.middleName }}</span>
        </p>
        <p class="inquiry-detail__row">
          <span class="inquiry-detail__label">Last Name</span>
          <span class="inquiry-detail__value">{{ lead?.lastName }}</span>
        </p>
        <p class="inquiry-detail__row">
          <span class="inquiry-detail__label">Title</span>
          <span class="inquiry-detail__value">{{ lead?.title }}</span>
        </p>
        <p class="inquiry-detail__row">
          <span class="inquiry-detail__label">Job Responsability</span>
          <span class="inquiry-detail__value">{{ lead?.jobResponsibility }}</span>
        </p>
        <p class="inquiry-detail__row">
          <span class="inquiry-detail__label">Job Department</span>
          <span class="inquiry-detail__value">{{ lead?.jobDepartment }}</span>
        </p>
        <p class="inquiry-detail__row">
          <span class="inquiry-detail__label">Country</span>
          <span class="inquiry-detail__value">{{ lead?.country }}</span>
        </p>
        <p class="inquiry-detail__row">
          <span class="inquiry-detail__label">State</span>
          <span class="inquiry-detail__value">{{ lead?.state }}</span>
        </p>
        <p class="inquiry-detail__row">
          <span class="inquiry-detail__label">City</span>
          <span class="inquiry-detail__value">{{ lead?.city }}</span>
        </p>
        <p class="inquiry-detail__row">
          <span class="inquiry-detail__label">ID Company</span>
          <span class="inquiry-detail__value">{{ lead?.company?.idCompany }}</span>
        </p>
        <p class="inquiry-detail__row">
          <span class="inquiry-detail__label">Company Name</span>
          <span class="inquiry-detail__value">{{ lead?.company?.name }}</span>
        </p>
        <p class="inquiry-detail__row">
          <span class="inquiry-detail__label">LinkedIn URL</span>
          <span class="inquiry-detail__value">{{ lead?.company?.linkedInUrl }}</span>
        </p>
        <p class="inquiry-detail__row">
          <span class="inquiry-detail__label">LinkedIn Company ID</span>
          <span class="inquiry-detail__value">{{ lead?.company?.linkedinCompanyId }}</span>
        </p>
        <p class="inquiry-detail__row">
          <span class="inquiry-detail__label">Technologies</span>
          <span class="inquiry-detail__value">{{ lead?.company?.technologies }}</span>
        </p>
        <p class="inquiry-detail__row">
          <span class="inquiry-detail__label">Industry</span>
          <span class="inquiry-detail__value">{{ lead?.company?.industry }}</span>
        </p>
        <p class="inquiry-detail__row">
          <span class="inquiry-detail__label">Size</span>
          <span class="inquiry-detail__value">{{ lead?.company?.size }}</span>
        </p>
      </div>
    </div>
  </div>

  <div class="inquiry-detail__section-container">
    <div class="inquiry-detail__section">
      <div class="inquiry-detail__section-title"></div>
      <div class="inquiry-detail__section-body">
        <p class="inquiry-detail__row">
          <span class="inquiry-detail__label">Email Address</span>
          <span class="inquiry-detail__value">{{ lead?.emailAddress }}</span>
        </p>
        <p class="inquiry-detail__row">
          <span class="inquiry-detail__label">LinkedIn URL Gen</span>
          <span class="inquiry-detail__value">{{ lead?.linkedInUrlGen }}</span>
        </p>
        <p class="inquiry-detail__row">
          <span class="inquiry-detail__label">Email Strategy</span>
          <span class="inquiry-detail__value">{{ lead?.emailStrategy }}</span>
        </p>
        <p class="inquiry-detail__row">
          <span class="inquiry-detail__label">Date Created</span>
          <span class="inquiry-detail__value">{{ lead?.dateCreated }}</span>
        </p>
        <p class="inquiry-detail__row">
          <span class="inquiry-detail__label">Date Updated</span>
          <span class="inquiry-detail__value">{{ lead?.dateUpdated }}</span>
        </p>
        <p class="inquiry-detail__row">
          <span class="inquiry-detail__label">Pattern Id</span>
          <span class="inquiry-detail__value">{{ lead?.patternId }}</span>
        </p>
        <p class="inquiry-detail__row">
          <span class="inquiry-detail__label">Email Status ID</span>
          <span class="inquiry-detail__value">{{ lead?.emailStatus?.id }}</span>
        </p>
        <p class="inquiry-detail__row">
          <span class="inquiry-detail__label">Contact Type ID</span>
          <span class="inquiry-detail__value">{{ lead?.contactTypeId }}</span>
        </p>
        <p class="inquiry-detail__row">
          <span class="inquiry-detail__label">Phone</span>
          <span class="inquiry-detail__value">{{ lead?.phone }}</span>
        </p>
      </div>
    </div>

    <div class="inquiry-detail__section">
      <div class="inquiry-detail__section-title"></div>
      <div class="inquiry-detail__section-body">
        <p class="inquiry-detail__row">
          <span class="inquiry-detail__label">Quantity Employees on LinkedIn</span>
          <span class="inquiry-detail__value">{{ lead?.company?.employeeCount }}</span>
        </p>
        <p class="inquiry-detail__row">
          <span class="inquiry-detail__label">Domain</span>
          <span class="inquiry-detail__value">{{ lead?.company?.domain }}</span>
        </p>
        <p class="inquiry-detail__row">
          <span class="inquiry-detail__label">Type</span>
          <span class="inquiry-detail__value">{{ lead?.company?.type }}</span>
        </p>
        <p class="inquiry-detail__row">
          <span class="inquiry-detail__label">Website</span>
          <span class="inquiry-detail__value">{{ lead?.company?.website }}</span>
        </p>
        <p class="inquiry-detail__row">
          <span class="inquiry-detail__label">Specialties</span>
          <span class="inquiry-detail__value">{{ lead?.company?.specialties }}</span>
        </p>
        <p class="inquiry-detail__row">
          <span class="inquiry-detail__label">Year Founded</span>
          <span class="inquiry-detail__value">{{ lead?.company?.yearFounded }}</span>
        </p>
      </div>
    </div>
  </div>
</div>

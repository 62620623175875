import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DatepickerDialogData } from '../../model/datepicker-dialog/datepicker-dialog-data.model';

@Component({
  selector: 'app-datepicker-dialog',
  templateUrl: './datepicker-dialog.component.html',
  styleUrls: ['./datepicker-dialog.component.scss'],
})
export class DatepickerDialogComponent {
  dateCtrl = new FormControl('');

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DatepickerDialogData,
    public dialog: MatDialogRef<DatepickerDialogComponent>,
  ) {
    if (data.required) {
      this.dateCtrl.setValidators(Validators.required);
      this.dateCtrl.updateValueAndValidity();
    }
  }

  onDismiss(): void {
    this.dialog.close(false);
  }
  onConfirm(): void {
    this.dialog.close(this.dateCtrl.value);
  }

  dateFilter = (date: Date | null): boolean => {
    const today = new Date();
    return date ? date > today : false;
  };
}

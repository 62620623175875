<div
  class="contact-detail"
  *ngIf="!isSearching && lead"
>
  <div class="contact-detail__section">
    <div class="contact-detail__section-title">Details</div>
    <div>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">ID Lead</span>
        <span class="contact-detail__value">{{ lead?.idLead }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">First Name</span>
        <span class="contact-detail__value">{{ lead?.firstName }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">Middle Name</span>
        <span class="contact-detail__value">{{ lead?.middleName }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">Last Name</span>
        <span class="contact-detail__value">{{ lead?.lastName }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">Title</span>
        <span class="contact-detail__value">{{ lead?.jobTitle }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">Job Responsability</span>
        <span class="contact-detail__value">{{ lead?.jobTitleResponsibility }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">Job Department</span>
        <span class="contact-detail__value">{{ lead?.jobTitleDepartment }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">Country</span>
        <span class="contact-detail__value">{{ lead?.country }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">State</span>
        <span class="contact-detail__value">{{ lead?.adminArea2 }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">City</span>
        <span class="contact-detail__value">{{ lead?.adminArea1 }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">ID Company</span>
        <span class="contact-detail__value">{{ lead?.lnCompanyId }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">Company Name</span>
        <span class="contact-detail__value">{{ lead?.company?.companyName }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">LinkedIn URL</span>
        <span class="contact-detail__value">{{ lead?.company?.linkedInUrl }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">LinkedIn Company ID</span>
        <span class="contact-detail__value">{{ lead?.company?.linkedInId }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">Technologies</span>
        <span class="contact-detail__value">{{ lead?.technologies }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">Industry</span>
        <span class="contact-detail__value">{{ lead?.company?.industry }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">Size</span>
        <span class="contact-detail__value">{{ lead?.company?.companySize }}</span>
      </p>
    </div>
  </div>

  <div class="contact-detail__section">
    <div>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">Email Address</span>
        <span class="contact-detail__value">{{ lead?.emailAddress }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">LinkedIn URL Gen</span>
        <span class="contact-detail__value">{{ lead?.linkedInUrl }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">Email Strategy</span>
        <span class="contact-detail__value">{{ lead?.emailStrategy }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">Date Created</span>
        <span class="contact-detail__value">{{ lead?.dateCreated }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">Date Updated</span>
        <span class="contact-detail__value">{{ lead?.dateUpdated }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">Pattern Id</span>
        <span class="contact-detail__value">{{ lead?.patternId }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">Email Status ID</span>
        <span class="contact-detail__value">{{ lead?.idEmailStatus }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">Contact Type ID</span>
        <span class="contact-detail__value">{{ lead?.contactTypeId }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">Phone</span>
        <span class="contact-detail__value">{{ lead?.phones }}</span>
      </p>
    </div>
    <div>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">Quantity Employees on LinkedIn</span>
        <span class="contact-detail__value">{{ lead?.qEmployeesOnLinkedIn }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">Domain</span>
        <span class="contact-detail__value">{{ lead?.company?.domain }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">Type</span>
        <span class="contact-detail__value">{{ lead?.company?.companyType }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">Website</span>
        <span class="contact-detail__value">{{ lead?.company?.website }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">Specialties</span>
        <span class="contact-detail__value">{{ lead?.company?.specialties }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">Year Founded</span>
        <span class="contact-detail__value">{{ lead?.company?.yearFounded }}</span>
      </p>
    </div>
  </div>
</div>
<app-inquiry-history
  *ngIf="lead"
  [historyData]="historyData"
  (setSelectedHistoryRow)="onSelectHistoryRow($event)"
></app-inquiry-history>
<ngx-skeleton-loader
  *ngIf="isSearching"
  count="12"
  appearance="line"
/>

<div
  class="classifier-body"
  id="emailColumn"
>
  <app-mail-classifier-details-toolbar></app-mail-classifier-details-toolbar>
  <div class="classifier-body__iframe-container">
    <iframe
      class="classifier-body__iframe"
      #iframe
      id="emailIframe"
      frameborder="0"
    ></iframe>

    <div
      class="classifier-body__not-found"
      *ngIf="!currentEmail"
    >
      <span *ngIf="!loading">Message not found</span>
    </div>
  </div>
</div>

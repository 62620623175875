/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './screens/home/home.component';
import { LoginComponent } from './screens/login/login.component';
import { ResetPasswordComponent } from './screens/login/reset-password/reset-password.component';
import { MailManagerComponent } from './screens/home/mail-manager/mail-manager.component';
import { UserListComponent } from './screens/management/user-management/user-list/user-list.component';
import { AddressSuggestionManagementComponent } from './screens/management/address-management/address-suggestion-management/address-suggestion-management.component';
import { UserDetailsComponent } from './screens/management/user-management/user-details/user-details.component';
import { UserCreateComponent } from './screens/management/user-management/user-create/user-create.component';
import { AuthGuard } from './guards/auth-guard.service';
import { AdminGuard } from './guards/admin-guard.service';
import { DiscrepanciesGuard } from './guards/discrepancies-guard.service';
import { SdrManagementComponent } from './screens/management/sdr-management/sdr-management.component';
import { EmailTemplateManagementComponent } from './screens/management/email-templates/email-template-management/email-template-management.component';
import { EmailTemplateEditorComponent } from './screens/management/email-templates/email-template-editor/email-template-editor.component';
import { ManagementComponent } from './screens/management/management.component';
import { DiscrepanciesComponent } from './screens/discrepancies/discrepancies.component';
import { ClientContactsListComponent } from './screens/management/client-contacts/client-contacts-list/client-contacts-list.component';
import { BotSetupComponent } from './screens/management/bot-setup/bot-setup.component';
import { ClientContactsEditComponent } from './screens/management/client-contacts/client-contacts-edit/client-contacts-edit.component';
import { FaqManagerListComponent } from './screens/management/faq-manager/faq-manager-list/faq-manager-list.component';
import { FaqManagerEditorComponent } from './screens/management/faq-manager/faq-manager-editor/faq-manager-editor.component';
import { ClientManagementContainerComponent } from './screens/management/client-management/client-management-container.component';
import { ReassignOperatorContainerComponent } from './screens/management/reassign-operator/reassign-operator-container.component';
import { OperatorUserManagementContainerComponent } from './screens/management/operator-user-management/operator-user-container.component';
import { OperatorUserAddComponent } from './screens/management/operator-user-management/operator-user-add/operator-user-add.component';
import { OperatorUserEditComponent } from './screens/management/operator-user-management/operator-user-edit/operator-user-edit.component';
import { SdrsManagementContainerComponent } from './screens/management/sdrs-management/sdrs-management-container.component';
import { MailReviewComponent } from './screens/mail-review/mail-review.component';
import { SdrConversationsComponent } from './screens/sdr-conversations/sdr-conversations.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'mailManager', pathMatch: 'full' },
      { path: 'mailManager', component: MailManagerComponent },
      { path: 'mail-review', component: MailReviewComponent },
      { path: 'sdr-conversations', component: SdrConversationsComponent },
      {
        path: 'management',
        component: ManagementComponent,
        children: [
          { path: '', redirectTo: 'users', pathMatch: 'full' },
          {
            path: 'operator-users',
            canActivate: [AdminGuard],
            children: [
              { path: '', component: OperatorUserManagementContainerComponent, canActivate: [AdminGuard] },
              { path: 'add', component: OperatorUserAddComponent, canActivate: [AdminGuard] },
              { path: 'edit/:email', component: OperatorUserEditComponent, canActivate: [AdminGuard] },
              { path: '**', redirectTo: '', pathMatch: 'full' },
            ],
          },
          { path: 'users', component: UserListComponent, canActivate: [AdminGuard] },
          { path: 'users/new', component: UserCreateComponent, canActivate: [AdminGuard] },
          { path: 'users/:userEmail', component: UserDetailsComponent, canActivate: [AdminGuard] },
          { path: 'client', component: ClientManagementContainerComponent, canActivate: [AdminGuard] },
          { path: 'reassign-operator', component: ReassignOperatorContainerComponent, canActivate: [AdminGuard] },
          { path: 'addresses', component: AddressSuggestionManagementComponent, canActivate: [AdminGuard] },
          { path: 'sdr-manage', component: SdrManagementComponent, canActivate: [AdminGuard] },
          { path: 'templates/manage', component: EmailTemplateManagementComponent, canActivate: [AdminGuard] },
          { path: 'templates/edit', component: EmailTemplateEditorComponent, canActivate: [AdminGuard] },
          { path: 'contacts/contact-manage', component: ClientContactsListComponent, canActivate: [AdminGuard] },
          { path: 'botsetup', component: BotSetupComponent, canActivate: [AdminGuard] },
          { path: 'contacts/contact-edit', component: ClientContactsEditComponent, canActivate: [AdminGuard] },
          { path: 'faq/faq-manage', component: FaqManagerListComponent, canActivate: [AdminGuard] },
          { path: 'faq/faq-edit', component: FaqManagerEditorComponent, canActivate: [AdminGuard] },
          { path: 'sdrs', component: SdrsManagementContainerComponent, canActivate: [AdminGuard] },
        ],
      },
      {
        path: 'discrepancies',
        canActivate: [DiscrepanciesGuard],
        component: DiscrepanciesComponent,
      },
    ],
  },
  { path: 'login', component: LoginComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', redirectTo: '/login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}

<h1 mat-dialog-title>{{title}}
    <button mat-icon-button mat-dialog-close class="close-button">
        <mat-icon>close</mat-icon>
    </button>
</h1>
<div mat-dialog-content>
    <p [innerHtml]="message"></p>
    
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onConfirm();" cdkFocusInitial>Accept</button>
</div>
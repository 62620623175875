<div
  fxLayout="row"
  fxLayoutAlign="space-between start"
  class="email-toolbar-wrapper"
>
  <div
    fxLayout="column"
    fxFlex="100%"
    *ngIf="currentEmail"
  >
    <div
      fxLayout="row wrap"
      fxLayoutAlign="space-between center"
      class="email-heading-wrapper"
    >
      <div
        fxFlex="50%"
        fxFlex.lt-lg="75"
        class="email-title"
      >
        <span
          [matTooltip]="currentEmail.subject"
          matTooltipPosition="below"
          >{{ currentEmail.subject }}</span
        >
        <mat-icon
          *ngIf="currentEmail.sendStatus === 'Error' && selectedFolder === 'Sent'"
          class="material-icons-outlined email-title-icon"
          [matTooltip]="currentEmail.errorMessage"
          matTooltipPosition="right"
        >
          announcement
        </mat-icon>
      </div>

      <div
        fxLayoutAlign="end end"
        [ngClass.lt-md]="'margin-bottom'"
        fxHide.lt-lg
      >
        <div
          class="toolbar-actions"
          fxLayout="row"
          fxLayoutAlign="end end"
          style="color: #4b4b4b"
        >
          <button
            mat-stroked-button
            *ngIf="selectedFolder !== 'Sent'"
            (click)="openPlainText()"
            matTooltip="Open Plain Text"
            id="open-plain-btn"
          >
            <mat-icon>description</mat-icon>
          </button>
          <button
            mat-stroked-button
            *ngIf="selectedFolder !== 'Sales Operations'"
            (click)="openFaq()"
            matTooltip="Open Faq"
            id="open-faq-btn"
          >
            <mat-icon>help</mat-icon>
          </button>
          <button
            mat-stroked-button
            *ngIf="hasInquiryPermission"
            (click)="openInquiry()"
            matTooltip="Open Inquiry"
            id="open-inquiry-btn"
          >
            <mat-icon>person_search</mat-icon>
          </button>
          <button
            mat-stroked-button
            *ngIf="currentEmail"
            (click)="copyDebug()"
            matTooltip="Clipboard Debug"
            id="copy-debug-btn"
          >
            <mat-icon>bug_report</mat-icon>
          </button>
          <!-- <button mat-stroked-button *ngIf="currentEmail && isSender" (click)="compose('reply')" matTooltip="Reply" [disabled]="isComposerOpen"><mat-icon>reply</mat-icon></button> -->
          <button
            mat-stroked-button
            *ngIf="currentEmail && isSender"
            (click)="compose('replyAttachment')"
            matTooltip="Reply All"
            [disabled]="isComposerOpen"
            id="reply-all-btn"
          >
            <mat-icon>reply_all</mat-icon>
          </button>
          <button
            mat-stroked-button
            *ngIf="currentEmail && isSender"
            (click)="compose('forward')"
            matTooltip="Forward"
            [disabled]="isComposerOpen"
            id="forward-btn"
          >
            <mat-icon>forward</mat-icon>
          </button>
          <button
            mat-stroked-button
            *ngIf="currentEmail && isSender && selectedFolder === 'Sent'"
            (click)="compose('resend')"
            matTooltip="Resend"
            [disabled]="isComposerOpen"
            id="resend-btn"
          >
            <mat-icon>repeat</mat-icon>
          </button>
          <button
            mat-stroked-button
            *ngIf="currentEmail && mailTo.length > 0"
            (click)="openMailTo()"
            matTooltip="Inspect Mailto"
            id="inspect-mail-to-btn"
          >
            <mat-icon>remove_red_eye</mat-icon>
          </button>
          <button
            mat-stroked-button
            *ngIf="isTrashEnabled"
            (click)="selectedMailService.triggerClassifyMail(trashOption)"
            matTooltip="Move to Trash"
            id="move-to-trash-btn"
          >
            <mat-icon>delete</mat-icon>
          </button>
          <!-- <button mat-stroked-button *ngIf="currentEmail" (click)="moveToTrash()" matTooltip="Move to Trash"><mat-icon>delete</mat-icon></button> -->
        </div>
      </div>

      <div
        fxLayoutAlign="end end"
        [ngClass.lt-md]="'margin-bottom'"
        fxHide.gt-md
      >
        <button
          mat-icon-button
          [matMenuTriggerFor]="menu"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button
            mat-menu-item
            *ngIf="selectedFolder !== 'Sales Operations'"
            (click)="openFaq()"
            matTooltip="Open Faq"
            id="open-faq-btn"
          >
            <mat-icon>help</mat-icon> Open Faq
          </button>
          <button
            mat-menu-item
            *ngIf="hasInquiryPermission"
            (click)="openInquiry()"
            matTooltip="Open Inquiry"
            id="open-inquiry-btn"
          >
            <mat-icon>person_search</mat-icon> Open Inquiry
          </button>
          <button
            mat-menu-item
            *ngIf="currentEmail"
            (click)="copyDebug()"
            matTooltip="Clipboard Debug"
            id="copy-debug-btn"
          >
            <mat-icon>bug_report</mat-icon>
            Clipboard Debug
          </button>
          <!-- <button mat-menu-item *ngIf="currentEmail && isSender" (click)="compose('reply')" matTooltip="Reply" [disabled]="isComposerOpen">
            <mat-icon>reply</mat-icon>
            Reply
          </button> -->
          <button
            mat-menu-item
            *ngIf="currentEmail && isSender"
            (click)="compose('replyAttachment')"
            matTooltip="Reply All"
            [disabled]="isComposerOpen"
            id="reply-all-btn"
          >
            <mat-icon>reply_all</mat-icon>
            Reply All
          </button>
          <button
            mat-menu-item
            *ngIf="currentEmail && isSender"
            (click)="compose('forward')"
            matTooltip="Forward"
            [disabled]="isComposerOpen"
            id="forward-btn"
          >
            <mat-icon>forward</mat-icon>
            Forward
          </button>
          <button
            mat-menu-item
            *ngIf="currentEmail && isSender && selectedFolder === 'Sent'"
            (click)="compose('resend')"
            matTooltip="Resend"
            [disabled]="isComposerOpen"
            id="resend-btn"
          >
            <mat-icon>repeat</mat-icon>
            Resend
          </button>
          <button
            mat-menu-item
            *ngIf="currentEmail && mailTo.length > 0"
            (click)="openMailTo()"
            matTooltip="Inspect Mailto"
            id="inspect-mail-to-btn"
          >
            <mat-icon>remove_red_eye</mat-icon>
            Inspect Mailto
          </button>
          <button
            mat-menu-item
            *ngIf="currentEmail"
            (click)="selectedMailService.triggerClassifyMail(trashOption)"
            matTooltip="Move to Trash"
            id="move-to-trash-btn"
          >
            <mat-icon>delete</mat-icon>
            Delete
          </button>
        </mat-menu>
      </div>
    </div>

    <div>
      <app-mail-sender-recipients-details [email]="currentEmail"></app-mail-sender-recipients-details>
    </div>

    <div
      *ngIf="currentEmail.hasAttachments"
      fxLayout="column"
    >
      <div
        fxFlex="row"
        fxFlexFill
        fxLayoutAlign="start start"
        id="readAttachmentsDiv"
      >
        <div
          *ngFor="let attachment of currentEmail.attachments"
          fxLayoutAlign="start center"
        >
          <app-attachment
            [attachment]="attachment"
            (clickEvt)="
              currentEmail.folder === 'Sent'
                ? messageAttachmentService.downloadRemoteFileSent(
                    attachment.key,
                    attachment.filename,
                    attachment.isSafe
                  )
                : messageAttachmentService.downloadRemoteFile(attachment.filename, attachment.isSafe)
            "
          ></app-attachment>
        </div>
      </div>
    </div>
  </div>
</div>

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { delay, finalize } from 'rxjs/operators';
import { UIGuid } from '../helpers/ui-guid';
import { ResponseStatus } from '../model/common/generic-response';
import { ServiceInfo } from '../model/common/service-info.model';
import { Email } from '../model/Email';
import { ClassificationStatusData } from '../model/mail-classifier/classification-status-data.model';
import { ClassifyReviewEmailDto } from '../model/mail-classifier/classify-review-email.dto';
import { MessageToClassifyDto } from '../model/mail-classifier/message-to-classify.dto';
import { ProcessConversionDto } from '../model/mail-classifier/process-conversion.dto';
import { UpdateConversionDto } from '../model/mail-classifier/update-conversion.dto';
import { Notification } from '../model/Notification';
import { NotificationCategory } from '../model/NotificationCategory';
import { NotificationDismissType } from '../model/NotificationDismissType';
import { NotificationType } from '../model/NotificationType';
import { NotificationsService } from '../services/notifications.service';
import { ActionService } from './action.service';
import { LoadingStateService } from './base/loading-state.service';
import { FeedService } from './feed.service';
import { HelperService } from './helper.service';
import { InteractionService } from './interaction.service';
import { LogService } from './log.service';
import { MailReviewService } from './mail-review.service';
import { SDAuthService } from './sd-auth.service';
import { SnackBarService } from './snackbar/snackbar.service';

const UNDO_STANDBY_TIME = 10 * 1000;

@Injectable({
  providedIn: 'root',
})
export class MailReviewClassifyService {
  classification: ClassificationStatusData;
  private userEmail = '';
  private currentMail: Email;

  constructor(
    private helperService: HelperService,
    private loadingStateService: LoadingStateService,
    private feedService: FeedService,
    private interactionService: InteractionService,
    private mailReviewService: MailReviewService,
    private notificationsService: NotificationsService,
    private actionsService: ActionService,
    private authService: SDAuthService,
    private logService: LogService,
    private snackBarService: SnackBarService,
  ) {
    this.mailReviewService.currentClassifierEmail$.subscribe((email: Email) => {
      this.currentMail = email;
    });

    this.authService.getCurrentUserEmail().then((email) => {
      this.userEmail = email;
    });
  }

  /**
   * @param serviceInfo - properties must match with service instance name and the name of the method in the service
   */
  classifyMail(
    serviceInfo: ServiceInfo,
    dto: MessageToClassifyDto | ClassifyReviewEmailDto | ProcessConversionDto | UpdateConversionDto,
  ) {
    const { classificationId, classificationName } = this.classification;
    const email = this.currentMail;

    if (email && classificationName) {
      this.feedService.registerLastClassified([email.storageId]);
      const actionIdentifier =
        email.storageId + '_classify_' + UIGuid.newGuid() + '_' + new Date().getTime().toString();

      let successClassifyClipboardInfo = `
        type: Success
        time: ${this.helperService.formatAMPM(new Date())}
        message: Mail classified as ${classificationName}
        subject: ${email.subject}
        sdr: ${email.sdr}
        targetFolder: ${classificationName}
        storageId: ${email.storageId}
        messageId: ${email.messageId}
      `;

      let successClassifyNotification = new Notification({
        id: 'successClassify_' + classificationId + '_' + email.storageId,
        type: NotificationType.Success,
        category: NotificationCategory.Classify,
        created_dt: new Date().toString(),
        message: `Mail classified as <span style="font-weight: bold;color:black;"> ${classificationName}</span>`,
        placeholderInfo1: email.subject,
        placeholderInfo2: email.sdr,
        undoSendAction: false,
        undoActionReady: false,
        undoColor: 'black',
        undoClassifyAction: true,
        debugAction: true,
        debugClipboardInfo: successClassifyClipboardInfo,
        undoClassifySnackbarMessage: 'Classify action on ' + classificationName + ' reverted successfully',
        undoSendSnackbarMessage: '',
        undoClassifyActionObject: { actionId: classificationId },
        dismissType: NotificationDismissType.AutoDismiss,
        actionIdentifier,
      });

      this.notificationsService.addNotificationToFeed(successClassifyNotification);

      this.actionsService.addActionItem(actionIdentifier, {});

      this.logService.submitLog({
        tags: [
          'ActionStarted',
          'Classify',
          successClassifyNotification.id,
          successClassifyNotification.placeholderInfo1,
          successClassifyNotification.placeholderInfo2,
        ],
        body: 'ActionStarted - ' + successClassifyNotification.message,
      });

      of(true)
        .pipe(delay(UNDO_STANDBY_TIME))
        .subscribe(() => {
          if (!this.actionsService.isUndone(actionIdentifier)) {
            console.log('Classifying Email: ', { emailId: email.messageId, classificationId: classificationId });
            this.loadingStateService.setLoadingState(true);

            this[serviceInfo.serviceInstance]
              [serviceInfo.functionName](dto)
              .pipe(finalize(() => this.loadingStateService.setLoadingState(false)))
              .subscribe({
                next: (response) => {
                  if (response.status === ResponseStatus.Error) {
                    const message = response?.message || 'An error occurred while trying to classify the message';
                    this.handledErrorNotification(message);
                    return;
                  }
                  this.snackBarService.showSuccess(response?.message || 'Mail classified successfully');
                  this.interactionService.setInteractionsUpdated(true);

                  this.logService.submitLog({
                    tags: [
                      'ActionCompleted',
                      'Classify',
                      successClassifyNotification.id,
                      successClassifyNotification.placeholderInfo1,
                      successClassifyNotification.placeholderInfo2,
                    ],
                    body: 'ActionCompleted - ' + successClassifyNotification.message,
                  });
                },
                error: (error: HttpErrorResponse) => {
                  this.unhandledErrorNotification(error);
                },
              });
          }
          setTimeout(() => {
            this.feedService.deleteFromLastClassified(email.storageId);
          }, 15000);

          this.actionsService.removeActionItem(actionIdentifier);
        });
    }
  }

  unhandledErrorNotification(error: HttpErrorResponse) {
    const { classificationName } = this.classification;
    const { subject, sdr, storageId, messageId } = this.currentMail;
    const errorMessage = error.error.Message || 'An unhandled error has occurred while trying to classify';
    let typeOfTheNotification = NotificationType.Error;
    let errorClassifyClipboardInfo = `
    type: Error
    time: ${this.helperService.formatAMPM(new Date())}
    message: Error classifying message as ${classificationName} ${errorMessage}
    subject: ${subject}
    sdr: ${sdr}
    targetFolder: ${classificationName}
    storageId: ${storageId}
    messageId: ${messageId}
    currentUser: ${this.userEmail}
    exceptionDetail: ${JSON.stringify(error?.error)}
  `;

    if (error?.status === 405) {
      typeOfTheNotification = NotificationType.Warning;
    }

    this.showErrroNotification(errorMessage, errorClassifyClipboardInfo, typeOfTheNotification);
  }

  handledErrorNotification(errorMessage: string) {
    const { classificationName } = this.classification;
    const { subject, sdr, storageId, messageId } = this.currentMail;
    let errorClassifyClipboardInfo = `
    type: Error
    time: ${this.helperService.formatAMPM(new Date())}
    message: Error classifying message as ${classificationName} ${errorMessage}
    subject: ${subject}
    sdr: ${sdr}
    targetFolder: ${classificationName}
    storageId: ${storageId}
    messageId: ${messageId}
    currentUser: ${this.userEmail}
  `;

    this.showErrroNotification(errorMessage, errorClassifyClipboardInfo, NotificationType.Error);
  }

  showErrroNotification(errorMessage: string, errorClassifyClipboardInfo, notificationType: NotificationType) {
    const { classificationId, classificationName } = this.classification;
    const { subject, sdr, storageId } = this.currentMail;

    let errorClassifyNotification = new Notification({
      id: 'errorClassify_' + classificationId + '_' + storageId,
      type: notificationType,
      category: NotificationCategory.Classify,
      created_dt: new Date().toString(),
      message: `Error classifying message as <span style="font-weight: bold;">${classificationName}</span> ${errorMessage}`,
      placeholderInfo1: subject,
      placeholderInfo2: sdr,
      undoSendAction: false,
      undoActionReady: true,
      undoSendReady: false,
      undoColor: 'black',
      undoClassifyAction: false,
      debugAction: true,
      debugClipboardInfo: errorClassifyClipboardInfo,
      undoClassifySnackbarMessage: '',
      undoSendSnackbarMessage: '',
      undoClassifyActionObject: null,
      dismissType: NotificationDismissType.NoDismissable,
    });
    this.notificationsService.addNotificationToFeed(errorClassifyNotification);
  }
}

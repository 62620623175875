import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ComposeEmail } from 'src/app/model/ComposeEmail';
import { ComposerMailService } from 'src/app/services/composer-mail.service';

@Component({
    selector: 'app-composer-min-window-bar',
    templateUrl: './composer-min-window-bar.component.html',
    styleUrls: ['./composer-min-window-bar.component.scss'],
    standalone: false
})
export class ComposerMinWindowBarComponent implements OnInit {
  @Output() closeDialog = new EventEmitter();
  @Output() toggleMinimize = new EventEmitter();
  @Output() expandDialog = new EventEmitter();
  @Input() minimized: boolean;
  currentComposedEmail: ComposeEmail;

  constructor(private composerMailService: ComposerMailService) {}

  ngOnInit() {
    this.composerMailService.composedEmail.subscribe((composedEmail) => {
      if (composedEmail) {
        this.currentComposedEmail = composedEmail;
      }
    });
  }

  onToggleMinimize() {
    if (this.minimized) {
      // Bug:When restoring editor dissapears...
      this.currentComposedEmail.currentEdition = (window as any).editorHtml;
      this.composerMailService.setComposedEmail(this.currentComposedEmail);
    }
    this.toggleMinimize.emit();
  }

  onExpandDialog() {
    this.expandDialog.emit();
  }

  onCloseDialog() {
    this.closeDialog.emit();
  }
}

<div
  *ngFor="let conversation of conversations; let i = index"
  class="conversation"
  [ngClass]="{ 'conversation--selected': selectedConversation?.conversationId === conversation?.conversationId }"
  id="conversation-{{ i + 1 }}"
  (click)="selectConversation(conversation)"
>
  <div class="conversation__info">
    <span class="conversation__participant-name">{{ conversation.participantsName[0] || 'Unknown' }}</span>
    <span
      class="conversation__type"
      [ngSwitch]="conversation?.conversationType"
    >
      <span *ngSwitchCase="'DIRECT_MESSAGE'">Direct message</span>
      <span *ngSwitchCase="'GROUP_CHAT'">Group chat</span>
      <span *ngSwitchCase="'IN_MAIL'">In mail</span>
      <span *ngSwitchDefault>{{ conversation?.conversationType | titlecase }}</span>
    </span>
  </div>
</div>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClassifierMessage } from '../../../../model/mail-classifier/classifier-message.model';

@Component({
  selector: 'app-mail-classifier-log',
  templateUrl: './mail-classifier-log.component.html',
  styleUrls: ['./mail-classifier-log.component.scss'],
})
export class MailClassifierLogComponent {
  @Input() message: ClassifierMessage;
  @Output() completeCase: EventEmitter<null> = new EventEmitter();
  @Output() emailClicked: EventEmitter<string> = new EventEmitter();
  constructor() {}

  onComplete() {
    this.completeCase.emit();
  }

  onClickEmail(email: string) {
    this.emailClicked.emit(email);
  }
}

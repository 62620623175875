import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfigurationService } from '../../../services/configuration.service';

@Component({
  selector: 'app-complete-case-dialog',
  templateUrl: './complete-case-dialog.component.html',
  styleUrls: ['./complete-case-dialog.component.scss'],
})
export class CompleteCaseDialogComponent implements OnInit, OnDestroy {
  classifictionStatuses: any[] = [];
  private destroy$ = new Subject<void>();

  constructor(
    private configurationService: ConfigurationService,
    private dialog: MatDialogRef<CompleteCaseDialogComponent>,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.configurationService
      .getClassificationMockStatuses()
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.classifictionStatuses = response;
      });
  }

  onCompleteCase(ruleId: number): void {
    this.dialog.close(ruleId);
  }
}

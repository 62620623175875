import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, inject, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ClassificationStatusEnum } from '../../../model/common/classification-status.enum';
import { ClassificationStatus } from '../../../model/common/classification-status.model';
import { Contact } from '../../../model/common/contact.model';
import { ConvertedDialogData } from '../../../model/common/converted-dialog-data.model';
import { ForwardedInteractionDialogData } from '../../../model/interaction/forwarded-interaction-dialog-data.model';
import { FunctionName } from '../../../model/common/function-name.enum';
import { Interaction } from '../../../model/common/interaction.model';
import { ServiceInfo } from '../../../model/common/service-info.model';
import { ServiceInstance } from '../../../model/common/service-instance.enum';
import { ClassificationStatusData } from '../../../model/mail-classifier/classification-status-data.model';
import { ClassifierMessage } from '../../../model/mail-classifier/classifier-message.model';
import { ClassifyReviewEmailDto } from '../../../model/mail-classifier/classify-review-email.dto';
import { MessageToClassifyDto } from '../../../model/mail-classifier/message-to-classify.dto';
import { ConfigurationService } from '../../../services/configuration.service';
import { MailReviewClassifyService } from '../../../services/mail-review-classify.service';
import { MailReviewService } from '../../../services/mail-review.service';
import { SnackBarService } from '../../../services/snackbar/snackbar.service';
import { BREAKPOINT_1280, BREAKPOINT_1450, BREAKPOINT_1650 } from '../../../utils/constants';
import { ConvertedDialogComponent } from '../../shared/converted-dialog/converted-dialog.component';
import { ForwardedInteractionDialogComponent } from '../../shared/forwarded-interaction-dialog/forwarded-interaction-dialog.component';

@Component({
  selector: 'app-mail-classification',
  templateUrl: './mail-classification.component.html',
  styleUrls: ['./mail-classification.component.scss'],
})
export class MailClassificationComponent implements OnInit, OnDestroy {
  @ViewChild('overlayContent') overlayContent!: TemplateRef<any>;
  @ViewChild('toggleButton', { read: ViewContainerRef }) private buttonRef!: ViewContainerRef;
  statuses: ClassificationStatus[] = [];
  classificationStatus = ClassificationStatusEnum;
  subscriptions = new Subscription();
  selectedInteraction: Interaction = null;
  currentContact: Contact = null;
  selectedMessage: ClassifierMessage = null;
  statusesToShow = 6;
  breakPointsNameMap = new Map([
    [Breakpoints.XSmall, 'XSmall'],
    [Breakpoints.Small, 'Small'],
    [Breakpoints.Medium, 'Medium'],
    [BREAKPOINT_1280, 'XSLarge'],
    [BREAKPOINT_1450, 'MLarge'],
    [BREAKPOINT_1650, 'Large'],
    [Breakpoints.XLarge, 'XLarge'],
  ]);
  breakpointValueMap = new Map([
    [this.breakPointsNameMap.get(Breakpoints.XSmall), 1],
    [this.breakPointsNameMap.get(Breakpoints.Small), 3],
    [this.breakPointsNameMap.get(Breakpoints.Medium), 6],
    [this.breakPointsNameMap.get(BREAKPOINT_1280), 3],
    [this.breakPointsNameMap.get(BREAKPOINT_1450), 4],
    [this.breakPointsNameMap.get(BREAKPOINT_1650), 6],
    [this.breakPointsNameMap.get(Breakpoints.XLarge), 7],
  ]);
  currentScreenSize: string;
  convertedInteraction: Interaction = null;
  private destroy$: Subject<boolean> = new Subject();

  private overlayRef: OverlayRef | null = null;
  isOverlayOpen = false;

  constructor(
    private overlay: Overlay,
    private configurationService: ConfigurationService,
    private dialog: MatDialog,
    private mailReviewService: MailReviewService,
    private mailReviewClassifyService: MailReviewClassifyService,
    private snackBarService: SnackBarService,
  ) {
    inject(BreakpointObserver)
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        BREAKPOINT_1280,
        BREAKPOINT_1450,
        BREAKPOINT_1650,
        Breakpoints.XLarge,
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            this.currentScreenSize = this.breakPointsNameMap.get(query) ?? 'Unknown';
            this.statusesToShow = this.breakpointValueMap.get(this.currentScreenSize) || 0;
          }
        }
      });
  }

  ngOnInit(): void {
    this.loadStatuses();

    this.mailReviewService.selectedInteraction$.pipe(takeUntil(this.destroy$)).subscribe((interaction) => {
      this.selectedInteraction = interaction;
    });

    this.mailReviewService.currentClassifierMessage$.pipe(takeUntil(this.destroy$)).subscribe((message) => {
      this.selectedMessage = message;
    });

    this.mailReviewService.currrentContact$.pipe(takeUntil(this.destroy$)).subscribe((contact) => {
      this.currentContact = contact;
    });

    this.mailReviewService.convertedInteraction$.pipe(takeUntil(this.destroy$)).subscribe((interaction) => {
      this.convertedInteraction = interaction;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  loadStatuses() {
    this.configurationService
      .getClassificationStatuses()
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: ClassificationStatus[]) => {
        this.statuses = response?.length
          ? response.filter((x) => x.reviewPriority !== null).sort((a, b) => a.reviewPriority - b.reviewPriority)
          : [];
      });
  }

  classify(event: Event, newStatus: ClassificationStatus) {
    event.stopPropagation();
    this.closeOverlay();

    if (newStatus.ruleDescription === ClassificationStatusEnum.Converted && this.selectedInteraction) {
      this.dialog.open(ConvertedDialogComponent, {
        data: {
          ...this.getConvertedDialogData(newStatus),
        } as ConvertedDialogData,
        minWidth: '40vw',
        hasBackdrop: false,
      });
      return;
    }

    if (newStatus.ruleDescription === ClassificationStatusEnum.Forwarded && this.selectedInteraction) {
      this.dialog.open(ForwardedInteractionDialogComponent, {
        data: {
          ...this.getForwardedDialogData(),
        } as ForwardedInteractionDialogData,
        minWidth: '40vw',
        hasBackdrop: false,
      });
      return;
    }

    if (newStatus.ruleDescription === ClassificationStatusEnum.Other && this.selectedInteraction) {
      this.handleClassifyEmailAsOther(newStatus);
      return;
    }

    this.handleClassifyEmail(newStatus);
  }

  private getConvertedDialogData(newStatus): ConvertedDialogData {
    const { ruleId, ruleDescription } = newStatus;
    const { id: messageHandlerId, fromMail, messageId, sdrId } = this.selectedMessage.email;

    const convertedDialogData: ConvertedDialogData = {
      fromMail,
      ruleId,
      ruleDescription,
      messageHandlerIds: [messageHandlerId],
      isMessageClassification: false,
      interaction: this.selectedInteraction,
      contact: this.currentContact,
      replyMessageId: messageId,
      messageId,
      sdrId,
    };
    return convertedDialogData;
  }

  private getForwardedDialogData(): ForwardedInteractionDialogData {
    const { interactionId, utcDatetimeCreated: interactionUtcDatetime, sdrId } = this.selectedInteraction;
    const { messageId } = this.selectedMessage.email;
    const { contactId } = this.currentContact;

    const forwardedInteractionDialogData: ForwardedInteractionDialogData = {
      sdrId,
      interactionId,
      contactId,
      interactionUtcDatetime,
      messageId,
    };
    return forwardedInteractionDialogData;
  }

  private handleClassifyEmailAsOther(newStatus: ClassificationStatus) {
    const { messageId, interactionId, sdrId } = this.selectedInteraction;
    const { id, sentDate, uid, sdr } = this.selectedMessage.email;
    const { contactId } = this.currentContact;

    const classifyReviewEmailDto: ClassifyReviewEmailDto = {
      messageHandlerId: id,
      ruleId: newStatus.ruleId,
      messageId,
      sdrId,
      interactionId,
      sentDate,
      uid,
      sdr,
      contactId,
    };

    const classification: ClassificationStatusData = {
      classificationId: newStatus.ruleId,
      classificationName: newStatus.ruleDescription,
    };

    if (
      !Object.values(classifyReviewEmailDto).every((value) => value) ||
      !Object.values(classification).every((value) => value)
    ) {
      this.snackBarService.showError(
        'An error occurred while retrieving the information required to run the classification.',
      );
      return;
    }

    this.classifyEmailAsOther(classifyReviewEmailDto, classification);
  }

  private classifyEmailAsOther(
    classifyReviewEmailDto: ClassifyReviewEmailDto,
    classification: ClassificationStatusData,
  ) {
    const serviceInfo: ServiceInfo = {
      serviceInstance: ServiceInstance.MailReviewService,
      functionName: FunctionName.ClassifyEmailAsOther,
    };

    this.mailReviewClassifyService.classification = classification;
    this.mailReviewClassifyService.classifyMail(serviceInfo, classifyReviewEmailDto);
  }

  private handleClassifyEmail(newStatus: ClassificationStatus) {
    const { id, uid, sdr, messageId } = this.selectedMessage?.email;

    const messageToClassifyDto: MessageToClassifyDto = {
      currentFolder: newStatus.ruleDescription,
      destinationFolderId: newStatus?.destinationFolderId || 1,
      messageHandlerId: id,
      messageId,
      uid,
      sdr,
    };

    const classification: ClassificationStatusData = {
      classificationId: newStatus.ruleId,
      classificationName: newStatus.ruleDescription,
    };

    if (
      !Object.values(messageToClassifyDto).every((value) => value) ||
      !Object.values(classification).every((value) => value)
    ) {
      this.snackBarService.showError(
        'An error occurred while retrieving the information required to run the classification.',
      );
      return;
    }
    this.classifyEmail(messageToClassifyDto, classification);
  }

  private classifyEmail(messageToClassifyDto: MessageToClassifyDto, classification: ClassificationStatusData) {
    const serviceInfo: ServiceInfo = {
      serviceInstance: ServiceInstance.MailReviewService,
      functionName: FunctionName.ClassifyEmail,
    };

    this.mailReviewClassifyService.classification = classification;
    this.mailReviewClassifyService.classifyMail(serviceInfo, messageToClassifyDto);
  }

  toggleOverlay() {
    this.isOverlayOpen ? this.closeOverlay() : this.openOverlay();
  }

  openOverlay() {
    if (this.overlayRef) {
      return;
    }

    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.buttonRef.element)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        },
      ])
      .withPush(false)
      .withFlexibleDimensions(false);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
    });

    this.overlayRef.backdropClick().subscribe(() => this.closeOverlay());

    const templatePortal = new TemplatePortal(this.overlayContent, this.buttonRef);
    this.overlayRef.attach(templatePortal);
    this.isOverlayOpen = true;
  }

  closeOverlay() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
      this.isOverlayOpen = false;
    }
  }
}

<div class="reset-password">
  <div class="reset-password__left-side">
    <div class="reset-password__logo">
      <img
        src="assets/images/logo.png"
        alt="WinDifferent"
      />
    </div>
    <div class="reset-password__form-container">
      <ng-container *ngIf="!recoveryCodeRequested; else UpdatePasswordForm">
        <div class="reset-password__title-container">
          <div class="reset-password__back-btn-container">
            <button
              mat-icon-button
              class="reset-password__back-btn"
              (click)="backToLogin()"
            >
              <mat-icon>arrow_back</mat-icon>
            </button>
            <span class="reset-password__page-name">Reset Password</span>
          </div>
          <p class="reset-password__module-name">Operations Module</p>
        </div>
        <form
          [formGroup]="sendCodeForm"
          (submit)="onSendCode()"
        >
          <p class="reset-password__description">Enter your registered email address. You will receive a code.</p>
          <mat-form-field appearance="outline">
            <mat-label><mat-icon>person</mat-icon>Email</mat-label>
            <input
              type="text"
              matInput
              formControlName="email"
              (click)="clearRecoveryError()"
            />
            <mat-error *ngIf="sendCodeForm.get('email').invalid">This field is required</mat-error>
          </mat-form-field>
          <p
            class="reset-password__error-message"
            *ngIf="accountNotFound"
          >
            We couldn't find an account with that email address.
          </p>
          <p
            class="reset-password__error-message"
            *ngIf="recoveryError"
          >
            {{ recoveryError }}
          </p>
          <div class="reset-password__btn-container">
            <button
              *ngIf="!loading"
              mat-flat-button
              color="primary"
              class="reset-password__send-code-btn"
              type="submit"
              [disabled]="sendCodeForm.get('email').invalid"
            >
              Send
            </button>
            <mat-spinner
              strokeWidth="5"
              diameter="35"
              *ngIf="loading"
            ></mat-spinner>
          </div>
        </form>
      </ng-container>

      <ng-template #UpdatePasswordForm>
        <div class="reset-password__title-container">
          <div class="reset-password__back-btn-container">
            <button
              mat-icon-button
              class="reset-password__back-btn"
              (click)="backToRecoveryCode()"
            >
              <mat-icon>arrow_back</mat-icon>
            </button>
            <span class="reset-password__page-name">Change your Password</span>
          </div>
          <p class="reset-password__module-name">Operations Module</p>
        </div>
        <form
          [formGroup]="updatePasswordForm"
          (submit)="onUpdatePassword()"
        >
          <div class="reset-password__password-requirements">
            <p>New password must contain:</p>
            <ul>
              <li>At least 8 characters</li>
              <li>At least 1 lowercase character</li>
              <li>At least 1 uppercase character</li>
              <li>At least 1 number</li>
              <li>At least 1 special character</li>
              <li>Up to 15 characters</li>
            </ul>
          </div>
          <mat-form-field appearance="outline">
            <mat-label><mat-icon>lock</mat-icon>Password</mat-label>
            <input
              type="password"
              matInput
              formControlName="newPassword"
              (click)="clearRecoveryError()"
            />
            <mat-error *ngIf="updatePasswordForm.get('newPassword').errors?.required">This field is required</mat-error>
            <mat-error *ngIf="updatePasswordForm.get('newPassword').errors?.pattern">
              Password does not meet the requirements
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label><mat-icon>person</mat-icon>Code</mat-label>
            <input
              type="text"
              matInput
              formControlName="code"
              (keydown.enter)="onUpdatePassword()"
              (click)="clearRecoveryError()"
            />
            <mat-error *ngIf="updatePasswordForm.get('code').invalid">This field is required</mat-error>
          </mat-form-field>
          <p
            class="reset-password__error-message"
            *ngIf="recoveryError"
          >
            {{ recoveryError }}
          </p>
          <div class="reset-password__btn-container">
            <button
              *ngIf="!loading"
              mat-flat-button
              color="primary"
              class="reset-password__update-btn"
              type="submit"
              [disabled]="!updatePasswordForm.valid"
            >
              Update
            </button>
            <mat-spinner
              strokeWidth="5"
              diameter="35"
              *ngIf="loading"
            ></mat-spinner>
          </div>
        </form>
      </ng-template>
    </div>
  </div>
  <img
    src="assets/images/login_image.svg"
    class="reset-password__right-img"
    alt="Operations Module Login"
  />
</div>

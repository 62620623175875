import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  formatPlain(str) {
    str = this.formatPlainReplace(str, /\\r\\n/g);
    str = this.formatPlainReplace(str, /\\r/g);
    str = this.formatPlainReplace(str, /\\n/g);
    str = this.formatPlainReplace(str, /\r\n/g);
    str = this.formatPlainReplace(str, /\r/g);
    str = this.formatPlainReplace(str, /\n/g);
    return str.trim();
  }

  formatPlainReplace(str, regex) {
    let parsedStr = str.replace(regex, '<br>');

    if (parsedStr.substr(0, 2).toLowerCase() === "b'" || parsedStr.substr(0, 2).toLowerCase() === 'b"') {
      parsedStr = parsedStr.substring(2);
    }

    if (parsedStr.endsWith("'") || parsedStr.endsWith('"')) {
      parsedStr = parsedStr.substring(0, parsedStr.length - 1);
    }

    return parsedStr;
  }

  formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }
}

export const environment = {
  production: true,
  isProd: true,
  apiBaseURL: 'https://api.windifferent.com',
  emailApiBaseURL: 'https://email-api-op-eks.sd-prd.bairesdev.net/v1',
  s3BaseURL: 'https://sd-om-attachments.s3.amazonaws.com/',
  notificationsSocketURL: 'wss://70onuvd6s9.execute-api.us-west-2.amazonaws.com/main',
  slackBotURL: 'https://kfo65kyril.execute-api.us-west-2.amazonaws.com/Prod/slack',
  authUserPoolId: 'us-west-2_wj3fNkeOI',
  authUserPoolWebClientId: '2kf685g02eeuu05ml5gp1g4399',
  authDomain: 'operations-module-prod.auth.us-west-2.amazoncognito.com',
  newApiBaseUrl: 'https://api-operations.wd-research.net/api/v1',
};

<div class="message">
  <div
    class="message__container"
    *ngIf="messages.length"
  >
    <div class="message__bar-actions">
      <div class="message__left-actions">
        <mat-checkbox
          *ngIf="existInboundMessages"
          id="all-messages-checkbox"
          color="primary"
          [checked]="isAllSelected$ | async"
          [indeterminate]="indeterminate$ | async"
          [matTooltip]="isAllSelectedTooltip$ | async"
          matTooltipPosition="above"
          (change)="toggleAllMessages($event.checked)"
        ></mat-checkbox>

        <span class="message__to-label">
          To
          <b class="message__participant-name">
            @for (participant of selectedConversation?.participantsName; track participant) {
              <span *ngIf="!$last">{{ participant }}, </span>
              <span *ngIf="$last"> {{ participant }} </span>
            } @empty {
              Unknown
            }
          </b>
        </span>

        <button
          mat-icon-button
          disableRipple
          class="message__linkedin-url-btn"
          (click)="openLinkedInProfile(participantLinkedInUrl)"
        >
          <img
            src="assets/images/linkedin_icon.png"
            height="16"
          />
        </button>
      </div>
      <div class="message__right-actions">
        <button
          *ngIf="existInboundMessages && hasSelectedMessages$ | async"
          class="message__action-btn"
          id="trash-btn"
          mat-icon-button
          matTooltip="Mark as Trash"
          matTooltipPosition="above"
          (click)="openConfirmClassifyTrashMessages()"
        >
          <mat-icon class="message__trash-icon">delete_outline</mat-icon>
        </button>
      </div>
    </div>

    <div class="message__body">
      <div
        *ngFor="let message of messages; let i = index"
        class="message__item"
        [ngClass]="{
          'message__item--outbound': !message.isInbound,
          'message__item--inbound': message.isInbound,
        }"
      >
        <input
          *ngIf="
            (message.isInbound && message.messageStatus === 'INBOX' && !message?.isTemp) ||
            (message.isInbound && selectedFolder !== 'INBOX' && !message?.isTemp)
          "
          type="checkbox"
          class="message__toggle-message"
          id="message-checkbox-{{ i }}"
          [checked]="message.selected"
          (change)="toggleMessage(message.messageHandlerId, $event.target.checked)"
        />
        <div
          class="message__plain"
          [ngClass]="{
            'message__plain--outbound': !message.isInbound && !message?.isTemp,
            'message__plain--inbound': message.isInbound && !message?.isTemp,
            'message__plain--temp': message?.isTemp,
          }"
        >
          <span
            class="message__text"
            [innerHTML]="message.messagePlain | newlineToBr"
          ></span>
          <div class="message__details">
            <span class="message__participant">{{ message?.participantName | titlecase }}</span>
            <div class="message__details-right">
              <span class="message__status">{{ message?.messageStatus | titlecase }}</span>
              <span>{{ message?.sentDate | date: 'YYYY-MM-dd h:mm a' }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="message__send-box">
      <app-send-message [selectedConversation]="selectedConversation"></app-send-message>
    </div>
  </div>
</div>

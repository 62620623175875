<div class="contact-search">
  <div class="contact-search__form">
    <div class="contact-search__fields-container">
      <!-- Campo Email -->
      <mat-form-field
        class="contact-search__email-field"
        subscriptSizing="dynamic"
      >
        <mat-label>Email</mat-label>
        <input
          matInput
          [formControl]="emailCtrl"
          maxlength="80"
          id="contact-email-input"
        />
        <mat-icon
          matSuffix
          aria-label="Clear"
          *ngIf="emailCtrl.value"
          (click)="onEmailClear()"
          class="contact-search__clear-email"
          id="contact-email-mat-icon-clear"
        >
          close
        </mat-icon>
        <mat-error *ngIf="emailCtrl.hasError('email')">Email does not meet the requirements</mat-error>
      </mat-form-field>

      <!-- Campo Contact ID -->
      <mat-form-field
        class="contact-search__contactId-field"
        subscriptSizing="dynamic"
      >
        <mat-label>Contact ID</mat-label>
        <input
          matInput
          [formControl]="contactIdCtrl"
          maxlength="80"
          id="contact-id-input"
        />
        <mat-error *ngIf="contactIdCtrl.hasError('pattern')">Only numbers are allowed</mat-error>
      </mat-form-field>
    </div>

    <!-- Botón de búsqueda -->
    <button
      mat-stroked-button
      (click)="search()"
      class="contact-search__search-btn"
      [disabled]="(emailCtrl.invalid && contactIdCtrl.invalid) || isSearching"
      id="contact-search-btn"
    >
      <span *ngIf="!isSearching">Search</span>
      <mat-spinner
        *ngIf="isSearching"
        diameter="20"
      ></mat-spinner>
    </button>

    <mat-checkbox
      [(ngModel)]="clientFilter"
      (ngModelChange)="onClientFilterChange($event)"
      class="client-filter-checkbox"
    >
      Client Filter
    </mat-checkbox>
  </div>
</div>

import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IReassignSdrPayload, ISdrFiltersResponse, ReassignSdrModalData, SdrFiltersEnum, SdrFiltersSearchKeyEnum } from '../../../../model/Sdr';
import { BehaviorSubject } from 'rxjs';
import { SdrService } from '../../../../services/sdr.service';
import { HttpStatusCode } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MODAL_DURATION } from '../../../../model/Utils';

@Component({
    selector: 'app-reassign-sdr',
    templateUrl: './reassign-sdr.component.html',
    styleUrls: ['./reassign-sdr.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ReassignSdrComponent {

  public selectedOperatorId$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  public avaliableOperators$: BehaviorSubject<ISdrFiltersResponse[]> = new BehaviorSubject<ISdrFiltersResponse[]>([]);

  constructor(
    public dialog: MatDialogRef<ReassignSdrComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ReassignSdrModalData,
    private readonly sdrService: SdrService,
    private readonly snackBar: MatSnackBar,
  ) {
    this.handleUserInput();
  }

  public formatFilterPlaceholderEmail(filter: ISdrFiltersResponse): string {
    return filter?.email;
  }

  public handleUserInput(userInput?: string): void {
    this.sdrService.getFilter(
      SdrFiltersEnum.OPERATOR,
      userInput,
      SdrFiltersSearchKeyEnum.EMAIL,
    ).subscribe(res => {
      if(res.code === HttpStatusCode.Ok) {
        this.avaliableOperators$.next(res.data);
      } else {
        this.showToast("Failed to load operators")
      }
    }, () => {
      this.showToast("Failed to load operators")
    })
  }

  public handleOperatorSelectionChange(selectedOperator: number): void {
    this.selectedOperatorId$.next(selectedOperator);
  }

  public onDismiss(): void {
    this.dialog.close(null);
  }

  public onConfirm(): void {
    this.dialog.close(this.generateBulkReassignPayload());
  }

  private generateBulkReassignPayload(): IReassignSdrPayload[] {
    const selectedOperatorId = this.selectedOperatorId$.value;

    if(!selectedOperatorId){
      return []
    }

    return this.data.sdrs.map(sdr => ({
      user_operator_id: sdr.user_operator_id,
      user_id: sdr.user_id,
      operator_id: selectedOperatorId,
      level_id: sdr.level.id,
    }))
  }

  private showToast(message: string): void {
    this.snackBar.open(
      message,
      null,
      {
        duration: MODAL_DURATION,
      }
    );
  }
}

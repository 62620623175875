<div class="mail-classifier">
  <mat-progress-bar
    *ngIf="isLoading"
    mode="indeterminate"
    [ngClass]="'mail-classifier__progress-bar'"
  ></mat-progress-bar>
  <app-mail-classifier-toolbar
    [pendingCount]="pendingCount"
    (navigateNext)="onNavigateNextMessage()"
    (navigatePrev)="onNavigatePreviousMessage()"
    (refreshMails)="onRefreshMails()"
    [isNextDisabled]="isNextBtnDisabled"
    [isPrevDisabled]="isPrevBtnDisabled"
  ></app-mail-classifier-toolbar>
  <ng-container *ngIf="classifierMessages.length; else NoLogs">
    <app-mail-classifier-log
      [message]="currentMessage"
      (completeCase)="onCompleteCase()"
      (emailClicked)="onClickEmail($event)"
    >
    </app-mail-classifier-log>
    <app-mail-classifier-details-body></app-mail-classifier-details-body>
  </ng-container>
  <ng-template #NoLogs>
    <div class="mail-classifier__empty-data-message">No data to display.</div>
  </ng-template>
</div>

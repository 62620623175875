import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { TOKEN_KEY } from '../services/sd-auth.service';
import 'rxjs-compat/add/operator/map';
import { catchError, switchMap, filter, take, timeout } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { SDAuthService } from '../services/sd-auth.service';
import { environment } from '../../environments/environment';

const DEFAULT_TIMEOUT = 5 * 60 * 1000;

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private authService: SDAuthService) {}

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(
    localStorage.getItem(TOKEN_KEY),
  );

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('password-public')) {
      return next.handle(request).pipe(
        timeout(DEFAULT_TIMEOUT),
        catchError((error) => this.errorHandling(error, request, next)),
      );
    }

    if (request.url.includes(environment.apiBaseURL) || request.url.includes(environment.newApiBaseUrl)) {
      request = this.addToken(request);
      return next.handle(request).pipe(
        timeout(DEFAULT_TIMEOUT),
        catchError((error) => this.errorHandling(error, request, next)),
      );
    } else {
      return next.handle(request).pipe(
        timeout(DEFAULT_TIMEOUT),
        catchError((error) => throwError(error)),
      );
    }
  }

  errorHandling(error: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler) {
    console.log('Http errorcode: ' + error?.status + ' ,url: ' + request.url);
    if (error && error?.status === 401) {
      console.log('Invalid token');
      return this.handle401Error(request, next);
    } else {
      return throwError(error);
    }
  }

  private addToken(request: HttpRequest<any>) {
    const token = this.refreshTokenSubject.getValue();

    if (token) {
      return request.clone({
        setHeaders: {
          Authorization: request.url.includes(environment.apiBaseURL)
            ? token
            : request.url.includes(environment.newApiBaseUrl)
              ? `Bearer ${token}`
              : '',
        },
      });
    }
    return request;
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    console.log('Silent refresh');
    if (!this.isRefreshing) {
      console.log('Silent refresh route 1');
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshSessionToken().pipe(
        switchMap((token: string) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token);
          localStorage.setItem(TOKEN_KEY, token);
          console.log('Auth service subscription');
          return next.handle(this.addToken(request)).pipe(timeout(DEFAULT_TIMEOUT));
        }),
        catchError((err) => {
          this.isRefreshing = false;
          return throwError(err);
        }),
      );
    } else {
      console.log('Silent refresh route 2');
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((jwt) => {
          console.log('Refresh token subject');
          return next.handle(this.addToken(request)).pipe(timeout(DEFAULT_TIMEOUT));
        }),
      );
    }
  }
}

<div class="inquiry-search">
  <div class="inquiry-search__row">
    <mat-form-field
      class="inquiry-search__form-field"
      appearance="fill"
      id="inquiry-clients-mat-form-field"
    >
      <mat-label>Clients</mat-label>
      <mat-select
        style="font-size: 12px"
        [(ngModel)]="selectedClient"
        (ngModelChange)="updateClients()"
        id="inquiry-clients-mat-select"
      >
        <mat-option
          *ngFor="let client of clients; let i = index"
          [value]="client"
          style="font-size: 12px"
          [id]="'inquiry-clients-mat-option-' + i"
        >
          {{ client }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      class="inquiry-search__form-field"
      appearance="fill"
      id="inquiry-sdr-mat-form-field"
    >
      <mat-label>SDR</mat-label>
      <mat-select
        style="font-size: 12px"
        [(ngModel)]="selectedSdr"
        id="inquiry-sdr-mat-select"
      >
        <mat-option
          *ngFor="let sdr of clientSdrs"
          [value]="sdr"
          style="font-size: 12px"
          id="inquiry-sdr-mat-option"
        >
          {{ sdr.email }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      class="inquiry-search__form-field"
      appearance="fill"
      id="inquiry-date-mat-form-field"
    >
      <mat-label>Date</mat-label>
      <input
        style="font-size: 12px"
        matInput
        [matDatepicker]="pickerSearchDate"
        [(ngModel)]="searchDate"
        id="inquiry-date-input"
      />
      <mat-datepicker-toggle
        matIconSuffix
        [for]="pickerSearchDate"
        id="inquiry-date-mat-datepicker-toggle"
      ></mat-datepicker-toggle>
      <mat-datepicker
        #pickerSearchDate
        id="inquiry-date-mat-datepicker"
      ></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="inquiry-search__row inquiry-search__row--max-h-50">
    <mat-form-field
      class="inquiry-search__form-field"
      appearance="fill"
      id="inquiry-email-mat-form-field"
    >
      <mat-label>Email</mat-label>
      <input
        matInput
        [(ngModel)]="email"
        #usrEmail="ngModel"
        maxlength="80"
        style="font-size: 12px"
        (ngModelChange)="validateEmail()"
        id="inquiry-email-input"
      />
      <mat-icon
        matSuffix
        aria-label="Clear"
        *ngIf="email"
        (click)="onEmailClear()"
        class="inquiry-search__clear-icon"
        id="inquiry-email-mat-icon-clear"
      >
        close
      </mat-icon>
    </mat-form-field>

    <mat-form-field
      class="inquiry-search__form-field"
      appearance="fill"
      id="inquiry-firstName-mat-form-field"
    >
      <mat-label>First Name</mat-label>
      <input
        matInput
        [(ngModel)]="firstName"
        #usrFirstName="ngModel"
        maxlength="80"
        style="font-size: 12px"
        id="inquiry-firstName-input"
      />
      <mat-icon
        matSuffix
        aria-label="Clear"
        *ngIf="firstName"
        (click)="onFirstNameClear()"
        class="inquiry-search__clear-icon"
        id="inquiry-firstName-mat-icon-clear"
      >
        close
      </mat-icon>
    </mat-form-field>

    <mat-form-field
      class="inquiry-search__form-field"
      appearance="fill"
      id="inquiry-lastName-mat-form-field"
    >
      <mat-label>Last Name</mat-label>
      <input
        matInput
        [(ngModel)]="lastName"
        #usrLastName="ngModel"
        maxlength="80"
        style="font-size: 12px"
        id="inquiry-lastName-input"
      />
      <mat-icon
        matSuffix
        aria-label="Clear"
        *ngIf="lastName"
        (click)="onLastNameClear()"
        class="inquiry-search__clear-icon"
        id="inquiry-lastName-mat-icon-clear"
      >
        close
      </mat-icon>
    </mat-form-field>

    <button
      mat-stroked-button
      (click)="search()"
      class="inquiry-search__button"
      [disabled]="!isValidSearch || isSearchLoading"
      id="inquiry-search-btn"
    >
      <span *ngIf="!isSearchLoading">Search</span>
      <mat-spinner
        class="inquiry-search__spinner"
        *ngIf="isSearchLoading"
        diameter="20"
      ></mat-spinner>
    </button>
  </div>
  <small
    class="inquiry-search__error-message"
    *ngIf="error"
    >{{ error }}</small
  >
</div>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Lead } from '../../../model/lead/lead.model';
import { InteractionService } from '../../../services/interaction.service';
import { LeadService } from '../../../services/lead.service';
import { MailReviewService } from '../../../services/mail-review.service';
import { SnackBarService } from '../../../services/snackbar/snackbar.service';

@Component({
    selector: 'app-alias-dialog',
    templateUrl: './alias-dialog.component.html',
    styleUrls: ['./alias-dialog.component.scss'],
    standalone: false
})
export class AliasDialogComponent implements OnInit, OnDestroy {
  contactFromSearch: Lead = null;
  contactFromForwarder: Lead = null;
  private destroy$ = new Subject<boolean>();

  constructor(
    private interactionService: InteractionService,
    private leadService: LeadService,
    private mailReviewService: MailReviewService,
    private snackbarService: SnackBarService,
  ) {}

  ngOnInit(): void {
    this.leadService.currentLead$.pipe(takeUntil(this.destroy$)).subscribe((lead) => {
      if (lead) {
        this.contactFromSearch = lead;
      }
    });

    combineLatest([this.leadService.currentLead$, this.interactionService.forwarders$])
      .pipe(takeUntil(this.destroy$), distinctUntilChanged())
      .subscribe(([currentLead, forwarders]) => {
        const email = forwarders.find((forwarder) => forwarder.originalEmail === currentLead?.emailAddress)?.email;

        if (email) {
          this.getContactFromForwarder(email);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  getContactFromForwarder(emailAddress: string): void {
    this.leadService
      .getLeadByEmail(emailAddress)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (lead) => {
          this.mailReviewService.setContactFromForwarder(lead || null);
          this.contactFromForwarder = lead || null;
        },
        error: () => {
          this.snackbarService.showError('An error occurred while getting contact');
        },
      });
  }
}

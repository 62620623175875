<div class="contact-toolbar">
  <button
    *ngIf="!isCreatingContact && !isShowingAlias"
    mat-stroked-button
    (click)="onCreateContact()"
  >
    <span>CREATE</span>
  </button>
  <button
    *ngIf="isCreatingContact && !isShowingAlias"
    mat-stroked-button
    (click)="onCancelCreateContact()"
  >
    CANCEL
  </button>

  <button
    *ngIf="isShowingAlias"
    mat-stroked-button
    [disabled]="invalidSetOrUndoAlias$ | async"
    (click)="onSetAlias()"
  >
    SET ALIAS
  </button>
  <button
    *ngIf="isShowingAlias"
    mat-stroked-button
    color="warn"
    [disabled]="invalidSetOrUndoAlias$ | async"
    (click)="onUndoAlias()"
  >
    UNDO ALIAS
  </button>
  <button
    *ngIf="isShowingAlias"
    mat-stroked-button
    (click)="onToggleShowAlias()"
  >
    CLOSE
  </button>
  <button
    *ngIf="isCreatingContact && !isShowingAlias"
    mat-stroked-button
    (click)="onSaveContact()"
    color="primary"
    [disabled]="!isValid || isSaving"
  >
    <span *ngIf="!isSaving">SAVE</span>
    <mat-spinner
      *ngIf="isSaving"
      diameter="20"
    ></mat-spinner>
  </button>
  <button
    *ngIf="!isCreatingContact && !isShowingAlias"
    mat-stroked-button
    color="primary"
    (click)="onToggleShowAlias()"
    [disabled]="invalidAlias$ | async"
  >
    <span>ALIAS</span>
  </button>
  <button
    *ngIf="!isCreatingContact && !isShowingAlias"
    mat-stroked-button
    color="primary"
    (click)="onReassignSdr()"
    [disabled]="invalidReassignSdr$ | async"
  >
    <span>REASSIGN</span>
  </button>
  <button
    *ngIf="!isCreatingContact && !isShowingAlias"
    mat-stroked-button
    color="primary"
    (click)="onAddToInteraction()"
    [disabled]="invalidAddToInteraction$ | async"
  >
    <span>ADD TO INTERACTION</span>
  </button>
</div>

<h1
  mat-dialog-title
  style="margin-bottom: 4px"
>
  {{ title }}
</h1>
<div mat-dialog-content>
  <p style="margin: 8px 0">{{ message }}</p>
</div>
<div mat-dialog-actions>
  <button
    mat-button
    (click)="onDismiss()"
    id="confirm-dialog-cancel-btn"
  >
    {{ cancelBtnText }}
  </button>
  <button
    mat-button
    (click)="onConfirm()"
    cdkFocusInitial
    color="primary"
    id="confirm-dialog-confirm-btn"
  >
    {{ confirmBtnText }}
  </button>
</div>

import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SdApiService } from '../../../../services/base/sd-api.service';
import { COMMA, ENTER, SEMICOLON } from '@angular/cdk/keycodes';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingStateService } from 'src/app/services/base/loading-state.service';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { UntypedFormControl, FormGroup, Validators } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { merge, Observable, of } from 'rxjs';
import { Attachment } from 'src/app/model/Attachment';
import { MessageAttachmentService } from 'src/app/services/message-attachment.service';
import { SDAuthService } from 'src/app/services/sd-auth.service';
import { AppConstants } from 'src/app/resources/app-constants';
import { Location } from '@angular/common';
import { ComposerMailService } from 'src/app/services/composer-mail.service';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';

declare var $: any;

const MODAL_DURATION = 3000;



@Component({
    selector: 'app-email-template-editor',
    templateUrl: './email-template-editor.component.html',
    styleUrls: ['./email-template-editor.component.scss'],
    standalone: false
})
export class EmailTemplateEditorComponent implements OnInit, AfterViewInit {
  constructor(
    private service: SdApiService,
    private router: Router,
    private dialog: MatDialog,
    private loadingStateService: LoadingStateService,
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    public messageAttachmentService: MessageAttachmentService,
    private sdApiService: SdApiService,
    private authService: SDAuthService,
    private location: Location,
    private composerMailService: ComposerMailService,
  ) {}

  pageTitle = '';
  templateTypeLabel = '';
  submitTemplateLabel = '';
  loading = false;
  templateType = '';
  mode = '';
  templateId = 0;
  title = '';
  notes = '';
  userEmail = '';
  userCreated = '';
  userModified = '';
  clientEditValue;
  sdrEditValue;
  salesRepEditValue;
  selectedClient: string = null;
  isInternalUse = false;
  isSubmitting = false;

  public sdrList = [];
  sdrControl = new UntypedFormControl('', [Validators.required]);

  public salesRepList = [];
  salesRepControl = new UntypedFormControl('', [Validators.required]);

  public ccList = [];
  ccControl = new UntypedFormControl('', []);

  public bccList = [];
  bccControl = new UntypedFormControl('', []);

  public tagList = [];
  tagControl = new UntypedFormControl('', []);

  filteredSdrs: Observable<string[]>;
  filteredSalesReps: Observable<string[]>;

  filteredClientOptions: Observable<any[]>;

  public clientFilterControl = new UntypedFormControl();
  availableClients: string[] = [];
  allClientsObj = [];

  allSdrs: string[] = [];
  availableSdrs: string[] = [];
  allSalesReps: string[] = [];
  availableSalesReps: string[] = [];
  removable = true;

  separatorKeyCodes = [ENTER, COMMA,  SEMICOLON];
  
  attachments: Attachment[] = [];
  uploadedAttachments: Attachment[] = [];

  @ViewChild('sdrAuto') sdrAutocomplete: MatAutocomplete;
  @ViewChild('salesRepAuto') salesRepAutocomplete: MatAutocomplete;
  @ViewChild('sdrInput') sdrInput: ElementRef;
  @ViewChild('salesRepInput') salesRepInput: ElementRef;
  @ViewChild('ccInput') ccInput: ElementRef;
  @ViewChild('bccInput') bccInput: ElementRef;
  @ViewChild('tagInput') tagInput: ElementRef;

  @ViewChild('inputFileControl', { read: ElementRef }) inputFileControl: ElementRef;

  ngOnInit() {
    this.loadingStateService.isLoading.subscribe((loading) => {
      this.loading = loading;
    });

    this.activatedRoute.queryParams.subscribe(async (params) => {
      if (params.type) {
        this.templateType = params.type;
      }
      this.templateId = params.id;
      this.mode = this.templateId == 0 ? 'new' : 'edit';

      this.userEmail = await this.authService.getCurrentUserEmail();

      if (this.mode === 'edit') {
        // Load the template
        this.loadingStateService.setLoadingState(true);
        this.sdApiService.loadTemplate(this.templateId).subscribe((templateData) => {
          this.loadingStateService.setLoadingState(false);
          // Get the type
          this.templateType =
            templateData.templateTypeId === AppConstants.REPLY_TEMPLATE_TYPE ? 'reply' : 'introduction';
          this.setLabels();

          this.userCreated = templateData.userCreated;
          this.userModified = this.userEmail;
          this.title = templateData.title;
          this.notes = templateData.notes;
          this.isInternalUse = templateData.internalUseOnly;
          if (templateData.tags) {
            templateData.tags.split(',').forEach((tag) => {
              this.tagList.push({ value: tag, invalid: false });
            });
          }

          (window as any).templateEditorHtml = templateData.templateText;
          $('#editorId').summernote('code', (window as any).templateEditorHtml);

          if (templateData.Attachment) {
            this.attachments = templateData.Attachment.map((attachment: any) => {
              return new Attachment({
                filename: attachment.Name || attachment.name,
                key: attachment.Key || attachment.key,
                isSafe: attachment.is_safe,
                size: attachment.Size || attachment.size,
                isLocal: false,
                contentType: attachment.content_type,
              });
            });
          }

          if (this.allClientsObj.length > 0) {
            const clientEquivalent = this.allClientsObj.filter((c) => c.id === templateData.clientId)[0];
            this.selectedClient = clientEquivalent.name;
          } else {
            this.clientEditValue = templateData.clientId;
          }

          if (this.templateType === 'introduction') {
            if (templateData.cc) {
              templateData.cc.split(',').forEach((cc) => {
                this.ccList.push({ value: cc, invalid: false });
              });
            }
            if (templateData.bcc) {
              templateData.bcc.split(',').forEach((bcc) => {
                this.bccList.push({ value: bcc, invalid: false });
              });
            }
            var sdrId = Number.parseInt(templateData.sdr);
            if (this.allClientsObj.length > 0) {
              const sdrClientEquivalent = this.allClientsObj.filter((c) =>
                c.sdrs.some((sdrs) => sdrs.id === sdrId),
              )[0];
              const sdrName =
                sdrClientEquivalent?.sdrs?.filter((sdrs) => sdrs.id === sdrId)[0]?.email || null;
              if (sdrName) {
                this.sdrList.push({ value: sdrName, invalid: false });
              }
              const salesRepClientEquivalent = this.allClientsObj.filter((c) =>
                c.salesReps.some((salesReps) => salesReps.idClientContact === templateData.clientContactId),
              )[0];
              const salesRepName = salesRepClientEquivalent.salesReps.filter(
                (salesReps) => salesReps.idClientContact === templateData.clientContactId,
              )[0].name;
              this.salesRepList.push({ value: salesRepName, invalid: false });
            } else {
              this.sdrEditValue = sdrId;
              this.salesRepEditValue = templateData.clientContactId;
            }
          }
        });

        // Disable controls
        this.clientFilterControl.disable();
        this.sdrControl.disable();
      } else {
        this.setLabels();
        this.userCreated = this.userEmail;
        this.userModified = '';
      }
    });

    this.loadingStateService.setLoadingState(true);
    this.service.getClientInfo().subscribe((response) => {
      this.loadingStateService.setLoadingState(false);
      const clients = (response.data || []).filter((client) => client.sdrs.some((sdr) => sdr.email !== null));
      const allSdrs = [];
      const allSalesReps = [];
      const invalidGeographicZones = ['SUSPENDED', 'INTERNAL USE'];
      clients?.forEach((c) => {
        c.salesReps = c.salesReps.filter((sr) => !invalidGeographicZones.includes(sr.geographic_zone));
        c.sdrs.forEach((sdr) => {
          allSdrs.push(sdr);
        });
        c.salesReps.forEach((salesRep) => {
          allSalesReps.push(salesRep);
        });
      });
      this.allSdrs = allSdrs.map((c) => c.email).sort();
      this.allSalesReps = allSalesReps.map((c) => c.name).sort();
      this.availableSdrs = this.allSdrs;
      this.availableSalesReps = this.allSalesReps;

      this.availableClients = clients.map((c) => c.name).sort();

      this.allClientsObj = clients;

      this.filteredClientOptions = this.clientFilterControl.valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.name)),
        map((name) => (name ? this._filterClient(name) : this.availableClients.slice())),
      );

      this.filteredSdrs = this.mergeSdrs();
      this.filteredSalesReps = this.mergeSalesReps();

      this.clientFilterControl.valueChanges.subscribe((client) => {
        if (
          this.clientFilterControl.value &&
          this.allClientsObj.some((c) => c.name.toLowerCase() == this.clientFilterControl.value.toLowerCase())
        ) {
          this.availableSdrs = this.allClientsObj
            .filter((c) => c.name.toLowerCase() == this.clientFilterControl.value.toLowerCase())[0]
            .sdrs.map((sdr) => sdr.email)
            .sort();
          this.availableSalesReps = this.allClientsObj
            .filter((c) => c.name.toLowerCase() == this.clientFilterControl.value.toLowerCase())[0]
            .salesReps.map((sdr) => sdr.name)
            .sort();
        } else {
          this.availableSdrs = this.allSdrs;
          this.availableSalesReps = this.allSalesReps;
          this.sdrList = [];
          this.salesRepList = [];
        }
      });

      if (this.mode === 'edit') {
        if (this.clientEditValue) {
          const clientEquivalent = this.allClientsObj.filter((c) => c.id === this.clientEditValue)[0];
          this.selectedClient = clientEquivalent?.name;
        }

        if (this.sdrEditValue) {
          const sdrClientEquivalent = this.allClientsObj.filter((c) =>
            c.sdrs.some((sdrs) => sdrs.id === this.sdrEditValue),
          )[0];
          const sdrName = sdrClientEquivalent?.sdrs?.filter((sdrs) => sdrs.id === this.sdrEditValue)[0].email;
          if (sdrName) this.sdrList.push({ value: sdrName, invalid: false });
        }

        if (this.salesRepEditValue) {
          const salesRepClientEquivalent = this.allClientsObj.filter((c) =>
            c.salesReps.some((salesReps) => salesReps.idClientContact === this.salesRepEditValue),
          )[0];
          const salesRepName = salesRepClientEquivalent?.salesReps?.filter(
            (salesReps) => salesReps.idClientContact === this.salesRepEditValue,
          )[0].name;
          this.salesRepList.push({ value: salesRepName, invalid: false });
        }
      }
    });
  }

  ngAfterViewInit() {
    $('#editorId').summernote({
      toolbar: [
        ['style', ['style']],
        ['font', ['bold', 'underline', 'italic', 'clear']],
        ['fontname', ['fontname']],
        ['fontsize', ['fontsize']],
        ['fontsizeunit', ['fontsizeunit']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
      ],
      fontNames: [
        'Arial',
        'Calibri',
        'Roboto',
        'Comic Sans MS',
        'Courier New',
        'Helvetica',
        'Impact',
        'sans-serif',
        'Tahoma',
        'Times New Roman',
        'Verdana',
      ],
      fontSizes: ['8', '9', '10', '11', '12', '14', '15', '16', '18', '24', '36'],
      fontSizeUnits: ['pt'],
      fontNamesIgnoreCheck: ['Arial'],
      dialogsInBody: true,
      disableResizeEditor: true,
      callbacks: {
        onChange(contents, $editable) {
          (window as any).templateEditorHtml = contents;
        },
      },
    });

    $('.note-editable').css('font-size', `${AppConstants.GMAIL_FONT_SIZE}${AppConstants.GMAIL_FONT_UNIT}`);
    $('.note-editable').css('font-family', AppConstants.GMAIL_FONT_FAMILY);

    $('#editorId').summernote('code', '');

    const htmlElementColl: HTMLCollectionOf<HTMLElement> = document.getElementsByClassName(
      'note-editable',
    ) as HTMLCollectionOf<HTMLElement>;
    const service = this.composerMailService;
    $('#editorId').on('summernote.paste', function (we, e) {
      for (let i = 0; i < e.originalEvent.clipboardData.items.length; i += 1) {
        if (
          e.originalEvent.clipboardData.items[i].kind == 'string' &&
          e.originalEvent.clipboardData.items[i].type.match('^text/html')
        ) {
          e.preventDefault();
          e.originalEvent.clipboardData.items[i].getAsString(function (s) {
            s = service.fixPastedHtml(s);
            $('#editorId').summernote('pasteHTML', s);
          });
        }
      }
    });

    let heightMeasure = '30vh';
    if (htmlElementColl[0]) {
      htmlElementColl[0].style.maxHeight = heightMeasure;
      htmlElementColl[0].style.minHeight = heightMeasure;
    }
    if (htmlElementColl[1]) {
      htmlElementColl[1].style.height = heightMeasure;
    }
  }

  setLabels() {
    if (this.mode === 'new') {
      this.pageTitle += 'Create ';
      this.submitTemplateLabel = 'CREATE TEMPLATE';
    } else {
      this.pageTitle += 'Edit ';
      this.submitTemplateLabel = 'SAVE';
    }

    if (this.templateType === 'reply') {
      this.pageTitle += 'reply ';
      this.templateTypeLabel = 'General Reply';
    } else {
      this.pageTitle += 'introduction ';
      this.templateTypeLabel = 'Introduction template';
    }
    this.pageTitle += 'template';
  }

  private _filterClient(client: string): string[] {
    const filterValue = client.toLowerCase();

    return this.availableClients.filter((e) => e.toLowerCase().includes(filterValue));
  }

  handleOperationError(message: string) {
    this.loadingStateService.setLoadingState(false);
    const errorConfirmDialogConfig = new MatDialogConfig();
    errorConfirmDialogConfig.data = {
      title: 'Error',
      message,
    };
    this.dialog.open(AlertDialogComponent, errorConfirmDialogConfig);
  }

    addSdr(event: MatChipInputEvent): void {
      const input = event.input;
      const value = event.value;
      if (!this.sdrAutocomplete.isOpen) {
        if ((value || '').trim()) {
          const emails = value.split(';').map(email => email.trim()).filter(email => email);
          
          emails.forEach(email => {
            if (this.isValidEmail(email) && this.validateSdr(email)) {  
              this.sdrList.push({ value: email, invalid: false });
            } else {
              this.sdrList.push({ value: email, invalid: true });  
            }
          });
        }
      
        if (input) {
          input.value = '';
        }
    }
    if (input) {
      input.value = '';
    }
    }

    isValidEmail(email: string): boolean {
      const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
      return emailPattern.test(email);
    }

  removeSdr(data: any): void {
    if (this.sdrList.indexOf(data) >= 0) {
      this.sdrList.splice(this.sdrList.indexOf(data), 1);
      this.filteredSdrs = this.mergeSdrs();
    }
  }

  addSalesRep(event): void {
    if (!this.salesRepAutocomplete.isOpen) {
      console.log('entro');
      event.value = event.value.trim();
      if (event.value) {
        this.salesRepList = [];
        if (this.validateSalesRep(event.value)) {
          this.salesRepList.push({ value: event.value, invalid: false });
        } else {
          this.salesRepList.push({ value: event.value, invalid: true });
        }
      }
      if (event.input) {
        event.input.value = '';
      }
    }
    if (event.input) {
      event.input.value = '';
    }
  }

  removeSalesRep(data: any): void {
    if (this.salesRepList.indexOf(data) >= 0) {
      this.salesRepList.splice(this.salesRepList.indexOf(data), 1);
      this.filteredSalesReps = this.mergeSalesReps();
    }
  }

  addCC(event): void {
    event.value = event.value.trim();
    if (event.value) {
      if (this.validateEmail(event.value)) {
        this.ccList.push({ value: event.value, invalid: false });
      } else {
        this.ccList.push({ value: event.value, invalid: true });
      }
    }
    if (event.input) {
      event.input.value = '';
    }
    if (event.input) {
      event.input.value = '';
    }
  }

  removeCC(data: any): void {
    if (this.ccList.indexOf(data) >= 0) {
      this.ccList.splice(this.ccList.indexOf(data), 1);
    }
  }

  addBCC(event): void {
    event.value = event.value.trim();
    if (event.value) {
      if (this.validateEmail(event.value)) {
        this.bccList.push({ value: event.value, invalid: false });
      } else {
        this.bccList.push({ value: event.value, invalid: true });
      }
    }
    if (event.input) {
      event.input.value = '';
    }
    if (event.input) {
      event.input.value = '';
    }
  }

  removeBCC(data: any): void {
    if (this.bccList.indexOf(data) >= 0) {
      this.bccList.splice(this.bccList.indexOf(data), 1);
    }
  }

  addTag(event): void {
    event.value = event.value.trim();
    if (event.value) {
      if (this.validateTag(event.value)) {
        this.tagList.push({ value: event.value, invalid: false });
      } else {
        this.tagList.push({ value: event.value, invalid: true });
      }
    }
    if (event.input) {
      event.input.value = '';
    }
    if (event.input) {
      event.input.value = '';
    }
  }

  removeTag(data: any): void {
    if (this.tagList.indexOf(data) >= 0) {
      this.tagList.splice(this.tagList.indexOf(data), 1);
    }
  }

  displayFn(input: string): string {
    return input ? input : '';
  }

  updateSdrAuto(): void {
    const enteredSdr = this.sdrControl.value;
    if (enteredSdr) {
      if (this.validateSdr(enteredSdr)) {
        this.sdrList.push({ value: enteredSdr, invalid: false });
      } else {
        this.sdrList.push({ value: enteredSdr, invalid: true });
      }
      this.sdrControl.setValue('');
      this.sdrInput.nativeElement.value = '';
    }
    this.filteredSdrs = this.mergeSdrs();
  }

  updateSalesRepAuto(): void {
    const enteredSalesRep = this.salesRepControl.value;
    if (enteredSalesRep) {
      this.salesRepList = [];
      if (this.validateSalesRep(enteredSalesRep)) {
        this.salesRepList.push({ value: enteredSalesRep, invalid: false });
      } else {
        this.salesRepList.push({ value: enteredSalesRep, invalid: true });
      }
      this.salesRepControl.setValue('');
      this.salesRepInput.nativeElement.value = '';
    }
    this.filteredSalesReps = this.mergeSalesReps();
  }

  private _filterSdrs(sdr = ''): string[] {
    const filterValue = sdr.toLowerCase();
    let sdrValues = this.availableSdrs;
    if (
      this.clientFilterControl.value &&
      this.allClientsObj.some((c) => c.name.toLowerCase() == this.clientFilterControl.value.toLowerCase())
    ) {
      sdrValues = this.allClientsObj
        .filter((c) => c.name.toLowerCase() == this.clientFilterControl.value.toLowerCase())[0]
        .sdrs.map((s) => s.email)
        .sort();
    }
    const selectedSdrs = this.sdrList.map((s) => s.value);
    sdrValues = sdrValues.filter((e) => !selectedSdrs.includes(e));
    return sdrValues.filter((e) => e.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterSalesReps(salesRep = ''): string[] {
    const filterValue = salesRep.toLowerCase();
    let salesRepValues = this.availableSalesReps;
    if (
      this.clientFilterControl.value &&
      this.allClientsObj.some((c) => c.name.toLowerCase() == this.clientFilterControl.value.toLowerCase())
    ) {
      salesRepValues = this.allClientsObj
        .filter((c) => c.name.toLowerCase() == this.clientFilterControl.value.toLowerCase())[0]
        .salesReps.map((sdr) => sdr.name)
        .sort();
    }

    const selectedSalesReps = this.salesRepList.map((s) => s.value);
    salesRepValues = salesRepValues.filter((e) => !selectedSalesReps.includes(e));
    return salesRepValues.filter((e) => e.toLowerCase().indexOf(filterValue) === 0);
  }

  private validateEmail(email) {
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  private validateSdr(sdr) {
    return this.allSdrs.includes(sdr);
  }

  private validateSalesRep(salesRep) {
    return this.allSalesReps.includes(salesRep);
  }

  private validateTag(tag) {
    // No rule for tag now
    return true;
  }

  updateClients() {
    // Redefine availableSdrs and availableSalesReps
    this.availableSdrs = this.allClientsObj
      .filter((c) => c.name == this.selectedClient)[0]
      .sdrs.map((sdr) => sdr.email)
      .sort();
    this.availableSalesReps = this.allClientsObj
      .filter((c) => c.name == this.selectedClient)[0]
      .salesReps.map((sdr) => sdr.name)
      .sort();

    this.sdrList = [];
    this.salesRepList = [];
    this.filteredSdrs = this.mergeSdrs();
    this.filteredSalesReps = this.mergeSalesReps();
  }

  get invalidForm(): boolean {
    if (this.attachments.some((attachment) => attachment.isUploading)) {
      return true;
    }
    if (!this.title || !this.selectedClient || !this.availableClients.includes(this.selectedClient)) {
      return true;
    }

    if (this.templateType === 'introduction') {
      if (
        this.ccList.some((cc) => cc.invalid) ||
        this.bccList.some((cc) => cc.invalid) ||
        this.sdrList.some((cc) => cc.invalid) ||
        this.salesRepList.some((cc) => cc.invalid)
      ) {
        return true;
      }

      if (this.sdrList.length == 0 || this.salesRepList.length == 0) {
        return true;
      }
    }
    return false;
  }

  async attach(files: FileList) {
    await this.messageAttachmentService.attach(files, this.attachments, this.inputFileControl, this.dialog);
    this.uploadedAttachments = this.attachments.filter((attachment) => !attachment.isUploading);
  }

  createTemplate() {
    const templateType =
      this.templateType === 'reply' ? AppConstants.REPLY_TEMPLATE_TYPE : AppConstants.INTRODUCTION_TEMPLATE_TYPE;
    const clientId = this.allClientsObj.filter((c) => c.name === this.selectedClient)[0].id;
    const templateText = (window as any).templateEditorHtml;
    const tagList = this.tagList.map((tag) => tag.value);
    this.isSubmitting = true;
    if (templateType === AppConstants.REPLY_TEMPLATE_TYPE) {
      this.sdApiService
        .createOrUpdateTemplate(
          this.mode,
          this.templateId,
          templateType,
          this.title,
          clientId,
          templateText,
          this.notes,
          tagList.join(','),
          this.attachments,
          '',
          '',
          '',
          0,
          this.userCreated,
          this.userModified,
          this.isInternalUse,
        )
        .subscribe((res) => {
          if (res.status === 'success') {
            const operationSuccessLabel = this.mode === 'new' ? ' created ' : ' edited ';
            this.snackBar.open(`General reply template ${operationSuccessLabel} successfully`, null, {
              duration: MODAL_DURATION,
            });
            // await new Promise(res => setTimeout(res, 2000));
            this.router.navigate(['/home/management/templates/manage']);
          } else {
            const operationFailureLabel = this.mode === 'new' ? ' creating ' : ' editing ';
            this.snackBar.open(`An error ocurred while ${operationFailureLabel} the template`, null, {
              duration: MODAL_DURATION,
            });
            this.isSubmitting = false;
          }
        });
    } else {
      const sdrIdList = this.sdrList.map((sdr) => {
        const client = this.allClientsObj.filter((c) => c.sdrs.some((sdrLookup) => sdrLookup.email === sdr.value))[0];
        return client.sdrs.filter((sdrLookup) => sdrLookup.email === sdr.value)[0].id;
      });
      const salesRepClientEquivalent = this.allClientsObj.filter((c) =>
        c.salesReps.some((salesReps) => salesReps.name === this.salesRepList[0].value),
      )[0];
      const salesRepId = salesRepClientEquivalent.salesReps.filter(
        (salesReps) => salesReps.name === this.salesRepList[0].value,
      )[0].idClientContact;

      this.sdApiService
        .createOrUpdateTemplate(
          this.mode,
          this.templateId,
          templateType,
          this.title,
          clientId,
          templateText,
          this.notes,
          tagList.join(','),
          this.attachments,
          this.ccList.map((cc) => cc.value).join(','),
          this.bccList.map((bcc) => bcc.value).join(','),
          sdrIdList.join(','),
          salesRepId,
          this.userCreated,
          this.userModified,
          this.isInternalUse,
        )
        .subscribe((res) => {
          if (res.status === 'success') {
            const operationSuccessLabel = this.mode === 'new' ? ' created ' : ' edited ';
            this.snackBar.open(`Introduction template ${operationSuccessLabel} successfully`, null, {
              duration: MODAL_DURATION,
            });
            this.router.navigate(['/home/management/templates/manage']);
          } else {
            const operationFailureLabel = this.mode === 'new' ? ' creating ' : ' editing ';
            this.snackBar.open(`An error ocurred while ${operationFailureLabel} the template`, null, {
              duration: MODAL_DURATION,
            });
            this.isSubmitting = false;
          }
        });
    }
  }

  cancel() {
    this.location.back();
  }

  private mergeSdrs() {
    return merge(
      this.sdrControl.valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.name)),
        map((name) => this._filterSdrs(name)),
      ),
      this.clientFilterControl.valueChanges.pipe(map(() => this._filterSdrs(this.sdrControl.value))),
    );
  }

  private mergeSalesReps() {
    return merge(
      this.salesRepControl.valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.name)),
        map((name) => this._filterSalesReps(name)),
      ),
      this.clientFilterControl.valueChanges.pipe(map(() => this._filterSalesReps(this.salesRepControl.value))),
    );
  }
}

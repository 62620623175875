<div
  fxLayout="column"
  style="background: white; height: 94vh"
  id="emailColumn"
>
  <div>
    <app-mail-details-tool-bar></app-mail-details-tool-bar>
  </div>

  <div
    fxFlex="70%"
    fxLayout="row"
    fxLayoutAlign="center center"
    style="padding: 0 16px; height: 100%"
  >
    <iframe
      id="emailIframe"
      #iframe
      frameborder="0"
      sandbox="allow-same-origin"
    ></iframe>

    <div
      style="overflow-y: hidden; padding: 20px"
      *ngIf="!currentEmail"
    >
      Please refresh and select an email to start classifying.
    </div>
  </div>
</div>

import { COMMA, ENTER, SEMICOLON } from '@angular/cdk/keycodes';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, UntypedFormControl, Validators } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { merge, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { FolderSdrsAddComponent } from '../folders-sdrs-add/folder-sdrs-add.component';
import { LoadingStateService } from 'src/app/services/base/loading-state.service';

import { SdApiService } from 'src/app/services/base/sd-api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { SelectionModel } from '@angular/cdk/collections';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';

const MODAL_DURATION = 3000;

interface ISdr {
  email: string;
  id: number;
  isDefault: boolean;
  isManualDiscrepancy: boolean;
  isReviewDiscrepancy: boolean;
  isSalesOperation: boolean;
}

@Component({
    selector: 'app-folders-sdrs-list',
    templateUrl: './folders-sdrs-list.component.html',
    styleUrls: ['./folders-sdrs-list.component.scss'],
    standalone: false
})
export class FoldersSdrsListComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() clientsInfo;
  @Input() sdrsInfo;
  @Input() userId;
  @Output() assignSdr: EventEmitter<string> = new EventEmitter(null);
  @Output() removeSdr = new EventEmitter<any>();
  @Output() listUpdated: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('sdrAuto') sdrAutocomplete: MatAutocomplete;
  @ViewChild('sdrInput') sdrInput: ElementRef;
  @ViewChild('checkboxAll') checkboxAll!: MatCheckbox;

  userFolderSdrs: ISdr[] = [];

  @ViewChild('sdrsSort') sort: MatSort;
  @ViewChild('sdrsPaginator') paginator: MatPaginator;

  searchKey: string;
  filteredClients: Observable<string[]>;
  filteredSearchClients: Observable<string[]>;
  availableClients = [];
  originalAssignedSdrsList = [];
  availableSdrs = [];
  searchText = '';
  displayedColumns: string[] = ['select', 'sdr', 'inbox', 'manual', 'review', 'salesOperations', 'actions'];
  public sdrList = [];
  sdrControl = new UntypedFormControl('', [Validators.required]);
  removable = true;
  allSdrs: string[] = [];
  selectedSdrs: any[] = [];
  discrepancySdrs: ISdr[] = [];
  reviewDiscrepancySdrs: ISdr[] = [];
  salesOperationsSdrs: ISdr[] = [];

  separatorKeyCodes = [ENTER, COMMA, SEMICOLON];

  selection = new SelectionModel<any>(true, []);

  constructor(
    private dialog: MatDialog,
    private loadingStateService: LoadingStateService,
    private service: SdApiService,
    private snackBar: MatSnackBar,
  ) {}

  clients: string[] = [];
  clientCtrl = new FormControl();
  sdrCtrl = new FormControl();

  filteredSdrs = [];
  searchTerm: string = '';

  private _assignedSdrsList: MatTableDataSource<any>;

  clientFilterControl = new FormControl();
  clientSearchFilterControl = new FormControl();
  selectedClient: string = null;
  selectedSearchClient: string = null;
  allClientsObj = [];

  @Input()
  set assignedSdrsList(value: MatTableDataSource<any>) {
    if (value?.data) {
      this._assignedSdrsList = value;
      this.originalAssignedSdrsList = [...value.data];
    }
  }

  get assignedSdrsList(): MatTableDataSource<any> {
    return this._assignedSdrsList;
  }

  ngOnInit(): void {
    this.clients = this.availableClients = this.clientsInfo?.map((client) => client.name)?.sort();
    this.allClientsObj = this.clients;
    this.availableSdrs = this.sdrsInfo.filter((sdr) => sdr.userStatus === true);
    this.allSdrs = this.sdrsInfo.map((c) => c.email).sort();
    this.updateAvailableSdrs();

    this.filteredClients = this.clientFilterControl.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.name)),
      map((name) => (name ? this._filterClient(name) : this.availableClients.slice())),
    );

    this.filteredSearchClients = this.clientSearchFilterControl.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.name)),
      map((name) => (name ? this._filterClient(name) : this.availableClients.slice())),
    );

    this.clientCtrl.valueChanges.subscribe((client) => {
      this.filterSdrsByClient(client);
    });
    this.clientSearchFilterControl.valueChanges.subscribe((value) => {
      if (!value) {
        this.selectedSearchClient = '';
        this.updateSearchClients();
      }
    });
    this.clientFilterControl.valueChanges.subscribe((value) => {
      if (!value) {
        this.selectedClient = '';
        this.updateClients();
      }
    });
  }

  private _filterClient(client: string): string[] {
    const filterValue = client.toLowerCase();

    return this.availableClients.filter((e) => e.toLowerCase().includes(filterValue));
  }

  updateClients() {
    const clientInfo = this.clientsInfo.find((c) => c.name === this.selectedClient);
    if (clientInfo) {
      this.availableSdrs = clientInfo.sdrs
        .filter((sdr) => !this.originalAssignedSdrsList.find((s) => s.sdr === sdr.email))
        .sort();
      this.sdrCtrl.setValue('');
      this.filteredSdrs = this.availableSdrs;
    }
  }

  updateSearchClients() {
    if (this.selectedSearchClient != null && this.selectedSearchClient != '') {
      this.assignedSdrsList.data = this.originalAssignedSdrsList.filter(
        (s) => s.customerName === this.selectedSearchClient,
      );
      this.assignedSdrsList.filteredData = this.originalAssignedSdrsList.filter(
        (s) => s.customerName === this.selectedSearchClient,
      );
    } else {
      this.assignedSdrsList.data = this.assignedSdrsList.filteredData = this.originalAssignedSdrsList;
    }
    this.onClearSearch();
  }

  onInputChange(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value;
    console.log('Texto ingresado:', inputValue);

    this.filteredSdrs = this.getFilteredSdrs(inputValue);
    //this.filteredSdrs = this.availableSdrs.filter((sdr) => !this.sdrList.some((item) => item.email.toLowerCase().includes(inputValue.toLowerCase())));
  }

  getFilteredSdrs(filter: string): any[] {
    return this.filteredSdrs.filter((sdr) => sdr.email.toLowerCase().includes(filter.toLowerCase()));
  }

  selectSdr(sdr: any) {
    if (!this.selectedSdrs.includes(sdr)) {
      this.selectedSdrs.push(sdr);
    }
    this.searchTerm = '';
    this.filteredSdrs = this.allSdrs.filter((item) => !this.selectedSdrs.includes(item));
  }

  onSdrSelectOpened(opened: boolean) {
    if (!opened) {
      this.searchTerm = '';
      this.filteredSdrs = [...this.availableSdrs];
    }
  }

  filterSdrsByClient(client: string | null) {
    this.onChangeClient(client);
    this.filterSdrs();
  }

  filterSdrs() {
    const search = this.searchTerm.toLowerCase();
    this.filteredSdrs = this.availableSdrs.filter((sdr) => sdr.email.toLowerCase().includes(search));
  }

  ngAfterViewInit(): void {
    this.updateDataSourceAccessors();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.assignedSdrsList) {
      this.updateDataSourceAccessors();
      this.updateAvailableSdrs();
    }
  }

  updateDataSourceAccessors() {
    if (!this.assignedSdrsList) return;
    this.assignedSdrsList.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'sdr':
          return item.sdr;
        default:
          return item[property];
      }
    };
    this.assignedSdrsList.sort = this.sort;
    this.assignedSdrsList.paginator = this.paginator;
    this.assignedSdrsList.filterPredicate = (data, filter) => {
      return this.displayedColumns.some((ele) => {
        return ele === 'sdr' && data[ele].toLowerCase().indexOf(filter) != -1;
      });
    };
  }

  updateAvailableSdrs() {
    this.availableSdrs = this.availableSdrs
      .filter((sdr) => !this.assignedSdrsList.data.find((item) => item.sdr === sdr.email))
      .sort();
    this.filteredSdrs = this.availableSdrs;
    this.sdrCtrl.setValue('');
  }

  isSdrValid() {
    return this.sdrList.length > 0;
  }

  applySearch() {
    this.assignedSdrsList.filter = this.searchText.trim().toLowerCase();
  }

  onClearSearch() {
    this.searchText = '';
    this.applySearch();
    this.selection.clear();
    if (this.checkboxAll) {
      this.checkboxAll.checked = false;
    }
  }

  onAssignSdr() {
    if (!this.sdrCtrl.value) return;
    this.assignSdr.emit(this.sdrCtrl.value);
  }

  onChangeClient(client: string) {
    const clientInfo = this.clientsInfo.find((c) => c.name === client);
    if (clientInfo) {
      this.availableSdrs = clientInfo.sdrs
        .filter((sdr) => !this.originalAssignedSdrsList.find((s) => s.sdr === sdr.email))
        .sort();
      this.sdrCtrl.setValue('');
    }
  }

  onSearchChangeClient(client: string) {
    if (client != null && client != '') {
      this.assignedSdrsList.data = this.originalAssignedSdrsList.filter((s) => s.customerName === client);
    } else {
      this.assignedSdrsList.data = this.originalAssignedSdrsList;
    }
  }

  onRemoveSdr(sdrEmail) {
    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.data = {
      title: 'Confirm',
      message: 'Are you sure you want to unassign this sdr?',
    };
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, confirmDialogConfig);
    confirmDialogRef.afterClosed().subscribe((response) => {
      if (response) {
        this.updateSdrStatus(sdrEmail); 
      }
    });
  }

  updateSdrStatus(sdrEmail) {
    const sdr = this.getSDR(sdrEmail);
    this.assignedSdrsList.data.splice(
      this.assignedSdrsList.data.findIndex((item) => item.sdr === sdr.email),
      1,
    );
    this.assignedSdrsList._updateChangeSubscription();
    this.originalAssignedSdrsList = this.assignedSdrsList.data;
    this.clientCtrl.setValue(null);
    this.onChangeClient('');
    this.updateAvailableSdrs();
    this.removeSdr.emit(sdr);
  }

  displayFn(inputStr: string): string {
    return inputStr ? inputStr : '';
  }

  private _filterClients(val: string): string[] {
    if (val) {
      val = val.toLowerCase();
      return this.availableClients.filter((option) => option.toLowerCase().indexOf(val) !== -1);
    }

    return this.availableClients;
  }

  private _filterSdrs(val: string): string[] {
    if (val) {
      val = val.toLowerCase();
      return this.availableSdrs.filter((option) => option.email.toLowerCase().indexOf(val) !== -1);
    }

    return this.availableSdrs;
  }

  showSelectedSdrs() {
    const dialogRef = this.dialog
      .open(FolderSdrsAddComponent, {
        panelClass: 'my-custom-dialog-class',
        minWidth: '1150px',
        data: {
          sdrs: this.sdrList,
          operatorId: this.userId,
        },
      })
      .afterClosed()
      .subscribe((response) => {
        if (response) {
          this.service.listSdrByOperator(this.userId).subscribe((userData) => {
            this.userFolderSdrs = userData.filter((x) => !!x.email) || [];

            this.assignedSdrsList = new MatTableDataSource(
              this.userFolderSdrs.map((item) => {
                return {
                  $key: item.id,
                  sdr: item.email,
                  isDefault: item.isDefault || false,
                  isManualDiscrepancy: item.isManualDiscrepancy || false,
                  isReviewDiscrepancy: item.isReviewDiscrepancy || false,
                  isSalesOperator: item.isSalesOperation || false,
                };
              }),
            );
            this.searchTerm = '';
            this.sdrList = [];
            this.clientCtrl.setValue(null);
            this.selectedClient = '';
            this.updateClients();
            this.onChangeClient('');
            this.updateAvailableSdrs();
            this.listUpdated.emit();
          });
        }
      });
  }

  removeSdrs(data: any): void {
    if (this.sdrList.indexOf(data) >= 0) {
      this.sdrList.splice(this.sdrList.indexOf(data), 1);
    }
  }

  isValidEmail(email: string): boolean {
    const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    return emailPattern.test(email);
  }

  private validateSdr(sdr) {
    return this.allSdrs.includes(sdr);
  }

  addSdr(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if (!this.sdrAutocomplete.isOpen) {
      if ((value || '').trim()) {
        const emails = value
          .split(';')
          .map((email) => email.trim())
          .filter((email) => email);

        emails.forEach((email) => {
          const sdr = this.getSDR(email);
          if (this.isValidEmail(email) && this.validateSdr(email)) {
            this.sdrList.push({
              id: sdr.id,
              email: email,
              isDefault: false,
              isManualDiscrepancy: false,
              isReviewDiscrepancy: false,
              isSalesOperator: false,
              invalid: false,
            });
          } else {
            this.sdrList.push({
              id: sdr.id,
              email: email,
              isDefault: false,
              isManualDiscrepancy: false,
              isReviewDiscrepancy: false,
              isSalesOperator: false,
              invalid: true,
            });
          }
        });
      }

      if (input) {
        input.value = '';
      }
    }
    if (input) {
      input.value = '';
    }
  }

  updateSdrAuto(): void {
    const enteredSdr = this.sdrControl.value;
    const sdr = this.getSDR(enteredSdr);
    if (enteredSdr) {
      const exists = this.sdrList.some((item) => item.id === sdr.id);
      if (!exists) {
        if (this.validateSdr(enteredSdr)) {
          this.sdrList.push({
            id: sdr.id,
            email: enteredSdr,
            isDefault: false,
            isManualDiscrepancy: false,
            isReviewDiscrepancy: false,
            isSalesOperator: false,
            invalid: false,
          });
        } else {
          this.sdrList.push({
            id: sdr.id,
            email: enteredSdr,
            isDefault: false,
            isManualDiscrepancy: false,
            isReviewDiscrepancy: false,
            isSalesOperator: false,
            invalid: true,
          });
        }
        this.sdrControl.setValue(enteredSdr);
        this.sdrInput.nativeElement.value = '';
      }
    }
    //this.filteredSdrs = this.mergeSdrs();
  }

  onIsDefaultChange(event: any, element: any, folder: string) {
    if (event.checked) {
      this.onAssignCheckedSdr(element.sdr, folder);
    } else {
      var count = 0;
      if (element.isDefault) count += 1;
      if (element.isManualDiscrepancy) count += 1;
      if (element.isReviewDiscrepancy) count += 1;
      if (element.isSalesOperator) count += 1;
      this.onRemoveCheckedSdr(element.sdr, folder, count);
    }
  }
  updateFilteredSdrs() {
    this.filteredSdrs = this.availableSdrs.filter((sdr) => !this.sdrList.some((item) => item.email === sdr.email));
  }

  handleOperationError(message: string) {
    this.loadingStateService.setLoadingState(false);
    const errorConfirmDialogConfig = new MatDialogConfig();
    errorConfirmDialogConfig.data = {
      title: 'Error',
      message,
    };
    this.dialog.open(AlertDialogComponent, errorConfirmDialogConfig);
  }

  onAssignCheckedSdr(sdrEmail: string, folder: string) {
    const sdr = this.getSDR(sdrEmail);
    this.loadingStateService.setLoadingState(true);
    this.service.assignSdr({ user_id: sdr!.id, operator_id: this.userId, sdr_type: folder }).subscribe(
      (response) => {
        if (response.code === 200) {
          this.snackBar.open(`${sdr!.email} assigned successfully.`, undefined, { duration: MODAL_DURATION });
        } else {
          this.handleOperationError(response.error);
        }
        this.loadingStateService.setLoadingState(false);
      },
      (error) => {
        this.handleOperationError('An error ocurred while assigning sdr.');
      },
    );
  }

  onRemoveCheckedSdr(sdrEmail: string, folder: string, count: number) {
    const sdr = this.getSDR(sdrEmail);
    this.loadingStateService.setLoadingState(true);
    this.service.removeSdr({ user_id: Number.parseInt(sdr.id), operator_id: this.userId, sdr_type: folder }).subscribe(
      (response) => {
        this.snackBar.open(`${sdr.email} was successfully unassigned`, undefined, { duration: MODAL_DURATION });
        this.loadingStateService.setLoadingState(false);
        if (count == 0) {
          this.updateSdrStatus(sdrEmail);
        }
      },
      (error) => {
        this.handleOperationError('An error ocurred while unassigning sdr');
      },
    );
  }

  getSDR(email: string) {
    return this.sdrsInfo.find((sdr) => sdr.email === email);
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.assignedSdrsList.filteredData.forEach((row) => this.selection.select(row));
    }
  }

  toggleRow(row) {
    this.selection.toggle(row);
  }

  isAllSelected() {
    return this.selection.selected.length === this.assignedSdrsList.data.length;
  }

  isAllFolderSelected(property: string): boolean {
    return this.assignedSdrsList.data.every((element) => element[property]);
  }

  isPartialSelected() {
    return this.selection.selected.length > 0 && !this.isAllSelected();
  }

  deleteSelected() {
    this.loadingStateService.setLoadingState(true);
    const selectedIds = this.selection.selected.map((row) => row.$key);
    this.service.deleteUsersFolders(this.userId, selectedIds).subscribe(
      (response) => {
        this.loadingStateService.setLoadingState(false);
        this.assignedSdrsList.data = this.assignedSdrsList.data.filter((row) => !selectedIds.includes(row.$key));
        this.originalAssignedSdrsList = this.originalAssignedSdrsList.filter((row) => !selectedIds.includes(row.$key));
        this.selection.clear();
        this.onChangeClient('');
        this.updateAvailableSdrs();
        this.snackBar.open(`The sdrs was successfully deleted.`, undefined, { duration: MODAL_DURATION });
      },
      (error) => {
        console.log(error);
        this.handleOperationError('An error ocurred while removing the sdr.');
      },
    );
  }

  updateSelected() {
    this.loadingStateService.setLoadingState(true);
    var result = [];
    var selectedIds = [];
    this.selection.selected.map((item) => {
      let folders = [];

      if (item.isDefault) {
        folders.push('default');
      }
      if (item.isManualDiscrepancy) {
        folders.push('manual_discrepancies');
      }
      if (item.isReviewDiscrepancy) {
        folders.push('review_discrepancies');
      }
      if (item.isSalesOperator) {
        folders.push('sales_operator');
      }
      if (folders.length === 0) {
        selectedIds.push(item.$key);
      }
      result.push({ 'sdr-id': item.$key, folders: folders });
    });
    this.service.updateUsersFolders(this.userId, result).subscribe(
      (response) => {
        this.loadingStateService.setLoadingState(false);
        this.assignedSdrsList.data = this.assignedSdrsList.data.filter((row) => !selectedIds.includes(row.$key));
        this.snackBar.open(`The sdrs was successfully assigned.`, undefined, { duration: MODAL_DURATION });
      },
      (error) => {
        console.log(error);
        this.handleOperationError('An error ocurred while removing the sdr.');
      },
    );

    this.selection.clear();
  }

  toggleAll(event: MatCheckboxChange, property: string): void {
    const isChecked = event.checked;
    this.assignedSdrsList.data.forEach((element) => {
      element[property] = isChecked;
    });
  }
}

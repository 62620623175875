import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { finalize, map, takeUntil } from 'rxjs/operators';
import { GenericDataMapping } from '../../model/common/generic-map-data.model';
import { MessageFeedDto } from '../../model/sdr-conversations/message-feed.dto';
import { Conversation, SdrConversation } from '../../model/sdr-conversations/sdrs-conversations.model';
import { SdrConversationsService } from '../../services/sdr-conversations.service';
import { SnackBarService } from '../../services/snackbar/snackbar.service';

@Component({
  selector: 'app-sdr-conversations',
  templateUrl: './sdr-conversations.component.html',
  styleUrls: ['./sdr-conversations.component.scss'],
  standalone: false,
})
export class SdrConversationsComponent implements OnInit, OnDestroy {
  originalSdrConversations: SdrConversation[] = [];
  filteredSdrConversations: SdrConversation[] = [];
  selectedConversation: Conversation = null;
  showExtraInfoSidenav = false;
  hasInteractions = false;
  protected isLoadingSdrsConversations = false;
  protected folders: GenericDataMapping[] = [
    { title: 'Inbox', value: 'INBOX' },
    { title: 'Converted', value: 'Converted' },
    { title: 'Trash', value: 'Trash_Deleted_Items' },
  ];
  protected conversationTypes: GenericDataMapping[] = [
    { title: 'All', value: '' },
    { title: 'Direct Message', value: 'DIRECT_MESSAGE' },
    { title: 'Group Chat', value: 'GROUP_CHAT' },
    { title: 'In Mail', value: 'IN_MAIL' },
  ];
  protected selectedFolder = '';
  protected selectedConversationType = '';
  protected sdrs: GenericDataMapping[] = [];
  protected selectedSdrId: number = null;
  protected hasMoreData = true;
  protected totalCount = 0;
  private page = 1;
  private pageSize = 50;
  private destroy$: Subject<boolean> = new Subject();

  get loadMoreDisabled(): boolean {
    const totalConversations =
      this.filteredSdrConversations?.reduce((acc, item) => acc + (item.conversations?.length || 0), 0) || 0;

    return this.isLoadingSdrsConversations || !this.hasMoreData || totalConversations == this.totalCount;
  }

  constructor(
    private sdrConversationsService: SdrConversationsService,
    private snackBarService: SnackBarService,
  ) {
    inject(BreakpointObserver)
      .observe([Breakpoints.WebLandscape])
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        for (const query of Object.keys(result.breakpoints)) {
          this.showExtraInfoSidenav = !result.breakpoints[query];
        }
      });
  }

  ngOnInit(): void {
    this.sdrConversationsService.setSelectedFolder(this.folders[0].value);
    this.sdrConversationsService.setSelectedConversationType(this.conversationTypes[0].value);

    this.sdrConversationsService.sdrsConversations$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (sdrConversations: SdrConversation[]) => {
        this.originalSdrConversations = sdrConversations || [];
        this.filteredSdrConversations = sdrConversations || [];

        this.setSdrs(this.filteredSdrConversations);
      },
    });

    this.sdrConversationsService.reloadConversations$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (reload: boolean) => {
        if (reload) {
          this.loadSdrsConversations(this.selectedFolder);
        }
      },
    });

    this.sdrConversationsService.selectedConversation$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (selectedConversation: Conversation) => {
        this.selectedConversation = selectedConversation ? { ...selectedConversation } : null;
      },
    });

    this.sdrConversationsService.hasInteractions$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (hasInteractions: boolean) => {
        this.hasInteractions = hasInteractions;
      },
    });

    this.sdrConversationsService.selectedFolder$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (selectedFolder: string) => {
        if (selectedFolder) {
          this.clearObs$();
          this.selectedFolder = selectedFolder;
          this.loadSdrsConversations(selectedFolder);
        }
      },
    });

    this.filterConversations$();
    this.totalCount$();
  }

  ngOnDestroy(): void {
    this.clearObs$();
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  totalCount$(): void {
    this.sdrConversationsService.totalCount$.subscribe({
      next: (totalCount: number) => {
        this.totalCount = totalCount;
      },
    });
  }

  filterConversations$(): void {
    combineLatest([this.sdrConversationsService.selectedSdr$, this.sdrConversationsService.selectedConversationType$])
      .pipe(
        takeUntil(this.destroy$),
        map(([sdrId, selectedConversationType]) => {
          this.selectedSdrId = sdrId;
          this.selectedConversationType = selectedConversationType;

          if (!sdrId && !selectedConversationType) {
            return this.originalSdrConversations;
          }

          let filteredData = this.originalSdrConversations;
          if (sdrId) {
            filteredData = filteredData.filter((sdr) => sdr.sdrId === sdrId);
          }

          if (selectedConversationType) {
            filteredData = filteredData
              .filter((sdr) =>
                sdr.conversations.some((conversation) => conversation.conversationType === selectedConversationType),
              )
              .map((sdr) => ({
                ...sdr,
                conversations: sdr.conversations.filter(
                  (conversation) => conversation.conversationType === selectedConversationType,
                ),
              }));
          }

          return filteredData;
        }),
      )
      .subscribe({
        next: (filteredConversations) => {
          this.filteredSdrConversations = filteredConversations;

          if (filteredConversations?.length > 0) {
            this.sdrConversationsService.setSelectedConversation(filteredConversations[0].conversations[0]);
          }
        },
        error: () => this.snackBarService.showError('An error occurred while filtering conversations'),
      });
  }

  setSdrs(sdrConversations: SdrConversation[]): void {
    this.sdrs = [
      { title: 'All', value: null },
      ...Array.from(
        new Map(sdrConversations.map((sdr) => [sdr.sdrId, { title: sdr.sdrName, value: sdr.sdrId }])).values(),
      ),
    ];
    this.sdrConversationsService.setSelectedSdr(null);
  }

  loadSdrsConversations(folder: string) {
    if (this.isLoadingSdrsConversations || !this.hasMoreData) return;
    const messageFeedDto: MessageFeedDto = {
      page: this.page,
      pageSize: this.pageSize,
      folderName: folder,
    };
    this.isLoadingSdrsConversations = true;
    this.sdrConversationsService
      .getSdrsConversationsByFolder(messageFeedDto)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.isLoadingSdrsConversations = false;
          this.sdrConversationsService.setReloadConversations(false);
        }),
      )
      .subscribe({
        next: (response) => {
          if (this.page === 1) {
            this.setDefaultConversation();
          }

          if (!response.length) {
            this.hasMoreData = false;
          }
        },
        error: () => {
          this.isLoadingSdrsConversations = false;
          this.snackBarService.showError('An error occurred while retrieving conversations');
        },
      });
  }

  setDefaultConversation() {
    this.sdrConversationsService.setSelectedConversation(this.originalSdrConversations?.[0]?.conversations[0] || null);
  }

  onSelectFolder(folder: string) {
    this.page = 1;
    this.hasMoreData = true;
    this.sdrConversationsService.setSelectedFolder(folder);
    this.sdrConversationsService.setSelectedConversation(null);
    this.sdrConversationsService.setCurrentConversationMessages(null);
  }

  onSelectConversationType(conversationType: string) {
    this.sdrConversationsService.setSelectedConversationType(conversationType);
  }

  onSelectSdr(sdrId: number) {
    this.sdrConversationsService.setSelectedSdr(sdrId);
  }

  onRefreshConversations() {
    this.page = 1;
    this.hasMoreData = true;
    this.clearObs$();
    this.loadSdrsConversations(this.selectedFolder);
    this.sdrConversationsService.setSelectedConversationType(this.conversationTypes[0].value);
    this.sdrConversationsService.setSelectedSdr(null);
  }

  clearObs$() {
    this.sdrConversationsService.setSdrsConversations(null);
    this.sdrConversationsService.setSelectedConversation(null);
    this.sdrConversationsService.setCurrentConversationMessages(null);
  }

  protected loadMoreConversations() {
    this.page++;
    this.loadSdrsConversations(this.selectedFolder);
  }
}

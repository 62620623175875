<div
  class="operator-user-form"
  [formGroup]="operatorUserForm"
>
  <div class="operator-user-form__row">
    <mat-form-field
      class="form-field-5 fx-50"
      appearance="outline"
      subscriptSizing="dynamic"
      id="operator-user-form-name-mat-form-field"
    >
      <mat-label>Name</mat-label>
      <input
        matInput
        formControlName="name"
        id="operator-user-form-name-input"
      />
    </mat-form-field>
    <mat-form-field
      class="form-field-5 fx-50"
      appearance="outline"
      subscriptSizing="dynamic"
      id="operator-user-form-last-name-mat-form-field"
    >
      <mat-label>Last Name</mat-label>
      <input
        matInput
        formControlName="last_name"
        id="operator-user-form-last-name-input"
      />
    </mat-form-field>
  </div>
  <div class="operator-user-form__row">
    <mat-form-field
      class="form-field-5 fx-100"
      appearance="outline"
      subscriptSizing="dynamic"
      id="operator-user-form-email-mat-form-field"
    >
      <mat-label>Email</mat-label>
      <input
        matInput
        formControlName="email"
        id="operator-user-form-email-input"
      />
    </mat-form-field>
  </div>
  <div class="operator-user-form__row">
    <mat-form-field
      class="form-field-5 fx-100"
      appearance="outline"
      subscriptSizing="dynamic"
      id="operator-user-form-password-mat-form-field"
    >
      <mat-label>Password</mat-label>
      <input
        matInput
        type="password"
        formControlName="password"
        [pattern]="passwordValidator"
        id="operator-user-form-password-input"
      />
      <mat-error *ngIf="operatorUserForm.get('password')?.invalid">
        Password does not meet strength conditions (Minimum eight and maximum 10 characters, at least one uppercase
        letter, one lowercase letter, one number and one special character).
      </mat-error>
    </mat-form-field>
  </div>
  <label>Operator Language(s)</label>
  <div class="operator-user-form__language-container">
    <ng-container>
      <mat-checkbox
        class="operator-user-form__language-checkbox"
        *ngFor="let language of suportedLanguages$ | async"
        color="primary"
        [checked]="operatorUserForm?.get('language')?.value?.includes(language.shortName)"
        (change)="handleLanguageCheck($event.checked, language.shortName)"
        id="operator-user-form-language-mat-checkbox"
      >
        <app-flag-viewer
          [languages]="[language]"
          [showFullName]="true"
        ></app-flag-viewer>
      </mat-checkbox>
    </ng-container>
  </div>
</div>

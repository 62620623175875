<div class="sdr-conversation-classification">
  <div class="sdr-conversation-classification__button-container">
    <ng-container *ngFor="let status of statuses; let i = index">
      <button
        mat-stroked-button
        color="primary"
        class="sdr-conversation-classification__button"
        [disabled]="!selectedInteraction || !selectedMessages?.length"
        (click)="handleClassification($event, status)"
      >
        {{ status.ruleDescription }}
      </button>
    </ng-container>
  </div>
</div>

<div
  class="information"
  [ngClass]="{ 'information--with-sales-rep': messageSalesRepresentative }"
>
  <div class="information__sales-rep">
    <span *ngIf="messageSalesRepresentative">{{ messageSalesRepresentative }}</span>
    <mat-icon
      *ngIf="messageSalesRepresentative"
      class="information__sales-rep-clear-icon"
      (click)="clearSalesRepresentative()"
      >close</mat-icon
    >
  </div>
  <div class="information__from-container">
    From <b class="information__sdr-name">{{ currentSdr.sdrName }}</b>
  </div>
</div>
<div
  class="send-message"
  [ngClass]="{ 'send-message--with-sales-rep': messageSalesRepresentative }"
>
  <mat-form-field
    subscriptSizing="dynamic"
    appearance="outline"
    class="send-message__field-message"
    id="form-field-message"
  >
    <textarea
      matInput
      #messageTextarea
      placeholder="Type a message"
      class="send-message__textarea"
      [formControl]="messageCtrl"
      (input)="adjustTextareaHeight(messageTextarea)"
    ></textarea>
  </mat-form-field>
  <div class="send-message__actions">
    <button
      mat-icon-button
      (click)="selectSalesRep()"
      [disableRipple]="true"
      class="select-template-btn"
      matTooltip="Select Sales Representative"
      id="mail-composer-select-sales-representative-btn"
    >
      <mat-icon class="send-message__support-agent-icon">support_agent</mat-icon>
    </button>
    <button
      mat-flat-button
      color="primary"
      [disabled]="!messageCtrl.value || !isConnectionValid"
      (click)="sendMessage()"
    >
      <span *ngIf="!isSendingMessage">Send</span>
      <mat-spinner
        *ngIf="isSendingMessage"
        diameter="20"
      ></mat-spinner>
    </button>
  </div>
</div>

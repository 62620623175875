<div class="address-suggestion">
  <div class="address-suggestion__title">Address Suggestion Management</div>

  <div class="form-address-suggestion">
    <span class="form-address-suggestion__title">Add Address:</span>
    <mat-form-field
      appearance="outline"
      class="form-address-suggestion__client-field form-field-5"
      subscriptSizing="dynamic"
      id="address-suggestion-client-mat-form-field"
    >
      <mat-label>Choose a client</mat-label>
      <mat-select
        name="availableClientsDropdown"
        [(ngModel)]="selectedClient"
        #clientsSelect
        id="address-suggestion-client-mat-select"
      >
        <mat-option
          [value]="client"
          *ngFor="let client of clients"
          id="address-suggestion-client-mat-option"
        >
          {{ client }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="form-address-suggestion__add-container">
      <mat-form-field
        appearance="outline"
        class="form-address-suggestion__add-field form-field-5"
        subscriptSizing="dynamic"
        id="address-suggestion-email-mat-form-field"
      >
        <mat-label>Email Address</mat-label>
        <input
          matInput
          [(ngModel)]="emailAddress"
          type="mail"
          [pattern]="emailPattern"
          #emailAddressElement="ngModel"
          id="address-suggestion-email-input"
        />
        <mat-icon
          matSuffix
          *ngIf="emailAddress"
          (click)="onEmailClear()"
          id="address-suggestion-email-mat-icon-clear"
          >close</mat-icon
        >
      </mat-form-field>
      <button
        mat-raised-button
        color="primary"
        (click)="addAddress()"
        [disabled]="!clientsSelect.selected || !emailAddress || emailAddressElement.errors?.pattern"
        id="address-suggestion-add-btn"
      >
        <mat-icon>add_circle</mat-icon>ADD
      </button>
    </div>
  </div>

  <div class="mat-elevation-z8 address-suggestion-table">
    <mat-form-field
      class="address-suggestion-table__search-field form-field-5"
      appearance="outline"
      subscriptSizing="dynamic"
      id="address-suggestion-search-mat-form-field"
    >
      <mat-label>Search Addresses</mat-label>
      <input
        matInput
        [(ngModel)]="searchKey"
        autocomplete="off"
        (keyup)="applyFilter()"
        id="address-suggestion-search-input"
      />
      <mat-icon
        matSuffix
        *ngIf="searchKey"
        (click)="onSearchClear()"
        id="address-suggestion-search-mat-icon-clear"
        >close</mat-icon
      >
    </mat-form-field>
    <mat-table
      [dataSource]="listData"
      matSort
      matSortActive="client"
      matSortDirection="asc"
      matSortDisableClear
    >
      <ng-container matColumnDef="client">
        <mat-header-cell
          class="address-suggestion-table__client-cell"
          *matHeaderCellDef
          mat-sort-header
        >
          Client
        </mat-header-cell>
        <mat-cell
          class="address-suggestion-table__client-cell"
          *matCellDef="let element"
        >
          {{ element.client }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="email">
        <mat-header-cell
          class="address-suggestion-table__email-cell"
          *matHeaderCellDef
          mat-sort-header
          >Email</mat-header-cell
        >
        <mat-cell
          class="address-suggestion-table__email-cell"
          *matCellDef="let element"
        >
          <span
            class="address-suggestion-table__email-span"
            *ngIf="!element.isEditing"
            >{{ element.email }}</span
          >
          <mat-form-field
            class="mail-set-form-field"
            floatLabel="never"
            *ngIf="element.isEditing"
            class="address-suggestion-table__email-form-field"
          >
            <input
              matInput
              [(ngModel)]="element.email"
              placeholder="Email Address"
              type="mail"
              [pattern]="emailPattern"
              #emailAddressElement="ngModel"
            />
          </mat-form-field>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell
          class="address-suggestion-table__actions-cell"
          *matHeaderCellDef
          >Actions</mat-header-cell
        >
        <mat-cell
          class="address-suggestion-table__actions-cell"
          *matCellDef="let element"
        >
          <button
            class="address-suggestion-table__action-btn"
            mat-icon-button
            *ngIf="!element.isEditing"
            (click)="editAddress(element.id)"
            id="address-suggestion-edit-address-btn"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            class="address-suggestion-table__action-btn"
            mat-icon-button
            *ngIf="element.isEditing"
            (click)="updateAddress(element.id, element.client, element.email)"
            [disabled]="false"
            id="address-suggestion-update-address-btn"
          >
            <mat-icon>done</mat-icon>
          </button>
          <button
            class="address-suggestion-table__action-btn"
            mat-icon-button
            *ngIf="element.isEditing"
            (click)="cancelEdit(element.id)"
            id="address-suggestion-cancel-edit-address-btn"
          >
            <mat-icon>not_interested</mat-icon>
          </button>
          <button
            class="address-suggestion-table__action-btn"
            mat-icon-button
            *ngIf="!element.isEditing"
            (click)="removeAddress(element.id)"
            id="address-suggestion-remove-address-btn"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="loading">
        <mat-footer-cell
          *matFooterCellDef
          colspan="2"
        >
          Loading data...
        </mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="noData">
        <mat-footer-cell
          *matFooterCellDef
          colspan="2"
        >
          No data.
        </mat-footer-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      <mat-footer-row
        *matFooterRowDef="['loading']"
        [ngClass]="{ hide: listData !== null }"
      ></mat-footer-row>
      <mat-footer-row
        *matFooterRowDef="['noData']"
        [ngClass]="{ hide: !(listData !== null && listData.data.length === 0) }"
      ></mat-footer-row>
    </mat-table>
    <mat-paginator
      [pageSize]="10"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>

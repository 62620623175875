import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { finalize, map, takeUntil } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../../../components/confirm-dialog/confirm-dialog.component';
import { ClassificationStatusEnum } from '../../../model/common/classification-status.enum';
import { ClassifyTrashMessagesDto } from '../../../model/sdr-conversations/classify-trash-messages.dto';
import { ConversationMessage, Message } from '../../../model/sdr-conversations/conversation-mesage.model';
import { Conversation } from '../../../model/sdr-conversations/sdrs-conversations.model';
import { SdrConversationsService } from '../../../services/sdr-conversations.service';
import { SnackBarService } from '../../../services/snackbar/snackbar.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  standalone: false,
})
export class MessageComponent implements OnInit, OnDestroy {
  selectedConversation: Conversation = null;
  messages: Message[] = [];
  conversation: ConversationMessage = null;
  enableDeleteMessages = false;
  enableClassify = false;

  private hasSelectedMessagesSubject = new BehaviorSubject<boolean>(this.checkIfSelected());
  hasSelectedMessages$ = this.hasSelectedMessagesSubject.asObservable();
  private isAllSelectedSubject = new BehaviorSubject<boolean>(this.checkIfSelected());
  isAllSelected$ = this.isAllSelectedSubject.asObservable();
  private destroy$: Subject<boolean> = new Subject();
  indeterminate$ = combineLatest([this.hasSelectedMessages$, this.isAllSelected$]).pipe(
    map(([hasSelectedMessages, isAllSelected]) => hasSelectedMessages && !isAllSelected),
  );
  isAllSelectedTooltip$ = this.isAllSelected$.pipe(
    map((isAllSelected) => (isAllSelected ? 'Unselect All' : 'Select All')),
  );
  protected selectedFolder = '';
  protected participantLinkedInUrl = '';

  get existInboundMessages(): boolean {
    return this.messages.some((m) => m.isInbound && m.messageStatus === 'INBOX' && !m.isTemp);
  }

  constructor(
    private sdrConversationsService: SdrConversationsService,
    private matDialog: MatDialog,
    private snackBarService: SnackBarService,
  ) {}

  ngOnInit(): void {
    this.sdrConversationsService.selectedConversation$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (selectedConversation: Conversation) => {
        if (selectedConversation) {
          this.selectedConversation = { ...selectedConversation };
          this.loadConversationMessages(this.selectedConversation?.conversationId);
        }
      },
    });

    this.sdrConversationsService.selectedFolder$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (selectedFolder: string) => {
        this.selectedFolder = selectedFolder;
      },
    });

    this.sdrConversationsService.currentConversationMessages$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (conversationMessages: ConversationMessage) => {
        this.conversation = this.messagesWithParticipantName(conversationMessages);
        this.messages = conversationMessages?.messages?.map((message) => ({ ...message, selected: false })) || [];
        this.participantLinkedInUrl = this.getParticipantLinkedInUrl();
        this.cancelSelection();
      },
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  messagesWithParticipantName(data) {
    const { messages, participants } = data || {};

    const participantMap = participants?.reduce((map, participant) => {
      map[participant.encryptedUrl] = `${participant.firstName} ${participant.lastName}`;
      return map;
    }, {});

    messages?.forEach((message) => {
      message.participantName = participantMap[message.senderIdentifier] || 'Unknown';
    });

    return data;
  }

  getParticipantLinkedInUrl(): string {
    const senderIdentifier = this.messages?.find((x) => x.isInbound)?.senderIdentifier;
    const participant = this.conversation?.participants?.find((p) => p.encryptedUrl === senderIdentifier);
    return participant ? `https://linkedin.com${participant.encryptedUrl}` : '';
  }

  cancelSelection() {
    this.toggleAllMessages(false);
    this.resetObservables();
    this.enableDeleteMessages = false;
    this.enableClassify = false;
  }

  checkIfAllSelected() {
    const numSelected = this.messages.filter(
      (m) => m?.selected === true && m?.messageStatus === 'INBOX' && !m?.isTemp,
    ).length;
    const numInbound = this.messages.filter(
      (m) => m?.isInbound === true && m?.messageStatus === 'INBOX' && !m?.isTemp,
    ).length;

    this.isAllSelectedSubject.next(numSelected === numInbound ? true : false);
  }

  checkIfSelected(): boolean {
    return this.messages?.some((m) => m.selected === true);
  }

  classifyTrashMessages(): void {
    const messageHandlerIds = this.messages
      .filter(
        (message) => message.isInbound && message.selected && message.messageStatus === 'INBOX' && !message?.isTemp,
      )
      .map((m) => m.messageHandlerId);

    const classifyTrashMessagesDto: ClassifyTrashMessagesDto = {
      sdrId: this.sdrConversationsService.findSdrdByConversationId(this.selectedConversation.conversationId)?.sdrId,
      messageHandlerIds: messageHandlerIds,
    };

    this.sdrConversationsService.classifyTrashMessages(classifyTrashMessagesDto).subscribe({
      next: () => {
        this.snackBarService.showSuccess('The messages were classified successfully');
        this.sdrConversationsService.updateConversation(ClassificationStatusEnum.Trash);
      },
      error: () => {
        this.snackBarService.showError('An error occurred while trying to classify messages');
      },
    });
  }

  openConfirmClassifyTrashMessages() {
    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.data = {
      title: 'Confirm',
      message: 'Are you sure you want to classify the selected messages as trash?',
    };
    const confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, confirmDialogConfig);
    confirmDialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.classifyTrashMessages();
      }
    });
  }

  resetObservables() {
    this.hasSelectedMessagesSubject.next(false);
    this.isAllSelectedSubject.next(false);
    this.sdrConversationsService.setSelectedMessages(null);
  }

  setSelectedMessages() {
    const selectedMessages = this.messages?.filter((m) => m.selected);
    this.sdrConversationsService.setSelectedMessages(selectedMessages);
  }

  toggleAllMessages(isSelected: boolean = false) {
    this.messages = this.messages.map((message) => ({
      ...message,
      selected: message?.isInbound && !message.isTemp && message.messageStatus === 'INBOX' ? isSelected : false,
    }));
    this.hasSelectedMessagesSubject.next(this.checkIfSelected());
    this.isAllSelectedSubject.next(isSelected);
    this.setSelectedMessages();
  }

  toggleMessage(messageHandlerId: string, isSelected: boolean): void {
    const message = this.messages.find((m) => m.messageHandlerId === messageHandlerId);

    if (message) {
      message.selected = isSelected;
      this.hasSelectedMessagesSubject.next(this.checkIfSelected());
      this.checkIfAllSelected();
    }

    this.setSelectedMessages();
  }

  private loadConversationMessages(conversationId: string) {
    this.sdrConversationsService.setIsLoadingConversationMessages(true);
    this.sdrConversationsService
      .getMessagesByConversationId(conversationId)
      .pipe(finalize(() => this.sdrConversationsService.setIsLoadingConversationMessages(false)))
      .subscribe({
        next: (conversationMessage) => {
          this.sdrConversationsService.setCurrentConversationMessages(conversationMessage);
        },
        error: () => {
          this.sdrConversationsService.setCurrentConversationMessages(null);
          this.snackBarService.showError('Error loading messages of the conversation');
        },
      });
  }

  openLinkedInProfile(url: string): void {
    if (url) {
      window.open(url, '_blank');
    }
  }
}

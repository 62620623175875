import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  SecurityContext,
  ViewChild,
} from '@angular/core';
import { MAT_AUTOCOMPLETE_DEFAULT_OPTIONS } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Subject, Subscription } from 'rxjs';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { AlertDialogComponent } from '../../../components/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from '../../../components/confirm-dialog/confirm-dialog.component';
import { MailStatus } from '../../../model/common/mail-status.enum';
import { ComposeEmail } from '../../../model/ComposeEmail';
import { ComposeEmailCategory } from '../../../model/ComposeEmailCategory';
import { FOLDERS } from '../../../model/Email';
import { LeadProfile } from '../../../model/lead-profile.model';
import { Sdr } from '../../../model/Sdr';
import { AppConstants } from '../../../resources/app-constants';
import { SdApiService } from '../../../services/base/sd-api.service';
import { ComposerMailService } from '../../../services/composer-mail.service';
import { InquiryService } from '../../../services/inquiry.service';
import { InteractionService } from '../../../services/interaction.service';
import { MessageAttachmentService } from '../../../services/message-attachment.service';
import { SnackBarService } from '../../../services/snackbar/snackbar.service';
import { MAX_BODY_SIZE, MAX_NUMBER_OF_ATTACHMENTS } from '../../../utils/constants';
import { RecipientFieldsComponent } from './recipient-fields/recipient-fields.component';
import { SelectSalesRepresentativeComponent } from './select-sales-representative/select-sales-representative.component';
import { SelectTemplateComponent } from './select-template/select-template.component';

declare var $: any;

interface IDialogData {
  currentFolder: string;
  lead: string;
}

@Component({
  selector: 'app-mail-composer',
  templateUrl: './mail-composer.component.html',
  styleUrls: ['./mail-composer.component.scss'],
  providers: [
    {
      provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
      useValue: { overlayPanelClass: 'autocomplete-overlay-pane' },
    },
  ],
})
export class MailComposerComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(RecipientFieldsComponent) recipientFields: RecipientFieldsComponent;
  @ViewChild('composerPreviewIframe') iframe: ElementRef;
  @ViewChild('inputFileControl', { read: ElementRef }) inputFileControl: ElementRef;
  @ViewChild('modalBar', { read: ElementRef }) modalBar: ElementRef;
  chainMessageSeparator = this.sanitizer.sanitize(
    SecurityContext.HTML,
    this.sanitizer.bypassSecurityTrustHtml('------------------------------------'),
  );
  public Editor = ClassicEditor;
  currentComposedEmail: ComposeEmail;
  messageChain: string;
  renderedBody: string;
  sdrs: Sdr[];
  htmlData: string;
  editorHtml: string;
  initialized: boolean;
  switchAllowed: boolean;
  switched: boolean;
  blockedSdrs: string[];
  iframeActive = false;
  viewEmailServiceSubscription: Subscription;
  subscriptions: Subscription = new Subscription();
  selectedTemplate;
  selectedSalesRep;
  templateText: string;
  categoryType = ComposeEmailCategory;
  leadCopied = false;
  isLoadingLead = false;
  destroy$ = new Subject<boolean>();
  isReplyReady = true;
  isIntroductionReady = true;

  constructor(
    public dialogRef: MatDialogRef<MailComposerComponent>,
    private composerMailService: ComposerMailService,
    private sdApiService: SdApiService,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    public messageAttachmentService: MessageAttachmentService,
    private inquiryService: InquiryService,
    @Inject(MAT_DIALOG_DATA) public dialogData: IDialogData,
    private snackbar: MatSnackBar,
    private interactionService: InteractionService,
    private snackBarService: SnackBarService,
  ) {}

  ngOnInit() {
    this.isReplyReady = true;
    this.isIntroductionReady = true;
    this.subscriptions.add(
      this.composerMailService.composedEmail.subscribe((composedEmail) => {
        if (composedEmail) {
          this.currentComposedEmail = composedEmail;
          this.messageChain = this.currentComposedEmail.bodyHtml;

          if (!this.initialized) {
            if (composedEmail?.category != ComposeEmailCategory.Resend) {
              this.messageChain = '<br>' + this.chainMessageSeparator + this.messageChain;
            } else {
              this.messageChain = this.chainMessageSeparator + this.messageChain;
            }
          } else {
            this.messageChain = this.chainMessageSeparator + this.messageChain;
          }
          this.switchAllowed = composedEmail?.category != ComposeEmailCategory.New;

          if (!composedEmail?.includeChainInEditor) {
            this.renderedBody = this.currentComposedEmail.currentEdition;
            (window as any).editorHtml = this.currentComposedEmail.currentEdition;
          }

          if (composedEmail?.template) {
            this.selectedTemplate = composedEmail.template;
            if (this.selectedTemplate.type_id === 1) {
              this.checkInteractionExist(MailStatus.Reply, false);
            }
            if (this.selectedTemplate.type_id === 2) {
              this.checkInteractionExist(MailStatus.Introduction, false);
            }
          }
        } else {
          (window as any).editorHtml = '';
        }
      }),
    );

    if (this.dialogData?.currentFolder === FOLDERS.REVIEW.toLowerCase()) {
      this.sdApiService.userSdrsReview.pipe(take(1)).subscribe((sdrs) => {
        this.sdrs = sdrs;
      });
    } else if (this.dialogData?.currentFolder === FOLDERS.SENT.toLowerCase()) {
      this.sdApiService.userSdrsSent.pipe(take(1)).subscribe((sdrs) => {
        this.sdrs = sdrs;
      });
    } else if (this.dialogData?.currentFolder === FOLDERS.SALE_OPERATORS.toLowerCase()) {
      this.sdApiService.userSdrsSales.pipe(take(1)).subscribe((sdrs) => {
        this.sdrs = sdrs;
      });
    } else {
      this.sdApiService.userSDRs.pipe(take(1)).subscribe((sdrs) => {
        this.sdrs = sdrs;
      });
    }

    this.subscriptions.add(
      this.sdApiService.blockedSdrs.subscribe((blockedSdrs) => {
        this.blockedSdrs = blockedSdrs;
      }),
    );

    this.composerMailService.isMailComposerMinimized
      .pipe(takeUntil(this.destroy$))
      .subscribe((isMailComposerMinimized) => {
        isMailComposerMinimized && this.updateBody();
      });
  }

  ngAfterViewInit() {
    const htmlElementColl: HTMLCollectionOf<HTMLElement> = document.getElementsByClassName(
      'note-editable',
    ) as HTMLCollectionOf<HTMLElement>;
    this.subscriptions.add(
      this.composerMailService.composedEmail.subscribe((composedEmail) => {
        if (composedEmail) {
          if (!composedEmail?.includeChainInEditor) {
            this.renderMessageReference();
            htmlElementColl[0].style.maxHeight = '200px';
            $('#myId').summernote('code', (window as any).editorHtml);
          } else {
            if (composedEmail?.category != ComposeEmailCategory.New) {
              if (!this.renderedBody || !this.renderedBody.includes(this.messageChain.substring(0, 30))) {
                if (!this.currentComposedEmail.restored) {
                  this.renderedBody = this.currentComposedEmail.currentEdition
                    ? this.currentComposedEmail.currentEdition
                    : this.currentComposedEmail.currentEdition + this.messageChain;
                } else {
                  this.renderedBody = this.currentComposedEmail.currentEdition;
                }
              } else {
                if (this.initialized && !this.switched) {
                  this.renderedBody = (window as any).editorHtml;
                }
              }
            } else {
              this.renderedBody = this.currentComposedEmail.currentEdition;
            }

            $('#myId').summernote({
              toolbar: [
                ['style', ['style']],
                ['font', ['bold', 'underline', 'italic', 'clear']],
                ['fontname', ['fontname']],
                ['fontsize', ['fontsize']],
                ['fontsizeunit', ['fontsizeunit']],
                ['color', ['color']],
                ['para', ['ul', 'ol', 'paragraph']],
              ],
              fontNames: [
                'Arial',
                'Calibri',
                'Roboto',
                'Comic Sans MS',
                'Courier New',
                'Helvetica',
                'Impact',
                'sans-serif',
                'Tahoma',
                'Times New Roman',
                'Verdana',
              ],
              fontSizes: ['8', '9', '10', '11', '12', '14', '15', '16', '18', '24', '36'],
              fontSizeUnits: ['pt'],
              fontNamesIgnoreCheck: ['Roboto'],
              dialogsInBody: true,
              disableResizeEditor: true,
              callbacks: {
                onInit: function () {
                  if (composedEmail?.sender == 'gmail') {
                    $('#myId').summernote('fontName', AppConstants.GMAIL_FONT_FAMILY);
                    $('#myId').summernote('fontSize', AppConstants.GMAIL_FONT_SIZE);
                    $('#myId').summernote('fontSizeUnit', AppConstants.GMAIL_FONT_UNIT);
                    $('.note-editable').css('font-size', AppConstants.GMAIL_FONT_SIZE + AppConstants.GMAIL_FONT_UNIT);
                    $('.note-editable').css('font-family', AppConstants.GMAIL_FONT_FAMILY);
                  } else {
                    $('#myId').summernote('fontName', AppConstants.OUTLOOK_FONT_FAMILY);
                    $('#myId').summernote('fontSize', AppConstants.OUTLOOK_FONT_SIZE);
                    $('#myId').summernote('fontSizeUnit', AppConstants.OUTLOOK_FONT_UNIT);

                    $('.note-editable').css(
                      'font-size',
                      AppConstants.OUTLOOK_FONT_SIZE + AppConstants.OUTLOOK_FONT_UNIT,
                    );
                    $('.note-editable').css('font-family', AppConstants.OUTLOOK_FONT_FAMILY);
                    $('.note-editable a').css('color', 'red');
                  }
                },
                onChange(contents, $editable) {
                  const bodySize = new Blob([contents]).size;
                  if (MAX_BODY_SIZE < bodySize) {
                    $('#myId').summernote('code', (window as any).editorHtml);
                    alert('Alert! The size of the body exceeds the allowed limit.');
                  } else {
                    (window as any).editorHtml = contents;
                  }
                },
              },
            });

            if (!this.initialized || composedEmail?.includeChainInEditor) {
              if (this.renderedBody) {
                $('#myId').summernote('code', this.renderedBody);
              }

              if (!this.initialized) {
                const service = this.composerMailService;
                $('#myId').on('summernote.paste', function (we, e) {
                  for (let i = 0; i < e.originalEvent.clipboardData.items.length; i += 1) {
                    if (
                      e.originalEvent.clipboardData.items[i].kind == 'string' &&
                      e.originalEvent.clipboardData.items[i].type.match('^text/html')
                    ) {
                      e.preventDefault();
                      e.originalEvent.clipboardData.items[i].getAsString(function (s) {
                        s = service.fixPastedHtml(s);
                        $('#myId').summernote('pasteHTML', '<p id="temp-paragraph" style="display:none"></p>');
                        $('#myId').summernote('pasteHTML', s);
                        $('.note-editable').find('p#temp-paragraph').remove();
                      });
                    }
                  }
                });
              }

              this.initialized = true;
              this.iframe.nativeElement.style.setProperty('display', 'none');
              (window as any).editorHtml = this.renderedBody;

              $('.note-editable').css('min-height', '25vh');
            }
          }
          this.switched = false;
          if (this.isSdrBlocked || !this.isSendConfigured) {
            $('#myId').next().find('.note-editable').attr('contenteditable', false);
          } else {
            $('#myId').next().find('.note-editable').attr('contenteditable', true);
          }
        }
      }),
    );

    const currentAction = (window as any).reType;
    if (currentAction == 'forward' && !this.currentComposedEmail.to.length) {
      setTimeout(function () {
        $('#to').focus();
      }, 500);
    } else {
      setTimeout(function () {
        $('.note-editable').focus();
      }, 500);
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  get displayCopyLeadBtn(): boolean {
    return (
      this.dialogData?.lead &&
      (this.dialogData?.currentFolder === 'review' ||
        this.dialogData?.currentFolder === 'discrepancies' ||
        this.dialogData?.currentFolder === 'converted') &&
      this.currentComposedEmail.category === this.categoryType.Forward
    );
  }

  @HostListener('mousedown', ['$event.target'])
  public onClick(targetElement) {
    const clickedInside = this.modalBar.nativeElement.contains(targetElement);
    if (clickedInside) {
      const autocompletes = Array.from(document.querySelectorAll('.autocomplete-overlay-pane > div'));
      autocompletes.forEach((elem) => {
        elem.classList.remove('mat-autocomplete-visible');
      });
    }
  }

  renderMessageReference() {
    if (this.currentComposedEmail.bodyHtml) {
      const msgRefHtmlBody = this.removeDocType(this.currentComposedEmail.bodyHtml);
      this.htmlData = this.sanitizer.sanitize(
        SecurityContext.HTML,
        this.sanitizer.bypassSecurityTrustHtml(msgRefHtmlBody),
      );
      this.setIframeReady(this.iframe, this.htmlData);
    } else {
      this.iframe.nativeElement.style.setProperty('display', 'none');
    }
  }

  send() {
    if (
      this.sdrs.some((sdr) => sdr.email === this.currentComposedEmail.from) &&
      this.currentComposedEmail.to.length > 0
    ) {
      const emailState = ComposeEmail.clone(this.currentComposedEmail);
      emailState.currentEdition = (window as any).editorHtml;
      this.composerMailService.setLastComposedEmail(emailState);
      if (
        this.currentComposedEmail.category != ComposeEmailCategory.New &&
        this.currentComposedEmail.includeChainInEditor
      ) {
        if (this.currentComposedEmail.sender == 'gmail') {
          (window as any).editorHtml = '<div> ' + (window as any).editorHtml;
        } else {
          (window as any).editorHtml = '<div> ' + (window as any).editorHtml;
        }

        const idx = (window as any).editorHtml.indexOf(this.chainMessageSeparator);

        if (idx > -1) {
          let a = (window as any).editorHtml;
          let b = ' </div> ';
          (window as any).editorHtml = [a.slice(0, idx), b, a.slice(idx)].join('');
        }
      }

      (window as any).editorHtml = (window as any).editorHtml.replace(this.chainMessageSeparator, '');
      // Remove added body
      let injectedStyle = '';
      if (this.currentComposedEmail.sender == 'outlook') {
        injectedStyle +=
          "\n <style type='text/css'> p,div { font-family: " +
          AppConstants.OUTLOOK_FONT_FAMILY +
          ', Arial, Helvetica, sans-serif; font-size: ' +
          AppConstants.OUTLOOK_FONT_SIZE +
          AppConstants.OUTLOOK_FONT_UNIT +
          '; line-height: ' +
          AppConstants.OUTLOOK_LINE_HEIGHT +
          ' } </style>';
      } else {
        injectedStyle +=
          "\n <style type='text/css'> p,div { font-family: " +
          AppConstants.GMAIL_FONT_FAMILY +
          ', Helvetica, sans-serif; font-size: ' +
          AppConstants.GMAIL_FONT_SIZE +
          AppConstants.GMAIL_FONT_UNIT +
          '; line-height:' +
          AppConstants.GMAIL_LINE_HEIGHT +
          ' } </style>';
      }

      this.currentComposedEmail.currentEdition = (window as any).editorHtml.replace(injectedStyle, '');

      // Gmail response header fix
      let responseHeader = '<div class="gmail_quote"><div dir="ltr" class="gmail_attr" style="line-height:initial;">';
      let responseHeaderFix = '<div class="gmail_quote"><div dir="ltr" class="gmail_attr">';
      this.currentComposedEmail.currentEdition = (window as any).editorHtml.replace(responseHeader, responseHeaderFix);

      // Outlook response header fix
      responseHeader = '<font face="Calibri, sans-serif" color="#000000" style="font-size:16px;line-height:initial;">';
      responseHeaderFix = '<font face="Calibri, sans-serif" color="#000000" style="font-size:16px;">';
      this.currentComposedEmail.currentEdition = (window as any).editorHtml.replace(responseHeader, responseHeaderFix);

      let attachError = this.messageAttachmentService.checkAttachmentSize(
        this.currentComposedEmail.attachments,
        this.currentComposedEmail.sender,
      );
      if (this.currentComposedEmail.attachments.length > MAX_NUMBER_OF_ATTACHMENTS) {
        attachError = `Can not attach more than ${MAX_NUMBER_OF_ATTACHMENTS} files`;
      }
      if (attachError !== '') {
        const errorConfirmDialogConfig = new MatDialogConfig();
        errorConfirmDialogConfig.data = {
          title: 'Attachment Errors',
          message: attachError,
        };
        this.dialog.open(AlertDialogComponent, errorConfirmDialogConfig);
      } else {
        this.composerMailService.sendEmail(this.currentComposedEmail);
        this.dialogRef.close();
      }
    }
  }

  close() {
    this.composerMailService.setComposedEmail(null);
    this.composerMailService.composeNew();
    this.dialogRef.close();
  }

  handleMinimizedDialog(value: boolean) {
    this.updateBody();
    this.dialogRef.close();
    this.composerMailService.setIsMailComposerMinimized(false);
    this.composerMailService.setIsMailComposerMediumOpen(value);
  }

  updateBody() {
    this.currentComposedEmail.currentEdition = (window as any).editorHtml;
    this.composerMailService.setComposedEmail(this.currentComposedEmail);
  }

  removeDocType(previewHtmlBody) {
    return previewHtmlBody.replace('"-//W3C//DTD HTML 4.01//EN"', '');
  }

  formatPlain(str) {
    str = this.formatPlainDouble(str);
    str = this.formatPlainSingle(str);
    return str;
  }

  formatPlainDouble(str) {
    let parsedStr = str.split('\\r\\n').join('<br>');
    parsedStr = parsedStr.split('\\r').join('<br>');
    parsedStr = parsedStr.split('\\n').join('<br>');
    parsedStr = parsedStr.trim();
    if (parsedStr.substr(0, 2).toLowerCase() == "b'" || parsedStr.substr(0, 2).toLowerCase() == 'b"') {
      parsedStr = parsedStr.substr(2);
    }
    if (parsedStr.substr(parsedStr.length - 1) == "'" || parsedStr.substr(parsedStr.length - 1) == '"') {
      parsedStr = parsedStr.substr(0, parsedStr.length - 1);
    }
    return parsedStr;
  }

  formatPlainSingle(str) {
    let parsedStr = str.split('\r\n').join('<br>');
    parsedStr = parsedStr.split('\r').join('<br>');
    parsedStr = parsedStr.split('\n').join('<br>');
    parsedStr = parsedStr.trim();
    if (parsedStr.substr(0, 2).toLowerCase() == "b'" || parsedStr.substr(0, 2).toLowerCase() == 'b"') {
      parsedStr = parsedStr.substr(2);
    }
    if (parsedStr.substr(parsedStr.length - 1) == "'" || parsedStr.substr(parsedStr.length - 1) == '"') {
      parsedStr = parsedStr.substr(0, parsedStr.length - 1);
    }
    return parsedStr;
  }

  private setIframeReady(iframe: ElementRef, html: string): void {
    const win: Window = iframe.nativeElement.contentWindow;
    if (win) {
      const doc: Document = win?.document;
      doc.open();
      doc.write(html);
      doc.close();
      this.iframe.nativeElement.style.setProperty('display', 'inherit');
      this.iframe.nativeElement.style.setProperty(
        'height',
        this.iframe.nativeElement.contentWindow?.document.body.scrollHeight + 'px',
      );
      this.iframe.nativeElement.style.setProperty('width', '95%');

      const htmlElement: HTMLIFrameElement = document.getElementById('emailIframe') as HTMLIFrameElement;
      const htmlIframe = htmlElement.contentWindow;
      htmlIframe?.document.querySelectorAll('*').forEach((n) => {
        if (n.tagName == 'A') {
          n.setAttribute('target', '_blank');
        }
      });
    }
  }

  switch() {
    const idx = (window as any).editorHtml.indexOf(this.chainMessageSeparator);

    if (idx > -1) {
      if (!this.currentComposedEmail.includeChainInEditor) {
        this.currentComposedEmail.currentEdition = (window as any).editorHtml.substring(0, idx);
        this.renderedBody = this.currentComposedEmail.currentEdition;
      } else {
        this.currentComposedEmail.currentEdition = (window as any).editorHtml.substring(
          0,
          idx + this.chainMessageSeparator.length,
        );
      }
    }
    this.currentComposedEmail.includeChainInEditor = !this.currentComposedEmail.includeChainInEditor;
    this.composerMailService.setComposedEmail(this.currentComposedEmail);
    this.switched = true;
    this.iframeActive = !this.iframeActive;

    const htmlElementColl: HTMLCollectionOf<HTMLElement> = document.getElementsByClassName(
      'note-editable',
    ) as HTMLCollectionOf<HTMLElement>;
    let heightMeasure = this.iframeActive ? '20vh' : '45vh';
    if (this.currentComposedEmail.category != ComposeEmailCategory.New) {
      if (htmlElementColl[0]) {
        htmlElementColl[0].style.maxHeight = heightMeasure;
        htmlElementColl[0].style.minHeight = heightMeasure;
      }
    } else {
      if (htmlElementColl[0]) {
        htmlElementColl[0].style.maxHeight = heightMeasure;
        htmlElementColl[0].style.minHeight = heightMeasure;
      }
      if (htmlElementColl[1]) {
        htmlElementColl[1].style.height = heightMeasure;
      }
    }
  }

  get invalidForm(): boolean {
    if (this.currentComposedEmail.attachments.some((attachment) => attachment.isUploading)) {
      return true;
    }

    if (this.recipientFields) {
      return (
        !this.sdrs.some((sdr) => sdr.email === this.currentComposedEmail.from) ||
        this.currentComposedEmail.to.length == 0 ||
        !this.recipientFields ||
        this.recipientFields.toEmailList.some((e) => e.invalid) ||
        this.recipientFields.ccEmailList.some((e) => e.invalid) ||
        this.recipientFields.bccEmailList.some((e) => e.invalid)
      );
    }
    if (!this.isReplyReady) return true;

    if (!this.isIntroductionReady) return true;

    return false;
  }

  get isSdrBlocked(): boolean {
    if (this.recipientFields) {
      return this.blockedSdrs?.some((sdr) => sdr === this.recipientFields.fromControl.value);
    }
    return false;
  }

  get isSendConfigured(): boolean {
    if (this.recipientFields) {
      const selectedSdr = this.sdrs.find((sdr) => sdr.email === this.recipientFields.fromControl.value);
      if (selectedSdr) {
        return selectedSdr.isSendConfigured;
      }
      return true;
    }
    return true;
  }

  get sendButtonText(): string {
    if (!this.isSendConfigured) {
      return 'SDR not configured to send emails';
    }
    if (this.isSdrBlocked) {
      return 'SDR currently blocked';
    }
    return 'Send';
  }

  selectTemplate() {
    const selectedSdr = this.sdrs.find((sdr) => sdr.email === this.recipientFields.fromControl.value);
    const dialogRef = this.dialog.open(SelectTemplateComponent, {
      panelClass: 'my-custom-dialog-class',
      minWidth: '1150px',
      data: {
        sdr: this.recipientFields.fromControl.value,
        currentFolder: this.dialogData?.currentFolder,
        client: {
          name: selectedSdr.client,
          id: selectedSdr.clientId,
        },
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.selectedTemplate = result;
        if (this.selectedTemplate.attachment && this.selectedTemplate.attachment.length > 0) {
          if (this.currentComposedEmail.attachments === undefined) {
            this.currentComposedEmail.attachments = [];
          }
          this.selectedTemplate.attachment.forEach((item: any) => {
            this.currentComposedEmail.attachments.push({
              filename: item.name,
              fileId: item.key,
              key: item.key,
              isSafe: item.is_safe,
              contentType: item.content_type,
              size: item.size,
            });
          });
        }
        if (this.selectedTemplate.isInternalUse) {
          this.currentComposedEmail.cc = [];
          this.recipientFields.ccEmailList = [];
          this.currentComposedEmail.to = [];
          this.recipientFields.toEmailList = [];
          this.currentComposedEmail.bcc = [];
          this.recipientFields.bccEmailList = [];
        }
        if (this.selectedTemplate.clientContactEmail && this.selectedTemplate.clientContactEmail !== '') {
          if (this.selectedTemplate.isInternalUse) {
            this.currentComposedEmail.to.push(this.selectedTemplate.clientContactEmail);
            this.recipientFields.toEmailList.push({ value: this.selectedTemplate.clientContactEmail, invalid: false });
          } else {
            this.currentComposedEmail.cc.push(this.selectedTemplate.clientContactEmail);
            this.recipientFields.ccEmailList.push({ value: this.selectedTemplate.clientContactEmail, invalid: false });
          }
        }
        if (this.selectedTemplate.cc && this.selectedTemplate.cc !== '') {
          const ccList = this.selectedTemplate.cc.split(';');
          ccList.forEach((item: string) => {
            this.currentComposedEmail.cc.push(item.trim());
            this.recipientFields.ccEmailList.push({ value: item, invalid: false });
          });
        }
        if (this.selectedTemplate.bcc && this.selectedTemplate.bcc !== '') {
          const bccList = this.selectedTemplate.bcc.split(';');
          bccList.forEach((item: string) => {
            this.currentComposedEmail.bcc.push(item.trim());
            this.recipientFields.bccEmailList.push({ value: item, invalid: false });
          });
        }

        this.currentComposedEmail.template = {
          id: this.selectedTemplate.id,
          title: this.selectedTemplate.title,
          type_id: this.selectedTemplate.templateTypeId,
        };

        if (this.selectedTemplate.templateTypeId === 1) {
          this.checkInteractionExist(MailStatus.Reply);
        }

        if (this.selectedTemplate.templateTypeId === 2) {
          this.checkInteractionExist(MailStatus.Introduction);
        }

        $('#myId').summernote('code', this.selectedTemplate.templateText + this.renderedBody);
      }
    });
  }

  checkInteractionExist(mailStatus: number, showNotification = true) {
    mailStatus === MailStatus.Reply ? (this.isReplyReady = false) : (this.isIntroductionReady = false);
    this.interactionService
      .checkInteractionExist({
        messageId: this.currentComposedEmail.messageId,
        sdrId: Number(this.currentComposedEmail.fromSdrId),
        statusId: mailStatus,
      })
      .subscribe({
        next: (response) => {
          if (!response) {
            this.invalidForm;
            if (showNotification) {
              var message = '';
              if (mailStatus === MailStatus.Reply)
                message = 'You must create a Reply interaction with this message before Send the reply';
              else
                message = 'You must create a Introduction interaction with this message before Send the introduction';
              this.snackBarService.showWarning(message, 'Close', { duration: 10000 });
            }
          } else {
            mailStatus === MailStatus.Reply ? (this.isReplyReady = true) : (this.isIntroductionReady = true);
          }
        },
        error: () =>
          mailStatus === MailStatus.Reply ? (this.isReplyReady = false) : (this.isIntroductionReady = false),
      });
  }

  selectSalesRep() {
    const selectedSdr = this.sdrs.find((sdr) => sdr.email === this.recipientFields.fromControl.value);
    const dialogRef = this.dialog.open(SelectSalesRepresentativeComponent, {
      panelClass: 'my-custom-dialog-class',
      minWidth: '500px',
      data: selectedSdr.id,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result !== '') {
        this.selectedSalesRep = result;
        this.currentComposedEmail.salesRepresentative = result;
        if (this.selectedSalesRep && this.selectedTemplate !== '') {
          const ccList = this.selectedSalesRep.split(';');
          this.currentComposedEmail.to = [];
          this.recipientFields.toEmailList = [];

          ccList.forEach((item: string) => {
            this.currentComposedEmail.to.push(item);
            this.recipientFields.toEmailList.push({ value: item, invalid: false });
          });
        }
      }
    });
  }

  onCopyLeadProfile() {
    this.isLoadingLead = true;
    const { messageId, fromSdrId } = this.currentComposedEmail;
    const lead = this.dialogData.lead;

    if (messageId && fromSdrId) {
      this.inquiryService
        .getLeadsData(messageId, fromSdrId)
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => (this.isLoadingLead = false)),
        )
        .subscribe({
          next: (response: LeadProfile) => {
            response
              ? this.copyLeadInfo(response)
              : this.snackBarService.showError(`No data found for lead with email ${lead}`);
          },
          error: () => this.snackBarService.showError(`No data found for lead with email ${lead}`),
        });
    }
  }

  async addAttachment(files: FileList) {
    if (!this.currentComposedEmail.attachments) {
      this.currentComposedEmail.attachments = [];
    }
    await this.messageAttachmentService.attach(
      files,
      this.currentComposedEmail.attachments,
      this.inputFileControl,
      this.dialog,
      this.currentComposedEmail.sender,
    );
    this.currentComposedEmail.currentEdition = (window as any).editorHtml;
    this.composerMailService.setComposedEmail(this.currentComposedEmail);
  }

  private copyLeadInfo(lead: LeadProfile): void {
    let textToCopy =
      `<div><p style="font-family: 'Courier New'">*** INTERNAL USE ONLY ***</p>` +
      `<p style="font-family: 'Courier New'">FYI, Lead profile details below...</p><br>`;

    textToCopy =
      textToCopy +
      [
        { key: 'Original Email', value: lead.emailAddress },
        { key: 'Name', value: `${lead.firstName} ${lead.lastName}` },
        { key: 'Title', value: lead.title || '' },
        { key: 'Responsibility', value: lead.jobResponsibility || '' },
        { key: 'Department', value: lead.jobDepartment || '' },
        { key: 'LinkedIn URL', value: lead.linkedInUrlGen ? `https://linkedin.com${lead.linkedInUrlGen}` : '' },
        { key: 'Region', value: lead.region || '' },
        { key: 'Country', value: lead.country || '' },
        { key: 'State', value: lead.state || '' },
        { key: 'City', value: lead.city || '' },
        { key: 'Phone', value: lead.phone || '' },
        { key: 'Company', value: lead.company.companyName || '' },
        { key: 'LinkedIn URL', value: lead.company.linkedInUrl || '' },
        { key: 'Industry', value: lead.company.industry || '' },
        { key: 'Size', value: lead.company.size || '' },
        { key: 'Employees Qty', value: lead.company.employeeCount || '' },
      ]
        .map((field) => {
          return `<p style="font-family: 'Courier New'"><span style="margin-right: 20px; width: 140px; display:inline-flex;">${field.key}:</span>${field.value}</p>`;
        })
        .join('');

    if (lead.comments) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          message: `Is the lead the same as ${lead.comments}?`,
          confirmBtnText: 'Yes',
          cancelBtnText: 'No',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (!result) {
          textToCopy +=
            `<p style="font-family: 'Courier New'">-------------------------------------------</p>` +
            `<p style="font-family: 'Courier New'">Original Lead contacted</p>`;
          textToCopy =
            textToCopy +
            [
              { key: 'Email', value: lead.originalLeadContactedEmail },
              { key: 'Name', value: lead.originalLeadContactedName },
              { key: 'Title', value: lead.originalLeadContactedTitle },
              {
                key: 'LinkedIn URL',
                value: lead.originalLeadContactedLinkedInUrl
                  ? `https://linkedin.com${lead.originalLeadContactedLinkedInUrl}`
                  : '',
              },
            ]
              .map((field) => {
                return `<p style="font-family: 'Courier New'"><span style="margin-right: 20px; width: 140px; display:inline-flex;">${field.key}:</span>${field.value}</p>`;
              })
              .join('');

          textToCopy += '</div>';
          this.pasteToEditor(textToCopy);
        } else {
          textToCopy =
            `<div><p style="font-family: 'Courier New'">*** INTERNAL USE ONLY ***</p>` +
            `<p style="font-family: 'Courier New'">FYI, Lead profile details below...</p><br>`;
          textToCopy =
            textToCopy +
            [
              { key: 'Original Email', value: lead.emailAddress },
              { key: 'Response Email', value: lead.comments },
              { key: 'Name', value: `${lead.firstName} ${lead.lastName}` },
              { key: 'Title', value: lead.title || '' },
              { key: 'Responsibility', value: lead.jobResponsibility || '' },
              { key: 'Department', value: lead.jobDepartment || '' },
              { key: 'LinkedIn URL', value: lead.linkedInUrlGen ? `https://linkedin.com${lead.linkedInUrlGen}` : '' },
              { key: 'Region', value: lead.region || '' },
              { key: 'Country', value: lead.country || '' },
              { key: 'State', value: lead.state || '' },
              { key: 'City', value: lead.city || '' },
              { key: 'Phone', value: lead.phone || '' },
              { key: 'Company', value: lead.company.companyName || '' },
              { key: 'LinkedIn URL', value: lead.company.linkedInUrl || '' },
              { key: 'Industry', value: lead.company.industry || '' },
              { key: 'Size', value: lead.company.size || '' },
              { key: 'Employees Qty', value: lead.company.employeeCount || '' },
            ]
              .map((field) => {
                return `<p style="font-family: 'Courier New'"><span style="margin-right: 20px; width: 140px; display:inline-flex;">${field.key}:</span>${field.value}</p>`;
              })
              .join('');

          textToCopy += '</div>';
          this.pasteToEditor(textToCopy);
        }
      });
    } else {
      textToCopy += '</div>';
      this.pasteToEditor(textToCopy);
    }
  }

  private async pasteToEditor(textToCopy: string) {
    $('#myId').summernote('pasteHTML', textToCopy);
    this.leadCopied = true;
  }
}

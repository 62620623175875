<div
  *ngFor="let conversation of conversations; let i = index"
  class="conversation"
  [ngClass]="{ 'conversation--selected': selectedConversation?.conversationId === conversation?.conversationId }"
  id="conversation-{{ i + 1 }}"
  (click)="selectConversation(conversation)"
>
  <mat-icon [ngClass]="'conversation__icon'">account_circle</mat-icon>
  <div class="conversation__info">
    <span>Conversation #{{ i + 1 }}</span>
    <span> {{ conversation.messages && conversation.messages?.slice(-1)[0]?.messagePlain | truncate: 50 }}</span>
  </div>
</div>

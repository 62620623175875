<div
  *ngIf="classifications?.length"
  class="mail-details-categorizer"
>
  <div
    *ngIf="selectedEmails >= 2"
    class="mail-details-categorizer__bulk-text"
  >
    Classify {{ selectedEmails }} emails as:
  </div>
  <div *ngFor="let section of classifications">
    <div class="mail-details-categorizer__category-title-wrapper">
      {{ section.sectionName }}
    </div>
    <div class="mail-details-categorizer__category-options-wrapper">
      <ng-container *ngFor="let classification of section.classifications">
        <button
          *ngIf="classification?.name"
          [ngClass]="'mail-details-categorizer__classification-btn'"
          mat-stroked-button
          color="primary"
          [disabled]="isClassificationDisabled || !classification.name"
          (click)="classify(classification)"
          [id]="classification.htmlId"
        >
          <span class="mail-details-categorizer__classification-name">
            {{ classification.name | titlecase }}
          </span>
        </button>
      </ng-container>
    </div>
  </div>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { GenericResponse } from '../model/common/generic-response';
import { ConversionDetailDto } from '../model/lead/conversion-detail.dto';
import { ConversionDetail } from '../model/lead/conversion-detail.model';
import { CorporateCompany } from '../model/lead/corporate-company.dto';
import { CreateLeadDto } from '../model/lead/create-lead-dto';
import { Lead } from '../model/lead/lead.model';
import { ReloadLead } from '../model/lead/reload-lead.model';
import { SetAliasDto } from '../model/lead/set-alias.dto';

@Injectable({
  providedIn: 'root',
})
export class LeadService {
  private currentLeadSubject = new BehaviorSubject<Lead>(null);
  currentContactIdSubject = new BehaviorSubject<number>(null);
  private isCreatingContactSubject = new BehaviorSubject<boolean>(false);
  private isSaveValidsubject = new BehaviorSubject<boolean>(false);
  private saveCalledSubject = new BehaviorSubject<boolean>(false);
  private showAliasSubject = new BehaviorSubject<boolean>(false);
  private registerProspectCalledSubject = new BehaviorSubject<boolean>(false);
  private reassignSdrCalledSubject = new BehaviorSubject<boolean>(false);
  private setAliasCalledSubject = new BehaviorSubject<boolean>(false);
  private undoAliasCalledSubject = new BehaviorSubject<boolean>(false);
  private reloadLeadForAliasSubject = new BehaviorSubject<ReloadLead>({ reload: false, idEmailStatus: undefined });

  constructor(private http: HttpClient) {}

  get currentLead$(): Observable<Lead> {
    return this.currentLeadSubject.asObservable();
  }

  get isCreatingContact$(): Observable<boolean> {
    return this.isCreatingContactSubject.asObservable();
  }

  get isSaveValid$(): Observable<boolean> {
    return this.isSaveValidsubject.asObservable();
  }

  get saveCalled$(): Observable<boolean> {
    return this.saveCalledSubject.asObservable();
  }

  get registerProspectCalled$(): Observable<boolean> {
    return this.registerProspectCalledSubject.asObservable();
  }

  get currentContactId$(): Observable<number> {
    return this.currentContactIdSubject.asObservable();
  }

  get reassignSdrCalled$(): Observable<boolean> {
    return this.reassignSdrCalledSubject.asObservable();
  }

  get showAlias$(): Observable<boolean> {
    return this.showAliasSubject.asObservable();
  }

  get setAliasCalled$(): Observable<boolean> {
    return this.setAliasCalledSubject.asObservable();
  }

  get undoAliasCalled$(): Observable<boolean> {
    return this.undoAliasCalledSubject.asObservable();
  }

  get reloadLeadForAlias$(): Observable<ReloadLead> {
    return this.reloadLeadForAliasSubject.asObservable();
  }

  setReassignSdrCalled(reassignCalled: boolean): void {
    this.reassignSdrCalledSubject.next(reassignCalled);
  }

  setCurrentContactId(contactId: number): void {
    this.currentContactIdSubject.next(contactId);
  }

  setCurrentLead(lead: Lead): void {
    this.currentLeadSubject.next(lead);
  }

  setIsCreatingContact(isCreatingContact: boolean): void {
    this.isCreatingContactSubject.next(isCreatingContact);
  }

  setIsSaveValid(isValid: boolean): void {
    this.isSaveValidsubject.next(isValid);
  }

  setSaveCalled(saveCalled: boolean): void {
    this.saveCalledSubject.next(saveCalled);
  }

  setRegisterProspectCalled(registerProspectCalled: boolean): void {
    this.registerProspectCalledSubject.next(registerProspectCalled);
  }

  setShowAliasCalled(showAlias: boolean): void {
    this.showAliasSubject.next(showAlias);
  }

  setAliasCalled(setAliasCalled: boolean): void {
    this.setAliasCalledSubject.next(setAliasCalled);
  }

  setUndoAliasCalled(undoAliasCalled: boolean): void {
    this.undoAliasCalledSubject.next(undoAliasCalled);
  }

  setReloadLeadForAlias(reloadLeadForAlias: ReloadLead): void {
    this.reloadLeadForAliasSubject.next(reloadLeadForAlias);
  }

  getLeadByEmail(email: string): Observable<Lead> {
    const url = `${environment.newApiBaseUrl}/leads/email-address/${email}`;

    return this.http.get(url).pipe(map((response: GenericResponse<Lead>) => response.data));
  }

  getLeadByContactId(contactId: number): Observable<Lead> {
    const url = `${environment.newApiBaseUrl}/leads/contact-id/${contactId}`;

    return this.http.get(url).pipe(map((response: GenericResponse<Lead>) => response.data));
  }

  createLead(createLeadDto: CreateLeadDto): Observable<number> {
    const url = `${environment.newApiBaseUrl}/leads/create-lead`;

    return this.http.post(url, createLeadDto).pipe(map((response: GenericResponse<number>) => response.data));
  }

  validateCorporateEmail(email: string): Observable<CorporateCompany> {
    const url = `${environment.newApiBaseUrl}/leads/company/${email}`;

    return this.http.get(url).pipe(map((response: GenericResponse<CorporateCompany>) => response.data));
  }

  setAlias(setAliasDto: SetAliasDto): Observable<boolean> {
    const url = `${environment.newApiBaseUrl}/leads/set-alias`;

    return this.http.post(url, setAliasDto).pipe(map((response: GenericResponse<boolean>) => response.data));
  }

  undoAlias(setAliasDto: SetAliasDto): Observable<boolean> {
    const url = `${environment.newApiBaseUrl}/leads/undo-alias`;

    return this.http.post(url, setAliasDto).pipe(map((response: GenericResponse<boolean>) => response.data));
  }

  getConversionDetails(conversionDetailDto: ConversionDetailDto) {
    const url = `${environment.newApiBaseUrl}/leads/get-conversion-details`;

    return this.http.post(url, conversionDetailDto).pipe(map((response: ConversionDetail) => response));
  }
}

import { AfterViewInit, Component, ElementRef, SecurityContext, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Attachment } from '../../../../model/Attachment';
import { Email as EmailClass } from '../../../../model/Email';
import { IAttachment } from '../../../../model/attachment.model';
import { ClassifierMessage, Email } from '../../../../model/mail-classifier/classifier-message.model';
import { MailReviewService } from '../../../../services/mail-review.service';
import { SelectedMailService } from '../../../../services/selected-mail.service';

@Component({
  selector: 'app-mail-classifier-details-body',
  templateUrl: './mail-classifier-details-body.component.html',
  styleUrls: ['./mail-classifier-details-body.component.scss'],
})
export class MailClassifierDetailsBodyComponent implements AfterViewInit {
  public htmlData: any;
  currentEmail: EmailClass;
  @ViewChild('iframe') iframe: ElementRef;
  doc: any;
  compRef: any;
  attachmentDynamicPadding = 0;
  screenHeight: number;
  screenWidth: number;
  loading = false;

  constructor(
    private sanitizer: DomSanitizer,
    private mailReviewService: MailReviewService,
    private selectedMailService: SelectedMailService,
  ) {}

  ngAfterViewInit() {
    this.mailReviewService.currentClassifierMessage$.subscribe((message: ClassifierMessage) => {
      message.email && this.setCurrentEmail(message?.email);

      this.htmlData = null;
      if (this.currentEmail && this.currentEmail.bodyHtml) {
        this.removeDocType();
        this.htmlData = this.sanitizer.sanitize(
          SecurityContext.HTML,
          this.sanitizer.bypassSecurityTrustHtml(this.currentEmail.bodyHtml),
        );
        this.setIframeReady(this.iframe, this.htmlData);
      } else {
        this.iframe.nativeElement.style.setProperty('display', 'none');
      }
      const element: HTMLIFrameElement = document.getElementById('emailIframe') as HTMLIFrameElement;
      const iframe = element.contentWindow;
      iframe.document.querySelectorAll('*').forEach((n) => n.scrollTo(0, 0));
    });
  }

  setCurrentEmail(email: Email) {
    const transformedAttachments = email.attachments.map((attachment: IAttachment) => {
      return new Attachment({
        filename: attachment.name,
        key: attachment.path,
        isSafe: attachment.isSafe,
        fileId: 'TODO discard',
        size: attachment.size,
        isLocal: false,
        contentType: attachment.contentType,
      });
    });

    this.currentEmail = new EmailClass({ ...email, attachments: transformedAttachments });

    this.mailReviewService.setClassifierEmail(this.currentEmail);
    this.selectedMailService.selectMail(this.currentEmail);
  }

  removeDocType() {
    this.currentEmail.bodyHtml = this.currentEmail.bodyHtml.replace('"-//W3C//DTD HTML 4.01//EN"', '');
  }

  private setIframeReady(iframe: ElementRef, html: string): void {
    const win: Window = iframe.nativeElement.contentWindow;

    const doc: Document = win.document;
    doc.open();
    doc.write(html);
    doc.close();
    this.iframe.nativeElement.style.setProperty('display', 'inherit');
    this.iframe.nativeElement.style.setProperty(
      'height',
      this.iframe.nativeElement.contentWindow.document.body.scrollHeight + 'px',
    );
    this.iframe.nativeElement.style.setProperty('width', '95%');

    const htmlElement: HTMLIFrameElement = document.getElementById('emailIframe') as HTMLIFrameElement;
    const htmlIframe = htmlElement.contentWindow;
    htmlIframe.document.querySelectorAll('*').forEach((n) => {
      if (n.tagName == 'A') {
        n.setAttribute('target', '_blank');
      }
    });
  }
}

<div
  class="mail-details-body"
  id="emailColumn"
>
  <div>
    <app-mail-details-tool-bar></app-mail-details-tool-bar>
  </div>

  <div class="mail-details-body__content">
    <iframe
      id="emailIframe"
      #iframe
      frameborder="0"
      sandbox="allow-same-origin"
    ></iframe>

    <div
      class="mail-details-body__content__no-email"
      *ngIf="!currentEmail"
    >
      Please refresh and select an email to start classifying.
    </div>
  </div>
</div>

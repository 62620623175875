<div class="operator-user-add">
  <div class="operator-user-add__container">
    <div class="operator-user-add__title">
      <mat-icon
        class="operator-user-add__back-btn"
        routerLink="'/management/operator-users'"
        >arrow_back_ios</mat-icon
      >
      <span>Create New Operator User</span>
    </div>
    <mat-divider></mat-divider>
    <app-operator-user-form [operatorUserForm]="operatorUserForm"></app-operator-user-form>
    <div class="operator-user-add__footer">
      <button
        mat-button
        (click)="cancel()"
        id="operator-user-cancel-btn"
      >
        CANCEL
      </button>
      <button
        class="operator-user-add__confirm-button"
        mat-raised-button
        color="primary"
        (click)="save()"
        cdkFocusInitial
        [disabled]="!operatorUserForm.valid"
        id="operator-user-add-new-operator-btn"
      >
        ADD NEW OPERATOR
      </button>
    </div>
  </div>
</div>

import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

const MODAL_DURATION = 3000;

@Component({
    selector: 'app-mail-referred-dialog',
    templateUrl: './mail-referred-dialog.component.html',
    styleUrls: ['./mail-referred-dialog.component.scss'],
    standalone: false
})
export class MailReferredDialogComponent implements OnInit {

  mailTo: string[] = [];
  lastMailTo: string;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private snackBar: MatSnackBar) {
    this.mailTo = data;
    this.lastMailTo = this.mailTo[this.mailTo.length - 1];
    // this.mailTo=["asd@asd.com","qwerty@asd.com","other@asd.com"];
  }

  ngOnInit() {
  }

  copyMailTo(mail: string) {
    const el = document.createElement('textarea');
    el.value = mail;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    this.snackBar.open(mail + ' copied to clipboard', null, { duration: MODAL_DURATION });
  }


}

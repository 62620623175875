export class Attachment {
  filename: string;
  content?: string;
  fileId?: string;
  size?: number;
  isUploading?: boolean;
  isLocal?: boolean;
  key: string;
  isSafe: boolean;
  contentType: string;
  contentId?: string;

  constructor(data?: any) {
    if (!data) return;

    this.filename = data.filename;
    this.content = data.content;
    this.fileId = data.fileId;
    this.size = data.size || 10;
    this.isUploading = data.isUploading;
    this.key = data.key;
    this.isSafe = data.isSafe;
    this.contentType = data.contentType;
    this.contentId = data.contentId;
  }
}

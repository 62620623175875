import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Subscription, of } from 'rxjs';
import { delay, repeatWhen } from 'rxjs/operators';
import { LogService } from 'src/app/services/log.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { Notification } from '../../../model/Notification';

@Component({
    selector: 'app-notification-list',
    templateUrl: './notification-list.component.html',
    styleUrls: ['./notification-list.component.scss'],
    standalone: false
})
export class NotificationListComponent implements OnInit, OnDestroy {
  notificationFeed: Notification[] = [];
  private subscription = new Subscription();

  constructor(private notificationsService: NotificationsService, private logService: LogService) {}

  ngOnInit() {
    this.startNotifications(false);

    of(null)
      .pipe(repeatWhen(x => x.pipe(delay(6 * 1000))))
      .subscribe(x => {
        if ((window as any).exited === 1) {
          (window as any).exited = 0;
          this.startNotifications(true);

          // Call log -> User stays on page after interrupting classify / send.
          this.notificationFeed.forEach(notification => {
            if (notification.undoSendAction) {
              this.logService.submitLog({
                tags: ['UserStayed', 'Send', notification.placeholderInfo1, notification.placeholderInfo2],
                body: 'UserStayed - ' + notification.message,
              });
            }
            if (notification.undoClassifyAction) {
              this.logService.submitLog({
                tags: [
                  'UserStayed',
                  'Classify',
                  notification.id,
                  notification.placeholderInfo1,
                  notification.placeholderInfo2,
                ],
                body: 'UserStayed - ' + notification.message,
              });
            }
          });
        }
      });
  }

  startNotifications(restart: boolean) {
    this.notificationsService.initialize(restart);
    this.subscription = this.notificationsService.feed.subscribe(notificationsFeed => {
      this.notificationFeed = notificationsFeed;
    });
  }

  ngOnDestroy() {
    this.disposeConnection();
  }

  @HostListener('window:beforeunload')
  callBeforeUnload() {
    (window as any).exited = 1;
    this.disposeConnection();
    if (this.notificationFeed.some(n => n.undoSendAction || n.undoClassifyAction)) {
      // Call log -> Leave on classify / undo attempt.
      this.notificationFeed.forEach(notification => {
        if (notification.undoSendAction) {
          this.logService.submitLog({
            tags: ['ActionInterruption', 'Send', notification.placeholderInfo1, notification.placeholderInfo2],
            body: 'Interrupted - ' + notification.message,
          });
        }
        if (notification.undoClassifyAction) {
          this.logService.submitLog({
            tags: [
              'ActionInterruption',
              'Classify',
              notification.id,
              notification.placeholderInfo1,
              notification.placeholderInfo2,
            ],
            body: 'Interrupted - ' + notification.message,
          });
        }
      });
      return false;
    }
  }

  disposeConnection() {
    this.subscription.unsubscribe();
    this.notificationsService.disconnect();
  }
}

<div
  class="contact-detail"
  id="alias-dialog"
>
  <div class="contact-detail__section">
    <div>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">ID Lead</span>
        <span class="contact-detail__value">{{ contactFromSearch?.idLead }}</span>
        <span class="contact-detail__value">{{ contactFromForwarder?.idLead }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">Email Address</span>
        <span class="contact-detail__value">{{ contactFromSearch?.emailAddress }}</span>
        <span class="contact-detail__value">{{ contactFromForwarder?.emailAddress }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">Domain</span>
        <span class="contact-detail__value">{{ contactFromSearch?.domain }}</span>
        <span class="contact-detail__value">{{ contactFromForwarder?.domain }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">First Name</span>
        <span class="contact-detail__value">{{ contactFromSearch?.firstName }}</span>
        <span class="contact-detail__value">{{ contactFromForwarder?.firstName }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">Middle Name</span>
        <span class="contact-detail__value">{{ contactFromSearch?.middleName }}</span>
        <span class="contact-detail__value">{{ contactFromForwarder?.middleName }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">Last Name</span>
        <span class="contact-detail__value">{{ contactFromSearch?.lastName }}</span>
        <span class="contact-detail__value">{{ contactFromForwarder?.lastName }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">LinkedIn Url</span>
        <span class="contact-detail__value">{{ contactFromSearch?.linkedInUrl }}</span>
        <span class="contact-detail__value">{{ contactFromForwarder?.linkedInUrl }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">Orig Full Name</span>
        <span class="contact-detail__value">{{ contactFromSearch?.origFullName }}</span>
        <span class="contact-detail__value">{{ contactFromForwarder?.origFullName }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">Country</span>
        <span class="contact-detail__value">{{ contactFromSearch?.country }}</span>
        <span class="contact-detail__value">{{ contactFromForwarder?.country }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">State</span>
        <span class="contact-detail__value">{{ contactFromSearch?.adminArea1 }}</span>
        <span class="contact-detail__value">{{ contactFromForwarder?.adminArea1 }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">City</span>
        <span class="contact-detail__value">{{ contactFromSearch?.adminArea2 }}</span>
        <span class="contact-detail__value">{{ contactFromForwarder?.adminArea2 }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">Title</span>
        <span class="contact-detail__value">{{ contactFromSearch?.jobTitle }}</span>
        <span class="contact-detail__value">{{ contactFromForwarder?.jobTitle }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">Responsibility</span>
        <span class="contact-detail__value">{{ contactFromSearch?.jobTitleResponsibility }}</span>
        <span class="contact-detail__value">{{ contactFromForwarder?.jobTitleResponsibility }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">Department</span>
        <span class="contact-detail__value">{{ contactFromSearch?.jobTitleDepartment }}</span>
        <span class="contact-detail__value">{{ contactFromForwarder?.jobTitleDepartment }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">Phone</span>
        <span class="contact-detail__value">{{ contactFromSearch?.phones }}</span>
        <span class="contact-detail__value">{{ contactFromForwarder?.phones }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">Id Email Status</span>
        <span class="contact-detail__value">{{ contactFromSearch?.idEmailStatus }}</span>
        <span class="contact-detail__value">{{ contactFromForwarder?.idEmailStatus }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">Date Created</span>
        <span class="contact-detail__value">{{ contactFromSearch?.dateCreated }}</span>
        <span class="contact-detail__value">{{ contactFromForwarder?.dateCreated }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">Date Updated</span>
        <span class="contact-detail__value">{{ contactFromSearch?.dateUpdated }}</span>
        <span class="contact-detail__value">{{ contactFromForwarder?.dateUpdated }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">Date Email Checked</span>
        <span class="contact-detail__value">{{ contactFromSearch?.dateEmailChecked }}</span>
        <span class="contact-detail__value">{{ contactFromForwarder?.dateEmailChecked }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">Id Company</span>
        <span class="contact-detail__value">{{ contactFromSearch?.companyId }}</span>
        <span class="contact-detail__value">{{ contactFromForwarder?.companyId }}</span>
      </p>
      <p class="contact-detail__paragraph">
        <span class="contact-detail__label">Company Name</span>
        <span class="contact-detail__value">{{ contactFromSearch?.company?.companyName }}</span>
        <span class="contact-detail__value">{{ contactFromForwarder?.company?.companyName }}</span>
      </p>
    </div>
  </div>
</div>

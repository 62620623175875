import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-color-selector',
    templateUrl: './color-selector.component.html',
    styleUrls: ['./color-selector.component.scss'],
    standalone: false
})
export class ColorSelectorComponent implements OnInit {

  @Input() selectedColor: string;

  constructor() { }

  ngOnInit() {
    if (!this.selectedColor)
      this.selectedColor = 'grey';
  }

}

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  message: string;
  title: string;
  confirmBtnText = 'ACCEPT';
  cancelBtnText = 'CANCEL';

  constructor(
    public dialog: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.title = data.title;
    this.message = data.message;
    if (data.confirmBtnText) {
      this.confirmBtnText = data.confirmBtnText;
    }
    if (data.cancelBtnText) {
      this.cancelBtnText = data.cancelBtnText;
    }
  }

  onDismiss(): void {
    this.dialog.close(false);
  }
  onConfirm(): void {
    this.dialog.close(true);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ClassificationStatus } from '../model/common/classification-status.model';
import { GenericResponse } from '../model/common/generic-response';
import { Customer } from '../model/configuration/customer.model';
import { Pattern } from '../model/configuration/pattern.model';
import { CatalogCollection, CatalogData, CatalogType } from '../model/mail-classifier/catalog-collection.model';
import { ClientRepresentative } from '../model/mail-classifier/client-representative.model';
import { LeadGenSources } from '../model/mail-classifier/lead-gen-sources.model';
import { SnackBarService } from './snackbar/snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  private clientRepresentativesSubject = new BehaviorSubject<ClientRepresentative>(null);
  private customersSubject = new BehaviorSubject<Customer[]>([]);

  constructor(
    private http: HttpClient,
    private snackbarService: SnackBarService,
  ) {}

  get clientRepresentatives$(): Observable<ClientRepresentative> {
    return this.clientRepresentativesSubject.asObservable();
  }

  get customers$() {
    return this.customersSubject.asObservable();
  }

  getClassificationStatuses(): Observable<ClassificationStatus[]> {
    const url = `${environment.newApiBaseUrl}/configuration/classification-status`;
    return this.http.get(url).pipe(map((response: GenericResponse<ClassificationStatus[]>) => response.data));
  }

  //TOOD: Remove this method after the new API is implemented
  getClassificationMockStatuses(): Observable<any[]> {
    return of([
      { ruleId: 0, ruleDescription: 'Do Nothing' },
      { ruleId: 47, ruleDescription: 'Converted' },
      { ruleId: 48, ruleDescription: 'Other' },
      { ruleId: 61, ruleDescription: 'Inbox' },
      { ruleId: 67, ruleDescription: 'Sales Operations' },
    ]);
  }

  getCatalogs(): Observable<CatalogCollection> {
    const url = `${environment.newApiBaseUrl}/configuration/bvmatrix`;

    return this.http.get(url).pipe(
      map((response: GenericResponse<CatalogData[]>) => {
        const catalogCollection: CatalogCollection = {
          matrix: [],
          country: [],
          delay: [],
          industry: [],
        };

        catalogCollection.matrix = response.data.filter((opt) => opt.type === CatalogType.Matrix);
        catalogCollection.country = response.data.filter((opt) => opt.type === CatalogType.Country);
        catalogCollection.delay = response.data.filter((opt) => opt.type === CatalogType.Delay);
        catalogCollection.industry = response.data.filter((opt) => opt.type === CatalogType.Industry);

        return catalogCollection;
      }),
    );
  }

  getLeadGenSources(): Observable<LeadGenSources[]> {
    const url = `${environment.newApiBaseUrl}/configuration/lead-gen-sources`;
    return this.http.get(url).pipe(map((response: GenericResponse<LeadGenSources[]>) => response.data));
  }

  getClientRepresentatives(sdrId: number): void {
    const url = `${environment.newApiBaseUrl}/configuration/client-representatives/${sdrId}`;

    this.http
      .get(url)
      .pipe(map((response: GenericResponse<ClientRepresentative>) => response.data))
      .subscribe({
        next: (clientRepresentatives: any) => {
          this.clientRepresentativesSubject.next(clientRepresentatives);
        },
        error: () => {
          this.snackbarService.showError('An error occurred while trying to get the client representatives');
        },
      });
  }

  getPatterns(): Observable<Pattern[]> {
    const url = `${environment.newApiBaseUrl}/configuration/patterns`;
    return this.http.get(url).pipe(map((response: GenericResponse<Pattern[]>) => response.data));
  }

  getCustomers(): Observable<Customer[]> {
    const url = `${environment.newApiBaseUrl}/configuration/customers`;
    return this.http.get(url).pipe(map((response: GenericResponse<Customer[]>) => response.data));
  }

  loadCustomers(): void {
    this.getCustomers().subscribe({
      next: (response) => {
        const customers = response?.length ? response : [];
        this.customersSubject.next(customers);
      },
    });
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

import { fetchAuthSession, getCurrentUser, signIn, signOut } from 'aws-amplify/auth';

export const TOKEN_KEY = 'idtoken';

@Injectable({
  providedIn: 'root',
})
export class SDAuthService {
  constructor(
    private router: Router,
    private http: HttpClient,
  ) {}

  async login(email: string, password: string) {
    try {
      const { nextStep } = await signIn({ username: email, password });
      if (nextStep.signInStep === 'CONFIRM_SIGN_UP') {
        throw new Error('User needs to confirm sign-up.');
      } else if (nextStep.signInStep === 'RESET_PASSWORD') {
        throw new Error('User needs to reset their password.');
      } else if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
        throw new Error('New password required. Complete password setup.');
      }

      const session = await fetchAuthSession();
      const token = session.tokens?.idToken?.toString();
      if (token) {
        localStorage.setItem(TOKEN_KEY, token);
      }

      return getCurrentUser();
    } catch (err) {
      console.error('Login error:', err);
      throw err;
    }
  }

  async logOut() {
    try {
      await signOut();
      localStorage.removeItem(TOKEN_KEY);
      this.router.navigate(['/login']);
    } catch (err) {
      console.error('Logout error:', err);
    }
  }

  isUserAuthenticatedGuard(): Promise<boolean | UrlTree> {
    return getCurrentUser()
      .then(() => true)
      .catch(() => this.router.createUrlTree(['/login']));
  }

  autoLogin() {
    if (localStorage.getItem(TOKEN_KEY)) {
      this.router.navigate(['/home']);
    }
  }

  async refreshToken() {
    try {
      const session = await fetchAuthSession();
      const newToken = session.tokens?.idToken?.toString();

      if (newToken) {
        localStorage.setItem(TOKEN_KEY, newToken);
      }
    } catch (err) {
      console.error('Token refresh error:', err);
      localStorage.removeItem(TOKEN_KEY);
      await this.router.navigate(['/']);
    }
  }

  refreshSessionToken(): Observable<string> {
    return from(fetchAuthSession()).pipe(map((session) => session.tokens?.idToken?.toString() || ''));
  }

  async getCurrentUserEmail(): Promise<string | null> {
    try {
      const user = await getCurrentUser();
      return user?.signInDetails?.loginId || null;
    } catch (err) {
      console.error('Error getting user email:', err);
      return null;
    }
  }

  sendRecoveryAccountCode(email: string): Observable<any> {
    const url = `${environment.apiBaseURL}/management/password-public-change-start`;
    return this.http.post(url, { username: email }).pipe(map((response) => response));
  }

  updatePassword(attrs: { username: string; password: string; code: string }): Observable<any> {
    const url = `${environment.apiBaseURL}/management/password-public-change-close`;
    return this.http.post(url, attrs).pipe(map((response) => response));
  }
}

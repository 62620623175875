<div class="converted-clients-topbar">
  <mat-icon
    class="converted-clients-topbar__close-icon"
    mat-dialog-close
    >close</mat-icon
  >
</div>
<table
  class="converted-clients-table"
  mat-table
  [dataSource]="dataSource"
  matSort
  matSortActive="date"
  matSortDirection="desc"
  matSortDisableClear
>
  <ng-container matColumnDef="date">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
    >
      Date
    </th>
    <td
      mat-cell
      *matCellDef="let row"
    >
      {{ row.date | date: 'dd/MM/yyyy' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="fullName">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
    >
      Full Name
    </th>
    <td
      mat-cell
      *matCellDef="let row"
    >
      {{ row.fullName }}
    </td>
  </ng-container>

  <ng-container matColumnDef="additionalInfo">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
    >
      Additional Info
    </th>
    <td
      mat-cell
      *matCellDef="let row"
    >
      {{ row.additionalInfo | titlecase }}
    </td>
  </ng-container>

  <tr
    mat-header-row
    *matHeaderRowDef="displayedColumns"
  ></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
  ></tr>
</table>

<div
  *ngIf="!dataSource.data.length"
  class="converted-clients-empty-table-mssg"
>
  No data to display
</div>

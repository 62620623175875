import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ClassificationStatus } from '../model/common/classification-status.model';
import { GenericResponse } from '../model/common/generic-response';
import { Pattern } from '../model/configuration/pattern.model';
import { CatalogCollection, CatalogData, CatalogType } from '../model/mail-classifier/catalog-collection.model';
import { ClientRepresentative } from '../model/mail-classifier/client-representative.model';
import { LeadGenSources } from '../model/mail-classifier/lead-gen-sources.model';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  constructor(private http: HttpClient) {}

  getClassificationStatuses(): Observable<ClassificationStatus[]> {
    const url = `${environment.newApiBaseUrl}/configuration/classification-status`;
    return this.http.get(url).pipe(map((response: GenericResponse<ClassificationStatus[]>) => response.data));
  }

  //TOOD: Remove this method after the new API is implemented
  getClassificationMockStatuses(): Observable<any[]> {
    return of([
      { ruleId: 0, ruleDescription: 'Do  Nothing' },
      { ruleId: 47, ruleDescription: 'Converted' },
      { ruleId: 48, ruleDescription: 'Other' },
      { ruleId: 61, ruleDescription: 'Inbox' },
      { ruleId: 67, ruleDescription: 'Sales Operations' },
    ]);
  }

  getCatalogs(): Observable<CatalogCollection> {
    const url = `${environment.newApiBaseUrl}/configuration/bvmatrix`;

    return this.http.get(url).pipe(
      map((response: GenericResponse<CatalogData[]>) => {
        const catalogCollection: CatalogCollection = {
          matrix: [],
          country: [],
          delay: [],
          industry: [],
        };

        catalogCollection.matrix = response.data.filter((opt) => opt.type === CatalogType.Matrix);
        catalogCollection.country = response.data.filter((opt) => opt.type === CatalogType.Country);
        catalogCollection.delay = response.data.filter((opt) => opt.type === CatalogType.Delay);
        catalogCollection.industry = response.data.filter((opt) => opt.type === CatalogType.Industry);

        return catalogCollection;
      }),
    );
  }

  getLeadGenSources(): Observable<LeadGenSources[]> {
    const url = `${environment.newApiBaseUrl}/configuration/lead-gen-sources`;
    return this.http.get(url).pipe(map((response: GenericResponse<LeadGenSources[]>) => response.data));
  }

  getClientRepresentatives(sdrId: number): Observable<ClientRepresentative> {
    const url = `${environment.newApiBaseUrl}/configuration/client-representatives/${sdrId}`;

    return this.http.get(url).pipe(map((response: GenericResponse<ClientRepresentative>) => response.data));
  }

  getPatterns(): Observable<Pattern[]> {
    const url = `${environment.newApiBaseUrl}/configuration/patterns`;
    return this.http.get(url).pipe(map((response: GenericResponse<Pattern[]>) => response.data));
  }
}

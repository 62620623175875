import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FaqDataService } from '../../../../services/faq-data.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { LoadingStateService } from 'src/app/services/base/loading-state.service';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { merge, Observable, of } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

const MODAL_DURATION = 3000;

@Component({
  selector: 'app-faq-manager-list',
  templateUrl: './faq-manager-list.component.html',
  styleUrls: ['./faq-manager-list.component.scss'],
  standalone: false,
})
export class FaqManagerListComponent implements OnInit, AfterViewInit {
  constructor(
    private service: FaqDataService,
    private router: Router,
    private dialog: MatDialog,
    private loadingStateService: LoadingStateService,
    private snackBar: MatSnackBar,
  ) {}

  faqListData: MatTableDataSource<any> = new MatTableDataSource([]);
  faqDisplayedColumns: string[] = ['date', 'title', 'client', 'actions'];
  @ViewChild('faqSort') faqSort: MatSort;
  @ViewChild('faqPaginator') faqPaginator: MatPaginator;
  faqSearchKey: string = '';

  loading = false;
  resultsLength = 0;
  firstLoad = true;

  ngOnInit() {
    this.loadingStateService.isLoading.subscribe((loading) => {
      this.loading = loading;
    });
  }

  ngAfterViewInit() {
    this.faqSort.sortChange.subscribe(() => (this.faqPaginator.pageIndex = 0));

    merge(this.faqSort.sortChange, this.faqPaginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.loadingStateService.setLoadingState(true);
          return this.service
            .getFaq(
              this.faqSearchKey,
              this.faqPaginator.pageIndex + 1,
              this.faqPaginator.pageSize,
              this.faqSort.active ? this.faqSort.active : 'date_created',
              this.faqSort.direction ? this.faqSort.direction : 'desc',
            )
            .pipe(catchError(() => of(null)));
        }),
        map((data) => {
          this.loadingStateService.setLoadingState(false);

          if (data === null) {
            return [];
          }

          this.firstLoad = false;
          this.resultsLength = data.total_count;
          return data.items;
        }),
      )
      .subscribe((data) => {
        this.faqListData = new MatTableDataSource(data);
      });
  }

  refreshData() {
    this.faqPaginator.pageIndex = 0;
    this.loadingStateService.setLoadingState(true);
    return this.service
      .getFaq(
        this.faqSearchKey,
        this.faqPaginator.pageIndex + 1,
        this.faqPaginator.pageSize,
        this.faqSort.active ? this.faqSort.active : 'date_created',
        this.faqSort.direction ? this.faqSort.direction : 'desc',
      )
      .pipe(catchError(() => of(null)))
      .pipe(
        map((data) => {
          this.loadingStateService.setLoadingState(false);

          if (data === null) {
            return [];
          }

          this.resultsLength = data.total_count;
          return data.items;
        }),
      )
      .subscribe((data) => (this.faqListData = new MatTableDataSource(data)));
  }

  onFaqSearchClear() {
    this.faqSearchKey = '';
    this.refreshData();
  }

  applyFaqFilter() {
    this.refreshData();
  }

  editFaq(id: string) {
    this.router.navigate(['/home/management/faq/faq-edit'], { queryParams: { id } });
  }

  removeFaq(id: string) {
    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.data = {
      title: 'Confirm',
      message: 'Are you sure you want to remove this faq?',
    };
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, confirmDialogConfig);
    confirmDialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.loadingStateService.setLoadingState(true);
        this.service.removeFaq(id).subscribe(
          (response) => {
            this.loadingStateService.setLoadingState(false);
            if (response.status == 204) {
              this.refreshData();
              this.snackBar.open('Faq template removed successfully', null, { duration: MODAL_DURATION });
            } else {
              this.handleOperationError(response.message);
            }
          },
          (error) => {
            console.log(error);
            this.handleOperationError('An error ocurred while removing the faq');
          },
        );
      }
    });
  }

  handleOperationError(message: string) {
    this.loadingStateService.setLoadingState(false);
    const errorConfirmDialogConfig = new MatDialogConfig();
    errorConfirmDialogConfig.data = {
      title: 'Error',
      message,
    };
    this.dialog.open(AlertDialogComponent, errorConfirmDialogConfig);
  }

  createFaq() {
    this.router.navigate(['/home/management/faq/faq-edit'], { queryParams: { id: 0 } });
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ClientContact, ClientContactApiResponse } from '../model/ClientContact';
import { AppConstants } from '../resources/app-constants';

@Injectable({
  providedIn: 'root',
})
export class ContactsDataService {
  private baseUrl = environment.newApiBaseUrl;
  private contactSegment = '/sales-representatives';
  constructor(private httpClient: HttpClient) {}

  // Define api interface
  loadContacts(
    name = '',
    status = '',
    client = '',
    email = '',
    page = 0,
    limit = AppConstants.CONTACTS_LIMIT,
    sortCol = 'date_created',
    sortDirection = 'desc'
  ): Observable<ClientContactApiResponse> {
    const offset = (page-1) * limit;    
    const nameFilter = name ? `&name=${name}` : '';
    const statusFilter = status ? `&status=${status}` : '';
    const clientFilter = client ? `&client=${client}` : '';
    const emailFilter = email ? `&email=${email}` : '';
    sortCol = sortCol === 'clientName' ? 'client.name' : sortCol;
    const queryUrl = `${this.baseUrl}${this.contactSegment}?limit=${limit}&offset=${offset}&orderBy=${sortCol}&orderType=${sortDirection}${nameFilter}${statusFilter}${clientFilter}${emailFilter}`;
    return this.httpClient
      .get(queryUrl)
      .pipe(
        map((response: any) => {
          const contacts : ClientContact[] = [];
          response.data.salesList.forEach(contact => {
            contacts.push(new ClientContact({
              id: contact.id,
              name: contact.name,
              status: contact.status,
              clientName: contact.client.name,
              clientId: contact.client.id,
              email: contact.email,
              geographicZone: contact.geographic_zone
            }))
          });
          return {
            items: contacts,
            total_count: response.data.totalCount
          };
        })
      );
  }

  loadContact(contactId) {
    const endpoint = environment.newApiBaseUrl + this.contactSegment + '/get-by-id/' + contactId;
    return this.httpClient.get(endpoint).pipe(
      map((response: any) => {
        return response.data;
      }),
    );
  }

  createOrUpdateContact(
    mode,
    contactId,
    name,
    clientId,
    comments,
    geographicZone,
    email,
    jobPosition,
    profileCc,
    emailTemplateCc,
    emailTemplateBcc,
    userCreated,
    userUpdated,
    status
  ) {
    let endpoint = environment.newApiBaseUrl + this.contactSegment;
    const body: any = {
      name,
      client: {id: clientId },
      comments,
      geographicZone : geographicZone,
      email,
      jobPosition : jobPosition,
      profileCC : profileCc,
      emailTemplateCC : emailTemplateCc,
      emailTemplateBCC : emailTemplateBcc,
      userCreated: userCreated,
      userUpdated: userUpdated,
    };

    if (mode === 'new') {
      endpoint = endpoint + '/create'
      body.status = 'Active';
      return this.httpClient.post(endpoint, body).pipe(map((response: any) => {
        return response;
      }));
    } else {
      endpoint = endpoint + '/update'
      body.id = contactId;
      body.status = status;
      return this.httpClient.patch(endpoint, body, { observe: 'response' }).pipe(map((response: any) => response));
    }
    
  }

  removeContact(contactId: number): Observable<any>{
    const url = `${environment.newApiBaseUrl}${this.contactSegment}/delete${contactId}`;

    return this.httpClient.delete(url).pipe(map(response => response));
  }
}

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../../../components/confirm-dialog/confirm-dialog.component';
import { HistoryInteraction } from '../../../model/common/history-interaction.model';
import { ProspectInfoDto } from '../../../model/common/prospect-info.dto';
import { ProspectInfo } from '../../../model/common/prospect-info.model';
import { ContactDialogData } from '../../../model/contact-dialog/contact-dialog-data.model';
import { Forwarder } from '../../../model/interaction/forwarder.model';
import { CreateLeadDto } from '../../../model/lead/create-lead-dto';
import { Lead } from '../../../model/lead/lead.model';
import { RegisterProspectDto } from '../../../model/leads-to-bulk/register-prospect-dto';
import { LoadingStateService } from '../../../services/base/loading-state.service';
import { InteractionService } from '../../../services/interaction.service';
import { LeadService } from '../../../services/lead.service';
import { LeadsToBulkService } from '../../../services/leads-to-bulk.service';
import { SnackBarService } from '../../../services/snackbar/snackbar.service';

@Component({
  selector: 'app-contact-dialog',
  templateUrl: './contact-dialog.component.html',
  styleUrls: ['./contact-dialog.component.scss'],
})
export class ContactDialogComponent implements OnInit, OnDestroy {
  emailAddress: string;
  customerId: number;
  isCreatingContact = false;
  searchResult: ProspectInfo = null;
  noResultsMessage = '';
  lead: Lead;
  historyData: HistoryInteraction[];
  forwarders: Forwarder[] = [];
  private destroy$ = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public parentData: ContactDialogData,
    private matDialog: MatDialog,
    private leadService: LeadService,
    private leadsToBulkService: LeadsToBulkService,
    private loadingStateService: LoadingStateService,
    private snackBarService: SnackBarService,
    private interactionService: InteractionService,
  ) {}

  ngOnInit() {
    const { contactId } = this.parentData;

    if (contactId) {
      this.getLeadByContactId(this.parentData.contactId);
    }

    if (contactId) {
      this.getForwarders(contactId);
    }

    this.emailAddress = this.parentData.emailAddress;
    this.customerId = this.parentData.customerId;

    this.leadService.isCreatingContact$.subscribe((isCreatingContact) => {
      this.isCreatingContact = isCreatingContact;
    });

    this.leadService.registerProspectCalled$.pipe(takeUntil(this.destroy$)).subscribe((registerProspectCalled) => {
      registerProspectCalled && this.handleRegisterProspect();
    });

    this.interactionService.forwarders$.pipe(takeUntil(this.destroy$)).subscribe((forwarders) => {
      this.forwarders = forwarders || [];
    });

    this.leadService.currentLead$.pipe(takeUntil(this.destroy$)).subscribe((lead) => {
      this.lead = lead;
    });
  }

  ngOnDestroy(): void {
    this.leadService.setClientFilterChecked(false);
    this.leadService.setCurrentLead(null);
    this.leadService.setCurrentContactId(null);
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  getForwarders(contactId: number) {
    this.interactionService.getForwarders(contactId).subscribe({
      next: (forwarders) => {
        this.interactionService.setForwarders(forwarders);
      },
      error: () => {
        this.snackBarService.showError('An error occurred while getting forwarders');
        this.interactionService.setForwarders([]);
      },
    });
  }

  createContact(contact: Lead) {
    const createContactDto: CreateLeadDto = {
      ...contact,
    };

    this.loadingStateService.setLoadingState(true);

    this.leadService
      .createLead(createContactDto)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.loadingStateService.setLoadingState(false)),
      )
      .subscribe({
        next: (contactId) => {
          this.snackBarService.showSuccess('Lead created successfully');
          this.leadService.setIsCreatingContact(false);
          this.leadService.setCurrentContactId(contactId);
          this.leadService.setClientFilterChecked(true);
          this.getLeadByContactId(contactId, true);
        },
        error: () => {
          this.snackBarService.showError('An error occurred while creating lead');
        },
      });
  }

  getLeadByContactId(contactId: number, fromCreate = false) {
    this.loadingStateService.setLoadingState(true);
    this.leadService.setCurrentLead(null);
    this.lead = null;

    this.leadService
      .getLeadByContactId(contactId)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.loadingStateService.setLoadingState(false)),
      )
      .subscribe({
        next: (lead) => {
          if (!lead) {
            this.snackBarService.showWarning('No contact found with the provided contactId.');
            return;
          }

          this.lead = lead || null;
          this.emailAddress = lead?.emailAddress || '';
          this.getInteractions(contactId);
          fromCreate && this.leadService.setCurrentContactId(contactId);
        },
        error: () => {
          this.snackBarService.showError('An error occurred while getting contact');

          fromCreate && this.leadService.setCurrentContactId(null);
        },
      });
  }

  getInteractions(contactId: number) {
    const prospectInfoDto: ProspectInfoDto = {
      contactId: contactId,
    };

    this.loadingStateService.setLoadingState(true);
    this.interactionService
      .getFullProspectInfo(prospectInfoDto)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.loadingStateService.setLoadingState(false)),
      )
      .subscribe({
        next: (response: ProspectInfo) => {
          this.searchResult = response || null;
          this.interactionService.setHasInteractions(response?.interactionsData?.interactions?.length > 0);
          this.noResultsMessage = response ? '' : "We couldn't find any results matching the search criteria";
          this.updateData();
        },
        error: () => {
          this.snackBarService.showError('An error occurred while getting contact');
        },
      });
  }

  handleRegisterProspect() {
    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.data = {
      title: 'Confirm',
      message: 'Are you sure you want to register a prospect?',
    };
    const confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, confirmDialogConfig);
    confirmDialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.registerProspect();
      }
    });
  }

  registerProspect() {
    const { interactionId, campaignId } = this.forwarders[0];

    const registerProspectDto: RegisterProspectDto = {
      contactId: this.lead.idLead,
      sdrId: this.parentData.sdrId,
      interactionId: interactionId,
      campaignId: campaignId,
    };

    if (!Object.values(registerProspectDto).every((value) => value)) {
      this.snackBarService.showError(
        'An error occurred while retrieving the information required to register the prospect.',
      );
      return;
    }
    this.leadService.setRegisterProspectCalled(false);
    this.loadingStateService.setLoadingState(true);

    this.leadsToBulkService
      .registerProspect(registerProspectDto)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.loadingStateService.setLoadingState(false)),
      )
      .subscribe({
        next: (contactId) => {
          this.snackBarService.showSuccess('Prospect registered successfully');
          this.leadService.setCurrentContactId(contactId);
          this.interactionService.setInteractionsUpdated(true);
          this.matDialog.closeAll();
        },
        error: () => {
          this.snackBarService.showError('An error occurred while registering prospect');
        },
      });
  }

  updateData() {
    if (this.lead) {
      this.lead.company = this.searchResult.companyData;
      this.lead.history =
        this.searchResult.interactionsData.interactions?.map((item) => ({
          $key: item.interactionId,
          leadToCampaignId: item.interactionId,
          campaignId: item.campaignId,
          campaignBulkId: item.interactionId,
          campaignBulkDate: item.utcDatetimeInteraction,
          customerName: item.customerName,
          userId: item.sdrId,
          userEmail: item.sdrEmailAddress,
          emailStatus: item.prospectStatus,
          dateStatus: item.utcDatetimeCreated,
        })) || [];

      this.historyData = this.lead.history;
    }
    this.leadService.setCurrentLead(this.lead);
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HistoryInteraction } from '../../../../model/common/history-interaction.model';
import { Lead } from '../../../../model/lead/lead.model';
import { LoadingStateService } from '../../../../services/base/loading-state.service';
import { LeadService } from '../../../../services/lead.service';
import { MailReviewService } from '../../../../services/mail-review.service';

@Component({
    selector: 'app-contact-detail',
    templateUrl: './contact-detail.component.html',
    styleUrls: ['./contact-detail.component.scss'],
    standalone: false
})
export class ContactDetailComponent implements OnInit, OnDestroy {
  lead: Lead;
  historyData: HistoryInteraction[] = [];
  isSearching = false;
  selectedHistoryRow = new Set<HistoryInteraction>();
  private destroy$ = new Subject<boolean>();

  constructor(
    private leadService: LeadService,
    private loadingStateService: LoadingStateService,
    private mailReviewService: MailReviewService,
  ) {}

  ngOnInit() {
    this.leadService.currentLead$.pipe(takeUntil(this.destroy$)).subscribe((lead) => {
      this.lead = lead;
      this.historyData = this.lead?.history || [];
    });

    this.loadingStateService.isLoading.pipe(takeUntil(this.destroy$)).subscribe((isLoading) => {
      this.isSearching = isLoading;
    });
  }

  onSelectHistoryRow(row) {
    this.selectedHistoryRow = row;
    this.mailReviewService.setSelectedHistoryInteraction(row);
  }

  ngOnDestroy(): void {
    this.mailReviewService.setSelectedHistoryInteraction(null);
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}

export interface GenericDataMapping {
  title: string;
  value: any;
  type?: ValueType;
}

export enum ValueType {
  Text = 'text',
  Url = 'url',
}

<div class="mail-review">
  <div class="mail-review__classifier">
    <app-mail-classifier></app-mail-classifier>
  </div>
  <div class="mail-review__interactions-classifications-container">
    <div
      class="mail-review__classifications-status-container"
      [ngClass]="{
        'mail-review__classifications-status-container--manual': currentStatusScreen === statusScreen.Manual
      }"
    >
      <app-mail-classification></app-mail-classification>
    </div>
    <div class="mail-review__search-leads">
      <app-mail-classifier-search-leads></app-mail-classifier-search-leads>
    </div>
  </div>
</div>

<div
  class="classifier-log"
  *ngIf="message && message.classifierLogId"
>
  <div class="classifier-log__heading">
    <div class="classifier-log__info">
      <div class="classifier-log__left">
        <div class="classifier-log__log-id">
          <span>ID &nbsp;<strong>{{ message.classifierLogId }}</strong></span>
        </div>
        <div class="classifier-log__sdr-id">
          <span>SDR &nbsp;<strong>{{ message.sdrId }}</strong></span>
        </div>
        <div class="classifier-log__sdr-email">
          <span><strong>{{ message.sdrMail }}</strong></span>
        </div>
      </div>
      <div class="classifier-log__complete-btn-container">
        <button
          class="classifier-log__complete-btn"
          mat-raised-button
          color="primary"
          (click)="onComplete()"
        >
          {{ 'Complete Case' }}
        </button>
      </div>
    </div>
    <div *ngIf="false" class="classifier-log__folders">
      <div class="classifier-log__input-folder">
        <span>Input Folder:</span>
        <app-mail-classifier-log-folder [folderType]="message.inputFolder"></app-mail-classifier-log-folder>
      </div>
      <mat-divider [vertical]="true"></mat-divider>
      <div class="classifier-log__output-folder">
        <span>Output Folder:</span>
        <app-mail-classifier-log-folder
          *ngIf="message?.outputFolder"
          [folderType]="message.outputFolder"
        ></app-mail-classifier-log-folder>
      </div>
    </div>
  </div>

  <div class="classifier-log__body">
    <ng-container *ngIf="message.email; else noRegister">
      <div class="classifier-log__sender-info">
        <div class="classifier-log__found-or-not-found">
          <span></span>
        </div>
        <span
          class="classifier-log__prospect-id clickable"
          (click)="onClickEmail(message.email.fromMail)"
        >
        </span>
        <span class="classifier-log__from-name">{{ message.email.fromName }}</span>
        <span
          class="classifier-log__from-mail clickable"
          (click)="onClickEmail(message.email.fromMail)"
        >
          {{ message.email.fromMail }}
        </span>
        <div class="classifier-log__status">
          <span>{{ message.inputFolder }}</span>
        </div>
      </div>

      <div class="classifier-log__lead-info">
        <div
          class="classifier-log__found-or-not-found"
          *ngIf="message.prospectFound === 'FOUND'"
        >
          <span class="tag tag--found">Found</span>
        </div>
        <div
          class="classifier-log__found-or-not-found"
          *ngIf="message.prospectFound === 'NOT FOUND'"
        >
          <span class="tag tag--not-found">Not Found</span>
        </div>
        <span
          class="classifier-log__prospect-id clickable"
          (click)="onClickEmail(message?.prospectData?.emailAddress)"
        >
          {{ message.prospectId && message.prospectId !== 0 ? message.prospectId : '' }}
        </span>
        <span class="classifier-log__from-name">
          {{ message?.prospectData?.fullName }}
        </span>
        <span
          class="classifier-log__from-mail clickable"
          (click)="onClickEmail(message?.prospectData?.emailAddress)"
        >
          {{ message?.prospectData?.emailAddress }}
        </span>
        <div class="classifier-log__status">
          <span>In Contacts</span>
        </div>
      </div>

      <div class="classifier-log__original-info">
        <div
          class="classifier-log__found-or-not-found"
          *ngIf="message.originalContactFound === 'FOUND'"
        >
          <span class="tag tag--found">Found</span>
        </div>
        <div
          class="classifier-log__found-or-not-found"
          *ngIf="message.originalContactFound === 'NOT FOUND'"
        >
          <span class="tag tag--not-found">Not Found</span>
        </div>
        <span
          class="classifier-log__prospect-id clickable"
          (click)="onClickEmail(message?.originalContactData?.emailAddress)"
        >
          {{ message?.originalContactData && message?.originalContactId !== 0 ? message.originalContactId : '' }}
        </span>
        <span class="classifier-log__from-name">
          {{ message?.originalContactData?.fullName }}
        </span>
        <span
          class="classifier-log__from-mail clickable"
          (click)="onClickEmail(message?.interactionsData?.contact?.emailAddress)"
        >
          {{ message?.originalContactData?.emailAddress }}
        </span>
        <div class="classifier-log__status">
          <span>Original Contact Sent</span>
        </div>
      </div>
    </ng-container>
    <ng-template #noRegister>
      <div class="tag tag--not-found">Not Registers Found</div>
    </ng-template>
  </div>
</div>

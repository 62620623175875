<div class="composer-top-bar">
  <div>Message</div>
  <div class="composer-top-bar__actions">
    <mat-icon
      mat-icon-button
      class="composer-top-bar__close-fullscreen-icon"
      (click)="onMinimizeDialog()"
      >close_fullscreen</mat-icon
    >
    <mat-icon
      class="composer-top-bar__close-icon"
      (click)="onCloseDialog()"
      >close</mat-icon
    >
  </div>
</div>

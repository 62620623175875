<div class="container">
  <div
    class="progress-bar-container"
    *ngIf="isLoadingSalesRep || isLoadingSalesRepConnected"
  >
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <span class="title">Select Sales Representative</span>
  <div class="sales-rep-select-container">
    <mat-form-field
      appearance="fill"
      class="field-container"
      id="sales-representative-select-mat-form-field"
    >
      <input
        type="text"
        aria-label="Number"
        matInput
        required
        [formControl]="salesRepFilterControl"
        [matAutocomplete]="salesRepAuto"
        id="sales-representative-select-input"
      />
      <mat-autocomplete
        #salesRepAuto="matAutocomplete"
        [displayWith]="displayFn"
        id="sales-representative-select-mat-autocomplete"
      >
        <ng-container *ngIf="!showSalesRepConnected">
          <mat-option
            *ngFor="let salesRep of filteredSalesRepOptions | async; let i = index"
            [value]="salesRep.name"
            [id]="'sales-representative-select-' + i + '-mat-option'"
          >
            <span class="sales-rep-name">{{ salesRep.name }}</span>
            <span class="sales-rep-geographic">{{ ' | ' + salesRep.geographicZone }}</span>
          </mat-option>
        </ng-container>

        <ng-container *ngIf="showSalesRepConnected">
          <mat-option
            *ngFor="let salesRep of filteredSalesRepConnectedOptions | async; let i = index"
            [value]="salesRep?.name"
            [id]="'sales-representative-select-' + i + '-mat-option'"
          >
            <span class="sales-rep-name">{{ salesRep?.name }}</span>
          </mat-option>
        </ng-container>
      </mat-autocomplete>
      <mat-error *ngIf="salesRepFilterControl.hasError('invalidSalesRep')">Sales Representative invalid</mat-error>
    </mat-form-field>
    <button
      *ngIf="data?.source === source.SendMessage"
      mat-icon-button
      [matTooltip]="showSalesRepConnected ? 'Hide Sales Rep Connected' : 'Show Sales Rep Connected'"
      (click)="onShowSalesRepConnected()"
      [disabled]="!allSalesRepConnected?.length"
    >
      <mat-icon>{{ showSalesRepConnected ? 'visibility_off' : 'visibility' }}</mat-icon>
    </button>
  </div>

  <div class="button-container">
    <button
      mat-button
      mat-dialog-close
      class="cancel-btn"
      id="sales-representative-cancel-btn"
    >
      Cancel
    </button>
    <button
      mat-raised-button
      color="primary"
      style="box-shadow: none"
      class="button-ok"
      [disabled]="!isValidSaleRep"
      (click)="onUseSalesRep()"
      id="sales-representative-ok-btn"
    >
      Confirm
    </button>
  </div>
</div>

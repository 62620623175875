export interface ClassifierMessageDto {
  statusScreen: StatusScreen;
  page: number;
  pageSize: number;
}

export enum StatusScreen {
  Review = 0,
  Manual = 1,
}

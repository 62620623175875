export interface GenericResponse<T> {
  status: ResponseStatus;
  data: T;
  message: string;
  errors: null;
}

export enum ResponseStatus {
  Error = 'error',
  Success = 'success',
}

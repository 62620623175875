import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { IInquiryLead } from 'src/app/model/InquiryLead';
import { InquiryService } from 'src/app/services/inquiry.service';

@Component({
    selector: 'app-inquiry-selection',
    templateUrl: './inquiry-selection.component.html',
    styleUrls: ['./inquiry-selection.component.scss'],
    standalone: false
})
export class InquirySelectionComponent implements OnInit, OnDestroy {
  emails: string[];
  inquiryLeads: IInquiryLead[];
  selectedIndex = 0;
  subscriptions: Subscription;
  constructor(private inquiryService: InquiryService) { }

  ngOnInit() {
    this.subscriptions = this.inquiryService.inquiryLeads.subscribe((inquiryLeads) => {
      if (inquiryLeads) {
        this.inquiryLeads = inquiryLeads;
        this.emails = inquiryLeads?.map(il => il.emailAddress);
        this.selectedIndex = inquiryLeads.findIndex(e => e.selected);
      }
    });
  }

  ngOnDestroy(){
    this.subscriptions.unsubscribe();
  }


  select(email: string, index: number) {
    this.inquiryLeads.filter(il => il.selected)[0].selected = false;
    const newSelection = this.inquiryLeads.find(il => il.emailAddress === email);
    if (!newSelection?.selected) {
        newSelection.selected = true;
        newSelection.filled = true;
        this.inquiryService.setInquiryLeads(this.inquiryLeads);
      this.selectedIndex = index;
    }
  }

}

<div class="dashboard">
  <div
    class="dashboard__filters"
    *ngIf="metabaseResources?.length > 0"
  >
    <mat-form-field
      class="dashboard__select"
      appearance="fill"
    >
      <mat-label>Select dashboard</mat-label>
      <mat-select
        [formControl]="selectDashboard"
        (selectionChange)="loadDashboard($event.value.metabaseId, $event.value.resourceType)"
      >
        <mat-option
          *ngFor="let resource of metabaseResources"
          [value]="resource"
        >
          {{ resource.metabaseResourceName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div
    #spinnerContainer
    class="dashboard__loader"
    *ngIf="iFrameLoading && metabaseResources?.length > 0"
  >
    <mat-spinner></mat-spinner>
  </div>
  <iframe
    class="dashboard__iframe"
    *ngIf="iframeUrl"
    id="dashboardFrame"
    [src]="iframeUrl"
    frameborder="0"
    height="iframeHeight"
    allowtransparency
  >
  </iframe>
  <div
    class="dashboard__dashboard-not-found"
    *ngIf="metabaseResources?.length === 0 && !iFrameLoading && !selectedDashboardNotAvailable"
  >
    <p>You have no assigned board</p>
  </div>
</div>

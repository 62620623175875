import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ClassificationStatusEnum } from '../model/common/classification-status.enum';
import { Contact } from '../model/common/contact.model';
import { GenericResponse } from '../model/common/generic-response';
import { InteractionData } from '../model/common/interaction-data.model';
import { Interaction } from '../model/common/interaction.model';
import { CheckConnectionDto } from '../model/sdr-conversations/check-connection.dto';
import { ClassifyMessageDto } from '../model/sdr-conversations/classify-message.dto';
import { ClassifyTrashMessagesDto } from '../model/sdr-conversations/classify-trash-messages.dto';
import {
  Conversation,
  LinkedInConversation,
  SdrConversation,
} from '../model/sdr-conversations/sdrs-conversations.model';
import { SendMessageDto } from '../model/sdr-conversations/send-message.dto';
import { ConversationMessage, Message } from '../model/sdr-conversations/conversation-mesage.model';
import { SalesRepresentativeConnected } from '../model/sdr-conversations/sales-rep-connected.model';
import { MessageFeedDto } from '../model/sdr-conversations/message-feed.dto';

@Injectable({
  providedIn: 'root',
})
export class SdrConversationsService {
  private reloadConversationsSubject = new BehaviorSubject<boolean>(false);
  private sdrsConversationsSubject = new BehaviorSubject<SdrConversation[]>(null);
  private selectedConversationSubject = new BehaviorSubject<Conversation>(null);
  private selectedInteractionSubject = new BehaviorSubject<Interaction>(null);
  private selectedMessagesSubject = new BehaviorSubject<Message[]>(null);
  private currentContactSubject = new BehaviorSubject<Contact>(null);
  private hasInteractionsSubject = new BehaviorSubject<boolean>(false);
  private currentConversationMessagesSubject = new BehaviorSubject<ConversationMessage>(null);
  private isLoadingConversationMessagesSubject = new BehaviorSubject<boolean>(false);
  private selectedFolderSubject = new BehaviorSubject<string>('');
  private selectedConversationTypeSubject = new BehaviorSubject<string>('');
  private selectedSdrSubject = new BehaviorSubject<number>(null);
  private totalCountSubject = new BehaviorSubject<number>(0);

  constructor(private http: HttpClient) {}

  get sdrsConversations$() {
    return this.sdrsConversationsSubject.asObservable();
  }

  setReloadConversations(value: boolean) {
    this.reloadConversationsSubject.next(value);
  }

  get reloadConversations$() {
    return this.reloadConversationsSubject.asObservable();
  }

  setSdrsConversations(sdrsConversations: SdrConversation[]) {
    this.sdrsConversationsSubject.next(sdrsConversations);
  }

  get selectedConversation$() {
    return this.selectedConversationSubject.asObservable();
  }

  setSelectedConversation(conversation: Conversation) {
    this.selectedConversationSubject.next(conversation);
  }

  get hasInteractions$() {
    return this.hasInteractionsSubject.asObservable();
  }

  setHasInteractions(hasInteractions: boolean) {
    this.hasInteractionsSubject.next(hasInteractions);
  }

  get selectedInteraction$() {
    return this.selectedInteractionSubject.asObservable();
  }
  setSelectedInteraction(interaction: Interaction) {
    this.selectedInteractionSubject.next(interaction);
  }

  get selectedMessages$() {
    return this.selectedMessagesSubject.asObservable();
  }

  setSelectedMessages(message: Message[]) {
    this.selectedMessagesSubject.next(message);
  }

  get currrentContact$() {
    return this.currentContactSubject.asObservable();
  }

  setCurrentContact(contact: Contact) {
    this.currentContactSubject.next(contact);
  }

  get currentConversationMessages$() {
    return this.currentConversationMessagesSubject.asObservable();
  }

  setCurrentConversationMessages(conversationMessages: ConversationMessage) {
    this.currentConversationMessagesSubject.next(conversationMessages);
  }

  get isLoadingConversationMessages$() {
    return this.isLoadingConversationMessagesSubject.asObservable();
  }

  setIsLoadingConversationMessages(isLoading: boolean) {
    this.isLoadingConversationMessagesSubject.next(isLoading);
  }

  get selectedFolder$() {
    return this.selectedFolderSubject.asObservable();
  }

  setSelectedFolder(folder: string) {
    this.selectedFolderSubject.next(folder);
  }

  get selectedConversationType$() {
    return this.selectedConversationTypeSubject.asObservable();
  }

  setSelectedConversationType(conversationType: string) {
    this.selectedConversationTypeSubject.next(conversationType);
  }

  get selectedSdr$() {
    return this.selectedSdrSubject.asObservable();
  }

  setSelectedSdr(sdrId: number) {
    this.selectedSdrSubject.next(sdrId);
  }

  get totalCount$() {
    return this.totalCountSubject.asObservable();
  }

  setTotalCount(totalCount: number) {
    this.totalCountSubject.next(totalCount);
  }

  findSdrdByConversationId = (conversationId) => {
    const sdrConversations = this.sdrsConversationsSubject.getValue() || [];
    for (const sdr of sdrConversations) {
      if (sdr?.conversations?.some((conv) => conv?.conversationId === conversationId)) {
        return sdr;
      }
    }
    return null;
  };

  checkConnection(checkConnectionDto: CheckConnectionDto): Observable<boolean> {
    const url = `${environment.newApiBaseUrl}/messages/check-connection`;

    return this.http.post(url, checkConnectionDto).pipe(map((response: GenericResponse<boolean>) => response.data));
  }

  getSdrsConversationsByFolder(messageFeedDto: MessageFeedDto): Observable<SdrConversation[]> {
    const url = `${environment.newApiBaseUrl}/messages/messages-feed`;

    return this.http.post(url, messageFeedDto).pipe(
      map((response: GenericResponse<LinkedInConversation>) => response.data),
      tap((response: LinkedInConversation) => {
        this.setTotalCount(response.totalCount || 0);
      }),
      map((sdrConversations: LinkedInConversation) =>
        sdrConversations.folderMessagesFeeds.map((sdr) => ({
          ...sdr,
          conversations: sdr.conversations.map((conversation) => {
            const participantNames = conversation.encryptedUrls
              .map((url) =>
                conversation.participants
                  .filter((p) => p.encryptedUrl === url)
                  .map((p) => `${p.firstName} ${p.lastName}`),
              )
              .flat();

            return {
              ...conversation,
              participantsName: participantNames,
            };
          }),
        })),
      ),
      tap((sdrConversations: SdrConversation[]) => {
        const existingSdrConversations = this.sdrsConversationsSubject.getValue();

        const updatedSdrConversations =
          messageFeedDto.page === 1
            ? sdrConversations
            : sdrConversations.reduce(
                (acc, newSdr) => {
                  const existingSdr = acc.find((sdr) => sdr.sdrId === newSdr.sdrId);

                  if (existingSdr) {
                    existingSdr.conversations = [
                      ...existingSdr.conversations,
                      ...newSdr.conversations.filter(
                        (newConv) =>
                          !existingSdr.conversations.some(
                            (existingConv) => existingConv.conversationId === newConv.conversationId,
                          ),
                      ),
                    ];
                  } else {
                    acc.push(newSdr);
                  }

                  return acc;
                },
                [...existingSdrConversations],
              );

        this.setSdrsConversations(updatedSdrConversations);
      }),
    );
  }

  getMessagesByConversationId(conversationId: string): Observable<ConversationMessage> {
    const url = `${environment.newApiBaseUrl}/messages/message-feed/conversation/${conversationId}`;

    return this.http.get(url).pipe(map((response: GenericResponse<ConversationMessage>) => response.data));
  }

  classifyTrashMessages(classifyTrashMessagesDto: ClassifyTrashMessagesDto) {
    const url = `${environment.newApiBaseUrl}/messages/classify-trash-messages`;

    return this.http.post(url, classifyTrashMessagesDto);
  }

  classifyMessage(classifyMessageDto: ClassifyMessageDto): Observable<GenericResponse<any>> {
    const url = `${environment.newApiBaseUrl}/messages/classify-message`;

    return this.http.post<GenericResponse<any>>(url, classifyMessageDto);
  }

  getSdrInteractions(sdrId: number, conversationId: string): Observable<InteractionData> {
    const url = `${environment.newApiBaseUrl}/messages/interaction-data/${sdrId}/${conversationId}`;

    return this.http.get(url).pipe(map((response: GenericResponse<InteractionData>) => response.data));
  }

  sendMessage(sendMessageDto: SendMessageDto): Observable<Object> {
    const url = `${environment.newApiBaseUrl}/messages/send-message`;

    return this.http.post(url, sendMessageDto);
  }

  addMessageToConversation(newMessage: Message): void {
    const currentConversationMessages = this.currentConversationMessagesSubject.getValue();
    const updatedConversation = {
      ...currentConversationMessages,
      messages: [...currentConversationMessages.messages, newMessage],
    };
    this.currentConversationMessagesSubject.next(updatedConversation);
  }

  updateConversation(classificationName?: string) {
    const currentConversation = this.currentConversationMessagesSubject.getValue();
    if (!this.selectedMessagesSubject.getValue()?.length) {
      return;
    }

    const isInbox = this.selectedFolderSubject.getValue() === 'INBOX';
    let messageHandlerIds = this.selectedMessagesSubject.getValue().map((m) => m.messageHandlerId);

    if (isInbox && classificationName === ClassificationStatusEnum.Converted) {
      currentConversation.messages.forEach((message) => (message.messageStatus = ClassificationStatusEnum.Converted));
      messageHandlerIds = [];
    }

    const filteredMessages = currentConversation.messages?.filter(
      (message) => !messageHandlerIds.includes(message.messageHandlerId),
    );

    const hasInboundMessages = filteredMessages.some((message) => message.isInbound === true && !message?.isTemp);

    if (classificationName === ClassificationStatusEnum.Trash || isInbox) {
      if (hasInboundMessages) {
        this.currentConversationMessagesSubject.next({ ...currentConversation, messages: filteredMessages });
      } else {
        this.reloadConversationsSubject.next(true);
      }
    } else {
      currentConversation.messages.forEach((message) => {
        if (messageHandlerIds.includes(message.messageHandlerId)) {
          message.messageStatus = classificationName;
        }
      });
      this.currentConversationMessagesSubject.next({ ...currentConversation });
    }
  }

  getSalesRepConnected(sdrId: number): Observable<SalesRepresentativeConnected[]> {
    const url = `${environment.newApiBaseUrl}/messages/get-sales-rep-connected/${sdrId}`;

    return this.http.get(url).pipe(map((response: GenericResponse<SalesRepresentativeConnected[]>) => response.data));
  }
}

import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { IconNames, Language, SuportedLanguages } from '../../model/Flags';

@Component({
    selector: 'app-flag-viewer',
    templateUrl: './flag-viewer.component.html',
    styleUrls: ['./flag-viewer.component.scss'],
    standalone: false
})
export class FlagViewerComponent {

  @Input() public languages: Language[] = [];
  @Input() public showFullName: boolean = false;
  @Input() public flexDirection: 'inline' | 'column' = 'inline';

  constructor(
    private readonly iconRegistry: MatIconRegistry,
    private readonly sanitizer: DomSanitizer
  ){
    this.iconRegistry.addSvgIcon(
      IconNames.usa,
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/usa_flag.svg')
    );
    this.iconRegistry.addSvgIcon(
      IconNames.spain,
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/spain_flag.svg')
    );
    this.iconRegistry.addSvgIcon(
      IconNames.brazil,
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/brazil_flag.svg')
    );
  }

  public getFlagForLanguage(language: string): string {
    switch(language){
      case SuportedLanguages.english:
        return IconNames.usa;
      case SuportedLanguages.spanish:
        return IconNames.spain;
      case SuportedLanguages.portuguese:
        return IconNames.brazil;
      default:
        return IconNames.usa;
    }
  }
}

<div class="select-template">
  <div class="title">Select Template for {{ data.client.name }} - {{ data.sdr }}</div>

  <mat-icon
    class="icon-button"
    mat-dialog-close
    >close</mat-icon
  >
</div>
<ng-container *ngIf="isLoading">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-container>
<mat-dialog-content>
  <mat-tab-group (selectedTabChange)="tabChanged()">
    <mat-tab *ngIf="showReply">
      <ng-template mat-tab-label>General Reply Templates</ng-template>
      <form class="select-template-form">
        <mat-form-field
          class="middle-width form-field-4"
          appearance="outline"
          id="select-template-search-general-mat-form-field"
        >
          <mat-label>Search here for title, notes, tags, client contact</mat-label>
          <input
            matInput
            (keyup)="filterReplyTemplates($event)"
            id="select-template-search-general-input"
          />
        </mat-form-field>
      </form>
      <div class="select-template__table-container">
        <div class="mat-elevation-z8 card-left">
          <mat-table
            [dataSource]="replyTemplatesSource"
            #replyTemplatesSort="matSort"
            matSort
            matSortActive="title"
          >
            <ng-container matColumnDef="titleReply">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="select-template__table-cell"
                >Title</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                class="select-template__table-cell"
                >{{ row.title }}</mat-cell
              >
            </ng-container>
            <ng-container matColumnDef="attachmentReply">
              <mat-header-cell
                *matHeaderCellDef
                class="select-template__table-cell"
              ></mat-header-cell>
              <mat-cell
                *matCellDef="let row"
                class="select-template__table-cell"
              >
                <mat-icon
                  *ngIf="row.attachment && row.attachment.length > 0"
                  class="iconSelectTemplate"
                  >attach_file</mat-icon
                >
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="notesReply">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="select-template__table-cell"
                >Notes</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                class="select-template__table-cell"
                >{{ row.notes }}</mat-cell
              >
            </ng-container>
            <ng-container matColumnDef="salesRepresentative">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="select-template__table-cell"
                >salesRepresentative</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                class="select-template__table-cell"
                >{{ row.clientContact }}</mat-cell
              >
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumnsReply; sticky: true"></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: displayedColumnsReply"
              (click)="selectTemplate(row)"
              [ngClass]="{ 'selected-row': row.isSelected }"
            ></mat-row>

            <ng-container matColumnDef="noData">
              <mat-footer-cell
                *matFooterCellDef
                colspan="2"
              >
                No data matching the filter
              </mat-footer-cell>
            </ng-container>
            <mat-footer-row
              *matFooterRowDef="['noData']"
              [ngClass]="{
                hide:
                  replyTemplatesSource && replyTemplatesSource.data.length && replyTemplatesSource.filteredData.length
              }"
            ></mat-footer-row>
          </mat-table>
        </div>
        <div class="mat-elevation-z8 card-right">
          <mat-card *ngIf="selectedTemplate">
            <mat-card-header>
              <mat-card-title class="card-title">{{ selectedTemplate.title }}</mat-card-title>
              <mat-card-subtitle
                class="card-subtitle"
                *ngIf="selectedTemplate.attachment.length || selectedTemplate.clientContact"
              >
                <mat-icon
                  *ngIf="selectedTemplate.attachment.length"
                  class="iconSelectTemplate card-attach"
                  >attach_file</mat-icon
                >
                <mat-chip
                  *ngIf="selectedTemplate.clientContact"
                  class="example-box card-client"
                  cdkDrag
                >
                  {{ selectedTemplate.clientContact }}
                </mat-chip>
              </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content class="card-content">
              <quill-view-html [content]="selectedTemplate?.templateText"></quill-view-html>
            </mat-card-content>
            <mat-card-actions
              *ngIf="selectedTemplate.notes"
              class="notes-container"
            >
              <div>
                <p
                  *ngIf="selectedTemplate.notes"
                  class="note-text"
                >
                  Notes: {{ selectedTemplate.notes }}
                </p>
              </div>
            </mat-card-actions>
          </mat-card>
          <mat-card *ngIf="!selectedTemplate">
            <div class="container">
              <img
                mat-card-image
                src="assets/images/empty_template_picture.png"
                class="card-image"
              />
              <div class="select-template-message">
                PLEASE SELECT A TEMPLATE <br />
                TO PREVIEW
              </div>
            </div>
          </mat-card>
        </div>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>Introduction Templates</ng-template>
      <form class="select-template-form">
        <mat-form-field
          class="middle-width form-field-4"
          appearance="outline"
          id="select-template-search-introduction-mat-form-field"
        >
          <mat-label>Search here for title, notes, tags, client contact</mat-label>
          <input
            matInput
            (keyup)="filterIntroductionTemplates($event)"
            id="select-template-search-introduction-input"
          />
        </mat-form-field>
        <div class="converted-btn-wrapper">
          <button
            mat-stroked-button
            type="button"
            (click)="openCovertedList()"
            [disabled]="isConvertedListLoading"
            id="select-template-client-contacts-btn"
          >
            <span *ngIf="!isConvertedListLoading">CLIENT CONTACTS</span>
            <mat-spinner
              class="search-btn-spinner"
              *ngIf="isConvertedListLoading"
              diameter="20"
            ></mat-spinner>
          </button>
        </div>
      </form>
      <div class="select-template__table-container">
        <div class="mat-elevation-z8 card-left">
          <mat-table
            [dataSource]="introductionTemplatesSource"
            #introductionTemplatesSort="matSort"
            matSort
            matSortActive="title"
          >
            <ng-container matColumnDef="title">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="select-template__table-cell"
                >Title</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                class="select-template__table-cell"
                >{{ row.title }}</mat-cell
              >
            </ng-container>
            <ng-container matColumnDef="attachment">
              <mat-header-cell
                *matHeaderCellDef
                class="select-template__table-cell"
              ></mat-header-cell>
              <mat-cell *matCellDef="let row">
                <mat-icon
                  *ngIf="row.attachment && row.attachment.length > 0"
                  class="iconSelectTemplate"
                  >attach_file</mat-icon
                >
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="notes">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="select-template__table-cell"
                >Notes</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                class="select-template__table-cell"
                >{{ row.notes }}</mat-cell
              >
            </ng-container>
            <ng-container matColumnDef="salesRepresentative">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="select-template__table-cell"
                >Sales Representative</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                class="select-template__table-cell"
                >{{ row.clientContact }}</mat-cell
              >
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: displayedColumns"
              (click)="selectTemplate(row)"
              [ngClass]="{ 'selected-row': row.isSelected }"
            ></mat-row>

            <ng-container matColumnDef="noData">
              <mat-footer-cell
                *matFooterCellDef
                colspan="2"
              >
                No data matching the filter
              </mat-footer-cell>
            </ng-container>
            <mat-footer-row
              *matFooterRowDef="['noData']"
              [ngClass]="{
                hide:
                  introductionTemplatesSource &&
                  introductionTemplatesSource.data.length &&
                  introductionTemplatesSource.filteredData.length
              }"
            ></mat-footer-row>
          </mat-table>
        </div>
        <div class="mat-elevation-z8 card-right">
          <mat-card *ngIf="selectedTemplate">
            <mat-card-header>
              <mat-card-title class="card-title">{{ selectedTemplate.title }}</mat-card-title>
              <mat-card-subtitle
                class="card-subtitle"
                *ngIf="selectedTemplate.attachment.length || selectedTemplate.clientContact"
              >
                <mat-icon
                  *ngIf="selectedTemplate.attachment.length"
                  class="iconSelectTemplate card-attach"
                  >attach_file</mat-icon
                >
                <mat-chip
                  *ngIf="selectedTemplate.clientContact"
                  class="example-box card-client"
                  cdkDrag
                >
                  {{ selectedTemplate.clientContact }}
                </mat-chip>
              </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content class="card-content">
              <quill-view-html [content]="selectedTemplate?.templateText"></quill-view-html>
            </mat-card-content>
            <mat-card-actions
              *ngIf="selectedTemplate.notes"
              class="notes-container"
            >
              <div>
                <p
                  *ngIf="selectedTemplate.notes"
                  class="note-text"
                >
                  Notes: {{ selectedTemplate.notes }}
                </p>
              </div>
            </mat-card-actions>
          </mat-card>
          <mat-card *ngIf="!selectedTemplate">
            <div class="container">
              <img
                mat-card-image
                src="assets/images/empty_template_picture.png"
                class="card-image"
              />
              <div class="select-template-message">
                PLEASE SELECT A TEMPLATE <br />
                TO PREVIEW
              </div>
            </div>
          </mat-card>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-raised-button
    color="primary"
    [mat-dialog-close]="selectedTemplate"
    style="box-shadow: none"
    class="buttom-template"
    [disabled]="!selectedTemplate"
    id="select-template-use-template-btn"
  >
    USE TEMPLATE
  </button>
</mat-dialog-actions>

<h1 mat-dialog-title>Undo Interaction</h1>
<mat-dialog-content>
  <mat-form-field
    subscriptSizing="dynamic"
    class="undo-interaction__details-form-field"
  >
    <mat-label>Details</mat-label>
    <input
      matInput
      [formControl]="detailsCtrl"
      id="undo-interaction-details-input"
    />
    <mat-icon
      matSuffix
      aria-label="Clear"
      *ngIf="detailsCtrl.value"
      (click)="onClearDetails()"
      class="undo-interaction__clear-details"
      id="undo-interaction-details-mat-icon-clear"
    >
      close
    </mat-icon>
    <mat-error *ngIf="detailsCtrl?.errors?.required">This field is required</mat-error>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-button
    mat-dialog-close
    [mat-dialog-close]="false"
    cdkFocusInitial
  >
    CANCEL
  </button>
  <button
    mat-button
    color="primary"
    (click)="onUndoInteraction()"
    [disabled]="isUndoing || detailsCtrl.invalid"
  >
    <span *ngIf="!isUndoing">UPDATE</span>
    <mat-spinner
      *ngIf="isUndoing"
      diameter="20"
    ></mat-spinner>
  </button>
</mat-dialog-actions>

<div
  *ngIf="isReview; else manual"
  class="mail-classification"
  (click)="statuses.length >= statusesToShow && toggleOverlay()"
  #toggleButton
>
  <b class="mail-classification__title">CLASSIFICATIONS</b>
  <div class="mail-classification__button-container">
    <ng-container *ngFor="let status of statuses; let i = index">
      <button
        *ngIf="i < statusesToShow"
        mat-stroked-button
        class="mail-classification__button"
        color="primary"
        [disabled]="isButtonDisabledForReview(status)"
        (click)="classify($event, status)"
      >
        {{ status.ruleDescription }}
      </button>
    </ng-container>
  </div>
  <mat-icon
    ngClass="mail-classification__overlay-icon"
    *ngIf="!isOverlayOpen && statuses.length >= statusesToShow"
    >keyboard_arrow_down</mat-icon
  >
  <mat-icon
    ngClass="mail-classification__overlay-icon"
    *ngIf="isOverlayOpen && statuses.length >= statusesToShow"
    >keyboard_arrow_up</mat-icon
  >
</div>
<ng-template #overlayContent>
  <div class="overlay">
    <div class="overlay__aditionals-classifications">
      <ng-container *ngFor="let status of statuses; let i = index">
        <button
          *ngIf="i >= statusesToShow"
          mat-stroked-button
          class="mail-classification__button"
          color="primary"
          [disabled]="isButtonDisabledForReview(status)"
          (click)="classify($event, status)"
        >
          {{ status.ruleDescription }}
        </button>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #manual>
  <div
    class="mail-classification mail-classification--manual"
    #toggleButton
  >
    <div class="mail-classification__button-container mail-classification__button-container--manual">
      <ng-container *ngFor="let status of statuses; let i = index">
        <button
          mat-stroked-button
          class="mail-classification__button"
          color="primary"
          [disabled]="isButtonDisabledForManual(status)"
          (click)="classify($event, status)"
        >
          {{ status.ruleDescription }}
        </button>
      </ng-container>
    </div>
  </div>
</ng-template>

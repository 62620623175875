<div
  class="open-inquiry-dialog"
  fxFill
  fxLayout="column"
>
  <div
    fxLayout="row"
    class="dialog-top-bar"
    fxLayoutAlign="space-between center"
  >
    <div class="title">{{ title }}</div>
    <div fxLayout="row">
      <mat-icon
        mat-dialog-close
        class="close-btn"
        >close</mat-icon
      >
    </div>
  </div>
  <div fxLayout="row">
    <ng-container *ngIf="isFeed">
      <app-inquiry-selection fxFlex="20"></app-inquiry-selection>
    </ng-container>
    <div
      fxLayout="column"
      [fxFlex]="isFeed ? '80' : '100'"
    >
      <ng-container *ngIf="isFeed">
        <app-inquiry-search
          fxFlex="20"
          [initialSelectedSdr]="sdr"
          [initialSelectedClient]="client"
          [initialEmail]="email"
          [initialDate]="date"
        ></app-inquiry-search>
      </ng-container>
      <ng-container *ngIf="!isFeed">
        <app-view-lead-toolbar
          [selectedHistoryRow]="selectedHistoryRow"
          fxFlex="10"
        ></app-view-lead-toolbar>
      </ng-container>
      <div fxFlex="80">
        <div
          fxLayout="column"
          fxFlex="100"
          fxFill
        >
          <app-inquiry-detail></app-inquiry-detail>
          <app-inquiry-history *ngIf="showHistory" (setSelectedHistoryRow)="onSelectHistoryRow($event)"></app-inquiry-history>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="recipient-fields">
  <mat-form-field
    class="dense-input form-field-5"
    [subscriptSizing]="'dynamic'"
    id="recipient-fields-from-mat-form-field"
  >
    <span
      matPrefix
      class="recipient-fields__label"
      >From:</span
    >
    <input
      type="text"
      aria-label="Number"
      matInput
      [formControl]="fromControl"
      [matAutocomplete]="auto"
      id="recipient-fields-from-input"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="displayFn"
      id="recipient-fields-from-mat-autocomplete"
    >
      <mat-option
        *ngFor="let sdr of filteredSdrs | async; let i = index"
        [value]="sdr"
        [id]="'recipient-fields-from-' + i + '-mat-option'"
      >
        {{ sdr }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field
    class="dense-input form-field-5"
    [subscriptSizing]="'dynamic'"
    id="recipient-fields-to-mat-form-field"
  >
    <span
      matPrefix
      class="recipient-fields__label"
      >To:</span
    >

    <mat-chip-grid #toChipList>
      <mat-chip
        [color]="item.invalid ? 'warn' : ''"
        selected
        *ngFor="let item of toEmailList; let i = index"
        [removable]="removable"
        (removed)="removeToEmail(item)"
        name="toChips"
        id="recipient-fields-to-mat-chip"
        [id]="'recipient-fields-to-' + i + '-mat-chip'"
      >
        {{ item.value }}
        <mat-icon
          matChipRemove
          *ngIf="removable"
          [id]="'recipient-fields-to-' + i + '-mat-icon-remove'"
          >cancel</mat-icon
        >
      </mat-chip>
      <input
        matInput
        id="to"
        [matChipInputFor]="toChipList"
        [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="addToEmail($event)"
        [formControl]="toControl"
        [matAutocomplete]="toAuto"
        #toInput
      />
    </mat-chip-grid>
    <mat-autocomplete
      #toAuto="matAutocomplete"
      [displayWith]="displayAddressFn"
      (optionSelected)="updateToAuto()"
      id="recipient-fields-to-mat-autocomplete"
    >
      <mat-option
        *ngFor="let address of filteredAddresses | async; let i = index"
        [value]="address"
        [id]="'recipient-fields-to-' + i + '-mat-option'"
      >
        {{ address }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field
    class="dense-input form-field-5"
    [subscriptSizing]="'dynamic'"
    id="recipient-fields-cc-mat-form-field"
  >
    <span
      matPrefix
      class="recipient-fields__label"
      >Cc:</span
    >
    <mat-chip-grid #ccChipList>
      <mat-chip-row
        [color]="item.invalid ? 'warn' : ''"
        selected
        *ngFor="let item of ccEmailList; let i = index"
        [removable]="removable"
        (removed)="removeCCEmail(item)"
        name="ccChips"
        [id]="'recipient-fields-cc-' + i + '-mat-chip-row'"
      >
        {{ item.value }}
        <mat-icon
          matChipRemove
          *ngIf="removable"
          [id]="'recipient-fields-cc-' + i + '-mat-icon-remove'"
          >cancel</mat-icon
        >
      </mat-chip-row>
      <input
        matInput
        [matChipInputFor]="ccChipList"
        [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="addCCEmail($event)"
        [formControl]="ccControl"
        [matAutocomplete]="ccAuto"
        #ccInput
        id="recipient-field-cc-input"
      />
    </mat-chip-grid>
    <mat-autocomplete
      #ccAuto="matAutocomplete"
      [displayWith]="displayAddressFn"
      (optionSelected)="updateCCAuto()"
      id="recipient-fields-cc-mat-autocomplete"
    >
      <mat-option
        *ngFor="let address of filteredAddressesCC | async; let i = index"
        [value]="address"
        [id]="'recipient-fields-cc-' + i + '-mat-option'"
      >
        {{ address }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field
    class="dense-input form-field-5"
    [subscriptSizing]="'dynamic'"
    id="recipient-fields-bcc-mat-form-field"
  >
    <span
      matPrefix
      class="recipient-fields__label"
      >Bcc:</span
    >
    <mat-chip-grid #bccChipList>
      <mat-chip-row
        [color]="item.invalid ? 'warn' : ''"
        selected
        *ngFor="let item of bccEmailList; let i = index"
        [removable]="removable"
        (removed)="removeBCCEmail(item)"
        name="bccChips"
        [id]="'recipient-fields-bcc-' + i + '-mat-chip-row'"
      >
        {{ item.value }}
        <mat-icon
          matChipRemove
          *ngIf="removable"
          [id]="'recipient-fields-bcc-' + i + '-mat-icon-remove'"
          >cancel</mat-icon
        >
      </mat-chip-row>
      <input
        matInput
        [matChipInputFor]="bccChipList"
        [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="addBCCEmail($event)"
        [formControl]="bccControl"
        [matAutocomplete]="bccAuto"
        #bccInput
        id="recipient-fields-bcc-input"
      />
    </mat-chip-grid>
    <mat-autocomplete
      #bccAuto="matAutocomplete"
      [displayWith]="displayAddressFn"
      (optionSelected)="updateBCCAuto()"
      id="recipient-fields-bcc-mat-autocomplete"
    >
      <mat-option
        *ngFor="let address of filteredAddressesBCC | async; let i = index"
        [value]="address"
        [id]="'recipient-fields-bcc-' + i + '-mat-option'"
      >
        {{ address }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <div class="recipient-fields__divider"></div>
  <mat-form-field
    class="dense-input form-field-5"
    [subscriptSizing]="'dynamic'"
    id="recipient-fields-subject-mat-form-field"
  >
    <span
      matPrefix
      class="recipient-fields__label"
      >Subject:</span
    >
    <input
      matInput
      placeholder=""
      (change)="updateSubject($event)"
      [(ngModel)]="subject"
      [disabled]="isSdrBlocked || !isSendConfigured"
      id="recipient-fields-subject-input"
    />
  </mat-form-field>
  <div class="recipient-fields__divider"></div>
</div>

import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { UndoInteractionDialogData } from '../../../model/interaction/undo-interaction-dialog-data.model';
import { UndoInteractionDto } from '../../../model/interaction/undo-interaction.dto';
import { InteractionService } from '../../../services/interaction.service';
import { SnackBarService } from '../../../services/snackbar/snackbar.service';

@Component({
  selector: 'app-undo-interaction-dialog',
  templateUrl: './undo-interaction-dialog.component.html',
  styleUrls: ['./undo-interaction-dialog.component.scss'],
})
export class UndoInteractionDialogComponent {
  detailsCtrl = new FormControl('', [Validators.required]);
  isUndoing = false;
  private destroy$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public parentData: UndoInteractionDialogData,
    public dialog: MatDialogRef<UndoInteractionDialogComponent>,
    private interactionService: InteractionService,
    private snackBarService: SnackBarService,
  ) {}

  onUndoInteraction() {
    const { interactionSourceId, interactionId, customerId, fromMail, prospectStatusId} = this.parentData;
    const details = this.detailsCtrl.value?.trim();
    const undoInteractionDto: UndoInteractionDto = {
      interactionSourceId,
      interactionId,
      details,
      customerId,
      fromMail,
      prospectStatusId
    };

    this.isUndoing = true;
    this.interactionService
      .undoInteraction(undoInteractionDto)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => (this.isUndoing = false)),
      )
      .subscribe({
        next: () => {
          this.snackBarService.showSuccess('Interaction undone successfully.');
          this.interactionService.setInteractionsUpdated(true);
          this.dialog.close();
        },
        error: () => {
          this.snackBarService.showError('An error occurred while undoing the interaction.');
        },
      });
  }

  onClearDetails() {
    this.detailsCtrl.reset('');
  }
}

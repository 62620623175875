<div class="classifier-toolbar">
  <div class="classifier-toolbar__counts">
    <span
      class="classifier-toolbar__pending"
      id="mail-count"
      >Pending {{ pendingCount }}</span
    >
    <button
      class="classifier-toolbar__refresh-btn"
      mat-stroked-button
      (click)="onRefresh()"
    >
      Refresh
    </button>
  </div>
  <div class="classifier-toolbar__navigation">
    <button
      class="classifier-toolbar__navigation-btn"
      mat-stroked-button
      (click)="onMovePrevious()"
      [disabled]="isPrevDisabled"
    >
      <mat-icon>navigate_before</mat-icon>
    </button>
    <button
      class="classifier-toolbar__navigation-btn"
      mat-stroked-button
      (click)="onMoveNext()"
      [disabled]="isNextDisabled"
    >
      <mat-icon>navigate_next</mat-icon>
    </button>
  </div>
</div>

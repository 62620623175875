<div class="management-progress-bar">
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="loading"
  ></mat-progress-bar>
</div>
<div class="management">
  <div class="management__wrapper">
    <ol class="management__breadcrumb">
      <li
        class="management__breadcrumb-item"
        [class.management__breadcrumb-item--active]="breadcrumbItem.active"
        *ngFor="let breadcrumbItem of breadcrumbItems"
      >
        <a
          *ngIf="!breadcrumbItem.active"
          (click)="navigate(breadcrumbItem.route)"
          >{{ breadcrumbItem.label }}</a
        >
        <span *ngIf="breadcrumbItem.active">{{ breadcrumbItem.label }}</span>
      </li>
    </ol>
  </div>

  <div class="management__content-wrapper">
    <div class="management__menu">
      <mat-nav-list style="padding: 0">
        <a
          mat-list-item
          [routerLink]="['/home/management/operator-users']"
          class="management__menu-item"
          [class.management__menu-selected]="menuSelected === 'operator-users'"
          id="management-operator-users-anchor"
          >Operator Users</a
        >
        <a
          mat-list-item
          [routerLink]="['/home/management/users']"
          class="management__menu-item"
          [class.management__menu-selected]="menuSelected === 'users'"
          id="management-users-anchor"
          >Users</a
        >
        <a
          mat-list-item
          [routerLink]="['/home/management/client']"
          class="management__menu-item"
          [class.management__menu-selected]="menuSelected === 'client'"
          id="management-client-management-anchor"
          >Client Management</a
        >
        <a
          mat-list-item
          [routerLink]="['/home/management/addresses']"
          class="management__menu-item"
          [class.management__menu-selected]="menuSelected === 'addresses'"
          id="management-address-suggestions-anchor"
          >Address Suggestions</a
        >
        <a
          mat-list-item
          [routerLink]="['/home/management/templates/manage']"
          class="management__menu-item"
          [class.management__menu-selected]="menuSelected === 'templates'"
          id="management-templates-anchor"
          >Templates</a
        >
        <a
          mat-list-item
          [routerLink]="['/home/management/contacts/contact-manage']"
          class="management__menu-item"
          [class.management__menu-selected]="menuSelected === 'contacts'"
          id="management-sales-rep-anchor"
          >Sales Representatives</a
        >
        <a
          mat-list-item
          [routerLink]="['/home/management/botsetup']"
          class="management__menu-item"
          [class.management__menu-selected]="menuSelected === 'botsetup'"
          id="management-bot-setup-anchor"
          >Bot Setup</a
        >
        <a
          mat-list-item
          [routerLink]="['/home/management/faq/faq-manage']"
          class="management__menu-item"
          [class.management__menu-selected]="menuSelected === 'faq'"
          id="management-faq-anchor"
          >FAQ</a
        >
        <a
          mat-list-item
          [routerLink]="['/home/management/sdrs']"
          class="management__menu-item"
          [class.management__menu-selected]="menuSelected === 'sdrs'"
          id="management-sdrs-management-anchor"
          >SDR's Management</a
        >
      </mat-nav-list>
    </div>

    <div class="management__mobile-menu-wrapper">
      <button
        mat-icon-button
        [matMenuTriggerFor]="menu"
      >
        <mat-icon>menu</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <a
          mat-menu-item
          [routerLink]="['/home/management/operator-users']"
          class="management__menu-item"
          id="management-operator-users-anchor"
          >Operator Users</a
        >
        <a
          mat-menu-item
          [routerLink]="['/home/management/users']"
          class="management__menu-item"
          id="management-users-anchor"
          >Users</a
        >
        <a
          mat-menu-item
          [routerLink]="['/home/management/client']"
          class="management__menu-item"
          id="management-client-management-anchor"
          >Client Management</a
        >
        <a
          mat-menu-item
          [routerLink]="['/home/management/addresses']"
          class="management__menu-item"
          id="management-address-suggestions-anchor"
          >Address Suggestions</a
        >
        <a
          mat-menu-item
          [routerLink]="['/home/management/templates/manage']"
          class="management__menu-item"
          id="management-templates-anchor"
          >Templates</a
        >
        <a
          mat-menu-item
          [routerLink]="['/home/management/contacts/contact-manage']"
          class="management__menu-item"
          id="management-sales-rep-anchor"
          >Sales Representatives</a
        >
        <a
          mat-menu-item
          [routerLink]="['/home/management/botsetup']"
          class="management__menu-item"
          id="management-bot-setup-anchor"
          >Bot Setup</a
        >
        <a
          mat-menu-item
          [routerLink]="['/home/management/faq/faq-manage']"
          class="management__menu-item"
          id="management-faq-anchor"
          >FAQ</a
        >
        <a
          mat-menu-item
          [routerLink]="['/home/management/sdrs']"
          class="management__menu-item"
          id="management-sdrs-management-anchor"
          >SDR's Management</a
        >
      </mat-menu>
    </div>

    <div class="management__list-container">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { SdApiService } from './base/sd-api.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

export const TOKEN_KEY = 'idtoken';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private apiBackEnd!: string;

  constructor(
    private sdApiService: SdApiService,
    private http: HttpClient,
  ) {
    this.apiBackEnd = environment.apiBaseURL;
  }

  isUserAdmin() {
    return this.sdApiService.loadUser().pipe(map((u) => u.permissions.filter((p) => p.name == 'manager').length > 0));
  }

  isUserSender() {
    return this.sdApiService.loadUser().pipe(map((u) => u.permissions.filter((p) => p.name == 'sender').length > 0));
  }

  hasPermission(permission: string) {
    return this.sdApiService.loadUser().pipe(map((u: any) => u.permissions.some((p) => p.name === permission)));
  }
}

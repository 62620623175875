<div class="template-editor">
  <div class="template-editor__container">
    <div class="template-editor__header">
      <span class="template-editor__title">{{ pageTitle }}</span>
      <button
        mat-raised-button
        class="template-editor__cancel-btn"
        (click)="cancel()"
        id="email-template-editor-cancel-btn"
      >
        Cancel
      </button>
    </div>

    <div class="template-editor__type-client-fields-row">
      <mat-form-field
        class="form-field-5"
        subscriptSizing="dynamic"
        appearance="outline"
        id="email-template-editor-type-mat-form-field"
      >
        <mat-label>Type</mat-label>
        <input
          matInput
          [(ngModel)]="templateTypeLabel"
          disabled
          id="email-template-editor-type-input"
        />
      </mat-form-field>
      <mat-form-field
        class="form-field-5"
        subscriptSizing="dynamic"
        appearance="outline"
        id="email-template-editor-client-mat-form-field"
      >
        <mat-label>Client</mat-label>
        <input
          type="text"
          aria-label="Number"
          matInput
          required
          [formControl]="clientFilterControl"
          [matAutocomplete]="clientsAuto"
          [(ngModel)]="selectedClient"
          id="email-template-editor-client-input"
        />
        <mat-autocomplete
          #clientsAuto="matAutocomplete"
          [displayWith]="displayFn"
          (optionSelected)="updateClients()"
          id="email-template-editor-client-mat-autocomplete"
        >
          <mat-option
            *ngFor="let client of filteredClientOptions | async"
            [value]="client"
            id="email-template-client-mat-option"
          >
            {{ client }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <div
      class="template-editor__sdr-salesRep-row"
      [class.hide]="templateType === 'reply'"
    >
      <mat-form-field
        class="form-field-5 fx-100"
        appearance="outline"
        [ngClass]="{
          'field-disabled': !selectedClient,
          'mat-form-field-invalid ng-invalid': !sdrList.length && sdrControl.touched
        }"
        id="email-template-editor-SDRs-mat-form-field"
      >
        <mat-label>SDRs</mat-label>
        <mat-chip-grid #sdrChipList>
          <mat-chip-row
            *ngFor="let item of sdrList; let i = index"
            [color]="item.invalid ? 'warn' : ''"
            selected
            [removable]="removable"
            (removed)="removeSdr(item)"
          >
            {{ item.value }}
            <mat-icon
              matChipRemove
              *ngIf="removable"
              >cancel</mat-icon
            >
          </mat-chip-row>
          <input
            matInput
            [matChipInputFor]="sdrChipList"
            [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
            [matChipInputAddOnBlur]="true"
            (matChipInputTokenEnd)="addSdr($event)"
            [formControl]="sdrControl"
            [matAutocomplete]="sdrAuto"
            [disabled]="!selectedClient"
            #sdrInput
          />
        </mat-chip-grid>

        <mat-autocomplete
          #sdrAuto="matAutocomplete"
          [displayWith]="displayFn"
          (optionSelected)="updateSdrAuto()"
          id="email-template-editor-SDRs-mat-autocomplete"
        >
          <mat-option
            *ngFor="let sdr of filteredSdrs | async"
            [value]="sdr"
            id="email-template-editor-SDRs-mat-option"
          >
            {{ sdr }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field
        class="form-field-5 fx-100"
        appearance="outline"
        [ngClass]="{
          'field-disabled': !selectedClient,
          'mat-form-field-invalid ng-invalid': !salesRepList.length && salesRepControl.touched
        }"
        id="email-template-editor-sales-rep-mat-form-field"
      >
        <mat-label>Sales rep.</mat-label>
        <mat-chip-grid #salesRepChipList>
          <mat-chip-row
            [color]="item.invalid ? 'warn' : ''"
            selected
            *ngFor="let item of salesRepList; let i = index"
            [removable]="removable"
            (removed)="removeSalesRep(item)"
            name="salesRepChips"
            id="email-template-editor-sales-rep-mat-chip-row"
            >{{ item.value }}
            <mat-icon
              matChipRemove
              *ngIf="removable"
              id="email-template-editor-sales-rep-mat-icon-remove"
              >cancel</mat-icon
            >
          </mat-chip-row>
          <input
            matInput
            [matChipInputFor]="salesRepChipList"
            [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
            [matChipInputAddOnBlur]="true"
            (matChipInputTokenEnd)="addSalesRep($event)"
            [formControl]="salesRepControl"
            [matAutocomplete]="salesRepAuto"
            [disabled]="!selectedClient"
            #salesRepInput
            id="email-template-editor-sales-rep-input"
          />
        </mat-chip-grid>
        <mat-autocomplete
          #salesRepAuto="matAutocomplete"
          [displayWith]="displayFn"
          (optionSelected)="updateSalesRepAuto()"
          id="email-template-editor-sales-rep-mat-autocomplete"
        >
          <mat-option
            *ngFor="let salesRep of filteredSalesReps | async"
            [value]="salesRep"
            id="email-template-editor-sales-rep-mat-option"
          >
            {{ salesRep }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="template-editor__row-form">
      <mat-form-field
        class="form-field-5 fx-100"
        appearance="outline"
        subscriptSizing="dynamic"
        id="email-template-editor-title-mat-form-field"
      >
        <mat-label>Title</mat-label>
        <input
          matInput
          [(ngModel)]="title"
          required
          maxlength="50"
          id="email-template-editor-title-input"
        />
      </mat-form-field>
    </div>

    <div class="template-editor__cc-bcc-row hide">
      <div>
        <label class="title-input">CC</label>
        <mat-form-field
          class="dense-input"
          appearance="fill"
          id="email-template-editor-CC-mat-form-field"
        >
          <mat-chip-grid #ccChipList>
            <mat-chip-row
              [color]="item.invalid ? 'warn' : ''"
              selected
              *ngFor="let item of ccList; let i = index"
              [removable]="removable"
              (removed)="removeCC(item)"
              name="ccChips"
              id="email-template-editor-CC-mat-chip-row"
              >{{ item.value }}
              <mat-icon
                matChipRemove
                *ngIf="removable"
                id="email-template-editor-CC-mat-icon-remove"
                >cancel</mat-icon
              >
            </mat-chip-row>
            <input
              matInput
              [matChipInputFor]="ccChipList"
              [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
              [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="addCC($event)"
              [formControl]="ccControl"
              #ccInput
              id="email-template-editor-CC-input"
            />
          </mat-chip-grid>
        </mat-form-field>
      </div>
      <div class="template-editor__cc-bcc-row">
        <label class="title-input">BCC</label>
        <mat-form-field
          class="dense-input"
          appearance="fill"
          id="email-template-editor-BCC-mat-form-field"
        >
          <mat-chip-grid #bccChipList>
            <mat-chip-row
              [color]="item.invalid ? 'warn' : ''"
              selected
              *ngFor="let item of bccList; let i = index"
              [removable]="removable"
              (removed)="removeBCC(item)"
              name="bccChips"
              id="email-template-editor-BCC-mat-chip-row"
              >{{ item.value }}
              <mat-icon
                matChipRemove
                *ngIf="removable"
                id="email-template-editor-BCC-mat-icon-remove"
                >cancel</mat-icon
              >
            </mat-chip-row>
            <input
              matInput
              [matChipInputFor]="bccChipList"
              [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
              [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="addBCC($event)"
              [formControl]="bccControl"
              #bccInput
              id="email-template-editor-BCC-input"
            />
          </mat-chip-grid>
        </mat-form-field>
      </div>
    </div>

    <div class="template-editor__row-form">
      <div class="fx-100">
        <div>
          <div id="editorId"></div>
        </div>
      </div>
    </div>

    <div class="template-editor__row-form">
      <label class="template-editor__title-input fx-100">Attachments</label>

      <div class="template-editor__attachment-btn">
        <label for="email-template-editor-attachment-input">
          <div class="action-pointer template-editor__attachment-btn-name">BROWSE FILES</div>
        </label>
        <input
          type="file"
          class="template-editor__attachment-input"
          (change)="attach($event.target.files)"
          multiple
          #inputFileControl
          id="email-template-editor-attachment-input"
        />
      </div>
      <div class="template-editor__attachment-progress-container">
        <div
          *ngIf="attachments?.length > 0"
          class="template-editor__attachment"
        >
          <ng-container
            *ngFor="let attachment of attachments"
            class="template-editor__attachment-progress"
          >
            <div
              *ngIf="attachment.isUploading"
              class="template-editor__attachment-progress-text"
            >
              {{ messageAttachmentService.getFileName(attachment.filename) | truncate: 15 : false : '..'
              }}{{ messageAttachmentService.getExtension(attachment.filename) }}
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="fx-100">
        <!-- Attachments -->
        <div
          *ngIf="attachments?.length > 0"
          class="template-editor__attachment uploaded-files"
        >
          <div
            *ngFor="let attachment of attachments"
            class="template-editor__attachment-item"
          >
            <div
              *ngIf="!attachment.isUploading"
              class="uploaded-file"
            >
              <a
                class="uploaded-file-text"
                (click)="
                  messageAttachmentService.downloadRemoteFileOld(attachment.filename, attachment.key, attachment.isSafe)
                "
                [style.cursor]="attachment.isSafe ? 'pointer' : 'initial'"
                id="email-template-editor-download-remote-file-anchor"
              >
                <div
                  class="template-editor__attachment-box"
                  matTooltip="{{ attachment.filename }}"
                >
                  <span class="attachment-name">
                    {{ messageAttachmentService.getFileName(attachment.filename) | truncate: 15 : false : '..'
                    }}{{ messageAttachmentService.getExtension(attachment.filename) }}
                  </span>
                  <span class="attachment-size"> {{ messageAttachmentService.getKbSize(attachment.size) }} kb </span>
                </div>
              </a>
              <a
                class="action-pointer"
                (click)="
                  messageAttachmentService.deleteAttachment(
                    attachment.filename,
                    attachment.fileId,
                    attachment.isLocal,
                    attachments
                  )
                "
                id="email-template-editor-delete-attachment-file-anchor"
              >
                <mat-icon>close</mat-icon>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="template-editor__row-form">
      <mat-form-field
        class="form-field-5 fx-100"
        appearance="outline"
        subscriptSizing="dynamic"
        id="email-template-editor-notes-mat-form-field"
      >
        <mat-label>Notes</mat-label>
        <textarea
          matInput
          [(ngModel)]="notes"
          style="resize: none"
          maxlength="1000"
          id="email-template-editor-notes-text-area"
          class="fx-100"
        ></textarea>
      </mat-form-field>
    </div>

    <div class="template-editor__row-form">
      <mat-form-field
        class="form-field-5 fx-100"
        appearance="outline"
        id="email-template-editor-tags-mat-form-field"
      >
        <mat-label>Tags</mat-label>
        <mat-chip-grid #tagChipList>
          <mat-chip-row
            [color]="item.invalid ? 'warn' : ''"
            *ngFor="let item of tagList; let i = index"
            [removable]="removable"
            (removed)="removeTag(item)"
            name="tagChips"
            id="email-template-editr-tags-mat-chip-row"
            >{{ item.value }}
            <mat-icon
              matChipRemove
              *ngIf="removable"
              id="email-template-editor-tags-mat-icon-remove"
              >cancel</mat-icon
            >
          </mat-chip-row>
          <input
            matInput
            [matChipInputFor]="tagChipList"
            [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
            [matChipInputAddOnBlur]="true"
            (matChipInputTokenEnd)="addTag($event)"
            [formControl]="tagControl"
            #tagInput
            id="email-template-editor-tags-input"
          />
        </mat-chip-grid>
      </mat-form-field>
    </div>

    <div
      class="template-editor__row-form"
      [class.hide]="templateType === 'reply'"
    >
      <div class="fx-100">
        <mat-slide-toggle
          color="primary"
          [(ngModel)]="isInternalUse"
          [color]="'Primary'"
          id="email-template-editor-internal-use-mat-slide-toggle"
          >Internal Use Only</mat-slide-toggle
        >
      </div>
    </div>

    <div class="template-editor__row-form btnCreateTemplate-row template-editor__row-form--end">
      <button
        (click)="createTemplate()"
        class="btnCreateTemplate"
        mat-raised-button
        color="primary"
        [disabled]="invalidForm || isSubmitting"
        id="email-template-editor-create-template-btn"
      >
        {{ submitTemplateLabel }}
      </button>
    </div>
  </div>
</div>

import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SDAuthService } from 'src/app/services/sd-auth.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss', '../login.component.scss'],
    standalone: false
})
export class ResetPasswordComponent {
  sendCodeForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)]),
  });

  updatePasswordForm = new FormGroup({
    newPassword: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(15),
      Validators.pattern(
        '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#=+$@$!%*?&§\\[\\]\\{\\}"\'\\(\\)\\^_\\-\\~\\|\\`\\<\\>\\,\\.\\;\\¨])[A-Za-zd#=+$@$!%*?&§\\[\\]\\{\\}"\'\\(\\)\\^_\\-\\~\\|\\`\\<\\>\\,\\.\\;\\¨].{8,15}$',
      ),
    ]),
    code: new FormControl('', [Validators.required]),
  });

  loading = false;
  recoveryCodeRequested = false;
  accountNotFound = false;
  recoveryError = '';

  constructor(
    private router: Router,
    private authService: SDAuthService,
    private snackbar: MatSnackBar,
  ) {}

  onSendCode() {
    this.loading = true;
    const emailCtrl = this.sendCodeForm.get('email');
    if (emailCtrl.valid) {
      this.authService.sendRecoveryAccountCode(emailCtrl.value.trim()).subscribe(
        (response) => {
          if (response.success) {
            this.snackbar.open(
              'A verification code has been sent to your email address. Please check your mailbox.',
              null,
              { duration: 3000 },
            );
            this.recoveryCodeRequested = true;
          }
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.recoveryError = error.error.message;
        },
      );
    }
  }

  onUpdatePassword() {
    if (this.updatePasswordForm.valid && this.sendCodeForm.valid) {
      this.loading = true;
      this.authService
        .updatePassword({
          username: this.sendCodeForm.get('email').value.trim(),
          password: this.updatePasswordForm.get('newPassword').value.trim(),
          code: this.updatePasswordForm.get('code').value.trim(),
        })
        .subscribe(
          (response) => {
            if (response.success) {
              this.snackbar.open('Password successfully updated', null, { duration: 3000 });
              this.backToLogin();
            }
            this.loading = false;
          },
          (error) => {
            this.loading = false;
            this.recoveryError = error.error.message;
          },
        );
    }
  }

  backToLogin() {
    this.router.navigate(['/login']);
  }

  backToRecoveryCode() {
    this.updatePasswordForm.reset();
    this.recoveryCodeRequested = false;
  }

  clearRecoveryError() {
    this.recoveryError = '';
  }
}

<div class="faq-manager">
  <div class="faq-manager__container">
    <div class="faq-manager__header">
      <span class="faq-manager__title">Manage Frequently Asked Questions</span>
      <button
        (click)="createFaq()"
        class="faq-manager__btn-faq"
        mat-raised-button
        color="primary"
        id="faq-manager-create-faq-btn"
      >
        CREATE FAQ
      </button>
    </div>

    <div class="faq-manager__table-container">
      <div class="mat-elevation-z8 shrink">
        <mat-form-field
          class="faq-manager__search-form-field form-field-5"
          appearance="outline"
          subscriptSizing="dynamic"
          id="faq-manager-search-mat-form-field"
        >
          <mat-label>Search</mat-label>
          <input
            matInput
            [(ngModel)]="faqSearchKey"
            autocomplete="off"
            (keyup)="applyFaqFilter()"
            id="faq-manager-search-input"
          />
          <mat-icon
            matSuffix
            *ngIf="faqSearchKey"
            (click)="onFaqSearchClear()"
            id="faq-manager-search-mat-icon-clear"
            >close</mat-icon
          >
        </mat-form-field>
        <mat-table
          [dataSource]="faqListData"
          matSort
          matSortDisableClear
          #faqSort="matSort"
          class="faq-manager__table"
        >
          <ng-container matColumnDef="date">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="faq-manager__date-cell"
              >Date</mat-header-cell
            >
            <mat-cell
              *matCellDef="let element"
              class="faq-manager__date-cell"
              >{{ element.date | date: 'MM/dd/yyyy' }}</mat-cell
            >
          </ng-container>
          <ng-container matColumnDef="title">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="faq-manager__title-cell"
              >Title</mat-header-cell
            >
            <mat-cell
              *matCellDef="let element"
              class="faq-manager__title-cell"
              >{{ element.title }}</mat-cell
            >
          </ng-container>
          <ng-container matColumnDef="client">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="faq-manager__client-cell"
              >Client</mat-header-cell
            >
            <mat-cell
              *matCellDef="let element"
              class="faq-manager__client-cell"
              >{{ element.client }}</mat-cell
            >
          </ng-container>
          <ng-container matColumnDef="actions">
            <mat-header-cell
              *matHeaderCellDef
              class="faq-manager__actions-cell"
              >Actions</mat-header-cell
            >
            <mat-cell
              *matCellDef="let element"
              class="faq-manager__actions-cell"
            >
              <button
                mat-icon-button
                (click)="editFaq(element.id)"
                id="faq-manager-edit-faq-btn"
              >
                <mat-icon class="faq-manager__edit-icon">edit</mat-icon>
              </button>
              <button
                class="delete-btn"
                mat-icon-button
                (click)="removeFaq(element.id)"
                id="faq-manager-remove-faq-btn"
              >
                <mat-icon class="faq-manager__delete-icon">delete</mat-icon>
              </button>
            </mat-cell> </ng-container
          >mat-row
          <ng-container matColumnDef="loading">
            <mat-footer-cell
              *matFooterCellDef
              colspan="2"
            >
              Loading data...
            </mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="noData">
            <mat-footer-cell
              *matFooterCellDef
              colspan="2"
            >
              No data.
            </mat-footer-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="faqDisplayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: faqDisplayedColumns"></mat-row>
          <mat-footer-row
            *matFooterRowDef="['loading']"
            [ngClass]="{ hide: faqListData !== null }"
          ></mat-footer-row>
          <mat-footer-row
            *matFooterRowDef="['noData']"
            [ngClass]="{ hide: !(faqListData !== null && faqListData.data.length === 0) }"
          ></mat-footer-row>
        </mat-table>
        <mat-paginator
          class="paginator-table"
          [length]="resultsLength"
          [pageSize]="10"
          showFirstLastButtons
          #faqPaginator="matPaginator"
        ></mat-paginator>
      </div>
    </div>
  </div>
</div>

<div class="modal-header">
  <h2>Reassign SDRs in bulk</h2>
</div>
<div class="modal-content">
  <div class="reassign-container">
    <div class="reassign-content">
      <h4>Reassign {{ data.sdrs.length }} SDRs to another Operator</h4>

      <mat-form-field class="reassign-sdr__operator">
        <input
          placeholder="Select Operator"
          matInput
          [matAutocomplete]="operatorFilter"
          (keyup)="handleUserInput(operatorFilterInput.value)"
          #operatorFilterInput
        />
        <mat-autocomplete
          #operatorFilter="matAutocomplete"
          [displayWith]="formatFilterPlaceholderEmail"
        >
          <mat-option
            *ngFor="let option of avaliableOperators$ | async"
            [value]="option"
            (onSelectionChange)="handleOperatorSelectionChange($event.source?.value.id)"
          >
            {{ option.email }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>
</div>
<div class="modal-actions">
  <button
    mat-button
    class="dismiss-button"
    (click)="onDismiss()"
  >
    Cancel
  </button>
  <button
    class="confirm-button"
    mat-button
    (click)="onConfirm()"
    cdkFocusInitial
    [disabled]="!!(selectedOperatorId$ | async) === false"
  >
    Confirm
  </button>
</div>

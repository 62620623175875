import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-mail-classifier-log-folder',
    templateUrl: './mail-classifier-log-folder.component.html',
    styleUrls: ['./mail-classifier-log-folder.component.scss'],
    standalone: false
})
export class MailClassifierLogFolderComponent implements OnInit, OnChanges {
  @Input() folderType: string;
  constructor() {}

  ngOnInit(): void {
    this.formatName();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.folderType) {
      this.formatName();
    }
  }

  formatName() {
    if (!this.folderType) return;
    const splitName = this.folderType.split('/');
    if (splitName[1]) {
      const folderName = splitName[1].replace(/\d{1,2}_/g, '');
      // @ts-ignore
      this.folderType = folderName.replaceAll('_', ' ');
    } else {
      // @ts-ignore
      this.folderType = splitName[0].replaceAll('_', ' ');
    }
  }
}

import { Component, Input } from '@angular/core';
import { ClientContactHistory } from '../../../model/client-contact-history/client-contact-history.model';

@Component({
    selector: 'app-client-contact-history',
    templateUrl: './client-contact-history.component.html',
    styleUrls: ['./client-contact-history.component.scss'],
    standalone: false
})
export class ClientContactHistoryComponent {
  @Input() clientContactHistoryData: ClientContactHistory[] = [];
  displayedColumns: string[] = [
    'contactId',
    'interactionId',
    'createdDate',
    'sdr',
    'salesRep',
    'geographicZone',
    'language',
    'campaignId',
    'templateId',
  ];
}

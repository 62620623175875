import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Conversation, SdrConversation } from '../../../model/sdr-conversations/sdrs-conversations.model';
import { SdrConversationsService } from '../../../services/sdr-conversations.service';

@Component({
  selector: 'app-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ConversationComponent {
  @Input() sdrConversation: SdrConversation;
  @Input() conversations: Conversation[];
  @Input() selectedConversation: Conversation = null;

  constructor(private sdrConversationsService: SdrConversationsService) {}

  selectConversation(conversation: Conversation) {
    if (conversation.conversationId !== this.selectedConversation?.conversationId) {
      this.sdrConversationsService.setCurrentConversationMessages(null);
      this.sdrConversationsService.setSelectedConversation(conversation);
    }
  }
}

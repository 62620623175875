import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { UIGuid } from '../../../helpers/ui-guid';
import { Conversation, Message } from '../../../model/sdr-conversations/sdrs-conversations.model';
import { SendMessageDto } from '../../../model/sdr-conversations/send-message.dto';
import { SdrConversationsService } from '../../../services/sdr-conversations.service';
import { SnackBarService } from '../../../services/snackbar/snackbar.service';
import { RecipientFieldsComponent } from '../../home/mail-composer/recipient-fields/recipient-fields.component';
import { SelectSalesRepresentativeComponent } from '../../home/mail-composer/select-sales-representative/select-sales-representative.component';

@Component({
  selector: 'app-send-message',
  templateUrl: './send-message.component.html',
  styleUrls: ['./send-message.component.scss'],
})
export class SendMessageComponent implements OnDestroy {
  @ViewChild(RecipientFieldsComponent) recipientFields: RecipientFieldsComponent;
  @Input() selectedConversation: Conversation;
  message = new FormControl('');
  isSendingMessage = false;
  selectedSalesRep = null;
  messageSalesRepresentative: string = '';
  private destroy$ = new Subject<boolean>();

  constructor(
    private sdrsConversationsService: SdrConversationsService,
    private snackBarService: SnackBarService,
    public dialog: MatDialog,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  sendMessage(): void {
    const { sdrId, conversationId } = this.selectedConversation;

    if (!sdrId || !conversationId || !this.message?.value) {
      this.snackBarService.showError(
        'An error occurred while collecting the information required to send the message.',
      );
      return;
    }
    const sendMessageDto: SendMessageDto = {
      sdrId,
      conversationId,
      message: this.message.value,
    };

    this.isSendingMessage = true;
    this.message.disable();
    this.sdrsConversationsService
      .sendMessage(sendMessageDto)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.isSendingMessage = false;
          this.message.enable();
        }),
      )
      .subscribe({
        next: () => {
          this.snackBarService.showSuccess('Message sent successfully');
          this.addMessageToConversation(this.message.value);
          this.message.reset('');
        },
        error: () => this.snackBarService.showError('An error occurred while sending the message'),
      });
  }

  addMessageToConversation(message: string) {
    const newMessage: Message = {
      messageId: UIGuid.newGuid(),
      messageHandlerId: UIGuid.newGuid(),
      messagePlain: message,
      isInbound: true,
      sentDate: new Date().toISOString(),
      messageStatus: 'Inbox',
      selected: false,
      isTemp: true,
      salesRepLinkedInUrl: this.selectedSalesRep,
    };

    this.sdrsConversationsService.addMessageToConversation(newMessage);
  }

  selectSalesRep() {
    this.messageSalesRepresentative = '';
    const dialogRef = this.dialog.open(SelectSalesRepresentativeComponent, {
      panelClass: 'my-custom-dialog-class',
      minWidth: '500px',
      data: {
        sdrId: this.selectedConversation.sdrId,
        source: 'send-message',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined && result !== '') {
        this.messageSalesRepresentative = 'The LinkedIn was added: ' + result;
        this.selectedSalesRep = result;
        if (this.selectedSalesRep) {
          const ccList = this.selectedSalesRep.split(';');
          this.recipientFields.toEmailList = [];

          ccList.forEach((item: string) => {
            this.recipientFields.toEmailList.push({ value: item, invalid: false });
          });
        }
      }
    });
  }
}

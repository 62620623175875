import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, inject, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Contact } from '../../../model/common/contact.model';
import { GenericDataMapping, ValueType } from '../../../model/common/generic-map-data.model';
import { Interaction } from '../../../model/common/interaction.model';

@Component({
  selector: 'app-interaction-item',
  templateUrl: './interaction-item.component.html',
  styleUrls: ['./interaction-item.component.scss'],
})
export class InteractionItemComponent implements OnChanges, OnDestroy {
  @Input() interaction: Interaction;
  @Input() contact: Contact;
  contactRows: GenericDataMapping[] = [];
  interactionRows: GenericDataMapping[] = [];
  valueType = ValueType;
  isMobileOrTablet = false;
  private destroy$: Subject<boolean> = new Subject();

  constructor() {
    inject(BreakpointObserver)
      .observe(['(max-width: 959px)'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        for (const query of Object.keys(result.breakpoints)) {
          this.isMobileOrTablet = result.breakpoints[query];
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['contact']) {
      this.setContactRows();
    }

    if (changes['interaction']) {
      this.setInteractionRows();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  setContactRows() {
    this.contactRows = [
      this.isMobileOrTablet && { title: 'Forwarder ID', value: this.interaction.forwardedContactId },
      this.isMobileOrTablet && { title: 'Campaing ID', value: this.interaction.campaignId },
      this.isMobileOrTablet && { title: 'Template ID', value: this.interaction.templateId },
      { title: 'Contact ID', value: this.contact.contactId },
      { title: 'Name', value: this.contact.fullName },
      { title: 'Title', value: this.contact.jobTitle },
      { title: 'Country', value: this.contact.country },
      { title: 'State', value: this.contact.adminArea1 },
      { title: 'City', value: this.contact.adminArea2 },
      {
        title: 'In Lead',
        value: this.contact.linkedInUrl ? `https://linkedin.com${this.contact.linkedInUrl}` : '',
        type: this.contact.linkedInUrl ? ValueType.Url : this.valueType.Text,
      },
    ];
  }

  setInteractionRows() {
    this.interactionRows = [
      { title: 'Company', value: this.contact.companyName },
      { title: 'Interaction ID', value: this.interaction.interactionId },
      { title: 'Message ID', value: this.interaction.messageId },
      { title: 'SDR ID', value: this.interaction.sdrId },
      { title: 'SDR Email', value: this.interaction.sdrEmailAddress },
      { title: 'Type', value: this.interaction.interactionType },
      { title: 'Source', value: this.interaction.interactionSource },
    ];
  }
}

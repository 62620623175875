import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HistoryInteraction } from '../../../model/common/history-interaction.model';
import { Sdr } from '../../../model/Sdr';
import { InquiryService } from '../../../services/inquiry.service';

@Component({
  selector: 'app-inquiry',
  templateUrl: './inquiry.component.html',
  styleUrls: ['./inquiry.component.scss'],
})
export class InquiryComponent implements OnInit {
  sdr: Sdr;
  client: string;
  email: string;
  date: Date;
  title: string;
  context: string = 'feed';
  showHistory: boolean = true;
  selectedHistoryRow = new Set<HistoryInteraction>();
  constructor(
    @Inject(MAT_DIALOG_DATA) public parentData: any,
    private inquiryService: InquiryService,
  ) {}

  ngOnInit() {
    this.showHistory = true;
    this.sdr = this.parentData.sdr;
    this.client = this.parentData.client;
    this.email = this.parentData.email;
    this.title = this.parentData.title || 'Open Inquiry';
    this.context = this.parentData.context || 'feed';
    if (this.parentData.selectedFolder === 'Sales Operations') this.showHistory = false;
    const today = new Date();
    this.inquiryService.setInquiryLeads([]);

    if (this.parentData.sdr) {
      this.inquiryService.searchInquiryLead(this.parentData.sdr.email, '', this.parentData.email, '', '');
    }
  }

  get isFeed(): boolean {
    return this.context === 'feed';
  }

  getFormattedDefaultDate(date: Date) {
    return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
  }

  onSelectHistoryRow(row) {
    this.selectedHistoryRow = row;
  }
}

<div
  class="email-feed-item-wrapper"
  [ngClass]="{
    selected: isSelected && !email.hasError,
    error: email.hasError && !isSelected,
    selectedError: isSelected && email.hasError,
    'selected--multipleSelected': multipleSelectedEmails,
  }"
>
  <div class="mail-notification">
    <mat-checkbox
      style="margin-right: 16px"
      color="primary"
      [checked]="isSelected"
      [disabled]="email.storageId === selectedMailId"
      (change)="toggleSelect()"
    >
    </mat-checkbox>

    <div
      class="mail-notification-content"
      (click)="select()"
    >
      <div class="mail-subject">
        <div
          *ngIf="isLoading"
          class="spinner-container"
        >
          <mat-spinner
            strokeWidth="5"
            diameter="20"
          ></mat-spinner>
        </div>
        <span class="email-subject">{{ email.subject }}</span>
        <div
          [ngSwitch]="email.sendStatus"
          *ngIf="selectedFolder === 'Sent'"
          class="mail-status"
        >
          <mat-icon
            *ngSwitchCase="SendStatusType.InProgress"
            class="material-icons-outlined"
            >pending</mat-icon
          >
          <mat-icon
            *ngSwitchCase="SendStatusType.Error"
            class="material-icons-outlined sent-status-error"
            >error</mat-icon
          >
        </div>
      </div>
      <div class="sdrs-info">
        <mat-chip-listbox
          class="sdrs-list"
          #chipList
        >
          <mat-chip-option
            [selectable]="false"
            [removable]="false"
            [disableRipple]="true"
            class="sdrs-list__chip-item"
          >
            <mat-icon>person</mat-icon>
            {{ email.sdr }}
          </mat-chip-option>
        </mat-chip-listbox>
        <mat-icon *ngIf="email.hasAttachments">attachment</mat-icon>
      </div>
      <div>
        <div class="sender-recipient-details">
          <span class="from-label">From </span> <span class="from-value">{{ email.from }} </span>
          <span class="to-label">to </span>
          <span class="to-value">{{ email.to.split(',', 3) }}{{ email.to.split(',').length > 3 ? '...' : '' }}</span>
        </div>
        <div class="date-wrapper">
          <p>Local: {{ transform() | date: 'MM/dd/yyyy, hh:mm:ss a' }}</p>
          <p class="secondary-text">ART: {{ getArgentinianDate() | date: 'E, dd LLL yyyy hh:mm aaa' }}</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="contact-list">
  <div class="contact-list__container">
    <div class="contact-list__header">
      <span class="contact-list__title">Manage Contacts</span>
      <button
        (click)="createContact()"
        class="contact-list__create-btn"
        mat-raised-button
        color="primary"
        id="client-contacts-create-contact-btn"
      >
        CREATE CONTACT
      </button>
    </div>

    <div class="contact-list__table-container">
      <div class="contact-list__search-container">
        <mat-form-field
          class="contact-list__search-form-field form-field-5"
          appearance="outline"
          id="client-contacts-search-contact-mat-form-field"
        >
          <mat-label>Search</mat-label>
          <input
            matInput
            [(ngModel)]="nameFilter"
            autocomplete="off"
            (keyup)="applyContactFilter()"
            id="client-contacts-search-contact-input"
          />
          <mat-icon
            matSuffix
            *ngIf="nameFilter"
            (click)="onContactSearchClear()"
            id="client-contacts-search-contact-mat-icon-clear"
            >close</mat-icon
          >
        </mat-form-field>
        <button
          mat-stroked-button
          class="contact-list__search-filters-btn"
          [matMenuTriggerFor]="menu"
          id="client-contacts-seach-filters-btn"
        >
          <mat-icon>filter_list</mat-icon>
          Filter
        </button>
        <mat-menu
          #menu="matMenu"
          class="contacts-search-panel"
        >
          <div (click)="$event.stopPropagation()">
            <div>Client</div>
            <mat-form-field
              appearance="fill"
              class="clients-dropdown"
              id="client-contacts-clients-mat-form-field"
            >
              <input
                type="text"
                aria-label="Number"
                matInput
                [formControl]="clientFilterCtrl"
                [matAutocomplete]="clientsAuto"
                id="client-contacts-clients-input"
              />
              <mat-autocomplete
                #clientsAuto="matAutocomplete"
                [displayWith]="displayFn"
                id="client-contacts-clients-mat-autocomplete"
              >
                <mat-option
                  *ngFor="let client of filteredClientOptions | async"
                  [value]="client"
                  id="client-contacts-clients-mat-option"
                >
                  {{ client }}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="clientFilterCtrl.hasError('invalidClientId')">Client not found</mat-error>
            </mat-form-field>
            <div>Email</div>
            <mat-form-field
              class="email-input"
              appearance="fill"
              id="client-contacts-email-mat-form-field"
            >
              <input
                matInput
                [(ngModel)]="emailFilter"
                maxlength="100"
                id="client-contacts-email-input"
              />
            </mat-form-field>
            <div>Status</div>
            <div>
              <mat-form-field
                class="status-picker"
                appearance="outline"
                id="client-contacts-status-mat-form-field"
              >
                <mat-select
                  [(value)]="statusFilter"
                  id="client-contacts-email-mat-select"
                >
                  <mat-option
                    *ngFor="let status of statusOptions"
                    [value]="status"
                    id="client-contacts-email-mat-option"
                  >
                    {{ status }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="filters-btn-wrapper">
              <button
                (click)="clearSearch()"
                mat-raised-button
                id="client-contacts-clear-filters-btn"
              >
                Clear Filters
              </button>
              <button
                (click)="search()"
                class="btnSearch"
                mat-raised-button
                color="primary"
                [disabled]="!canExecuteSearch"
                id="client-contacts-search-btn"
              >
                Search
              </button>
            </div>
          </div>
        </mat-menu>
      </div>
      <div class="mat-elevation-z0 shrink contact-list__table">
        <mat-table
          [dataSource]="contactListData"
          matSort
          matSortDisableClear
          #contactSort="matSort"
        >
          <ng-container matColumnDef="name">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="contact-list__name-cell"
              >Name</mat-header-cell
            >
            <mat-cell
              *matCellDef="let element"
              class="contact-list__name-cell"
              >{{ element.name }}</mat-cell
            >
          </ng-container>
          <ng-container matColumnDef="status">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="contact-list__status-cell"
              >Status</mat-header-cell
            >
            <mat-cell
              class="contact-list__status-cell"
              *matCellDef="let element"
              [ngClass]="{
                'status-active': element.status === 'Active',
                'status-inactive': element.status !== 'Active'
              }"
            >
              {{ element.status }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="clientName">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="contact-list__client-name-cell"
              >Client</mat-header-cell
            >
            <mat-cell
              *matCellDef="let element"
              class="contact-list__client-name-cell"
              >{{ element.clientName }}</mat-cell
            >
          </ng-container>
          <ng-container matColumnDef="email">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="contact-list__email-cell"
              >Email</mat-header-cell
            >
            <mat-cell
              *matCellDef="let element"
              class="contact-list__email-cell"
              >{{ element.email }}</mat-cell
            >
          </ng-container>
          <ng-container matColumnDef="actions">
            <mat-header-cell
              *matHeaderCellDef
              class="contact-list__actions-cell"
              >Actions</mat-header-cell
            >
            <mat-cell
              *matCellDef="let element"
              class="contact-list__actions-cell"
            >
              <button
                mat-icon-button
                (click)="editContact(element.id)"
                id="client-contacts-edit-contact-btn"
              >
                <mat-icon class="contact-list__edit-icon">edit</mat-icon>
              </button>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="loading">
            <mat-footer-cell
              *matFooterCellDef
              colspan="2"
            >
              Loading data...
            </mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="noData">
            <mat-footer-cell
              *matFooterCellDef
              colspan="2"
            >
              No data.
            </mat-footer-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="contactDisplayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: contactDisplayedColumns"></mat-row>
          <mat-footer-row
            *matFooterRowDef="['loading']"
            [ngClass]="{ hide: contactListData !== null }"
          ></mat-footer-row>
          <mat-footer-row
            *matFooterRowDef="['noData']"
            [ngClass]="{ hide: !(contactListData !== null && contactListData.data.length === 0) }"
          ></mat-footer-row>
        </mat-table>
        <mat-paginator
          class="paginator-table"
          [length]="resultsLength"
          [pageSize]="10"
          showFirstLastButtons
          #contactPaginator="matPaginator"
        ></mat-paginator>
      </div>
    </div>
  </div>
</div>

<div class="mail-details-toolbar">
  <div
    class="mail-details-toolbar__content"
    *ngIf="currentEmail"
  >
    <div class="mail-details-toolbar__header">
      <div class="mail-details-toolbar__title">
        <span
          [matTooltip]="currentEmail.subject"
          matTooltipPosition="below"
          >{{ currentEmail.subject }}</span
        >
        <mat-icon
          *ngIf="currentEmail.sendStatus === 'Error' && selectedFolder === 'Sent'"
          class="material-icons-outlined email-title-icon"
          [matTooltip]="currentEmail.errorMessage"
          matTooltipPosition="right"
        >
          announcement
        </mat-icon>
      </div>

      <div class="mail-details-toolbar__actions">
        <button
          *ngIf="selectedFolder !== 'Sent'"
          mat-stroked-button
          color="primary"
          (click)="openPlainText()"
          matTooltip="Open Plain Text"
          id="open-plain-btn"
          class="mail-details-toolbar__action-button"
        >
          <mat-icon class="material-icons-outlined">description</mat-icon>
        </button>
        <button
          *ngIf="selectedFolder !== 'Sales Operations'"
          mat-stroked-button
          color="primary"
          (click)="openFaq()"
          matTooltip="Open Faq"
          id="open-faq-btn"
          class="mail-details-toolbar__action-button"
        >
          <mat-icon class="material-icons-outlined">help_outline</mat-icon>
        </button>
        <button
          *ngIf="hasInquiryPermission"
          mat-stroked-button
          color="primary"
          (click)="openInquiry()"
          matTooltip="Open Inquiry"
          id="open-inquiry-btn"
          class="mail-details-toolbar__action-button"
        >
          <mat-icon class="material-icons-outlined">person_search</mat-icon>
        </button>
        <button
          *ngIf="currentEmail"
          mat-stroked-button
          color="primary"
          (click)="copyDebug()"
          matTooltip="Clipboard Debug"
          id="copy-debug-btn"
          class="mail-details-toolbar__action-button"
        >
          <mat-icon class="material-icons-outlined">bug_report</mat-icon>
        </button>

        <button
          *ngIf="currentEmail && isSender"
          mat-stroked-button
          color="primary"
          (click)="compose('replyAttachment')"
          matTooltip="Reply All"
          [disabled]="isComposerOpen"
          id="reply-all-btn"
          class="mail-details-toolbar__action-button"
        >
          <mat-icon>reply_all</mat-icon>
        </button>
        <button
          *ngIf="currentEmail && isSender"
          mat-stroked-button
          color="primary"
          (click)="compose('forward')"
          matTooltip="Forward"
          [disabled]="isComposerOpen"
          id="forward-btn"
          class="mail-details-toolbar__action-button"
        >
          <mat-icon>arrow_forward</mat-icon>
        </button>
        <button
          *ngIf="currentEmail && isSender && selectedFolder === 'Sent'"
          mat-stroked-button
          color="primary"
          (click)="compose('resend')"
          matTooltip="Resend"
          [disabled]="isComposerOpen"
          id="resend-btn"
          class="mail-details-toolbar__action-button"
        >
          <mat-icon>repeat</mat-icon>
        </button>
        <button
          *ngIf="currentEmail && mailTo.length > 0"
          mat-stroked-button
          color="primary"
          (click)="openMailTo()"
          matTooltip="Inspect Mailto"
          id="inspect-mail-to-btn"
          class="mail-details-toolbar__action-button"
        >
          <mat-icon class="material-icons-outlined">remove_red_eye</mat-icon>
        </button>
        <button
          mat-stroked-button
          *ngIf="isTrashEnabled"
          color="primary"
          (click)="selectedMailService.triggerClassifyMail(trashOption)"
          matTooltip="Move to Trash"
          id="move-to-trash-btn"
          class="mail-details-toolbar__action-button"
        >
          <mat-icon class="material-icons-outlined">delete</mat-icon>
        </button>
      </div>

      <div class="mail-details-toolbar__menu">
        <button
          mat-icon-button
          [matMenuTriggerFor]="menu"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button
            *ngIf="selectedFolder !== 'Sales Operations'"
            mat-menu-item
            (click)="openFaq()"
            class="mail-details-toolbar__menu-item"
            id="open-faq-btn"
          >
            <mat-icon class="material-icons-outlined">help_outline</mat-icon> Open Faq
          </button>
          <button
            *ngIf="hasInquiryPermission"
            mat-menu-item
            (click)="openInquiry()"
            class="mail-details-toolbar__menu-item"
            id="open-inquiry-btn"
          >
            <mat-icon class="material-icons-outlined">person_search</mat-icon> Open Inquiry
          </button>
          <button
            *ngIf="currentEmail"
            mat-menu-item
            (click)="copyDebug()"
            class="mail-details-toolbar__menu-item"
            id="copy-debug-btn"
          >
            <mat-icon class="material-icons-outlined">bug_report</mat-icon>
            Clipboard Debug
          </button>
          <button
            *ngIf="currentEmail && isSender"
            mat-menu-item
            (click)="compose('replyAttachment')"
            [disabled]="isComposerOpen"
            class="mail-details-toolbar__menu-item"
            id="reply-all-btn"
          >
            <mat-icon>reply_all</mat-icon>
            Reply All
          </button>
          <button
            *ngIf="currentEmail && isSender"
            mat-menu-item
            (click)="compose('forward')"
            [disabled]="isComposerOpen"
            class="mail-details-toolbar__menu-item"
            id="forward-btn"
          >
            <mat-icon>arrow_forward</mat-icon>
            Forward
          </button>
          <button
            *ngIf="currentEmail && isSender && selectedFolder === 'Sent'"
            mat-menu-item
            (click)="compose('resend')"
            [disabled]="isComposerOpen"
            class="mail-details-toolbar__menu-item"
            id="resend-btn"
          >
            <mat-icon>repeat</mat-icon>
            Resend
          </button>
          <button
            *ngIf="currentEmail && mailTo.length > 0"
            mat-menu-item
            (click)="openMailTo()"
            class="mail-details-toolbar__menu-item"
            id="inspect-mail-to-btn"
          >
            <mat-icon class="material-icons-outlined">remove_red_eye</mat-icon>
            Inspect Mailto
          </button>
          <button
            *ngIf="currentEmail"
            mat-menu-item
            (click)="selectedMailService.triggerClassifyMail(trashOption)"
            class="mail-details-toolbar__menu-item"
            id="move-to-trash-btn"
          >
            <mat-icon class="material-icons-outlined">delete</mat-icon>
            Delete
          </button>
        </mat-menu>
      </div>
    </div>

    <div>
      <app-mail-sender-recipients-details [email]="currentEmail"></app-mail-sender-recipients-details>
    </div>

    <div
      *ngIf="currentEmail.hasAttachments"
      class="mail-details-toolbar__attachments-container"
      id="readAttachmentsDiv"
    >
      <div
        *ngFor="let attachment of currentEmail.attachments"
        class="mail-details-toolbar__attachment"
      >
        <app-attachment
          [attachment]="attachment"
          (clickEvt)="
            currentEmail.folder === 'Sent'
              ? messageAttachmentService.downloadRemoteFileSent(attachment.key, attachment.filename, attachment.isSafe)
              : messageAttachmentService.downloadRemoteFile(attachment.filename, attachment.isSafe)
          "
        ></app-attachment>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-content>
  <mat-form-field>
    <mat-label>Choose a date</mat-label>
    <input
      matInput
      [matDatepicker]="picker"
      [formControl]="dateCtrl"
      [matDatepickerFilter]="dateFilter"
      subscriptSizing="dynamic"
    />
    <mat-hint>MM/DD/YYYY</mat-hint>
    <mat-datepicker-toggle
      matIconSuffix
      [for]="picker"
    ></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button
    mat-button
    (click)="onDismiss()"
    id="confirm-dialog-cancel-btn"
  >
    CANCEL
  </button>
  <button
    mat-button
    (click)="onConfirm()"
    cdkFocusInitial
    [disabled]="dateCtrl.invalid"
    color="primary"
    id="confirm-dialog-confirm-btn"
  >
    CONFIRM
  </button>
</div>

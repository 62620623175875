/* eslint-disable max-len */
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HomeComponent } from './screens/home/home.component';
import { LoginComponent } from './screens/login/login.component';
import { MailManagerComponent } from './screens/home/mail-manager/mail-manager.component';

import { OverlayModule } from '@angular/cdk/overlay';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { TopNavigationComponent } from './components/top-navigation/top-navigation.component';
import { MailsFeedComponent } from './screens/home/mail-manager/mails-feed/mails-feed.component';
import { MailDetailsComponent } from './screens/home/mail-manager/mail-details/mail-details.component';
import { MailFeedItemComponent } from './screens/home/mail-manager/mails-feed/mail-feed-item/mail-feed-item.component';
import { MailDetailsToolBarComponent } from './screens/home/mail-manager/mail-details/mail-details-tool-bar/mail-details-tool-bar.component';
import { MailDetailsContainerComponent } from './screens/home/mail-manager/mail-details/mail-details-container/mail-details-container.component';
import { MailDetailsBodyComponent } from './screens/home/mail-manager/mail-details/mail-details-container/mail-details-body/mail-details-body.component';
import { MailDetailsCategorizerComponent } from './screens/home/mail-manager/mail-details/mail-details-container/mail-details-categorizer/mail-details-categorizer.component';
import { ColorSelectorComponent } from './components/color-selector/color-selector.component';
import { MailComposerComponent } from './screens/home/mail-composer/mail-composer.component';
import { ComposerWindowBarComponent } from './screens/home/mail-composer/composer-window-bar/composer-window-bar.component';
import { RecipientFieldsComponent } from './screens/home/mail-composer/recipient-fields/recipient-fields.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { QuillModule } from 'ngx-quill';
import { MailComposerMinComponent } from './screens/home/mail-composer-min/mail-composer-min.component';
import { ComposerMinWindowBarComponent } from './screens/home/mail-composer-min/composer-min-window-bar/composer-min-window-bar.component';
import { AuthInterceptorService } from './interceptor/auth-interceptor.service';
import { MailReferredDialogComponent } from './screens/home/mail-manager/mail-details/mail-details-tool-bar/mail-referred-dialog/mail-referred-dialog.component';

import { UserListComponent } from './screens/management/user-management/user-list/user-list.component';
import { AddressSuggestionManagementComponent } from './screens/management/address-management/address-suggestion-management/address-suggestion-management.component';
import { UserDetailsComponent } from './screens/management/user-management/user-details/user-details.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { UserCreateComponent } from './screens/management/user-management/user-create/user-create.component';
import { NotificationListComponent } from './screens/home/notifications/notification-list.component';
import { NotificationItemComponent } from './screens/home/notifications/notification-item/notification-item.component';
import { NgxSummernoteModule } from 'ngx-summernote';
import { InquiryComponent } from './screens/home/inquiry/inquiry.component';
import { InquirySearchComponent } from './screens/home/inquiry/inquiry-search/inquiry-search.component';
import { InquirySelectionComponent } from './screens/home/inquiry/inquiry-selection/inquiry-selection.component';
import { InquiryDetailComponent } from './screens/home/inquiry/inquiry-detail/inquiry-detail.component';
import { InquiryHistoryComponent } from './screens/home/inquiry/inquiry-history/inquiry-history.component';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';
import { BlockedSdrsDialogComponent } from './screens/home/mail-manager/mails-feed/blocked-sdrs-dialog/blocked-sdrs-dialog.component';
import { SdrManagementComponent } from './screens/management/sdr-management/sdr-management.component';
import { MultiselectAutocompleteComponent } from './components/multiselect-autocomplete/multiselect-autocomplete.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { EmailTemplateManagementComponent } from './screens/management/email-templates/email-template-management/email-template-management.component';
import { EmailTemplateEditorComponent } from './screens/management/email-templates/email-template-editor/email-template-editor.component';
import { SelectTemplateComponent } from './screens/home/mail-composer/select-template/select-template.component';

import { MailSenderRecipientsDetailsComponent } from './screens/home/mail-manager/mail-details/mail-details-tool-bar/mail-sender-recipients-details/mail-sender-recipients-details.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MailFeedSkeletonLoaderComponent } from './screens/home/mail-manager/mails-feed/mail-feed-skeleton-loader/mail-feed-skeleton-loader.component';
import { ConvertedClientsListComponent } from './screens/converted-clients-list/converted-clients-list.component';
import { AttachmentComponent } from './components/attachment/attachment.component';
import { ManagementComponent } from './screens/management/management.component';
import { CommonModule } from '@angular/common';
import { DiscrepanciesComponent } from './screens/discrepancies/discrepancies.component';
import { DiscrepancyDetailsBodyComponent } from './screens/discrepancies/discrepancy-details-body/discrepancy-details-body.component';
import { DiscrepancyDetailsToolBarComponent } from './screens/discrepancies/discrepancy-details-tool-bar/discrepancy-details-tool-bar.component';
import { FaqManagerListComponent } from './screens/management/faq-manager/faq-manager-list/faq-manager-list.component';
import { FaqManagerEditorComponent } from './screens/management/faq-manager/faq-manager-editor/faq-manager-editor.component';
import { FaqOperatorComponent } from './screens/home/faq-operator/faq-operator.component';
import { ClassifierToolbarComponent } from './screens/discrepancies/classifier-toolbar/classifier-toolbar.component';
import { ClassifierComponent } from './screens/discrepancies/classifier/classifier.component';
import { ClassifierLogComponent } from './screens/discrepancies/classifier-log/classifier-log.component';
import { ClassifierLogFolderComponent } from './screens/discrepancies/classifier-log-folder/classifier-log-folder.component';
import { SearchBarComponent } from './screens/discrepancies/search-bar/search-bar.component';
import { SearchLeadsComponent } from './screens/discrepancies/search-leads/search-leads.component';
import { ClientContactsListComponent } from './screens/management/client-contacts/client-contacts-list/client-contacts-list.component';
import { BotSetupComponent } from './screens/management/bot-setup/bot-setup.component';
import { DiscrepanciesSdrsListComponent } from './screens/management/user-management/user-details/discrepancies-sdrs-list/discrepancies-sdrs-list.component';
import { ResetPasswordComponent } from './screens/login/reset-password/reset-password.component';
import { ClientContactsEditComponent } from './screens/management/client-contacts/client-contacts-edit/client-contacts-edit.component';
import { SelectSalesRepresentativeComponent } from './screens/home/mail-composer/select-sales-representative/select-sales-representative.component';
import { SearchResultItemComponent } from './screens/discrepancies/search-result-item/search-result-item.component';
import { LeadToBulkDialogComponent } from './screens/discrepancies/lead-to-bulk-dialog/lead-to-bulk-dialog.component';
import { LtbEmailClassifierComponent } from './screens/discrepancies/ltb-email-classifier/ltb-email-classifier.component';
import { LeadSummaryComponent } from './screens/discrepancies/lead-summary/lead-summary.component';
import { NewLeadDialogComponent } from './screens/discrepancies/new-lead-dialog/new-lead-dialog.component';
import { NewLeadFormComponent } from './screens/discrepancies/new-lead-form/new-lead-form.component';
import { FormRowComponent } from './screens/discrepancies/new-lead-form/form-row/form-row.component';
import { NewLeadPreviewDialogComponent } from './screens/discrepancies/new-lead-preview-dialog/new-lead-preview-dialog.component';
import { FoldersSdrsListComponent } from './screens/management/user-management/user-details/folders-sdrs-list/folders-sdrs-list.component';
import { ContactDateDialogComponent } from './screens/discrepancies/ltb-email-classifier/contact-date-dialog/contact-date-dialog.component';
import { ClientManagementContainerComponent } from './screens/management/client-management/client-management-container.component';
import { FlagViewerComponent } from './components/flag-viewer/flag-viewer.component';
import { AssignOperatorComponent } from './screens/management/client-management/assign-operator/assign-operator.component';
import { TableActionsComponent } from './components/table-actions/table-actions.component';
import { SetOperatorLevelComponent } from './screens/management/client-management/set-operator-level/set-level.component';
import { UnsetOperatorComponent } from './screens/management/client-management/unset-operator/unset-operator.component';
import { ReassignOperatorContainerComponent } from './screens/management/reassign-operator/reassign-operator-container.component';
import { OperatorCardComponent } from './screens/management/reassign-operator/operator-card/operator-card.component';
import { OperatorUserManagementContainerComponent } from './screens/management/operator-user-management/operator-user-container.component';
import { OperatorUserFormComponent } from './screens/management/operator-user-management/operator-user-form/operator-user-form.component';
import { OperatorUserAddComponent } from './screens/management/operator-user-management/operator-user-add/operator-user-add.component';
import { OperatorUserEditComponent } from './screens/management/operator-user-management/operator-user-edit/operator-user-edit.component';
import { OperatorUserActiveToggleComponent } from './screens/management/operator-user-management/operator-user-active-toggle/operator-user-active-toggle.component';
import { AdvancedSearchDialogComponent } from './screens/discrepancies/advanced-search-dialog/advanced-search-dialog.component';
import { LeadInteractionItemComponent } from './screens/discrepancies/lead-interaction-item/lead-interaction-item.component';
import { SdrsManagementContainerComponent } from './screens/management/sdrs-management/sdrs-management-container.component';
import { ViewLeadToolbarComponent } from './screens/discrepancies/view-lead-toolbar/view-lead-toolbar.component';
import { ReassignSdrComponent } from './screens/management/sdrs-management/reassign-sdr/reassign-sdr.component';
import { MaterialModule } from './material/material.module';
import { MailReviewComponent } from './screens/mail-review/mail-review.component';
import { MailClassificationComponent } from './screens/mail-review/mail-classification/mail-classification.component';
import { ConversationComponent } from './screens/sdr-conversations/conversation/conversation.component';
import { SdrConversationsComponent } from './screens/sdr-conversations/sdr-conversations.component';
import { MessageComponent } from './screens/sdr-conversations/message/message.component';
import { ExtraInfoComponent } from './screens/sdr-conversations/extra-info/extra-info.component';
import { NewlineToBrPipe } from './pipes/newline-to-br.pipe';
import { InteractionItemComponent } from './screens/sdr-conversations/interaction-item/interaction-item.component';
import { InteractionsComponent } from './screens/sdr-conversations/interactions/interactions.component';
import { ClassificationComponent } from './screens/sdr-conversations/classification/classification.component';
import { SendMessageComponent } from './screens/sdr-conversations/send-message/send-message.component';
import { ConvertedDialogComponent } from './screens/shared/converted-dialog/converted-dialog.component';
import { MailClassifierComponent } from './screens/mail-review/mail-classifier/mail-classifier.component';
import { MailClassifierToolbarComponent } from './screens/mail-review/mail-classifier/mail-classifier-toolbar/mail-classifier-toolbar.component';
import { MailClassifierLogComponent } from './screens/mail-review/mail-classifier/mail-classifier-log/mail-classifier-log.component';
import { MailClassifierDetailsToolbarComponent } from './screens/mail-review/mail-classifier/mail-classifier-details-body/mail-classifier-details-toolbar/mail-classifier-details-toolbar.component';
import { MailClassifierDetailsBodyComponent } from './screens/mail-review/mail-classifier/mail-classifier-details-body/mail-classifier-details-body.component';
import { MailClassifierSearchLeadsComponent } from './screens/mail-review/mail-classifier-search-leads/mail-classifier-search-leads.component';
import { MailClassifierSearchInteractionsComponent } from './screens/mail-review/mail-classifier-search-leads/mail-classifier-search-interactions/mail-classifier-search-interactions.component';
import { MailClassifierAdvanceSearchDialogComponent } from './screens/mail-review/mail-classifier-advance-search-dialog/mail-classifier-advance-search-dialog.component';
import { MailClassifierLeadInteractionItemComponent } from './screens/mail-review/mail-classifier-search-leads/mail-classifier-lead-interaction-item/mail-classifier-lead-interaction-item.component';
import { MailClassifierLogFolderComponent } from './screens/mail-review/mail-classifier/mail-classifier-log/mail-classifier-log-folder/mail-classifier-log-folder.component';
import { ContactDialogComponent } from './screens/shared/contact-dialog/contact-dialog.component';
import { ContactDetailComponent } from './screens/shared/contact-dialog/contact-detail/contact-detail.component';
import { ContactSearchComponent } from './screens/shared/contact-dialog/contact-search/contact-search.component';
import { ContactFormComponent } from './screens/shared/contact-dialog/contact-form/contact-form.component';
import { ContactToolbarComponent } from './screens/shared/contact-dialog/contact-toolbar/contact-toolbar.component';
import { UndoInteractionDialogComponent } from './screens/shared/undo-interaction-dialog/undo-interaction-dialog.component';
import { ForwardedInteractionDialogComponent } from './screens/shared/forwarded-interaction-dialog/forwarded-interaction-dialog.component';
import { CompleteCaseDialogComponent } from './screens/mail-review/complete-case-dialog/complete-case-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ManagementComponent,
    LoginComponent,
    MailManagerComponent,
    TopNavigationComponent,
    MailsFeedComponent,
    ComposerMinWindowBarComponent,
    MailDetailsComponent,
    MailFeedItemComponent,
    MailDetailsToolBarComponent,
    MailDetailsContainerComponent,
    MailDetailsBodyComponent,
    MailDetailsCategorizerComponent,
    ColorSelectorComponent,
    MailComposerComponent,
    ComposerWindowBarComponent,
    RecipientFieldsComponent,
    MailComposerMinComponent,
    BlockedSdrsDialogComponent,
    MailReferredDialogComponent,
    UserListComponent,
    UserDetailsComponent,
    ConfirmDialogComponent,
    AlertDialogComponent,
    UserCreateComponent,
    NotificationListComponent,
    NotificationItemComponent,
    InquiryComponent,
    InquirySearchComponent,
    InquirySelectionComponent,
    InquiryDetailComponent,
    InquiryHistoryComponent,
    AddressSuggestionManagementComponent,
    SdrManagementComponent,
    EmailTemplateManagementComponent,
    EmailTemplateEditorComponent,
    SanitizeHtmlPipe,
    BlockedSdrsDialogComponent,
    MultiselectAutocompleteComponent,
    TruncatePipe,
    SelectTemplateComponent,
    MailSenderRecipientsDetailsComponent,
    MailFeedSkeletonLoaderComponent,
    ConvertedClientsListComponent,
    AttachmentComponent,
    DiscrepanciesComponent,
    DiscrepancyDetailsBodyComponent,
    DiscrepancyDetailsToolBarComponent,
    ClassifierToolbarComponent,
    ClassifierComponent,
    ClassifierLogComponent,
    ClassifierLogFolderComponent,
    SearchBarComponent,
    SearchLeadsComponent,
    ClientContactsListComponent,
    BotSetupComponent,
    DiscrepanciesSdrsListComponent,
    ResetPasswordComponent,
    ClientContactsEditComponent,
    SelectSalesRepresentativeComponent,
    SearchResultItemComponent,
    LeadToBulkDialogComponent,
    LtbEmailClassifierComponent,
    FaqManagerListComponent,
    FaqManagerEditorComponent,
    FaqOperatorComponent,
    LeadSummaryComponent,
    NewLeadDialogComponent,
    NewLeadFormComponent,
    FormRowComponent,
    NewLeadPreviewDialogComponent,
    FoldersSdrsListComponent,
    ContactDateDialogComponent,
    ClientManagementContainerComponent,
    ReassignOperatorContainerComponent,
    OperatorCardComponent,
    FlagViewerComponent,
    AssignOperatorComponent,
    TableActionsComponent,
    SetOperatorLevelComponent,
    UnsetOperatorComponent,
    OperatorUserManagementContainerComponent,
    OperatorUserFormComponent,
    OperatorUserAddComponent,
    OperatorUserEditComponent,
    OperatorUserActiveToggleComponent,
    AdvancedSearchDialogComponent,
    LeadInteractionItemComponent,
    SdrsManagementContainerComponent,
    ReassignSdrComponent,
    ViewLeadToolbarComponent,
    MailReviewComponent,
    MailClassificationComponent,
    ConversationComponent,
    SdrConversationsComponent,
    MessageComponent,
    ExtraInfoComponent,
    NewlineToBrPipe,
    InteractionItemComponent,
    InteractionsComponent,
    ClassificationComponent,
    SendMessageComponent,
    ConvertedDialogComponent,
    MailClassifierComponent,
    MailClassifierToolbarComponent,
    MailClassifierLogComponent,
    MailClassifierDetailsToolbarComponent,
    MailClassifierDetailsBodyComponent,
    MailClassifierSearchLeadsComponent,
    MailClassifierSearchInteractionsComponent,
    MailClassifierAdvanceSearchDialogComponent,
    MailClassifierLeadInteractionItemComponent,
    MailClassifierLogFolderComponent,
    ContactDialogComponent,
    ContactDetailComponent,
    ContactSearchComponent,
    ContactFormComponent,
    ContactToolbarComponent,
    UndoInteractionDialogComponent,
    ForwardedInteractionDialogComponent,
    CompleteCaseDialogComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    QuillModule.forRoot(),
    CKEditorModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MaterialModule,
    NgxSummernoteModule,
    OverlayModule,
    NgxSkeletonLoaderModule.forRoot({ animation: 'pulse' }),
    DragDropModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

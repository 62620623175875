import { MetabaseDashboardModule } from './screens/home/metabase-dashboard/metabase-dashboard.module';
/* eslint-disable max-len */
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './screens/home/home.component';
import { MailManagerComponent } from './screens/home/mail-manager/mail-manager.component';
import { LoginComponent } from './screens/login/login.component';

import { OverlayModule } from '@angular/cdk/overlay';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { QuillModule } from 'ngx-quill';
import { ColorSelectorComponent } from './components/color-selector/color-selector.component';
import { TopNavigationComponent } from './components/top-navigation/top-navigation.component';
import { AuthInterceptorService } from './interceptor/auth-interceptor.service';
import { ComposerMinWindowBarComponent } from './screens/home/mail-composer-min/composer-min-window-bar/composer-min-window-bar.component';
import { MailComposerMinComponent } from './screens/home/mail-composer-min/mail-composer-min.component';
import { ComposerWindowBarComponent } from './screens/home/mail-composer/composer-window-bar/composer-window-bar.component';
import { MailComposerComponent } from './screens/home/mail-composer/mail-composer.component';
import { RecipientFieldsComponent } from './screens/home/mail-composer/recipient-fields/recipient-fields.component';
import { MailDetailsBodyComponent } from './screens/home/mail-manager/mail-details/mail-details-container/mail-details-body/mail-details-body.component';
import { MailDetailsCategorizerComponent } from './screens/home/mail-manager/mail-details/mail-details-container/mail-details-categorizer/mail-details-categorizer.component';
import { MailDetailsContainerComponent } from './screens/home/mail-manager/mail-details/mail-details-container/mail-details-container.component';
import { MailDetailsToolBarComponent } from './screens/home/mail-manager/mail-details/mail-details-tool-bar/mail-details-tool-bar.component';
import { MailReferredDialogComponent } from './screens/home/mail-manager/mail-details/mail-details-tool-bar/mail-referred-dialog/mail-referred-dialog.component';
import { MailFeedItemComponent } from './screens/home/mail-manager/mails-feed/mail-feed-item/mail-feed-item.component';
import { MailsFeedComponent } from './screens/home/mail-manager/mails-feed/mails-feed.component';


import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { MultiselectAutocompleteComponent } from './components/multiselect-autocomplete/multiselect-autocomplete.component';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { InquiryDetailComponent } from './screens/home/inquiry/inquiry-detail/inquiry-detail.component';
import { InquiryHistoryComponent } from './screens/home/inquiry/inquiry-history/inquiry-history.component';
import { InquirySearchComponent } from './screens/home/inquiry/inquiry-search/inquiry-search.component';
import { InquirySelectionComponent } from './screens/home/inquiry/inquiry-selection/inquiry-selection.component';
import { InquiryComponent } from './screens/home/inquiry/inquiry.component';
import { SelectTemplateComponent } from './screens/home/mail-composer/select-template/select-template.component';
import { BlockedSdrsDialogComponent } from './screens/home/mail-manager/mails-feed/blocked-sdrs-dialog/blocked-sdrs-dialog.component';
import { NotificationItemComponent } from './screens/home/notifications/notification-item/notification-item.component';
import { NotificationListComponent } from './screens/home/notifications/notification-list.component';
import { AddressSuggestionManagementComponent } from './screens/management/address-management/address-suggestion-management/address-suggestion-management.component';
import { EmailTemplateEditorComponent } from './screens/management/email-templates/email-template-editor/email-template-editor.component';
import { EmailTemplateManagementComponent } from './screens/management/email-templates/email-template-management/email-template-management.component';
import { UserCreateComponent } from './screens/management/user-management/user-create/user-create.component';
import { UserDetailsComponent } from './screens/management/user-management/user-details/user-details.component';
import { UserListComponent } from './screens/management/user-management/user-list/user-list.component';

import { CommonModule } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AttachmentComponent } from './components/attachment/attachment.component';
import { ConvertedClientsListComponent } from './screens/converted-clients-list/converted-clients-list.component';
import { MailSenderRecipientsDetailsComponent } from './screens/home/mail-manager/mail-details/mail-details-tool-bar/mail-sender-recipients-details/mail-sender-recipients-details.component';
import { MailFeedSkeletonLoaderComponent } from './screens/home/mail-manager/mails-feed/mail-feed-skeleton-loader/mail-feed-skeleton-loader.component';
import { ManagementComponent } from './screens/management/management.component';

import { FaqOperatorComponent } from './screens/home/faq-operator/faq-operator.component';
import { FaqManagerEditorComponent } from './screens/management/faq-manager/faq-manager-editor/faq-manager-editor.component';
import { FaqManagerListComponent } from './screens/management/faq-manager/faq-manager-list/faq-manager-list.component';

import { SelectSalesRepresentativeComponent } from './screens/home/mail-composer/select-sales-representative/select-sales-representative.component';
import { ResetPasswordComponent } from './screens/login/reset-password/reset-password.component';
import { BotSetupComponent } from './screens/management/bot-setup/bot-setup.component';
import { ClientContactsEditComponent } from './screens/management/client-contacts/client-contacts-edit/client-contacts-edit.component';
import { ClientContactsListComponent } from './screens/management/client-contacts/client-contacts-list/client-contacts-list.component';
import { DiscrepanciesSdrsListComponent } from './screens/management/user-management/user-details/discrepancies-sdrs-list/discrepancies-sdrs-list.component';

import { FoldersSdrsListComponent } from './screens/management/user-management/user-details/folders-sdrs-list/folders-sdrs-list.component';

import { FlagViewerComponent } from './components/flag-viewer/flag-viewer.component';
import { TableActionsComponent } from './components/table-actions/table-actions.component';
import { AssignOperatorComponent } from './screens/management/client-management/assign-operator/assign-operator.component';
import { ClientManagementContainerComponent } from './screens/management/client-management/client-management-container.component';
import { UnsetOperatorComponent } from './screens/management/client-management/unset-operator/unset-operator.component';
import { OperatorUserActiveToggleComponent } from './screens/management/operator-user-management/operator-user-active-toggle/operator-user-active-toggle.component';
import { OperatorUserAddComponent } from './screens/management/operator-user-management/operator-user-add/operator-user-add.component';
import { OperatorUserManagementContainerComponent } from './screens/management/operator-user-management/operator-user-container.component';
import { OperatorUserEditComponent } from './screens/management/operator-user-management/operator-user-edit/operator-user-edit.component';
import { OperatorUserFormComponent } from './screens/management/operator-user-management/operator-user-form/operator-user-form.component';

import { SdrsManagementContainerComponent } from './screens/management/sdrs-management/sdrs-management-container.component';

import { MatChipsModule } from '@angular/material/chips';
import { DatepickerDialogComponent } from './components/datepicker-dialog/datepicker-dialog.component';
import { AllowOnlyNumbersDirective } from './directives/allow-only-numbers.directive';
import { MaterialModule } from './material/material.module';
import { NewlineToBrPipe } from './pipes/newline-to-br.pipe';
import { AliasDialogComponent } from './screens/mail-review/alias-dialog/alias-dialog.component';
import { ClientContactHistoryComponent } from './screens/mail-review/client-contact-history/client-contact-history.component';
import { CompleteCaseDialogComponent } from './screens/mail-review/complete-case-dialog/complete-case-dialog.component';
import { MailClassificationComponent } from './screens/mail-review/mail-classification/mail-classification.component';
import { MailClassifierAdvanceSearchDialogComponent } from './screens/mail-review/mail-classifier-advance-search-dialog/mail-classifier-advance-search-dialog.component';
import { MailClassifierLeadInteractionItemComponent } from './screens/mail-review/mail-classifier-search-leads/mail-classifier-lead-interaction-item/mail-classifier-lead-interaction-item.component';
import { MailClassifierSearchInteractionsComponent } from './screens/mail-review/mail-classifier-search-leads/mail-classifier-search-interactions/mail-classifier-search-interactions.component';
import { MailClassifierSearchLeadsComponent } from './screens/mail-review/mail-classifier-search-leads/mail-classifier-search-leads.component';
import { MailClassifierDetailsBodyComponent } from './screens/mail-review/mail-classifier/mail-classifier-details-body/mail-classifier-details-body.component';
import { MailClassifierDetailsToolbarComponent } from './screens/mail-review/mail-classifier/mail-classifier-details-body/mail-classifier-details-toolbar/mail-classifier-details-toolbar.component';
import { MailClassifierLogFolderComponent } from './screens/mail-review/mail-classifier/mail-classifier-log/mail-classifier-log-folder/mail-classifier-log-folder.component';
import { MailClassifierLogComponent } from './screens/mail-review/mail-classifier/mail-classifier-log/mail-classifier-log.component';
import { MailClassifierToolbarComponent } from './screens/mail-review/mail-classifier/mail-classifier-toolbar/mail-classifier-toolbar.component';
import { MailClassifierComponent } from './screens/mail-review/mail-classifier/mail-classifier.component';
import { MailReviewComponent } from './screens/mail-review/mail-review.component';
import { ReassignSdrComponent } from './screens/management/sdrs-management/reassign-sdr/reassign-sdr.component';
import { FolderSdrsAddComponent } from './screens/management/user-management/user-details/folders-sdrs-add/folder-sdrs-add.component';
import { ClassificationComponent } from './screens/sdr-conversations/classification/classification.component';
import { ConversationComponent } from './screens/sdr-conversations/conversation/conversation.component';
import { ExtraInfoComponent } from './screens/sdr-conversations/extra-info/extra-info.component';
import { InteractionItemComponent } from './screens/sdr-conversations/interaction-item/interaction-item.component';
import { InteractionsComponent } from './screens/sdr-conversations/interactions/interactions.component';
import { MessageComponent } from './screens/sdr-conversations/message/message.component';
import { SdrConversationsComponent } from './screens/sdr-conversations/sdr-conversations.component';
import { SendMessageComponent } from './screens/sdr-conversations/send-message/send-message.component';
import { ContactDetailComponent } from './screens/shared/contact-dialog/contact-detail/contact-detail.component';
import { ContactDialogComponent } from './screens/shared/contact-dialog/contact-dialog.component';
import { ContactFormComponent } from './screens/shared/contact-dialog/contact-form/contact-form.component';
import { ContactSearchComponent } from './screens/shared/contact-dialog/contact-search/contact-search.component';
import { ContactToolbarComponent } from './screens/shared/contact-dialog/contact-toolbar/contact-toolbar.component';
import { ConvertedDialogComponent } from './screens/shared/converted-dialog/converted-dialog.component';
import { ForwardedInteractionDialogComponent } from './screens/shared/forwarded-interaction-dialog/forwarded-interaction-dialog.component';
import { UndoInteractionDialogComponent } from './screens/shared/undo-interaction-dialog/undo-interaction-dialog.component';
import { ViewLeadToolbarComponent } from './screens/home/inquiry/view-lead-toolbar/view-lead-toolbar.component';
import { MetabaseDashboardComponent } from './screens/home/metabase-dashboard/metabase-dashboard.component';

@NgModule({ declarations: [
        AppComponent,
        HomeComponent,
        ManagementComponent,
        LoginComponent,
        MailManagerComponent,
        TopNavigationComponent,
        MailsFeedComponent,
        ComposerMinWindowBarComponent,
        MailFeedItemComponent,
        MailDetailsToolBarComponent,
        MailDetailsContainerComponent,
        MailDetailsBodyComponent,
        MailDetailsCategorizerComponent,
        ColorSelectorComponent,
        MailComposerComponent,
        ComposerWindowBarComponent,
        RecipientFieldsComponent,
        MailComposerMinComponent,
        BlockedSdrsDialogComponent,
        MailReferredDialogComponent,
        UserListComponent,
        UserDetailsComponent,
        ConfirmDialogComponent,
        AlertDialogComponent,
        UserCreateComponent,
        NotificationListComponent,
        NotificationItemComponent,
        InquiryComponent,
        InquirySearchComponent,
        InquirySelectionComponent,
        InquiryDetailComponent,
        InquiryHistoryComponent,
        AddressSuggestionManagementComponent,
        EmailTemplateManagementComponent,
        EmailTemplateEditorComponent,
        SanitizeHtmlPipe,
        BlockedSdrsDialogComponent,
        MultiselectAutocompleteComponent,
        TruncatePipe,
        SelectTemplateComponent,
        MailSenderRecipientsDetailsComponent,
        MailFeedSkeletonLoaderComponent,
        ConvertedClientsListComponent,
        AttachmentComponent,
        ClientContactsListComponent,
        BotSetupComponent,
        DiscrepanciesSdrsListComponent,
        ResetPasswordComponent,
        ClientContactsEditComponent,
        SelectSalesRepresentativeComponent,
        FaqManagerListComponent,
        FaqManagerEditorComponent,
        FaqOperatorComponent,
        FoldersSdrsListComponent,
        FolderSdrsAddComponent,
        ClientManagementContainerComponent,
        FlagViewerComponent,
        AssignOperatorComponent,
        TableActionsComponent,
        UnsetOperatorComponent,
        OperatorUserManagementContainerComponent,
        OperatorUserFormComponent,
        OperatorUserAddComponent,
        OperatorUserEditComponent,
        OperatorUserActiveToggleComponent,
        SdrsManagementContainerComponent,
        ReassignSdrComponent,
        MailReviewComponent,
        MailClassificationComponent,
        ConversationComponent,
        SdrConversationsComponent,
        MessageComponent,
        ExtraInfoComponent,
        NewlineToBrPipe,
        InteractionItemComponent,
        InteractionsComponent,
        ClassificationComponent,
        SendMessageComponent,
        ConvertedDialogComponent,
        MailClassifierComponent,
        MailClassifierToolbarComponent,
        MailClassifierLogComponent,
        MailClassifierDetailsToolbarComponent,
        MailClassifierDetailsBodyComponent,
        MailClassifierSearchLeadsComponent,
        MailClassifierSearchInteractionsComponent,
        MailClassifierAdvanceSearchDialogComponent,
        MailClassifierLeadInteractionItemComponent,
        MailClassifierLogFolderComponent,
        ContactDialogComponent,
        ContactDetailComponent,
        ContactSearchComponent,
        ContactFormComponent,
        ContactToolbarComponent,
        UndoInteractionDialogComponent,
        ForwardedInteractionDialogComponent,
        CompleteCaseDialogComponent,
        DatepickerDialogComponent,
        AliasDialogComponent,
        AllowOnlyNumbersDirective,
        ClientContactHistoryComponent,
        ViewLeadToolbarComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        CommonModule,
        AppRoutingModule,
        QuillModule.forRoot(),
        CKEditorModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        OverlayModule,
        NgxSkeletonLoaderModule.forRoot({ animation: 'pulse' }),
        DragDropModule,
        MatChipsModule], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true,
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { LoadingStateService } from '../../../../services/base/loading-state.service';
import { InteractionService } from '../../../../services/interaction.service';
import { LeadService } from '../../../../services/lead.service';

@Component({
  selector: 'app-contact-toolbar',
  templateUrl: './contact-toolbar.component.html',
  styleUrls: ['./contact-toolbar.component.scss'],
})
export class ContactToolbarComponent implements OnInit, OnDestroy {
  isCreatingContact = false;
  isValid = false;
  isSaving = false;
  invalidAddToInteraction$ = combineLatest([
    this.leadService.clientFilterChecked$,
    this.interactionService.hasInteractions$,
    this.interactionService.forwarders$,
    this.leadService.currentLead$,
  ]).pipe(
    map(([isChecked, hasInteractions, forwarders, currentLead]) => {
      const hasForwarders = forwarders?.length > 0;
      const emailMatches = hasForwarders && forwarders[0]?.email === currentLead?.emailAddress;
      return !isChecked || hasInteractions || !hasForwarders || !emailMatches;
    }),
  );
  private destroy$ = new Subject<boolean>();

  constructor(
    private interactionService: InteractionService,
    private leadService: LeadService,
    private loadingStateService: LoadingStateService,
  ) {}

  ngOnInit(): void {
    this.leadService.isCreatingContact$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isCreatingContact) => (this.isCreatingContact = isCreatingContact));

    this.leadService.isValid$.pipe(takeUntil(this.destroy$)).subscribe((isValid) => {
      this.isValid = isValid;
    });

    this.loadingStateService.isLoading.pipe(takeUntil(this.destroy$)).subscribe((isLoading) => {
      this.isSaving = isLoading;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  onCreateContact(): void {
    this.leadService.setIsCreatingContact(true);
  }

  onCancelCreateContact(): void {
    this.leadService.setIsCreatingContact(false);
  }

  onSaveContact(): void {
    this.leadService.setSaveCalled(true);
  }

  onAddToInteraction(): void {
    this.leadService.setRegisterProspectCalled(true);
  }
}

import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  private baseOptions: MatSnackBarConfig = {
    verticalPosition: 'bottom',
    horizontalPosition: 'center',
    duration: 5000,
  };
  private successOptions: MatSnackBarConfig = {
    ...this.baseOptions,
    panelClass: ['snackbar-success'],
  };
  private warningOptions: MatSnackBarConfig = {
    ...this.baseOptions,
    panelClass: ['snackbar-warning'],
  };
  private errorOptions: MatSnackBarConfig = {
    ...this.baseOptions,
    duration: 10000,
    panelClass: ['snackbar-error'],
  };

  public constructor(private matSnackBar: MatSnackBar) {}
  /**
   * Displays a SnackBar with the specified message and configuration.
   *
   * @param {string}             message Message to display to the user.
   * @param {string}             closeMessage Message for the close action.
   * @param {MatSnackBarConfig}  config Base configuration for the SnackBar.
   * @param {MatSnackBarConfig}  optionsOverride Additional configuration to override defaults.
   *
   * @return  {void}
   */
  private showSnackBar(
    message: string,
    closeMessage: string,
    config: MatSnackBarConfig,
    optionsOverride?: MatSnackBarConfig,
  ): void {
    const finalConfig = { ...config, ...optionsOverride };
    this.matSnackBar.open(message, closeMessage || 'Close', finalConfig);
  }

  /**
   * Displays a success message in the SnackBar.
   *
   * @param {string}             message Message to display to the user.
   * @param {string}             closeMessage Message for the close action.
   * @param {MatSnackBarConfig}  optionsOverride Additional configuration to override defaults.
   *
   * @return  {void}
   */
  public showSuccess(
    message: string = 'The action was completed successfully',
    closeMessage?: string,
    optionsOverride?: MatSnackBarConfig,
  ): void {
    this.showSnackBar(message, closeMessage, this.successOptions, optionsOverride);
  }

  /**
   * Displays a warning message in the SnackBar.
   *
   * @param {string}             message Message to display to the user.
   * @param {string}             closeMessage Message for the close action.
   * @param {MatSnackBarConfig}  optionsOverride Additional configuration to override defaults.
   *
   * @return  {void}
   */
  public showWarning(message: string = '', closeMessage?: string, optionsOverride?: MatSnackBarConfig): void {
    this.showSnackBar(message, closeMessage, this.warningOptions, optionsOverride);
  }

  /**
   * Displays an error message in the SnackBar.
   *
   * @param {string}             message Message to display to the user.
   * @param {string}             closeMessage Message for the close action.
   * @param {MatSnackBarConfig}  optionsOverride Additional configuration to override defaults.
   *
   * @return  {void}
   */
  public showError(
    message: string = 'An error has occurred',
    closeMessage?: string,
    optionsOverride?: MatSnackBarConfig,
  ): void {
    this.showSnackBar(message, closeMessage, this.errorOptions, optionsOverride);
  }
}

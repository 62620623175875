import { Component, Input } from '@angular/core';
import { Email } from 'src/app/model/Email';

@Component({
    selector: 'app-mail-sender-recipients-details',
    templateUrl: './mail-sender-recipients-details.component.html',
    styleUrls: ['./mail-sender-recipients-details.component.scss'],
    standalone: false
})
export class MailSenderRecipientsDetailsComponent {
  @Input() email: Email;
  constructor() {}

  getArgentinianDate() {
    return new Date(this.email.date + 'Z').toLocaleString('en-US', { timeZone: 'America/Argentina/Buenos_Aires' });
  }

  transform(){
    return new Date(this.email.date + 'Z').toLocaleString();
  }

  sliceRecipients(recipients) {
    return recipients.split(',').slice(0, 3).join(', ');
  }

  formatRecipients(recipients) {
    return recipients.split(',').join(`,\n`);
  }
}

<mat-card class="mat-elevation-z2 top-navigation">
  <div class="top-navigation__left-content">
    <div class="mobile-only">
      <mat-icon
        (click)="toggleFeed()"
        class="top-navigation__mobileFeedToggle"
        >list</mat-icon
      >
    </div>
    <div>
      <img
        src="assets/images/logo.png"
        class="top-navigation__logo"
      />
    </div>
    <button
      mat-button
      class="top-navigation__button"
      id="navigate-home"
      (click)="navigateToHome()"
      routerLink="/home/mailManager"
      routerLinkActive="top-navigation__button--active"
    >
      <mat-icon
        fontIcon="home"
        class="material-icons-outlined top-navigation__button-icon"
      ></mat-icon>
      Home
    </button>
    <button
      mat-button
      *ngIf="hasSdrConversationsPermission"
      class="top-navigation__button"
      id="sdr-conversation-menu-item"
      (click)="navigateToConversations()"
      [class.top-navigation__button--active]="isActiveRoute('/home/sdr-conversations')"
    >
      <img
        src="/assets/images/linkedin.svg"
        class="top-navigation__button-icon"
      />
      <span>LinkedIn conversations</span>
    </button>
    <button
      mat-button
      *ngIf="hasDiscrepanciesPermission"
      class="top-navigation__button"
      id="mail-review-menu-item"
      (click)="navigateToMailReview()"
      [class.top-navigation__button--active]="isActiveRoute('/home/mail-review')"
    >
      <mat-icon class="material-icons-outlined top-navigation__button-icon">contact_mail</mat-icon>
      Review emails
    </button>
    <button
      mat-button
      *ngIf="hasDiscrepanciesPermission"
      class="top-navigation__button"
      id="mail-manual-menu-item"
      (click)="navigateToMailManual()"
      [class.top-navigation__button--active]="isActiveRoute('/home/mail-manual')"
    >
      <mat-icon class="material-icons-outlined top-navigation__button-icon">edit</mat-icon>
      Manual emails
    </button>
    <button
      mat-button
      *ngIf="hasMetabasePermission"
      class="top-navigation__button"
      id="metabase-dashboard-menu-item"
      (click)="navigateToMetabaseDashboard()"
      [class.top-navigation__button--active]="isActiveRoute('/home/metabase-dashboard')"
    >
      <mat-icon class="material-icons-outlined top-navigation__button-icon">dashboard</mat-icon>
      Metabase dashboard
    </button>
  </div>
  <div class="top-navigation__right-content">
    <div
      class="top-navigation__account-container"
      [matMenuTriggerFor]="accountMenu"
    >
      <mat-icon class="material-icons-outlined top-navigation__account-icon">account_circle</mat-icon>
      <span class="top-navigation__account-text">
        {{ userEmail }}
      </span>

      <mat-menu #accountMenu="matMenu">
        <button
          (click)="navigateToManagement()"
          *ngIf="isAdmin"
          mat-menu-item
          id="management-btn"
        >
          Management
        </button>
        <button
          (click)="logOut()"
          mat-menu-item
          id="sign-out-btn"
        >
          Sign out
        </button>
      </mat-menu>
    </div>
  </div>
</mat-card>

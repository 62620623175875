<div class="faq-manager-editor">
  <div class="faq-manager-editor__title">
    <span>{{ pageTitle }}</span>
    <button
      mat-raised-button
      class="faq-manager-editor__cancel-btn"
      (click)="cancel()"
      id="faq-manager-editor-cancel-btn"
    >
      Cancel
    </button>
  </div>

  <div class="faq-manager-editor__row-form">
    <mat-form-field
      appearance="outline"
      class="form-field-5 faq-manager-editor__field"
      subscriptSizing="dynamic"
      id="faq-manager-editor-client-mat-form-field"
    >
      <mat-label>Client</mat-label>
      <input
        type="text"
        aria-label="Number"
        matInput
        required
        [formControl]="clientFilterControl"
        [matAutocomplete]="clientsAuto"
        [(ngModel)]="selectedClient"
        id="faq-manager-editor-client-input"
      />
      <mat-autocomplete
        #clientsAuto="matAutocomplete"
        [displayWith]="displayFn"
        id="faq-manager-editor-client-mat-autocomplete"
      >
        <mat-option
          *ngFor="let client of filteredClientOptions | async"
          [value]="client"
          id="faq-manager-editor-client-mat-option"
        >
          {{ client }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <div class="faq-manager-editor__row-form">
    <mat-form-field
      class="form-field-5 faq-manager-editor__field"
      appearance="outline"
      subscriptSizing="dynamic"
      id="faq-manager-editor-title-mat-form-field"
    >
      <mat-label>Title</mat-label>
      <input
        matInput
        [(ngModel)]="title"
        required
        maxlength="50"
        id="faq-manager-editor-title-input"
      />
    </mat-form-field>
  </div>

  <div class="faq-manager-editor__row-form">
    <div class="faq-manager-editor__summernote-container">
      <div>
        <div id="faqEditorId"></div>
      </div>
    </div>
  </div>

  <div class="faq-manager-editor__btn-create-faq-container">
    <button
      (click)="createFaq()"
      class="faq-manager-editor__btn-create-faq"
      mat-raised-button
      color="primary"
      [disabled]="invalidForm || isSubmitting"
      id="faq-manager-editor-create-faq-btn"
    >
      {{ submitFaqLabel }}
    </button>
  </div>
</div>

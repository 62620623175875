<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane.mail-composer-container"
  class="mail-composer"
>
  <div
    cdkDragHandle
    #modalBar
    class="mail-composer__header-container"
  >
    <app-composer-window-bar
      (closeDialog)="close()"
      (minimizeDialog)="handleMinimizedDialog($event)"
    ></app-composer-window-bar>
  </div>
  <mat-dialog-content class="mail-composer__content">
    <app-recipient-fields></app-recipient-fields>
    <div class="mail-composer__note-container">
      <div id="mail-note"></div>
    </div>

    <iframe
      id="composerPreviewIframe"
      #composerPreviewIframe
      frameborder="0"
      class="mail-composer__iframe"
    ></iframe>

    <div class="mail-composer__attachments-wrapper">
      <div *ngFor="let attachment of currentComposedEmail.attachments">
        <app-attachment
          [attachment]="attachment"
          [isRemovable]="true"
          (clickEvt)="
            messageAttachmentService.downloadDraftFile(
              attachment.filename,
              attachment.isSafe,
              attachment.content,
              attachment.isLocal,
              attachment.key
            )
          "
          (removeEvt)="
            messageAttachmentService.deleteAttachment(
              attachment.filename,
              attachment.fileId,
              attachment.isLocal,
              currentComposedEmail.attachments
            )
          "
        >
        </app-attachment>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="start">
    <div class="mail-composer__actions">
      <button
        mat-flat-button
        color="primary"
        (click)="send()"
        class="mail-composer__send-btn"
        [ngClass]="{
          'mail-composer__send-btn--disabled':
            isSdrBlocked || !isSendConfigured || !isReplyReady || !isIntroductionReady
        }"
        [disabled]="invalidForm || isSdrBlocked || !isSendConfigured"
        id="mail-composer-send-btn"
      >
        {{ sendButtonText }}
      </button>
      <div class="mail-composer__attachment-btn">
        <label
          for="inputFile"
          matTooltip="Add Attachment"
        >
          <span><mat-icon>attach_file</mat-icon></span>
        </label>
        <input
          type="file"
          id="inputFile"
          (change)="addAttachment($event.target.files)"
          multiple
          #inputFileControl
        />
      </div>
      <button
        mat-button
        *ngIf="switchAllowed && !selectedTemplate && currentComposedEmail.category !== categoryType.Forward"
        (click)="selectTemplate()"
        [disableRipple]="true"
        class="mail-composer__select-template-btn"
        matTooltip="Select Template"
        id="mail-composer-select-template-btn"
      >
        <mat-icon class="mail-composer__button-icon">sticky_note_2</mat-icon>
      </button>
      <p
        *ngIf="selectedTemplate"
        class="mail-composer__template-label"
      >
        Template Used:
      </p>
      <p
        *ngIf="selectedTemplate"
        class="mail-composer__template-title"
      >
        {{ selectedTemplate.title }}
      </p>
      <button
        mat-icon-button
        *ngIf="switchAllowed && currentComposedEmail.category === categoryType.Forward"
        (click)="selectSalesRep()"
        [disableRipple]="true"
        class="mail-composer__select-sales-rep-btn"
        matTooltip="Select Sales Representative"
        id="mail-composer-select-sales-representative-btn"
      >
        <mat-icon class="mail-composer__button-icon">face</mat-icon>
      </button>
      <button
        mat-icon-button
        *ngIf="displayCopyLeadBtn"
        (click)="onCopyLeadProfile()"
        [disableRipple]="true"
        class="mail-composer__copy-lead-profile-btn"
        matTooltip="Copy Lead Profile"
        [disabled]="leadCopied || isLoadingLead"
        id="mail-composer-copy-lead-profile-btn"
      >
        <mat-icon
          *ngIf="!isLoadingLead"
          class="mail-composer__button-icon"
          >group</mat-icon
        >
        <mat-spinner
          *ngIf="isLoadingLead"
          diameter="20"
        ></mat-spinner>
      </button>
      <button
        mat-icon-button
        *ngIf="displayGetDetailsBtn"
        (click)="onGetConversionDetails()"
        [disableRipple]="true"
        class="mail-composer__get-details-btn"
        matTooltip="Get Conversion Details"
        [disabled]="conversionDetailsPasted || isLoadingConversionDetails"
        id="mail-composer-get-details-btn"
      >
        <mat-icon
          *ngIf="!isLoadingConversionDetails"
          class="mail-composer__button-icon"
          >read_more</mat-icon
        >
        <mat-spinner
          *ngIf="isLoadingConversionDetails"
          diameter="20"
        ></mat-spinner>
      </button>
    </div>
  </mat-dialog-actions>
</div>

import { Component, OnInit } from '@angular/core';
import { ClientsDataContext } from '../../model/Sdr';
import { SdApiService } from '../../services/base/sd-api.service';

@Component({
  selector: 'app-mail-review',
  templateUrl: './mail-review.component.html',
  styleUrls: ['./mail-review.component.scss'],
})
export class MailReviewComponent implements OnInit {
  constructor(private sdApiService: SdApiService) {}

  ngOnInit() {
    this.sdApiService.setClientsContext(ClientsDataContext.DISCREPANCIES);
  }
}

import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, takeUntil } from 'rxjs/operators';
import { ForwardedInteractionDialogData } from '../../../model/interaction/forwarded-interaction-dialog-data.model';
import { ForwardedInteractionDto } from '../../../model/interaction/forwarded-interaction.dto';
import { InteractionService } from '../../../services/interaction.service';
import { LeadService } from '../../../services/lead.service';
import { SnackBarService } from '../../../services/snackbar/snackbar.service';

@Component({
  selector: 'app-forwarded-interaction-dialog',
  templateUrl: './forwarded-interaction-dialog.component.html',
  styleUrls: ['./forwarded-interaction-dialog.component.scss'],
})
export class ForwardedInteractionDialogComponent implements OnInit, OnDestroy {
  forwardedControlIdCtrl = new FormControl<number | null>(null, [Validators.required]);
  isForwarding = false;
  isSearching = false;
  forwardedContactName = null;
  forwardedContactEmail = null;
  private destroy$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public parentData: ForwardedInteractionDialogData,
    public dialog: MatDialogRef<ForwardedInteractionDialogComponent>,
    private interactionService: InteractionService,
    private leadService: LeadService,
    private snackBarService: SnackBarService,
  ) {}

  ngOnInit(): void {
    this.forwardedControlIdCtrl.valueChanges
      .pipe(takeUntil(this.destroy$), distinctUntilChanged(), debounceTime(500))
      .subscribe(() => {
        this.forwardedContactName = null;
        this.forwardedContactEmail = null;
        this.forwardedControlIdCtrl.valid && this.handleForwardedId();
      });
  }

  get validForm(): boolean {
    return this.forwardedContactName && this.forwardedContactEmail;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onForwardedInteraction() {
    const { contactId, sdrId, interactionId, interactionUtcDatetime, messageId } = this.parentData;
    const forwardedContactId = this.forwardedControlIdCtrl.value;
    const forwardedInteractionDto: ForwardedInteractionDto = {
      contactId,
      interactionId,
      forwardedContactId,
      sdrId,
      interactionUtcDatetime,
    };

    this.isForwarding = true;
    this.interactionService
      .forwardedInteraction(forwardedInteractionDto)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => (this.isForwarding = false)),
      )
      .subscribe({
        next: () => {
          this.snackBarService.showSuccess('Interaction forwarded created successfully.');
          this.interactionService.setInteractionsUpdated(true);
          this.dialog.close();
        },
        error: () => {
          this.snackBarService.showError('An error occurred while forwarding the interaction.');
        },
      });
  }

  onClearDetails() {
    this.forwardedControlIdCtrl.reset(null);
    this.forwardedContactName = null;
    this.forwardedContactEmail = null;
  }

  handleForwardedId() {
    const forwardedContactId = this.forwardedControlIdCtrl.value;
    this.isSearching = true;
    this.leadService
      .getLeadByContactId(forwardedContactId)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => (this.isSearching = false)),
      )
      .subscribe({
        next: (lead) => {
          if (!lead) {
            this.snackBarService.showWarning('No contact found with the provided contactId.');
          }
          this.forwardedContactEmail = lead?.emailAddress || '';
          this.forwardedContactName = lead?.origFullName || '';
        },
        error: () => {
          this.snackBarService.showError('An error occurred while forwarding the interaction.');
        },
      });
  }
}

<a
  class="attachment"
  (click)="onClickAttachment()"
  [style.cursor]="attachment.isSafe ? 'pointer' : 'initial'"
>
  <div
    class="attachment__box"
    matTooltip="{{ attachment.filename }}"
  >
    <div class="attachment__info-container">
      <span class="attachment__name">
        {{ messageAttachmentService.getFileName(attachment.filename) | truncate: 15 : false : '..'
        }}{{ messageAttachmentService.getExtension(attachment.filename) }}
      </span>
      <span class="attachment__size"> {{ messageAttachmentService.getKbSize(attachment.size) }} kb </span>
    </div>
    <div
      *ngIf="attachment.isUploading"
      class="attachment__action-container"
    >
      <mat-spinner
        [diameter]="20"
        style="margin-left: 10px; margin-right: 5px"
      ></mat-spinner>
    </div>
    <div
      *ngIf="!attachment.isUploading"
      class="attachment__action-container"
    >
      <mat-icon
        *ngIf="isRemovable && !attachment.isUploading"
        (click)="onRemoveAttachment($event)"
        >cancel</mat-icon
      >
    </div>
  </div>
</a>

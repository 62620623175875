<div class="complete-case-dialog__top-bar">
  <span class="complete-case-dialog__title">Complete Case</span>
  <mat-icon
    mat-dialog-close
    class="complete-case-dialog__close-btn"
    >close</mat-icon
  >
</div>
<mat-dialog-content class="complete-case-dialog__content">
  <button
    mat-stroked-button
    *ngFor="let classification of classifictionStatuses"
    (click)="onCompleteCase(classification.ruleId)"
    id="{{ classification.ruleDescription }}-btn"
  >
    {{ classification.ruleDescription }}
  </button>
</mat-dialog-content>

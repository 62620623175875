import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { GenericResponse } from '../model/common/generic-response';
import { CorporateCompany } from '../model/lead/corporate-company.dto';
import { CreateLeadDto } from '../model/lead/create-lead-dto';
import { Lead } from '../model/lead/lead.model';

@Injectable({
  providedIn: 'root',
})
export class LeadService {
  private currentLeadSubject = new BehaviorSubject<Lead>(null);
  currentContactIdSubject = new BehaviorSubject<number>(null);
  private isCreatingContactSubject = new BehaviorSubject<boolean>(false);
  private isValidsubject = new BehaviorSubject<boolean>(false);
  private saveCalledSubject = new BehaviorSubject<boolean>(false);
  private registerProspectCalledSubject = new BehaviorSubject<boolean>(false);
  private clientFilterCheckedSubject = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {}

  get currentLead$(): Observable<Lead> {
    return this.currentLeadSubject.asObservable();
  }

  get isCreatingContact$(): Observable<boolean> {
    return this.isCreatingContactSubject.asObservable();
  }

  get isValid$(): Observable<boolean> {
    return this.isValidsubject.asObservable();
  }

  get saveCalled$(): Observable<boolean> {
    return this.saveCalledSubject.asObservable();
  }

  get clientFilterChecked$(): Observable<boolean> {
    return this.clientFilterCheckedSubject.asObservable();
  }

  get registerProspectCalled$(): Observable<boolean> {
    return this.registerProspectCalledSubject.asObservable();
  }

  get currentContactId$(): Observable<number> {
    return this.currentContactIdSubject.asObservable();
  }

  setCurrentContactId(contactId: number): void {
    this.currentContactIdSubject.next(contactId);
  }

  setCurrentLead(lead: Lead): void {
    this.currentLeadSubject.next(lead);
  }

  setIsCreatingContact(isCreatingContact: boolean): void {
    this.isCreatingContactSubject.next(isCreatingContact);
  }

  setIsValid(isValid: boolean): void {
    this.isValidsubject.next(isValid);
  }

  setSaveCalled(saveCalled: boolean): void {
    this.saveCalledSubject.next(saveCalled);
  }

  setClientFilterChecked(markClientFilter: boolean): void {
    this.clientFilterCheckedSubject.next(markClientFilter);
  }

  setRegisterProspectCalled(registerProspectCalled: boolean): void {
    this.registerProspectCalledSubject.next(registerProspectCalled);
  }

  getLeadByEmail(email: string): Observable<Lead> {
    const url = `${environment.newApiBaseUrl}/leads/email-address/${email}`;

    return this.http.get(url).pipe(map((response: GenericResponse<Lead>) => response.data));
  }

  getLeadByContactId(contactId: number): Observable<Lead> {
    const url = `${environment.newApiBaseUrl}/leads/contact-id/${contactId}`;

    return this.http.get(url).pipe(map((response: GenericResponse<Lead>) => response.data));
  }

  createLead(createLeadDto: CreateLeadDto): Observable<number> {
    const url = `${environment.newApiBaseUrl}/leads/create-lead`;

    return this.http.post(url, createLeadDto).pipe(map((response: GenericResponse<number>) => response.data));
  }

  validateCorporateEmail(email: string): Observable<CorporateCompany> {
    const url = `${environment.newApiBaseUrl}/leads/company/${email}`;

    return this.http.get(url).pipe(map((response: GenericResponse<CorporateCompany>) => response.data));
  }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { GenericResponse } from '../model/common/generic-response';
import { ProspectInfo } from '../model/common/prospect-info.model';
import { LeadSearchParams } from '../model/mail-classifier/lead-search-params.model';
import { LeadToBulkResult } from '../model/mail-classifier/lead-to-bulk-result.model';
import { LeadToBulkDto } from '../model/mail-classifier/leads-to-bulk.dto';
import { RegisterProspectDto } from '../model/leads-to-bulk/register-prospect-dto';

@Injectable({
  providedIn: 'root',
})
export class LeadsToBulkService {
  constructor(private httpClient: HttpClient) {}

  searchLeadsToBulk(leadToBulkDto: LeadToBulkDto): Observable<LeadToBulkResult[]> {
    const url = `${environment.newApiBaseUrl}/leads-to-bulk`;

    return this.httpClient
      .post(url, leadToBulkDto)
      .pipe(map((response: GenericResponse<LeadToBulkResult[]>) => response.data));
  }

  simpleSearchLeadToBulk(leadSearchParams: LeadSearchParams): Observable<ProspectInfo> {
    const url = `${environment.newApiBaseUrl}/leads-to-bulk/simple-search`;
    const params = new HttpParams()
      .append('clientId', leadSearchParams.clientId)
      .append('sdrId', leadSearchParams.sdrId)
      .append('email', leadSearchParams.email);

    return this.httpClient.get(url, { params }).pipe(map((response: GenericResponse<ProspectInfo>) => response.data));
  }

  registerProspect(registerProspectDto: RegisterProspectDto): Observable<number> {
    const url = `${environment.newApiBaseUrl}/leads-to-bulk/register-prospect`;

    return this.httpClient
      .post(url, registerProspectDto)
      .pipe(map((response: GenericResponse<number>) => response.data));
  }
}

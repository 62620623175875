import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, Injectable, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import {
  DateRange,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
  MatDateRangeSelectionStrategy,
} from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import dayjs from 'dayjs';
import { Subject, Subscription } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { LeadToBulkDto } from '../../../model/mail-classifier/leads-to-bulk.dto';
import { LeadsToBulkService } from '../../../services/leads-to-bulk.service';
import { MailReviewService } from '../../../services/mail-review.service';

@Injectable()
export class SevenDayRangeSelectionStrategy<D> implements MatDateRangeSelectionStrategy<D> {
  constructor(private _dateAdapter: DateAdapter<D>) {}

  selectionFinished(date: D | null): DateRange<D> {
    return this._createSevenDayRange(date);
  }

  createPreview(activeDate: D | null): DateRange<D> {
    return this._createSevenDayRange(activeDate);
  }

  private _createSevenDayRange(date: D | null): DateRange<D> {
    if (date) {
      const end = this._dateAdapter.addCalendarDays(date, 0);
      const start = this._dateAdapter.addCalendarDays(date, -6);
      return new DateRange<D>(start, end);
    }

    return new DateRange<D>(null, null);
  }
}

@Component({
  selector: 'app-mail-classifier-advance-search-dialog',
  templateUrl: './mail-classifier-advance-search-dialog.component.html',
  styleUrls: ['./mail-classifier-advance-search-dialog.component.scss'],
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: SevenDayRangeSelectionStrategy,
    },
  ],
})
export class MailClassifierAdvanceSearchDialogComponent implements OnInit, OnDestroy {
  form: FormGroup = new FormGroup({});
  isLoading = false;
  selectedResult = new SelectionModel<any>(true, []);
  displayedColumns: string[] = ['id', 'first_name', 'last_name', 'email'];
  searchResultsData: MatTableDataSource<any> = new MatTableDataSource([]);
  subscriptions = new Subscription();
  noResultsMessage = 'No Data';
  destroy$ = new Subject<boolean>();

  constructor(
    public dialogRef: MatDialogRef<MailClassifierAdvanceSearchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private leadsToBulkService: LeadsToBulkService,
    private mailReviewService: MailReviewService,
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private initForm() {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.minLength(3)]),
      searchDate: new FormControl(new Date()),
      fromDate: new FormControl(dayjs().subtract(6, 'days').format(), this.validDateRange),
      toDate: new FormControl(dayjs().format(), this.validDateRange),
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      bySingleDate: new FormControl(false),
      matchAnyCondition: new FormControl(false),
    });
  }

  get isValid(): boolean {
    return this.form.get('firstName').value || this.form.get('lastName').value || this.form.get('email').value;
  }

  get isValidEmail(): boolean {
    return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(this.form.get('email')?.value?.trim());
  }

  private validDateRange = (control: AbstractControl): ValidationErrors | null => {
    const shoudlFilterByDateRange = !this.form.get('bySingleDate')?.value;

    if (shoudlFilterByDateRange) {
      const fromDateControl = this.form.get('fromDate');
      const toDateControl = this.form.get('toDate');
      const startDate = (dayjs(fromDateControl?.value) || dayjs()).format('MM/DD/YYYY');
      const endDate = dayjs(toDateControl?.value).format('MM/DD/YYYY');
      const diff = dayjs(endDate).diff(startDate, 'days');
      const isValidDateRange = diff >= 0 && diff < 7;

      if (isValidDateRange) {
        if (control === toDateControl) {
          fromDateControl?.setErrors(null);
        } else {
          toDateControl?.setErrors(null);
        }
        return null;
      } else {
        if (control === toDateControl) {
          fromDateControl?.setErrors({ invalidDateRange: true });
        } else {
          toDateControl?.setErrors({ invalidDateRange: true });
        }
        return { invalidDateRange: true };
      }
    }

    return null;
  };

  onRowClicked(row) {
    this.mailReviewService.setSearchParams({
      sdrId: this.data.sdr,
      email: row.emailAddress,
      clientId: this.data.client,
    });
    this.mailReviewService.setClickedEmail(row.emailAddress);
    this.dialogRef.close({ selectedLeadEmail: row.email });
  }

  onSubmitSearch() {
    if (this.isValid && this.data.sdr) {
      this.isLoading = true;
      const form = this.form.getRawValue();

      const rangeFromDate = new Date(form.fromDate).toISOString().split('T')[0];
      const rangeToDate = new Date(form.toDate).toISOString().split('T')[0];

      const leadToBulkDto: LeadToBulkDto = {
        userId: this.data.sdr,
        rangeFrom: rangeFromDate,
        rangeTo: rangeToDate,
        filters: {
          emailAddress: form.email?.trim(),
          firstName: form.firstName,
          lastName: form.lastName,
          orCondition: true,
        },
      };

      this.subscriptions.add(
        this.leadsToBulkService
          .searchLeadsToBulk(leadToBulkDto)
          .pipe(
            takeUntil(this.destroy$),
            finalize(() => (this.isLoading = false)),
          )
          .subscribe({
            next: (response) => {
              this.searchResultsData = new MatTableDataSource(response || []);
              this.noResultsMessage = response?.length
                ? ''
                : "We couldn't find any results matching the search criteria";
            },
            error: () => (this.noResultsMessage = 'An Error occurred while executing search'),
          }),
      );
    }
  }
}

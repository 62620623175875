import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TableFilter, TableFilterKeyChangedEvent } from '../../model/TableFilter';

@Component({
    selector: 'app-table-actions',
    templateUrl: './table-actions.component.html',
    styleUrls: ['./table-actions.component.scss'],
    standalone: false
})
export class TableActionsComponent {
  @ViewChild('filter', { static: false }) public filter: ElementRef;

  @Input() public showSelectAll: boolean = true;
  @Input() public showSelectAllAction: boolean = false;
  @Input() public selectAllActionIcon: string = '';
  @Input() public selectAllActionLabel: string = '';
  @Input() public selectAllActionDisabled: boolean = false;
  @Input() public showFilter: boolean = true;
  @Input() public showFilterKeyDropdown: boolean = false;
  @Input() public filters: TableFilter[] = [];
  @Input() public allItensSelected: boolean = false;

  @Output() public selectAllEvent = new EventEmitter<boolean>();
  @Output() public selectAllActionEvent = new EventEmitter<void>();
  @Output() public filterUserInputEvent = new EventEmitter<string>();
  @Output() public filterKeyChangedEvent = new EventEmitter<TableFilterKeyChangedEvent>();

  public handleSelectAllEvent(selectAll: boolean): void {
    this.selectAllEvent.emit(selectAll);
  }

  public handleSelectAllActionEvent(): void {
    this.selectAllActionEvent.emit();
  }

  public handleFilterUserInputEvent(userInput: string): void {
    this.filterUserInputEvent.emit(userInput);
  }

  public handleFilterKeyChangedEvent(selectedFilterKey: string): void {
    this.filterKeyChangedEvent.emit({
      newKey: selectedFilterKey,
      currentFilter: this.filter.nativeElement.value ?? '',
    });
  }

  public resetFilterField(): void {
    this.filter.nativeElement.value = '';
  }
}

<div
  [ngClass]="{
    success: notification.type === notificationType.Success,
    error: notification.type === notificationType.Error,
    warning: notification.type === notificationType.Warning,
    inprogress: notification.undoClassifyQueued || notification.undoSendQueued
  }"
  class="notification-overlay"
>
  <div class="notification-item">
    <div class="notification-time-wrapper">
      <div
        [ngSwitch]="notification.type"
        class="notification-icon-wrapper"
      >
        <mat-icon
          *ngSwitchCase="notificationType.Success"
          class="notif-type-icon"
          >check_circle</mat-icon
        >
        <mat-icon
          *ngSwitchCase="notificationType.Error"
          class="notif-type-icon"
          >error</mat-icon
        >
        <mat-icon
          *ngSwitchCase="notificationType.Warning"
          class="notif-type-icon"
          >add_alert</mat-icon
        >
      </div>
      <span
        style="color: black; font-size: 10px"
        class="notification-time"
      >
        {{ notification.time | date: 'hh:mm a' }}
      </span>
    </div>
    <div class="notification-recipients">
      <span
        style="color: black; font-size: 15px"
        [innerHTML]="notification.message | sanitizeHtml"
      ></span>
      <span style="color: black; font-size: 15px; font-weight: 300">{{ notification.placeholderInfo1 }} </span>
      <span class="notification-recipients">{{ getNotificationRecipients() }} </span>
      <span
        style="color: black; font-size: 15px; font-weight: 300; font-style: italic"
        *ngIf="lifetime !== -1"
      >
        Auto-dismissing in: {{ lifetime }}
      </span>
    </div>
    <div class="notification-actions-wrapper">
      <div class="notification-actions-buttons">
        <button
          mat-icon-button
          (click)="undoClassifyAction()"
          *ngIf="notification.undoClassifyAction"
          matTooltip="Undo"
          [disabled]="notification.undoClassifyQueued"
          [ngStyle]="{ color: notification.undoColor }"
          id="notification-undo-classify-btn"
        >
          <mat-icon>undo</mat-icon>
        </button>
        <button
          mat-icon-button
          (click)="undoSendAction()"
          *ngIf="notification.undoSendAction"
          matTooltip="Undo"
          [disabled]="notification.undoSendQueued"
          [ngStyle]="{ color: notification.undoColor }"
          id="notification-undo-send-btn"
        >
          <mat-icon>undo</mat-icon>
        </button>
        <button
          mat-icon-button
          (click)="debug()"
          *ngIf="notification.debugAction"
          matTooltip="Clipboard Debug"
          id="notification-copy-debug-btn"
        >
          <mat-icon>bug_report</mat-icon>
        </button>
      </div>
    </div>

    <div class="notification-close">
      <div class="notification-close-icon">
        <mat-icon
          (click)="clear()"
          matTooltip="Close"
          class="close-display"
          id="notification-close-btn"
          >close</mat-icon
        >
      </div>
    </div>
  </div>
</div>

import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { GetLocationDto } from '../../../../model/catalog/get-location.dto';
import { Location } from '../../../../model/catalog/location.model';
import { ContactType } from '../../../../model/common/contact-type.enum';
import { Pattern } from '../../../../model/configuration/pattern.model';
import { Lead } from '../../../../model/lead/lead.model';
import { CatalogService } from '../../../../services/catalog.service';
import { ConfigurationService } from '../../../../services/configuration.service';
import { LeadService } from '../../../../services/lead.service';
import { SnackBarService } from '../../../../services/snackbar/snackbar.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() addContact: EventEmitter<Lead> = new EventEmitter();

  contactForm: FormGroup;
  contactTypes = ContactType;
  countries: Location[] = [];
  states: Location[] = [];
  cities: Location[] = [];
  adminArea1: Location[] = [];
  adminArea2: Location[] = [];
  patterns: Pattern[] = [];
  private destroy$ = new Subject<boolean>();

  constructor(
    private fb: FormBuilder,
    private catalogService: CatalogService,
    private configurationService: ConfigurationService,
    private leadService: LeadService,
    private snackBarService: SnackBarService,
  ) {
    this.createForm();
    this.getCountries();
    this.getPatterns();
  }

  ngOnInit(): void {
    this.contactForm
      .get('linkedInUrl')
      .valueChanges.pipe(debounceTime(1000))
      .subscribe((value) => {
        if (value) {
          const formattedValue = `/${value.replace(/^\/|\/$/g, '')}`;

          if (formattedValue !== value) {
            this.contactForm.get('linkedInUrl').setValue(formattedValue, { emitEvent: false });
          }
        }

        this.updateOrigFullNameValidators();
      });

    this.leadService.saveCalled$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.createContact();
    });

    this.contactForm.statusChanges.subscribe((status) => {
      const isValid = status === 'VALID';
      this.leadService.setIsValid(isValid);
    });
  }

  ngAfterViewInit(): void {
    this.contactForm.get('country').valueChanges.subscribe(() => {
      this.contactForm.get('adminArea1').reset();
      this.contactForm.get('adminArea2').reset();
      this.getStates();
    });
    this.contactForm.get('adminArea1').valueChanges.subscribe(() => {
      this.contactForm.get('adminArea2').reset();
      this.getCities();
    });

    this.contactForm
      .get('emailAddress')
      .valueChanges.pipe(distinctUntilChanged(), debounceTime(1000))
      .subscribe((email) => {
        if (email && this.contactForm.get('emailAddress').valid) {
          this.validateCorporateEmail(email);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  get contactTypeOptions() {
    return Object.keys(this.contactTypes).filter((key) => !isNaN(Number(key)));
  }

  get isValid(): boolean {
    return this.contactForm.valid;
  }

  createContact() {
    if (this.contactForm.valid) {
      const contactTypeId = parseInt(this.contactForm.get('contactTypeId')?.value);
      const { country, adminArea1, adminArea2 } = this.contactForm.getRawValue();
      this.addContact.emit({
        ...this.contactForm.getRawValue(),
        contactTypeId,
        country: country.name,
        adminArea1: adminArea1?.name || null,
        adminArea2: adminArea2?.name || null,
      });
    }
  }

  createForm() {
    this.contactForm = this.fb.group({
      emailAddress: ['', [Validators.required, Validators.pattern('^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      lnCompanyId: [{ value: null, disabled: true }],
      linkedInCompanyUrl: [{ value: '', disabled: true }],
      contactTypeId: [null, Validators.required],
      linkedInUrl: ['', Validators.pattern(/^\/in\/[a-zA-Z0-9_-]+$/)],
      origFullName: [''],
      firstName: ['', Validators.required],
      middleName: [''],
      lastName: ['', Validators.required],
      jobTitle: ['', Validators.required],
      country: [null, Validators.required],
      adminArea1: [null],
      adminArea2: [null],
      patternId: [null, Validators.required],
      kwKey: [''],
      phones: [
        '',
        Validators.pattern(
          /^(\+?\d{1,4}[\s.-]?)?\(?\d{2,4}\)?[\s.-]?\d{3,4}[\s.-]?\d{3,4}( x\d{1,5})?(, (\+?\d{1,4}[\s.-]?)?\(?\d{2,4}\)?[\s.-]?\d{3,4}[\s.-]?\d{3,4}( x\d{1,5})?)*$/,
        ),
      ],
    });
  }

  getCountries() {
    this.contactForm.get('adminArea1').disable();
    this.contactForm.get('adminArea2').disable();

    const locationDto: GetLocationDto = {};
    this.catalogService
      .getLocations(locationDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (locations) => {
          this.countries = locations || [];
          if (this.countries.length) {
            this.contactForm.get('country').enable();
          } else {
            this.contactForm.get('country').disable();
          }
        },
        error: () => {
          this.snackBarService.showError('An error occurred while getting countries');
        },
      });
  }

  getStates() {
    const countryId = this.contactForm.get('country')?.value?.id;

    if (!countryId) return;

    this.contactForm.get('adminArea2').disable();
    const locationDto: GetLocationDto = {
      countryId,
    };

    this.catalogService
      .getLocations(locationDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (locations) => {
          this.states = locations || [];
          this.states.length
            ? this.contactForm.get('adminArea1').enable()
            : this.contactForm.get('adminArea1').disable();
        },
        error: () => {
          this.snackBarService.showError('An error occurred while getting states');
        },
      });
  }

  getCities() {
    const adminArea01 = this.contactForm.get('adminArea1')?.value?.id;

    if (!adminArea01) return;

    const locationDto: GetLocationDto = {
      adminArea01,
    };
    this.catalogService
      .getLocations(locationDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (locations) => {
          this.cities = locations || [];
          this.cities.length
            ? this.contactForm.get('adminArea2').enable()
            : this.contactForm.get('adminArea2').disable();
        },
        error: () => {
          this.snackBarService.showError('An error occurred while getting cities');
        },
      });
  }

  getPatterns() {
    this.configurationService
      .getPatterns()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (patterns) => {
          this.patterns = patterns || [];
        },
        error: () => {
          this.snackBarService.showError('An error occurred while getting patterns');
        },
      });
  }

  updateOrigFullNameValidators() {
    const linkedInUrl = this.contactForm.get('linkedInUrl');
    const origFullName = this.contactForm.get('origFullName');

    linkedInUrl.value && linkedInUrl.valid
      ? origFullName.setValidators([Validators.required])
      : origFullName.clearValidators();

    origFullName.updateValueAndValidity();
  }

  validateCorporateEmail(email: string) {
    this.leadService
      .validateCorporateEmail(email)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (corporateCompany) => {
          if (corporateCompany) {
            this.contactForm.get('lnCompanyId').setValue(corporateCompany.linkedInId);
            this.contactForm.get('linkedInCompanyUrl').setValue(corporateCompany.linkedInUrl);
            this.contactForm.get('contactTypeId').setValue(ContactType.Corporate.toString());
            this.contactForm.get('contactTypeId').disable();
          }
        },
        error: () => {
          this.snackBarService.showError('An error occurred while validating corporate email');
        },
      });
  }
}

<form
  class="contact-form"
  [formGroup]="contactForm"
>
  <mat-form-field
    appearance="outline"
    class="form-field-5"
    subscriptSizing="dynamic"
  >
    <mat-label>First Name</mat-label>
    <input
      matInput
      formControlName="firstName"
    />
  </mat-form-field>
  <mat-form-field
    appearance="outline"
    class="form-field-5"
    subscriptSizing="dynamic"
  >
    <mat-label>Middle Name</mat-label>
    <input
      matInput
      formControlName="middleName"
    />
  </mat-form-field>
  <mat-form-field
    appearance="outline"
    class="form-field-5"
    subscriptSizing="dynamic"
  >
    <mat-label>Last Name</mat-label>
    <input
      matInput
      formControlName="lastName"
    />
  </mat-form-field>
  <mat-form-field
    appearance="outline"
    class="form-field-5"
    subscriptSizing="dynamic"
  >
    <mat-label>Email Address</mat-label>
    <input
      matInput
      formControlName="emailAddress"
    />
    <mat-error
      *ngIf="
        contactForm.get('emailAddress').hasError('pattern') &&
        contactForm.get('emailAddress').touched &&
        contactForm.get('emailAddress').value
      "
    >
      Email does not meet the requirements
    </mat-error>
  </mat-form-field>
  <mat-form-field
    appearance="outline"
    class="contact-form__small-field form-field-5"
    subscriptSizing="dynamic"
  >
    <mat-label>LinkedIn Company ID</mat-label>
    <input
      matInput
      formControlName="lnCompanyId"
    />
  </mat-form-field>
  <mat-form-field
    appearance="outline"
    class="form-field-5"
    subscriptSizing="dynamic"
  >
    <mat-label>LinkedIn Company Url</mat-label>
    <input
      matInput
      formControlName="linkedInCompanyUrl"
    />
  </mat-form-field>
  <mat-form-field
    appearance="outline"
    class="form-field-5"
    subscriptSizing="dynamic"
  >
    <mat-label>LinkedInUrl</mat-label>
    <input
      matInput
      formControlName="linkedInUrl"
    />
    <mat-error
      *ngIf="
        contactForm.get('linkedInUrl').hasError('pattern') &&
        contactForm.get('linkedInUrl').touched &&
        contactForm.get('linkedInUrl').value
      "
    >
      * Format {{ '{' }} /in/ {{ '}' }} {{ '{' }} text {{ '}' }}
    </mat-error>
  </mat-form-field>
  <mat-form-field
    appearance="outline"
    class="contact-form__small-field form-field-5"
    subscriptSizing="dynamic"
  >
    <mat-label>Original Full Name</mat-label>
    <input
      matInput
      formControlName="origFullName"
    />
  </mat-form-field>
  <mat-form-field
    appearance="outline"
    class="form-field-5"
    subscriptSizing="dynamic"
  >
    <mat-label>Title</mat-label>
    <input
      matInput
      formControlName="jobTitle"
    />
  </mat-form-field>
  <mat-form-field
    appearance="outline"
    class="form-field-5"
    subscriptSizing="dynamic"
  >
    <mat-label>Contact Type</mat-label>
    <mat-select formControlName="contactTypeId">
      <mat-option
        [value]="typeId"
        *ngFor="let typeId of contactTypeOptions"
      >
        {{ contactTypes[typeId] }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field
    appearance="outline"
    class="form-field-5"
    subscriptSizing="dynamic"
  >
    <mat-label>Country</mat-label>
    <mat-select formControlName="country">
      <mat-option
        *ngFor="let country of countries"
        [value]="country"
      >
        {{ country?.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field
    appearance="outline"
    class="form-field-5"
    subscriptSizing="dynamic"
  >
    <mat-label>Admin Area 1</mat-label>
    <mat-select formControlName="adminArea1">
      <mat-option
        [value]="state"
        *ngFor="let state of states"
      >
        {{ state.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field
    appearance="outline"
    class="form-field-5"
    subscriptSizing="dynamic"
  >
    <mat-label>Admin Area 2</mat-label>
    <mat-select formControlName="adminArea2">
      <mat-option
        [value]="city"
        *ngFor="let city of cities"
      >
        {{ city.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field
    appearance="outline"
    class="form-field-5"
    subscriptSizing="dynamic"
  >
    <mat-label>Pattern</mat-label>
    <mat-select formControlName="patternId">
      <mat-option
        [value]="pattern.patternId"
        *ngFor="let pattern of patterns"
      >
        {{ pattern.description }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field
    appearance="outline"
    class="contact-form__small-field form-field-5"
    subscriptSizing="dynamic"
  >
    <mat-label>KW Key</mat-label>
    <input
      matInput
      formControlName="kwKey"
    />
  </mat-form-field>
  <mat-form-field
    appearance="outline"
    class="form-field-5"
  >
    <mat-label>Phones</mat-label>
    <input
      matInput
      formControlName="phones"
    />
    <mat-error
      *ngIf="
        contactForm.get('phones').hasError('pattern') &&
        contactForm.get('phones').touched &&
        contactForm.get('phones').value
      "
    >
      * Format {{ '{' }}Phone{{ '}' }} x {{ '{' }}Extension{{ '}' }}, {{ '{' }}Phone{{ '}' }}
    </mat-error>
  </mat-form-field>
</form>

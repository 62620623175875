<div class="open-inquiry">
  <div class="open-inquiry__top-bar">
    <div class="open-inquiry__title">{{ title }}</div>
    <mat-icon
      mat-dialog-close
      class="open-inquiry__close-btn"
      >close</mat-icon
    >
  </div>
  <div class="open-inquiry__content">
    <app-inquiry-selection
      *ngIf="isFeed"
      class="open-inquiry__inquiry-selection"
    ></app-inquiry-selection>

    <div
      class="open-inquiry__search-container"
      [ngClass]="{ 'open-inquiry__search-container--feed': isFeed }"
    >
      <app-inquiry-search
        *ngIf="isFeed"
        class="open-inquiry__inquiry-search"
        [initialSelectedSdr]="sdr"
        [initialSelectedClient]="client"
        [initialEmail]="email"
        [initialDate]="date"
      ></app-inquiry-search>

      <app-view-lead-toolbar
        *ngIf="!isFeed"
        [selectedHistoryRow]="selectedHistoryRow"
        class="open-inquiry__view-lead-toolbar"
      ></app-view-lead-toolbar>
      <div class="open-inquiry__inquiry-detail-container">
        <app-inquiry-detail></app-inquiry-detail>
        <app-inquiry-history
          *ngIf="showHistory"
          (setSelectedHistoryRow)="onSelectHistoryRow($event)"
        ></app-inquiry-history>
      </div>
    </div>
  </div>
</div>

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-mail-classifier-toolbar',
  templateUrl: './mail-classifier-toolbar.component.html',
  styleUrls: ['./mail-classifier-toolbar.component.scss'],
})
export class MailClassifierToolbarComponent {
  @Input() pendingCount = 0;
  @Input() isNextDisabled = false;
  @Input() isPrevDisabled = false;
  @Output() navigateNext: EventEmitter<null> = new EventEmitter();
  @Output() navigatePrev: EventEmitter<null> = new EventEmitter();
  @Output() refreshMails: EventEmitter<null> = new EventEmitter();

  constructor() {}

  onMoveNext() {
    this.navigateNext.emit();
  }

  onMovePrevious() {
    this.navigatePrev.emit();
  }

  onRefresh() {
    this.refreshMails.emit();
  }
}

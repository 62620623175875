<div
  *ngIf="!loadingToken"
  class="mail-manager"
>
  <div class="mail-manager__progress-bar">
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="loading"
    ></mat-progress-bar>
  </div>
  <div class="mail-manager__content">
    <div
      class="mail-manager__mail-feed"
      [ngClass]="{
        'mail-manager__mail-details--hidden': !showFeed && isSmallScreen
      }"
    >
      <app-mails-feed></app-mails-feed>
    </div>
    <div
      class="mail-manager__mail-details"
      [ngClass]="{
        'mail-manager__mail-details--hidden': showFeed && isSmallScreen
      }"
    >
      <app-mail-details-container></app-mail-details-container>
    </div>
  </div>
</div>

<div
  *ngIf="showMinComposer"
  class="mail-manager__mail-composer-min"
>
  <app-mail-composer-min></app-mail-composer-min>
</div>

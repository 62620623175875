import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { delay, finalize } from 'rxjs/operators';
import { UIGuid } from '../helpers/ui-guid';
import { ClassificationStatusEnum } from '../model/common/classification-status.enum';
import { ResponseStatus } from '../model/common/generic-response';
import { ServiceInfo } from '../model/common/service-info.model';
import { ClassificationStatusData } from '../model/mail-classifier/classification-status-data.model';
import { Notification } from '../model/Notification';
import { NotificationCategory } from '../model/NotificationCategory';
import { NotificationDismissType } from '../model/NotificationDismissType';
import { NotificationType } from '../model/NotificationType';
import { ClassifyMessageDto } from '../model/sdr-conversations/classify-message.dto';
import { ProcessMessageConversionDto } from '../model/sdr-conversations/process-message-conversion.dto';
import { Conversation } from '../model/sdr-conversations/sdrs-conversations.model';
import { NotificationsService } from '../services/notifications.service';
import { ActionService } from './action.service';
import { LoadingStateService } from './base/loading-state.service';
import { FeedService } from './feed.service';
import { HelperService } from './helper.service';
import { InteractionService } from './interaction.service';
import { LogService } from './log.service';
import { SDAuthService } from './sd-auth.service';
import { SdrConversationsService } from './sdr-conversations.service';
import { SnackBarService } from './snackbar/snackbar.service';
import { InteractionRequestDto } from '../model/interaction/interaction-request.dto';

const UNDO_STANDBY_TIME = 5 * 1000;

@Injectable({
  providedIn: 'root',
})
export class ClassifyMessageService {
  classification: ClassificationStatusData = null;
  selectedConversation: Conversation = null;
  private userEmail = '';

  constructor(
    private actionsService: ActionService,
    private authService: SDAuthService,
    private feedService: FeedService,
    private helperService: HelperService,
    private interactionService: InteractionService,
    private loadingStateService: LoadingStateService,
    private logService: LogService,
    private notificationsService: NotificationsService,
    private snackBarService: SnackBarService,
    private sdrConversationService: SdrConversationsService,
  ) {
    this.authService.getCurrentUserEmail().then((email) => {
      this.userEmail = email;
    });

    this.sdrConversationService.selectedConversation$.subscribe((selectedConversation) => {
      this.selectedConversation = selectedConversation ? { ...selectedConversation } : null;
    });
  }

  classifyMessage(
    serviceInfo: ServiceInfo,
    dto: ClassifyMessageDto | ProcessMessageConversionDto | InteractionRequestDto,
  ) {
    const { conversationId } = this.selectedConversation;
    const { sdrName } = this.sdrConversationService.findSdrdByConversationId(this.selectedConversation?.conversationId);
    const { classificationId, classificationName } = this.classification;
    this.feedService.registerLastClassified([conversationId]);
    const actionIdentifier = conversationId + '_classify_' + UIGuid.newGuid() + '_' + new Date().getTime().toString();
    let successClassifyClipboardInfo = `
        type: Success
        time: ${this.helperService.formatAMPM(new Date())}
        message: Mail classified as ${classificationName}
        sdr: ${sdrName}
        targetFolder: ${classificationName}
        conversationId: ${conversationId}
      `;
    let successClassifyNotification = new Notification({
      id: 'successClassify_' + classificationId,
      type: NotificationType.Success,
      category: NotificationCategory.SdrConversation,
      created_dt: new Date().toString(),
      message: "Mail classified as <span style='font-weight: bold;'>" + classificationName + '</span>',
      placeholderInfo1: '',
      placeholderInfo2: sdrName,
      undoSendAction: false,
      undoActionReady: false,
      undoColor: 'black',
      undoClassifyAction: true,
      debugAction: true,
      debugClipboardInfo: successClassifyClipboardInfo,
      undoClassifySnackbarMessage: 'Classify action on ' + classificationName + ' reverted successfully',
      undoSendSnackbarMessage: '',
      undoClassifyActionObject: { actionId: classificationId },
      dismissType: NotificationDismissType.AutoDismiss,
      actionIdentifier,
    });
    this.notificationsService.addNotificationToFeed(successClassifyNotification);
    this.actionsService.addActionItem(actionIdentifier, {});
    this.logService.submitLog({
      tags: [
        'ActionStarted',
        'Classify',
        successClassifyNotification.id,
        successClassifyNotification.placeholderInfo1,
        successClassifyNotification.placeholderInfo2,
      ],
      body: 'ActionStarted - ' + successClassifyNotification.message,
    });
    of(true)
      .pipe(delay(UNDO_STANDBY_TIME))
      .subscribe(() => {
        if (!this.actionsService.isUndone(actionIdentifier)) {
          console.log('Classifying Email: ', { conversationId: conversationId, classificationId: classificationId });
          this.loadingStateService.setLoadingState(true);
          this[serviceInfo.serviceInstance]
            [serviceInfo.functionName](dto)
            .pipe(finalize(() => this.loadingStateService.setLoadingState(false)))
            .subscribe({
              next: (response) => {
                if (response.status === ResponseStatus.Error) {
                  const message = response?.message || 'An error occurred while trying to classify';
                  this.handledErrorNotification(message);
                  return;
                }
                this.snackBarService.showSuccess(response?.message || 'Message classified successfully');
                this.interactionService.setInteractionsUpdated(true);

                this.sdrConversationService.updateConversation(classificationName);
                this.logService.submitLog({
                  tags: [
                    'ActionCompleted',
                    'Classify',
                    successClassifyNotification.id,
                    successClassifyNotification.placeholderInfo1,
                    successClassifyNotification.placeholderInfo2,
                  ],
                  body: 'ActionCompleted - ' + successClassifyNotification.message,
                });
              },
              error: (error: HttpErrorResponse) => {
                this.unhandledErrorNotification(error);
              },
            });
        }
        setTimeout(() => {
          this.feedService.deleteFromLastClassified(conversationId);
        }, 15000);
        this.actionsService.removeActionItem(actionIdentifier);
      });
  }

  unhandledErrorNotification(error: HttpErrorResponse) {
    const { conversationId } = this.selectedConversation;
    const { sdrName } = this.sdrConversationService.findSdrdByConversationId(conversationId);
    const { classificationName } = this.classification;
    let typeOfTheNotification = NotificationType.Error;
    const errorMessage = error.error.Message || 'An unhandled error has occurred while trying to classify';
    let errorClassifyClipboardInfo = `
      type: Error
      time: ${this.helperService.formatAMPM(new Date())}
      message: Error classifying message as ${classificationName} ${errorMessage}
      sdr: ${sdrName}
      targetFolder: ${classificationName}
      conversationId: ${conversationId}
      currentUser: ${this.userEmail}
      exceptionDetail: ${JSON.stringify(error?.error)}
    `;
    if (error?.error?.StatusCode === 405) {
      typeOfTheNotification = NotificationType.Warning;
    }
    this.showErrroNotification(errorMessage, errorClassifyClipboardInfo, typeOfTheNotification);
  }

  handledErrorNotification(errorMessage: string) {
    const { conversationId } = this.selectedConversation;
    const { sdrName } = this.sdrConversationService.findSdrdByConversationId(conversationId);
    const { classificationName } = this.classification;
    let errorClassifyClipboardInfo = `
      type: Error
      time: ${this.helperService.formatAMPM(new Date())}
      message: Error classifying message as ${classificationName} ${errorMessage}
      sdr: ${sdrName}
      targetFolder: ${classificationName}
      conversationId: ${conversationId}
      currentUser: ${this.userEmail}
    `;
    this.showErrroNotification(errorMessage, errorClassifyClipboardInfo, NotificationType.Error);
  }

  showErrroNotification(errorMessage: string, errorClassifyClipboardInfo, notificationType: NotificationType) {
    const { conversationId } = this.selectedConversation;
    const { sdrName } = this.sdrConversationService.findSdrdByConversationId(conversationId);
    const { classificationId, classificationName } = this.classification;
    let errorClassifyNotification = new Notification({
      id: 'errorClassify_' + classificationId + '_' + conversationId,
      type: notificationType,
      category: NotificationCategory.SdrConversation,
      created_dt: new Date().toString(),
      message: `Error classifying message as <span style="font-weight: bold;">${classificationName}</span> ${errorMessage}`,
      placeholderInfo1: '',
      placeholderInfo2: sdrName,
      undoSendAction: false,
      undoActionReady: true,
      undoSendReady: false,
      undoColor: 'black',
      undoClassifyAction: false,
      debugAction: true,
      debugClipboardInfo: errorClassifyClipboardInfo,
      undoClassifySnackbarMessage: '',
      undoSendSnackbarMessage: '',
      undoClassifyActionObject: null,
      dismissType: NotificationDismissType.NoDismissable,
    });
    this.notificationsService.addNotificationToFeed(errorClassifyNotification);
  }
}

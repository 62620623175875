import { Component, OnInit } from '@angular/core';
import { IInquiryLead, InquiryLead } from 'src/app/model/InquiryLead';
import { InquiryService } from 'src/app/services/inquiry.service';

@Component({
    selector: 'app-inquiry-detail',
    templateUrl: './inquiry-detail.component.html',
    styleUrls: ['./inquiry-detail.component.scss'],
    standalone: false
})
export class InquiryDetailComponent implements OnInit {
  lead: IInquiryLead;
  constructor(private inquiryService: InquiryService) { }

  ngOnInit() {
    this.inquiryService.inquiryLeads.subscribe((inquiryLeads) => {
      if (inquiryLeads?.length) {
        this.lead = inquiryLeads.filter(il => il.selected)[0];
      } else {
        this.lead = new InquiryLead({});
      }
    });
  }

}

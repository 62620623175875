<div class="search-bar">
  <mat-accordion displayMode="flat">
    <mat-expansion-panel
      ngClass="search-bar__expansion-panel"
      [expanded]="!collapseSearch"
    >
      <mat-expansion-panel-header>
        <mat-panel-title><p class="search-bar__title">Search Interactions</p></mat-panel-title>
      </mat-expansion-panel-header>
      <form
        (submit)="onSubmitSearch()"
        [formGroup]="searchForm"
        class="search-bar__form"
      >
        <div class="search-bar__fields-container">
          <mat-form-field
            appearance="outline"
            class="form-field-5"
            subscriptSizing="dynamic"
          >
            <mat-label>Select Client</mat-label>
            <input
              matInput
              type="text"
              formControlName="client"
              [matAutocomplete]="autoClient"
              (click)="onClickSelectInput('client')"
            />
            <mat-autocomplete
              (optionSelected)="onChangeClient($event.option.value)"
              #autoClient="matAutocomplete"
              [displayWith]="getClientName.bind(this)"
            >
              <mat-option
                *ngFor="let client of filteredClientNames | async"
                [value]="client.clientId"
              >
                <div class="multi-line-text">{{ client.name }} | {{ client.clientId }}</div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field
            appearance="outline"
            class="form-field-5"
            subscriptSizing="dynamic"
          >
            <mat-label>Select SDR</mat-label>
            <input
              matInput
              type="text"
              formControlName="sdr"
              [matAutocomplete]="autoSdr"
              (click)="onClickSelectInput('sdr')"
            />
            <mat-autocomplete
              disableRipple
              #autoSdr="matAutocomplete"
              [displayWith]="getSDREmail.bind(this)"
            >
              <mat-option
                *ngFor="let sdr of filteredSdrs | async"
                [value]="sdr.id"
              >
                <div class="multi-line-text">{{ sdr.email }} | {{ sdr.id }}</div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field
            appearance="outline"
            class="form-field-5"
            subscriptSizing="dynamic"
          >
            <mat-label>Email</mat-label>
            <input
              matInput
              formControlName="email"
            />
            <mat-error *ngIf="email.invalid && email.errors?.['minlength']">
              Email must be at least 3 characters long.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="search-bar__search-buttons-container">
          <button
            mat-stroked-button
            type="button"
            color="primary"
            (click)="onAdvanceSearch()"
            [disabled]="isLoading || !searchForm.get('sdr').value"
            class="search-bar__advance-search-btn"
          >
            ADVANCED SEARCH
          </button>
          <button
            class="search-bar__search-btn"
            mat-stroked-button
            color="primary"
            type="submit"
            [disabled]="!isValid || isLoading"
          >
            <span *ngIf="!isLoading">Search</span>
            <mat-spinner
              [ngClass]="'search-bar__spinner'"
              *ngIf="isLoading"
              diameter="20"
            ></mat-spinner>
          </button>
        </div>
      </form>
    </mat-expansion-panel>
  </mat-accordion>
</div>

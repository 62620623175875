import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MessageAttachmentService } from 'src/app/services/message-attachment.service';

@Component({
    selector: 'app-attachment',
    templateUrl: './attachment.component.html',
    styleUrls: ['./attachment.component.scss'],
    standalone: false
})
export class AttachmentComponent {
  @Input() attachment;
  @Input() isRemovable: false;
  @Output() clickEvt = new EventEmitter();
  @Output() removeEvt = new EventEmitter();
  constructor(public messageAttachmentService: MessageAttachmentService) {}

  onClickAttachment() {
    this.clickEvt.emit();
  }

  onRemoveAttachment(event) {
    event.stopPropagation();
    this.removeEvt.emit();
  }
}

<div class="login">
  <div class="login__left-side">
    <div class="login__logo">
      <img
        src="assets/images/logo.png"
        alt="WinDifferent"
      />
    </div>
    <div class="login__form-container">
      <div class="login__title-container">
        <p class="login__page-name">Sign In</p>
        <p class="login__module-name">Operations Module</p>
      </div>
      <form
        [formGroup]="form"
        (submit)="login()"
      >
        <mat-form-field appearance="outline">
          <mat-label><mat-icon>person</mat-icon>Email</mat-label>
          <input
            type="text"
            matInput
            formControlName="email"
            (click)="loginError = ''"
          />
          <mat-error *ngIf="form.get('email').invalid">This field is required</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label><mat-icon>lock</mat-icon>Password</mat-label>
          <input
            type="password"
            matInput
            formControlName="password"
            (click)="loginError = ''"
            (keydown.enter)="login()"
          />
          <mat-error *ngIf="form.get('password').invalid">This field is required</mat-error>
        </mat-form-field>

        <div class="login__error-container">
          <span
            *ngIf="loginError"
            class="login__error-message"
          >
            {{ loginError }}
          </span>
        </div>
        <a
          class="login__forgot-password-btn"
          [routerLink]="['/reset-password']"
          >Forgot your password?</a
        >
        <div class="login__btn-container">
          <button
            *ngIf="!loading"
            mat-flat-button
            color="primary"
            class="login__login-btn"
            type="submit"
          >
            Sign In
          </button>
          <mat-spinner
            strokeWidth="5"
            diameter="35"
            *ngIf="loading"
          ></mat-spinner>
        </div>
      </form>
    </div>
  </div>
  <img
    src="assets/images/login_image.svg"
    class="login__right-img"
    alt="Operations Module Login"
  />
</div>

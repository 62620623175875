<div class="send-messagge">
  <mat-form-field
    subscriptSizing="dynamic"
    class="send-messagge__field-message"
    id="form-field-message"
  >
    <textarea
      matInput
      placeholder="Type a message"
      [formControl]="message"
    ></textarea>
  </mat-form-field>
  <button
    mat-icon-button
    color="primary"
    [disabled]="!message.value"
    (click)="sendMessage()"
  >
    <mat-icon *ngIf="!isSendingMessage">send</mat-icon>
    <mat-spinner
      *ngIf="isSendingMessage"
      diameter="20"
    ></mat-spinner>
  </button>
</div>

<div class="bot-setup">
  <div class="bot-setup__container bot-setup__container--mobile">
    <div class="bot-setup__title"><span>Bot Setup</span></div>
    <div class="bot-setup__info-text">
      <span>Download Report</span>
    </div>
    <div class="bot-setup__date-picker-container">
      <mat-form-field
        class="bot-setup__date-form-field"
        appearance="outline"
        id="bot-setup-date-from-mat-form-field"
      >
        <mat-label>Date From</mat-label>
        <input
          matInput
          [matDatepicker]="pickerFrom"
          [(ngModel)]="fromDate"
          #fromControl="ngModel"
          id="bot-setup-date-from-input"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="pickerFrom"
          id="bot-setup-date-from-mat-datepicker-toggle"
        ></mat-datepicker-toggle>
        <mat-datepicker #pickerFrom></mat-datepicker>
      </mat-form-field>

      <mat-form-field
        class="bot-setup__date-form-field"
        appearance="outline"
        id="bot-setup-date-to-mat-form-field"
      >
        <mat-label>Date To</mat-label>
        <input
          matInput
          [matDatepicker]="pickerTo"
          [(ngModel)]="toDate"
          #toControl="ngModel"
          id="bot-setup-date-to-input"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="pickerTo"
          id="bot-setup-date-to-mat-datepicker-toggle"
        ></mat-datepicker-toggle>
        <mat-datepicker #pickerTo></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="bot-setup__button-container">
      <button
        mat-raised-button
        color="primary"
        (click)="downloadSimultionReport()"
        [disabled]="!fromDate || !toDate || fromControl.invalid || toControl.invalid || fromDate > toDate"
        id="bot-setup-simulation-btn"
      >
        SIMULATION
      </button>
      <button
        mat-raised-button
        color="primary"
        (click)="downloadClassificationReport()"
        [disabled]="!fromDate || !toDate || fromControl.invalid || toControl.invalid || fromDate > toDate"
        id="bot-setup-classification-btn"
      >
        CLASSIFICATION
      </button>
    </div>
  </div>
</div>

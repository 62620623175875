import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { GenericResponse } from '../model/common/generic-response';
import { ProspectInfoDto } from '../model/common/prospect-info.dto';
import { ProspectInfo } from '../model/common/prospect-info.model';
import { ProcessConversionDto } from '../model/mail-classifier/process-conversion.dto';
import { UpdateConversionDto } from '../model/mail-classifier/update-conversion.dto';
import { ProcessMessageConversionDto } from '../model/sdr-conversations/process-message-conversion.dto';
import { UndoInteractionDto } from '../model/interaction/undo-interaction.dto';
import { ForwardedInteractionDto } from '../model/interaction/forwarded-interaction.dto';
import { Forwarder } from '../model/interaction/forwarder.model';
import { InteractionRequestDto } from '../model/interaction/interaction-request.dto';

@Injectable({
  providedIn: 'root',
})
export class InteractionService {
  private refreshInteractionsSubject = new BehaviorSubject<boolean>(false);
  private hasInteractionsSubject = new BehaviorSubject<boolean>(false);
  private forwardersSubject = new BehaviorSubject<Forwarder[]>(null);
  private jobPostingId = new BehaviorSubject<number>(null);

  constructor(private httpClient: HttpClient) {}

  get interactionsUpdated$() {
    return this.refreshInteractionsSubject.asObservable();
  }

  get hasInteractions$() {
    return this.hasInteractionsSubject.asObservable();
  }

  get forwarders$() {
    return this.forwardersSubject.asObservable();
  }

  get jobPostingId$() {
    return this.jobPostingId.getValue();
  }

  setJobPostingId(jobPostingId: number) {
    this.jobPostingId.next(jobPostingId);
  }

  setInteractionsUpdated(created: boolean) {
    this.refreshInteractionsSubject.next(created);
  }

  setHasInteractions(hasInteractions: boolean) {
    this.hasInteractionsSubject.next(hasInteractions);
  }

  setForwarders(hasForwarders: any[]) {
    this.forwardersSubject.next(hasForwarders);
  }

  checkInteractionExist(attr: { messageId: string; sdrId: number; statusIds: Array<number> }): Observable<boolean> {
    const url = `${environment.newApiBaseUrl}/interactions/check-interaction-exists`;

    return this.httpClient.post(url, attr).pipe(map((response: GenericResponse<boolean>) => response.data));
  }

  getFullProspectInfo(prospectInfoDto: ProspectInfoDto): Observable<ProspectInfo> {
    const url = `${environment.newApiBaseUrl}/interactions/get-full-prospect-info`;

    return this.httpClient
      .post(url, prospectInfoDto)
      .pipe(map((response: GenericResponse<ProspectInfo>) => response.data));
  }

  processConversion(processConversionDto: ProcessConversionDto): Observable<any> {
    const url = `${environment.newApiBaseUrl}/interactions/process-conversion`;

    return this.httpClient.post(url, processConversionDto);
  }

  updateConversion(updateConversionDto: UpdateConversionDto): Observable<any> {
    const url = `${environment.newApiBaseUrl}/interactions/update-conversion`;

    return this.httpClient.post(url, updateConversionDto);
  }

  processMessageConversion(processMessaggeConversionDto: ProcessMessageConversionDto): Observable<any> {
    const url = `${environment.newApiBaseUrl}/interactions/process-message-conversion`;

    return this.httpClient.post(url, processMessaggeConversionDto);
  }

  undoInteraction(interactionDto: UndoInteractionDto): Observable<boolean> {
    const url = `${environment.newApiBaseUrl}/interactions/undo-interaction`;

    return this.httpClient.post(url, interactionDto).pipe(map((response: GenericResponse<boolean>) => response.data));
  }

  forwardedInteraction(interactionDto: ForwardedInteractionDto): Observable<number> {
    const url = `${environment.newApiBaseUrl}/interactions/forward-email-reply`;

    return this.httpClient.post(url, interactionDto).pipe(map((response: GenericResponse<number>) => response.data));
  }

  getForwarders(contactId: number, sdrId: number): Observable<Forwarder[]> {
    const url = `${environment.newApiBaseUrl}/interactions/forwaders/${contactId}/${sdrId}`;

    return this.httpClient.get(url).pipe(map((response: GenericResponse<Forwarder[]>) => response.data));
  }

  toContactMessage(interaciontRequestDto: InteractionRequestDto): Observable<any> {
    const url = `${environment.newApiBaseUrl}/interactions/to-contact-message`;

    return this.httpClient
      .post(url, interaciontRequestDto)
      .pipe(map((response: GenericResponse<any>) => response.data));
  }
}

<div class="folders-sdrs-list-container">
  <mat-form-field
    class="form-field-5 field fx-100"
    subscriptSizing="dynamic"
    appearance="outline"
  >
    <mat-label>Client</mat-label>
    <input
      type="text"
      aria-label="Number"
      matInput
      [formControl]="clientFilterControl"
      [matAutocomplete]="clientsAuto1"
      [(ngModel)]="selectedClient"
    />
    <mat-autocomplete
      #clientsAuto1="matAutocomplete"
      [displayWith]="displayFn"
      (optionSelected)="updateClients()"
    >
      <mat-option
        *ngFor="let client of filteredClients | async"
        [value]="client"
      >
        {{ client }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <div class="add-sdrs-container">
    <mat-form-field
      class="form-field-5 field"
      appearance="outline"
      [ngClass]="{
        'mat-form-field-invalid ng-invalid': !sdrList.length && sdrControl.touched
      }"
      id="email-template-editor-SDRs-mat-form-field"
    >
      <mat-label>SDRs</mat-label>
      <mat-chip-grid #sdrChipList>
        <mat-chip-row
          *ngFor="let item of sdrList; let i = index"
          [color]="item.invalid ? 'warn' : ''"
          selected
          [removable]="removable"
          (removed)="removeSdrs(item); updateFilteredSdrs()"
        >
          {{ item.email }}
          <mat-icon
            matChipRemove
            *ngIf="removable"
            >cancel</mat-icon
          >
        </mat-chip-row>
        <input
          matInput
          [matChipInputFor]="sdrChipList"
          [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
          [matChipInputAddOnBlur]="true"
          (matChipInputTokenEnd)="addSdr($event)"
          [formControl]="sdrControl"
          [matAutocomplete]="sdrAuto"
          #sdrInput
          (input)="onInputChange($event)"
        />
      </mat-chip-grid>

      <mat-autocomplete
        #sdrAuto="matAutocomplete"
        [displayWith]="displayFn"
        (optionSelected)="updateSdrAuto(); updateFilteredSdrs()"
        id="email-template-editor-SDRs-mat-autocomplete"
      >
        <mat-option
          *ngFor="let sdr of filteredSdrs"
          [value]="sdr.email"
          id="email-template-editor-SDRs-mat-option"
        >
          {{ sdr.email }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <button
      mat-raised-button
      color="primary"
      (click)="showSelectedSdrs()"
      [disabled]="!isSdrValid()"
      class="field__button"
      id="folders-sdrs-add-btn"
    >
      <mat-icon style="margin-right: 5px">add_circle</mat-icon>ADD
    </button>
  </div>

  <div class="mat-elevation-z8 shrink">
    <div class="div-form-fields-container">
      <mat-form-field
        class="form-field-5 field-c"
        subscriptSizing="dynamic"
        appearance="outline"
      >
        <mat-label>Client</mat-label>
        <input
          type="text"
          aria-label="Number"
          matInput
          [formControl]="clientSearchFilterControl"
          [matAutocomplete]="clientsAuto"
          [(ngModel)]="selectedSearchClient"
        />
        <mat-autocomplete
          #clientsAuto="matAutocomplete"
          [displayWith]="displayFn"
          (optionSelected)="updateSearchClients()"
        >
          <mat-option
            *ngFor="let client of filteredSearchClients | async"
            [value]="client"
          >
            {{ client }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        class="form-field-5 search-sdr-field"
        subscriptSizing="dynamic"
      >
        <mat-label>Search SDR</mat-label>
        <input
          matInput
          [(ngModel)]="searchText"
          autocomplete="off"
          (keyup)="applySearch()"
          id="folders-sdrs-search-sdr-input"
        />
        <mat-icon
          matSuffix
          *ngIf="searchText"
          (click)="onClearSearch()"
          id="folders-sdrs-search-sdr-mat-icon-clear"
        >
          close
        </mat-icon>
      </mat-form-field>
      <button
        mat-raised-button
        color="warn"
        (click)="deleteSelected()"
        [disabled]="selection.isEmpty()"
        class="div-form-fields-container"
      >
        <mat-icon>delete</mat-icon> Delete Selected
      </button>

      <button
        mat-raised-button
        color="primary"
        (click)="updateSelected()"
        [disabled]="selection.isEmpty()"
        class="div-form-fields-container"
      >
        <mat-icon>edit</mat-icon> Update Selected
      </button>
    </div>
    <mat-table
      [dataSource]="assignedSdrsList"
      matSort
      matSortActive="sdr"
      matSortDirection="asc"
      matSortDisableClear
      #sdrsSort="matSort"
    >
      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            #checkboxAll
            (change)="$event ? toggleAllRows() : null"
            [checked]="isAllSelected()"
            [indeterminate]="isPartialSelected()"
          >
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? toggleRow(row) : null"
            [checked]="selection.isSelected(row)"
          >
          </mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container
        matColumnDef="sdr"
        class="sdr-column"
      >
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          >SDR</mat-header-cell
        >
        <mat-cell *matCellDef="let element">{{ element.sdr }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="inbox">
        <mat-header-cell
          *matHeaderCellDef
          class="center header-checkbox"
          ><div>
            Inbox
            <mat-checkbox
              (change)="toggleAll($event, 'isDefault')"
              [checked]="isAllFolderSelected('isDefault')"
            ></mat-checkbox></div
        ></mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          class="center"
        >
          <mat-checkbox
            color="primary"
            (change)="onIsDefaultChange($event, element, 'default')"
            [(ngModel)]="element.isDefault"
            [checked]="element?.isDefault"
          >
          </mat-checkbox>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="manual">
        <mat-header-cell
          *matHeaderCellDef
          class="center header-checkbox"
          ><div>
            Manual
            <mat-checkbox
              (change)="toggleAll($event, 'isManualDiscrepancy')"
              [checked]="isAllFolderSelected('isManualDiscrepancy')"
            >
            </mat-checkbox></div
        ></mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          class="center"
        >
          <mat-checkbox
            color="primary"
            [(ngModel)]="element.isManualDiscrepancy"
            (change)="onIsDefaultChange($event, element, 'manual_discrepancies')"
            [checked]="element?.isManualDiscrepancy"
          >
          </mat-checkbox>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="review">
        <mat-header-cell
          *matHeaderCellDef
          class="center header-checkbox"
          ><div>
            Review
            <mat-checkbox
              (change)="toggleAll($event, 'isReviewDiscrepancy')"
              [checked]="isAllFolderSelected('isReviewDiscrepancy')"
            >
            </mat-checkbox></div
        ></mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          class="center"
        >
          <mat-checkbox
            color="primary"
            (change)="onIsDefaultChange($event, element, 'review_discrepancies')"
            [(ngModel)]="element.isReviewDiscrepancy"
            [checked]="element?.isReviewDiscrepancy"
          >
          </mat-checkbox>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="salesOperations">
        <mat-header-cell
          *matHeaderCellDef
          class="center header-checkbox"
          ><div>
            Sales Operations
            <mat-checkbox
              (change)="toggleAll($event, 'isSalesOperator')"
              [checked]="isAllFolderSelected('isSalesOperator')"
            >
            </mat-checkbox></div
        ></mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          class="center"
        >
          <mat-checkbox
            color="primary"
            (change)="onIsDefaultChange($event, element, 'sales_operator')"
            [(ngModel)]="element.isSalesOperator"
            [checked]="element?.isSalesOperator"
          >
          </mat-checkbox>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell
          *matHeaderCellDef
          class="center"
          >Actions</mat-header-cell
        >
        <mat-cell
          *matCellDef="let row"
          class="center"
        >
          <button
            mat-icon-button
            (click)="onRemoveSdr(row.sdr)"
            id="folders-sdrs-remove-sdr-btn"
          >
            <mat-icon>remove_circle</mat-icon>
          </button>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="loading">
        <mat-footer-cell
          *matFooterCellDef
          colspan="2"
        >
          Loading data...
        </mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="noData">
        <mat-footer-cell
          *matFooterCellDef
          colspan="2"
        >
          No data.
        </mat-footer-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      <mat-footer-row
        *matFooterRowDef="['loading']"
        [ngClass]="{ hide: assignedSdrsList !== null }"
      ></mat-footer-row>
      <mat-footer-row
        *matFooterRowDef="['noData']"
        [ngClass]="{ hide: !(assignedSdrsList !== null && assignedSdrsList.data.length === 0) }"
      ></mat-footer-row>
    </mat-table>
    <mat-paginator
      [pageSize]="10"
      showFirstLastButtons
      #sdrsPaginator="matPaginator"
    ></mat-paginator>
  </div>
</div>

<div class="message">
  <div
    class="message__container"
    *ngIf="messages.length"
  >
    <div class="message__bar-actions">
      <div class="message__left-actions">
        <mat-checkbox
          *ngIf="enableDeleteMessages || enableClassify"
          id="all-messages-checkbox"
          color="primary"
          [checked]="isAllSelected$ | async"
          [indeterminate]="indeterminate$ | async"
          [matTooltip]="isAllSelectedTooltip$ | async"
          matTooltipPosition="above"
          (change)="toggleAllMessages($event.checked)"
        ></mat-checkbox>
        <button
          *ngIf="(hasSelectedMessages$ | async) === false && !enableDeleteMessages && !enableClassify"
          class="message__action-btn"
          id="trash-btn"
          mat-icon-button
          matTooltip="Mark as Trash"
          matTooltipPosition="above"
          (click)="toggleClassifyTrashMessages()"
        >
          <mat-icon>delete</mat-icon>
        </button>
        <button
          *ngIf="(hasSelectedMessages$ | async) && enableDeleteMessages"
          class="message__action-btn message__action-btn--left-border"
          id="confirm-trash-btn"
          mat-icon-button
          matTooltip="Confirm"
          matTooltipPosition="above"
          (click)="openConfirmClassifyTrashMessages()"
        >
          <mat-icon>check_circle</mat-icon>
        </button>
      </div>
      <div class="message__right-actions">
        <button
          *ngIf="enableDeleteMessages || enableClassify"
          class="message__action-btn"
          id="cancel-trash-btn"
          mat-icon-button
          matTooltip="Cancel"
          matTooltipPosition="above"
          (click)="cancelSelection()"
        >
          <mat-icon>cancel</mat-icon>
        </button>

        <button
          *ngIf="(hasSelectedMessages$ | async) === false && !enableClassify && !enableDeleteMessages"
          class="message__action-btn"
          id="trash-btn"
          mat-icon-button
          matTooltip="Mark message"
          matTooltipPosition="above"
          (click)="toggleClassifyMessage()"
        >
          <mat-icon>edit</mat-icon>
        </button>
      </div>
    </div>

    <div class="message__body">
      <!-- <mat-radio-group> -->
      <div
        *ngFor="let message of messages; let i = index"
        class="message__item"
        [ngClass]="{
          'message__item--outbound': !message.isInbound,
          'message__item--inbound': message.isInbound
        }"
      >
        <input
          *ngIf="
            (message.isInbound && message.messageStatus === 'Inbox' && !message?.isTemp && enableDeleteMessages) ||
            (message.isInbound && message.messageStatus === 'Inbox' && !message?.isTemp && enableClassify)
          "
          type="checkbox"
          class="message__toggle-message"
          id="message-checkbox-{{ i }}"
          [checked]="message.selected"
          (change)="toggleMessage(message.messageHandlerId, $event.target.checked)"
        />
        <div
          class="message__plain"
          [ngClass]="{
            'message__plain--outbound': !message.isInbound && !message?.isTemp,
            'message__plain--inbound': message.isInbound && !message?.isTemp,
            'message__plain--temp': message?.isTemp
          }"
        >
          <span
            class="message__text"
            [innerHTML]="message.messagePlain | newlineToBr"
          ></span>
          <div class="message__details">
            <span class="message__status">{{ message?.messageStatus }}</span>
            <span>{{ message?.sentDate | date: 'YYYY-MM-dd h:mm a' }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="message__send-box">
      <app-send-message [selectedConversation]="selectedConversation"></app-send-message>
    </div>
  </div>
</div>

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Forwarder } from '../../../../model/interaction/forwarder.model';
import { ClassifierMessage } from '../../../../model/mail-classifier/classifier-message.model';
import { LoadingStateService } from '../../../../services/base/loading-state.service';
import { InteractionService } from '../../../../services/interaction.service';
import { LeadService } from '../../../../services/lead.service';
import { MailReviewService } from '../../../../services/mail-review.service';

@Component({
  selector: 'app-contact-toolbar',
  templateUrl: './contact-toolbar.component.html',
  styleUrls: ['./contact-toolbar.component.scss'],
})
export class ContactToolbarComponent implements OnInit, OnDestroy {
  isCreatingContact = false;
  isValid = false;
  isSaving = false;
  isShowingAlias = false;
  currentMessage: ClassifierMessage = null;
  forwarders: Forwarder[] = [];
  invalidAddToInteraction$ = combineLatest([
    this.interactionService.forwarders$,
    this.leadService.currentLead$,
    this.mailReviewService.currentClassifierMessage$,
  ]).pipe(
    map(([forwarders, currentLead, currentMessage]) => {
      const hasForwarders = forwarders?.length > 0;
      const emailMatches = hasForwarders && forwarders[0]?.email === currentLead?.emailAddress;
      const hasInvalidHistory = currentLead?.history?.some(
        (history) => history.prospectStatus === 'Sent' && history.sdrId == currentMessage.sdrId,
      );
      return !hasForwarders || !emailMatches || hasInvalidHistory;
    }),
  );
  invalidReassignSdr$ = combineLatest([
    this.mailReviewService.currentClassifierMessage$,
    this.mailReviewService.selectedHistoryInteraction$,
  ]).pipe(
    map(([message, historyInteraction]) => {
      const sdrMatches =
        message?.customerId === historyInteraction?.customerId &&
        message?.sdrId !== historyInteraction?.sdrId &&
        historyInteraction.prospectStatus === 'Sent';
      return !sdrMatches;
    }),
  );
  invalidAlias$ = combineLatest([this.leadService.currentLead$, this.interactionService.forwarders$]).pipe(
    map(([currentLead, forwarders]) => {
      const existLead = currentLead;
      const hasForwarders = forwarders?.find((forwarder) => forwarder.originalEmail === currentLead?.emailAddress);
      return !existLead || !hasForwarders;
    }),
  );
  invalidSetOrUndoAlias$ = combineLatest([
    this.leadService.currentLead$,
    this.mailReviewService.contactFromForwarder$,
  ]).pipe(
    map(([currentLead, contactFromForwarder]) => {
      return !currentLead?.idLead || !contactFromForwarder?.idLead;
    }),
  );
  private destroy$ = new Subject<boolean>();

  constructor(
    private interactionService: InteractionService,
    private leadService: LeadService,
    private loadingStateService: LoadingStateService,
    private mailReviewService: MailReviewService,
  ) {}

  ngOnInit(): void {
    this.mailReviewService.currentClassifierMessage$.pipe(takeUntil(this.destroy$)).subscribe((currentMessage) => {
      this.currentMessage = currentMessage;
    });

    this.leadService.isCreatingContact$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isCreatingContact) => (this.isCreatingContact = isCreatingContact));

    this.leadService.isSaveValid$.pipe(takeUntil(this.destroy$)).subscribe((isValid) => {
      this.isValid = isValid;
    });

    this.loadingStateService.isLoading.pipe(takeUntil(this.destroy$)).subscribe((isLoading) => {
      this.isSaving = isLoading;
    });

    this.leadService.showAlias$.pipe(takeUntil(this.destroy$)).subscribe((showAlias) => {
      this.isShowingAlias = showAlias;
    });
  }

  ngOnDestroy(): void {
    this.leadService.setShowAliasCalled(false);
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  onCreateContact(): void {
    this.leadService.setIsCreatingContact(true);
  }

  onCancelCreateContact(): void {
    this.leadService.setIsCreatingContact(false);
  }

  onSaveContact(): void {
    this.leadService.setSaveCalled(true);
  }

  onAddToInteraction(): void {
    this.leadService.setRegisterProspectCalled(true);
  }

  onReassignSdr(): void {
    this.leadService.setReassignSdrCalled(true);
  }

  onToggleShowAlias(): void {
    const showAlias = !this.isShowingAlias;
    this.leadService.setShowAliasCalled(showAlias);
  }

  onSetAlias(): void {
    this.leadService.setAliasCalled(true);
  }

  onUndoAlias(): void {
    this.leadService.setUndoAliasCalled(true);
  }
}

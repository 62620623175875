<div class="client-management">
  <div class="client-management__container">
    <div class="client-management__title">
      <mat-icon>arrow_back_ios</mat-icon>
      <span>Client Management</span>
    </div>
    <mat-divider></mat-divider>
    <div class="client-management__actions">
      <span>Select a client</span>

      <mat-form-field
        subscriptSizing="dynamic"
        appearance="outline"
        class="form-field-5 client-management__client-field"
        id="client-management-client-mat-form-field"
      >
        <mat-label>Client</mat-label>
        <mat-select
          (selectionChange)="getOperatorsList($event.value)"
          id="client-management-client-mat-select"
        >
          <mat-option
            *ngFor="let customer of customers$ | async"
            [value]="customer"
            id="client-management-client-mat-option"
          >
            {{ customer.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="client-management__action-buttons">
        <button
          *ngIf="(currentTab$ | async) === OperatorManagementTables.SDR && false"
          color="primary"
          mat-raised-button
          (click)="openAssignOperatorModal()"
          [disabled]="!selectedCustomer"
          id="client-management-assign-operator-btn"
        >
          <mat-icon>group_add</mat-icon>
          <span>{{ 'Assign Operator to SDR' }}</span>
        </button>
      </div>
    </div>
    <mat-divider></mat-divider>
    <mat-tab-group (selectedTabChange)="handleTabChange($event.index)">
      <mat-tab label="Operators">
        <div class="client-management__table-container">
          <app-table-actions
            [showSelectAll]="false"
            [showFilterKeyDropdown]="true"
            [filters]="operatorsFilters$ | async"
            (filterKeyChangedEvent)="handleOperatorFilterKeyChangedEvent($event)"
            (filterUserInputEvent)="handleFilterUserInputEvent($event)"
            #operatorTableActions
          ></app-table-actions>

          <div class="mat-elevation-z8 shrink">
            <mat-table
              [dataSource]="operatorDataSource"
              matSort
              #operatorSort="matSort"
            >
              <ng-container matColumnDef="name">
                <mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  class="client-management__name-cell"
                  >Operator Name</mat-header-cell
                >
                <mat-cell
                  *matCellDef="let element"
                  class="client-management__name-cell"
                  >{{ element.name }}</mat-cell
                >
              </ng-container>
              <ng-container matColumnDef="email">
                <mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  class="client-management__email-cell"
                  >User Email</mat-header-cell
                >
                <mat-cell
                  *matCellDef="let element"
                  class="client-management__email-cell"
                  >{{ element.email }}</mat-cell
                >
              </ng-container>
              <ng-container matColumnDef="status">
                <mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  class="client-management__status-cell"
                  >Operator Status</mat-header-cell
                >
                <mat-cell
                  *matCellDef="let element"
                  class="client-management__status-cell"
                >
                  <span class="client-management__rounded-background">
                    {{ element.status }}
                  </span>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="languages">
                <mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  class="client-management__languages-cell"
                  >Language</mat-header-cell
                >
                <mat-cell
                  *matCellDef="let element"
                  class="client-management__languages-cell"
                >
                  <app-flag-viewer [languages]="formatLanguages(element.languages)"></app-flag-viewer>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="actions">
                <mat-header-cell
                  *matHeaderCellDef
                  class="client-management__actions-cell"
                  >Actions</mat-header-cell
                >
                <mat-cell
                  *matCellDef="let element"
                  class="client-management__actions-cell"
                >
                  <button
                    class="client-management__action-btn"
                    mat-icon-button
                    (click)="handleOperatorEditClick(element)"
                    id="client-management-edit-operator-btn"
                  >
                    <mat-icon>edit</mat-icon>
                  </button>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="noData">
                <tr
                  class="client-management__no-data-row mat-mdc-row"
                  *matNoDataRow
                >
                  <td
                    class="client-management__no-data"
                    colspan="2"
                  >
                    {{ operatorDataSource.data || operatorHasError ? 'No data.' : 'Select a client.' }}
                  </td>
                </tr>
              </ng-container>
              <mat-header-row *matHeaderRowDef="displayedOperatorColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedOperatorColumns"></mat-row>
            </mat-table>
            <mat-paginator
              [pageSizeOptions]="[10, 50, 100]"
              showFirstLastButtons
              #operatorPaginator
            ></mat-paginator>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="SDRS">
        <div class="client-management__table-container">
          <app-table-actions
            [showSelectAll]="false"
            (filterUserInputEvent)="handleFilterUserInputEvent($event)"
            #sdrTableActions
          ></app-table-actions>

          <div class="mat-elevation-z8 shrink">
            <mat-table
              [dataSource]="sdrDataSource"
              matSort
              #sdrSort="matSort"
            >
              <ng-container matColumnDef="user_name">
                <mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  class="client-management__sdr-name-cell"
                  >SDR Name</mat-header-cell
                >
                <mat-cell
                  *matCellDef="let element"
                  class="client-management__sdr-name-cell"
                  >{{ element.user_name }}</mat-cell
                >
              </ng-container>
              <ng-container matColumnDef="actions">
                <mat-header-cell
                  *matHeaderCellDef
                  class="client-management__sdr-actions-cell"
                  >Actions</mat-header-cell
                >
                <mat-cell
                  *matCellDef="let element"
                  class="client-management__sdr-actions-cell"
                >
                  <button
                    class="client-management__action-btn"
                    mat-icon-button
                    (click)="handleSDREditClick(element)"
                    id="client-management-edit-sdr-btn"
                  >
                    <mat-icon>edit</mat-icon>
                  </button>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="noData">
                <tr
                  class="client-management__no-data-row mat-mdc-row"
                  *matNoDataRow
                >
                  <td
                    class="client-management__no-data"
                    colspan="2"
                  >
                    {{ sdrDataSource.data || sdrHasError ? 'No data.' : 'Select a client.' }}
                  </td>
                </tr>
              </ng-container>
              <mat-header-row *matHeaderRowDef="displayedSdrColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedSdrColumns"></mat-row>
            </mat-table>
            <mat-paginator
              [pageSizeOptions]="[10, 50, 100]"
              showFirstLastButtons
              #sdrPaginator
            ></mat-paginator>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

export interface CatalogCollection {
  matrix: CatalogData[];
  country: CatalogData[];
  delay: CatalogData[];
  industry: CatalogData[];
}

export interface CatalogData {
  id: number;
  type: string;
  name: string;
  value: number;
  customerId: number;
}

export enum CatalogType {
  Matrix = 'Matrix',
  Country = 'Country',
  Delay = 'Delay',
  Industry = 'Industry',
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, merge, of, timer } from 'rxjs';
import { catchError, concatMap, switchMap } from 'rxjs/operators';
import { PRODUCT_TAG } from '../model/Config';
import { Email, FOLDERS } from '../model/Email';
import { Sdr } from '../model/Sdr';
import { LoadingStateService } from './base/loading-state.service';
import { SdApiService } from './base/sd-api.service';

const REFRESH_FREQUENCY = 365 * 24 * 60 * 60 * 1000;
const CLASSIFIED_BUFFER = 100;

@Injectable({
  providedIn: 'root',
})
export class FeedService {
  private currentSdrs = new BehaviorSubject<Sdr[]>([]);

  private currentClients = new BehaviorSubject<any[]>([]);

  private currentFolder = new BehaviorSubject<string>(FOLDERS.INBOX);

  private emailFeed = new BehaviorSubject<Email[]>([]);

  private sortByOldestBS = new BehaviorSubject<boolean>(true);

  private currentFeed: Email[] = [];

  private currentSdrsSnapshot: Sdr[] = [];

  public lastClassified: string[][];

  private currentPage = new BehaviorSubject<number>(1);

  pageKeyMap: any = [null];

  private lastPageSubject = new BehaviorSubject<boolean>(false);

  pageChanged = false;

  private showFeedSubject = new BehaviorSubject<boolean>(true);

  constructor(
    private sdApiService: SdApiService,
    private loadingState: LoadingStateService,
  ) {
    // React on sdr change
    this.currentSdrs
      .asObservable()
      .pipe(
        switchMap((sdrs: Sdr[]) => {
          this.loadingState.clearLoadingState();
          this.loadingState.clearFeedLoadingState();
          this.pageChanged = false;
          this.currentSdrsSnapshot = sdrs;
          this.currentFeed = [];
          this.emailFeed.next([]);
          this.pageKeyMap = [null];
          this.currentPage.next(1);
          let observables: Observable<Email[]>[];

          if (this.currentFolder.getValue() !== FOLDERS.SENT) {
            if (this.isTerminalFolder()) {
              if (this.currentFolder.getValue() !== FOLDERS.CONVERTED) {
                return this.sdApiService.loadSingleMailFeed(
                  sdrs.map((sdr) => sdr.email),
                  this.currentFolder.getValue(),
                );
              } else {
                return this.sdApiService.loadSingleMailFeed(
                  sdrs.map((sdr) => sdr.email),
                  this.currentFolder.getValue(),
                  1,
                );
              }
            } else {
              if (this.currentFolder.getValue() !== FOLDERS.CONVERTED)
                observables = [
                  this.sdApiService.loadSingleMailFeed2(
                    sdrs.map((sdr) => sdr.email),
                    this.currentFolder.getValue(),
                  ),
                ];
              else
                observables = [
                  this.sdApiService.loadSingleMailFeed2(
                    sdrs.map((sdr) => sdr.email),
                    this.currentFolder.getValue(),
                    1,
                  ),
                ];
            }
          } else {
            if (sdrs.length > 0) {
              observables = [
                this.sdApiService.loadSingleMailSent(
                  sdrs.map((sdr) => sdr.email),
                  this.currentFolder.getValue(),
                  1,
                ),
              ];
            } else {
              return [];
            }
          }

          return timer(0, REFRESH_FREQUENCY).pipe(
            concatMap(() => {
              return merge(...observables);
            }),
            catchError((error) => {
              console.log(error);
              return of([]);
            }),
          );
        }),
        catchError((error) => {
          console.log(error);
          return of([]);
        }),
      )
      .subscribe(
        (results) => {
          const processed = this.process(results);
          this.emailFeed.next(processed);
          this.currentFeed = processed;
          this.sdApiService
            .sendLog({
              tags: [PRODUCT_TAG, 'AfterProcessingResults', 'FeedChange', 'ClientTrace'],
              body: this.getLogDataFromFeed(processed),
            })
            .subscribe();
        },
        () => {},
        () => {},
      );

    // React on page change
    this.currentPage
      .asObservable()
      .pipe(
        switchMap((pageNumber: number) => {
          if (!this.pageChanged) {
            return [];
          }
          this.loadingState.clearLoadingState();
          this.loadingState.clearFeedLoadingState();
          const sdrs = this.currentSdrs.getValue();
          this.currentFeed = [];
          this.emailFeed.next([]);
          let observables: Observable<Email[]>[];
          if (this.currentFolder.getValue() === FOLDERS.SENT) {
            observables = [
              this.sdApiService.loadSingleMailSent(
                sdrs.map((sdr) => sdr.email),
                this.currentFolder.getValue(),
                pageNumber,
              ),
            ];
          } else {
            observables = [
              this.sdApiService.loadSingleMailFeed2(
                sdrs.map((sdr) => sdr.email),
                this.currentFolder.getValue(),
                pageNumber,
              ),
            ];
          }

          return timer(0, REFRESH_FREQUENCY).pipe(
            concatMap(() => {
              return merge(...observables);
            }),
            catchError((error) => {
              console.log(error);
              return of([]);
            }),
          );
        }),
        catchError((error) => {
          console.log(error);
          return of([]);
        }),
      )
      .subscribe(
        (results) => {
          if (this.pageChanged) {
            const processed = this.process(results);
            this.emailFeed.next(processed);
            this.currentFeed = processed;
            this.sdApiService
              .sendLog({
                tags: [PRODUCT_TAG, 'AfterProcessingResults', 'FeedChange', 'ClientTrace'],
                body: this.getLogDataFromFeed(processed),
              })
              .subscribe();
            this.pageChanged = false;
          }
        },
        () => {},
        () => {},
      );
  }

  cacheCleanEmails(feed: Email[], sdrs: Sdr[]) {
    const clean = [];
    sdrs.forEach((sdr) => {
      feed.forEach((email) => {
        if (email.sdr === sdr.email) {
          clean.push(email);
        }
      });
    });
    return this.removeDuplicates(clean);
  }

  process(delta: Email[]) {
    return this.filterLastClassified(
      this.removeDuplicates(this.removeOld(this.appendAndMerge(this.filterFolder(delta)))),
    );
  }

  removeOld(feed: Email[]) {
    const buffer = [];
    feed.forEach((mail) => {
      if (Date.now() - mail.downloadTimestamp < REFRESH_FREQUENCY) buffer.push(mail);
    });
    return buffer;
  }

  removeDuplicates(delta: Email[]) {
    return delta.filter((mail, index) => {
      return (
        index ===
        delta.findIndex((obj) => {
          return obj.storageId === mail.storageId;
        })
      );
    });
  }

  filterFolder(delta: Email[]) {
    return delta.filter((email) => email.folder === this.currentFolder.getValue());
  }

  appendAndMerge(delta: Email[]) {
    this.currentFeed.push(...delta);
    return Email.sortByDAte(this.currentFeed);
  }

  updateSdrs(sdrs: Sdr[]) {
    this.currentSdrs.next(sdrs);
  }

  updateClients(clients: any[]) {
    this.currentClients.next(clients);
  }

  updateSelectedFolder(folder: string) {
    this.currentFolder.next(folder);
    this.sortByOldestBS.next(folder !== FOLDERS.SENT);
  }

  getFirstMail() {
    return this.currentFeed ? this.currentFeed[0] : null;
  }

  get selectedFolder() {
    return this.currentFolder.asObservable();
  }

  addMailToFeed(mail: Email) {
    this.currentFeed.push(mail);
    this.currentFeed = this.removeDuplicates(this.currentFeed);
    this.emailFeed.next(this.currentFeed);
    this.sdApiService
      .sendLog({
        tags: [PRODUCT_TAG, 'AfterAddMailToFeed', 'FeedChange', 'ClientTrace'],
        body: this.getLogDataFromFeed(this.currentFeed),
      })
      .subscribe();
  }

  removeMailFromFeed(mail: Email) {
    const newList = [];
    this.currentFeed.forEach((item) => {
      if (item.storageId !== mail.storageId) newList.push(item);
    });
    this.currentFeed = this.removeDuplicates(newList);
    this.emailFeed.next(this.currentFeed);
    this.sdApiService
      .sendLog({
        tags: [PRODUCT_TAG, 'AfterRemoveMailFromFeed', 'FeedChange', 'ClientTrace'],
        body: this.getLogDataFromFeed(this.currentFeed),
      })
      .subscribe();
  }

  get feed() {
    return this.emailFeed.asObservable();
  }

  get sortByOldest() {
    return this.sortByOldestBS.asObservable();
  }

  sortFeed(order: string) {
    this.sortByOldestBS.next(order === 'asc');
  }

  refresh() {
    const sdrs = this.currentSdrsSnapshot;
    this.currentSdrs.next([]);
    this.currentSdrs.next(sdrs);
  }

  getLogDataFromFeed(feed: Email[]) {
    let result = 'Current feed contains ';
    feed.forEach((mail) => {
      result += mail.uid + ',';
    });
    return result;
  }

  ensureInitialized() {
    if (!this.lastClassified) {
      this.lastClassified = [];
    }
  }

  registerLastClassified(classifiedEmailIds: string[]) {
    this.ensureInitialized();
    this.lastClassified.push(classifiedEmailIds);
  }

  // When undoing or getting classify error
  deleteFromLastClassified(emailId: string) {
    this.ensureInitialized();
    if (this.lastClassified && Array.isArray(this.lastClassified)) {
      const idx = this.lastClassified.findIndex((el) => el.includes(emailId));
      if (idx !== -1) {
        this.lastClassified.splice(idx, 1);
      }
    }
  }

  filterLastClassified(feed: Email[]) {
    this.ensureInitialized();
    const buffer = [];
    feed.forEach((mail) => {
      const merged = [].concat.apply([], this.lastClassified);
      if (merged.indexOf(mail.storageId) == -1) {
        buffer.push(mail);
      } else {
        this.sdApiService
          .sendLog({
            tags: [PRODUCT_TAG, 'FilterLastClassified', 'ClientTrace'],
            body: 'Recent classified email prevented from returning to inbox, storageId:' + mail.storageId,
          })
          .subscribe();
      }
    });
    return buffer;
  }

  setNextPage() {
    const nextPage = this.currentPage.getValue() + 1;
    this.currentPage.next(nextPage);
  }

  setPreviousPage() {
    const previousPage = this.currentPage.getValue() - 1;
    this.currentPage.next(previousPage);
  }

  get selectedPage() {
    return this.currentPage.asObservable();
  }

  get isLastPage() {
    return this.lastPageSubject.asObservable();
  }

  private isTerminalFolder() {
    return (
      !this.currentFolder.getValue() ||
      this.currentFolder.getValue().toLowerCase() === 'Converted'.toLowerCase() ||
      this.currentFolder.getValue().toLowerCase() === 'Other'.toLowerCase() ||
      this.currentFolder.getValue().toLowerCase() === 'Sales Operations'.toLowerCase() ||
      this.currentFolder.getValue().toLowerCase() === 'Spam WD'.toLowerCase() ||
      this.currentFolder.getValue().toLowerCase() === 'Spam'.toLowerCase()
    );
  }

  setShowFeed(showFeed: boolean) {
    this.showFeedSubject.next(showFeed);
  }

  get showFeed() {
    return this.showFeedSubject.asObservable();
  }
}

<div class="inquiry-selection">
  <div class="inquiry-selection__title">Results ({{ emails.length }})</div>
  <div
    class="inquiry-selection__item"
    *ngFor="let email of emails; let i = index"
    (click)="select(email, i)"
    [ngClass]="selectedIndex === i ? 'inquiry-selection__selected' : ''"
  >
    <span class="inquiry-selection__element">{{ email }}</span>
  </div>
</div>

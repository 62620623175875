import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { UIGuid } from '../../../helpers/ui-guid';
import { Source } from '../../../model/common/source.enum';
import { ClientRepresentative } from '../../../model/mail-classifier/client-representative.model';
import { SalesRepDialogData } from '../../../model/sales-representative/sales-rep-dialog-data.model';
import { SalesRepresentative } from '../../../model/SalesRepresentative';
import { CheckConnectionDto } from '../../../model/sdr-conversations/check-connection.dto';
import { Message } from '../../../model/sdr-conversations/conversation-mesage.model';
import { SalesRepresentativeConnected } from '../../../model/sdr-conversations/sales-rep-connected.model';
import { Conversation, SdrConversation } from '../../../model/sdr-conversations/sdrs-conversations.model';
import { SendMessageDto } from '../../../model/sdr-conversations/send-message.dto';
import { ConfigurationService } from '../../../services/configuration.service';
import { SdrConversationsService } from '../../../services/sdr-conversations.service';
import { SnackBarService } from '../../../services/snackbar/snackbar.service';
import { RecipientFieldsComponent } from '../../home/mail-composer/recipient-fields/recipient-fields.component';
import { SelectSalesRepresentativeComponent } from '../../home/mail-composer/select-sales-representative/select-sales-representative.component';
import { SelectTemplateComponent } from '../../home/mail-composer/select-template/select-template.component';

@Component({
  selector: 'app-send-message',
  templateUrl: './send-message.component.html',
  styleUrls: ['./send-message.component.scss'],
  standalone: false,
})
export class SendMessageComponent implements OnInit, OnDestroy {
  @ViewChild(RecipientFieldsComponent) recipientFields: RecipientFieldsComponent;
  @ViewChild('messageTextarea') messageTextarea!: ElementRef<HTMLTextAreaElement>;
  @Input() selectedConversation: Conversation;
  messageCtrl = new FormControl('');
  isSendingMessage = false;
  selectedSalesRepLinkedInUrl = '';
  messageSalesRepresentative: string = '';
  isConnectionValid = true;
  private destroy$ = new Subject<boolean>();
  clientRepresentatives: ClientRepresentative = null;
  currentSdr: SdrConversation = null;

  constructor(
    private sdrsConversationsService: SdrConversationsService,
    private configurationService: ConfigurationService,
    private snackBarService: SnackBarService,
    public dialog: MatDialog,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  ngOnInit(): void {
    this.configurationService.clientRepresentatives$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (clientRepresentatives) => {
        this.clientRepresentatives = clientRepresentatives;
      },
    });

    this.sdrsConversationsService.selectedConversation$.pipe(takeUntil(this.destroy$)).subscribe({
      next: () => {
        this.currentSdr = this.sdrsConversationsService.findSdrdByConversationId(
          this.selectedConversation?.conversationId,
        );
        this.clearSalesRepresentative();
        this.messageCtrl.reset('');
        setTimeout(() => {
          this.adjustTextareaHeight(this.messageTextarea.nativeElement);
        });
      },
    });
  }

  sendMessage(): void {
    const { conversationId } = this.selectedConversation;

    if (!this.currentSdr?.sdrId || !conversationId || !this.messageCtrl?.value) {
      this.snackBarService.showError(
        'An error occurred while collecting the information required to send the message.',
      );
      return;
    }
    const sendMessageDto: SendMessageDto = {
      sdrId: this.currentSdr?.sdrId,
      conversationId,
      message: this.messageCtrl.value,
      salesRepLinkedInUrl: this.selectedSalesRepLinkedInUrl,
    };

    this.isSendingMessage = true;
    this.messageCtrl.disable();
    this.sdrsConversationsService
      .sendMessage(sendMessageDto)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.isSendingMessage = false;
          this.messageCtrl.enable();
        }),
      )
      .subscribe({
        next: () => {
          this.snackBarService.showSuccess('Message sent successfully');
          this.addMessageToConversation(this.messageCtrl.value);
          this.clearSalesRepresentative();
          this.messageCtrl.reset('');
        },
        error: () => this.snackBarService.showError('An error occurred while sending the message'),
      });
  }

  addMessageToConversation(message: string) {
    const newMessage: Message = {
      messageId: UIGuid.newGuid(),
      messageHandlerId: UIGuid.newGuid(),
      messagePlain: message,
      isInbound: true,
      sentDate: new Date().toISOString(),
      messageStatus: 'Inbox',
      selected: false,
      isTemp: true,
      salesRepLinkedInUrl: this.selectedSalesRepLinkedInUrl,
    };

    this.sdrsConversationsService.addMessageToConversation(newMessage);
  }

  selectSalesRep() {
    const dialogRef = this.dialog.open(SelectSalesRepresentativeComponent, {
      minWidth: '500px',
      data: {
        sdrId: this.currentSdr?.sdrId,
        source: Source.SendMessage,
      } as SalesRepDialogData,
    });
    dialogRef.afterClosed().subscribe((result: SalesRepresentative | SalesRepresentativeConnected) => {
      if (!result) return;

      this.messageSalesRepresentative = 'The selected Sales Representative was: ' + result.name;
      this.selectedSalesRepLinkedInUrl =
        (result as SalesRepresentative)?.profileCC || (result as SalesRepresentativeConnected)?.entryptedUrl;
      this.selectTemplate();

      if (this.selectedSalesRepLinkedInUrl) {
        this.checkConnection();
      }
    });
  }

  checkConnection() {
    const checkConnectionDto: CheckConnectionDto = {
      conversationId: this.selectedConversation.conversationId,
      sdrId: this.currentSdr?.sdrId,
      salesRepLinkedInUrl: this.selectedSalesRepLinkedInUrl,
    };

    this.sdrsConversationsService.checkConnection(checkConnectionDto).subscribe({
      next: (response) => {
        if (response) {
          this.isConnectionValid = true;
        } else {
          this.isConnectionValid = false;
          this.snackBarService.showError('The selected SDR is not connected to the Sales Representative');
        }
      },
      error: () => {
        this.isConnectionValid = false;
        this.snackBarService.showError(
          'An error occurred while validating the SDR connection with the Sales Representative',
        );
      },
    });
  }

  selectTemplate() {
    const dialogRef = this.dialog.open(SelectTemplateComponent, {
      minWidth: '1150px',
      data: {
        sdr: this.currentSdr?.sdrId,
        tags: 'LinkedIn',
        currentFolder: 'INBOX',
        client: {
          name: this.clientRepresentatives.clientName,
          id: this.clientRepresentatives.clientId,
        },
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.messageCtrl.setValue(result.templateText);
        setTimeout(() => {
          this.adjustTextareaHeight(this.messageTextarea.nativeElement);
        });
      }
    });
  }

  clearSalesRepresentative() {
    this.messageSalesRepresentative = '';
    this.selectedSalesRepLinkedInUrl = '';
    this.isConnectionValid = true;
  }

  adjustTextareaHeight(textarea: HTMLTextAreaElement) {
    if (!textarea) {
      return;
    }

    textarea.style.height = 'auto';

    const scrollHeight = textarea.scrollHeight;
    const maxHeight = this.messageSalesRepresentative ? window.innerHeight * 0.5 - 174 : window.innerHeight * 0.5 - 134;

    if (scrollHeight > maxHeight) {
      textarea.style.height = `${maxHeight}px`;
      textarea.style.overflowY = 'auto';
    } else {
      textarea.style.height = `${scrollHeight}px`;
      textarea.style.overflowY = 'hidden';
    }
  }
}

import { InquiryLeadHistory } from './InquiryLeadHistory';

interface ICompany {
  idCompany: number;
  source: string;
  name: string;
  domain: string;
  size: number;
  city: string;
  linkedinCompanyId: string;
  linkedInUrl: string;
  industry: string;
  industryId: number;
  technologies: string;
  sizeId: number;
  employeeCount: number;
  typeId: number;
  type: string;
  website: string;
  specialties?: string;
  yearFounded?: string;
}

class Company implements ICompany {
  idCompany: number;
  source: string;
  name: string;
  domain: string;
  size: number;
  city: string;
  linkedinCompanyId: string;
  linkedInUrl: string;
  industry: string;
  industryId: number;
  technologies: string;
  sizeId: number;
  employeeCount: number;
  typeId: number;
  type: string;
  website: string;
  specialties?: string;
  yearFounded?: string;

  constructor(company?: any) {
    if (!company) return;

    this.idCompany = company.id_company;
    this.source = company.source;
    this.name = company.company_name;
    this.domain = company.domain;
    this.size = company.size;
    this.city = company.city;
    this.linkedinCompanyId = company.linkedin_company_id;
    this.linkedInUrl = company.linkedin_url;
    this.industry = company.industry;
    this.industryId = company.industry_id;
    this.technologies = company.technologies;
    this.sizeId = company.company_size_id;
    this.employeeCount = company.employee_count;
    this.typeId = company.company_type_id;
    this.type = company.company_type;
    this.website = company.website;
    this.specialties = company.specialties;
    this.yearFounded = company.year_founded;
  }
}

interface IClassificationStatus {
  id: number;
  name: string;
}

export interface IInquiryLead {
  leadId?: number;
  company?: ICompany;
  emailAddressSource?: string;
  dateCreated?: string;
  dateUpdated?: string;
  patternId?: number;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  title?: string;
  emailAddress: string;
  linkedInUrlGen?: string;
  region?: string;
  country?: string;
  state?: string;
  city?: string;
  phone?: string;
  history?: InquiryLeadHistory[];
  domainLeadsQuantity?: number;
  selected: boolean;
  filled: boolean;
  mobile?: string;
  comments?: string;
  contactTypeId?: number;
  phoneExtension?: string;
  phoneOffice?: string;
  leadsSameDomain?: number;
  emailStatus?: IClassificationStatus;
  pattern?: string;
  jobResponsibility?: string;
  jobDepartment?: string;
  emailStrategy?: string;
  qEmployeesOnLinkedin?: number;
  originalLeadContactedEmail?: string;
  originalLeadContactedName?: string;
  originalLeadContactedTitle?: string;
  originalLeadContactedLinkedinUrl?: string;
}

export class InquiryLead implements IInquiryLead {
  leadId?: number;
  company?: ICompany;
  emailAddressSource?: string;
  dateCreated?: string;
  dateUpdated?: string;
  patternId?: number;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  title?: string;
  emailAddress: string;
  linkedInUrlGen?: string;
  region?: string;
  country?: string;
  state?: string;
  city?: string;
  phone?: string;
  history?: InquiryLeadHistory[];
  domainLeadsQuantity?: number;
  selected: boolean;
  filled: boolean;
  mobile?: string;
  comments?: string;
  contactTypeId?: number;
  phoneExtension?: string;
  phoneOffice?: string;
  leadsSameDomain?: number;
  emailStatus?: IClassificationStatus;
  pattern?: string;
  jobResponsibility?: string;
  jobDepartment?: string;
  technologies?: string;
  emailStrategy?: string;
  qEmployeesOnLinkedin?: number;
  originalLeadContactedEmail?: string;
  originalLeadContactedName?: string;
  originalLeadContactedTitle?: string;
  originalLeadContactedLinkedinUrl?: string;

  constructor(data?: any) {
    if (!data) return;

    this.leadId = data.id_lead;
    this.company = new Company(data.company);
    this.emailAddressSource = data.email_address_source;
    this.patternId = data.pattern_id;
    this.firstName = data.first_name;
    this.middleName = data.middle_name;
    this.lastName = data.last_name;
    this.title = data.title;
    this.emailAddress = data.email_address;
    this.linkedInUrlGen = data.linked_in_url_gen;
    this.region = data.region;
    this.country = data.country;
    this.city = data.city;
    this.state = data.state;
    this.phone = data.phone;
    this.history = Array.isArray(data.history) ? data.history : [];
    this.dateCreated = data.date_created ? new Date(data.date_created).toLocaleString() : null;
    this.dateUpdated = data.date_created ? new Date(data.date_updated).toLocaleString() : null;
    this.domainLeadsQuantity = data.domain_leads_quantity;
    this.selected = false;
    this.filled = false;
    this.mobile = data.phone_mobile;
    this.comments = data.comments;
    this.contactTypeId = data.contact_type_id;
    this.phoneExtension = data.phone_extension;
    this.phoneOffice = data.phone_office;
    this.leadsSameDomain = data.count_leads_same_domain;
    this.emailStatus = data.email_status;
    this.pattern = data.pattern;
    this.jobResponsibility = data.job_responsibility;
    this.jobDepartment = data.job_department;
    this.emailStrategy = data.email_strategy;
    this.qEmployeesOnLinkedin = data.q_employees_on_linkedin;
    this.originalLeadContactedEmail = data.original_lead_contacted_email;
    this.originalLeadContactedName = data.original_lead_contacted_name;
    this.originalLeadContactedTitle = data.original_lead_contacted_title;
    this.originalLeadContactedLinkedinUrl = data.original_lead_contacted_linkedin_url;
  }
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LeadSearchParams } from '../../../../model/mail-classifier/lead-search-params.model';
import { SdApiService } from '../../../../services/base/sd-api.service';
import { MailReviewService } from '../../../../services/mail-review.service';
import { SDAuthService } from '../../../../services/sd-auth.service';
import { MailClassifierAdvanceSearchDialogComponent } from '../../mail-classifier-advance-search-dialog/mail-classifier-advance-search-dialog.component';

@Component({
  selector: 'app-mail-classifier-search-interactions',
  templateUrl: './mail-classifier-search-interactions.component.html',
  styleUrls: ['./mail-classifier-search-interactions.component.scss'],
})
export class MailClassifierSearchInteractionsComponent implements OnInit, OnDestroy {
  @Input() isLoading;
  @Input() collapseSearch = false;
  searchForm: FormGroup = new FormGroup({});
  fullClientsInfo = [];
  clientNames = [];
  sdrs = [];
  filteredClientNames: Observable<string[]>;
  filteredSdrs: Observable<string[]>;
  susbcriptions = new Subscription();

  constructor(
    private sdApiService: SdApiService,
    private sdAuthService: SDAuthService,
    private mailReviewService: MailReviewService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.sdAuthService.getCurrentUserEmail().then((email) => {
      this.susbcriptions.add(
        combineLatest([
          this.sdApiService.userClients,
          this.sdApiService.getUserData(email),
          this.mailReviewService.currentClassifierMessage$,
        ]).subscribe(([clientsReponse, userDataResponse, currentClassifierMessage]: any) => {
          if (clientsReponse?.length) {
            const discrepancySdrs = userDataResponse.manual_discrepancies || [];
            const reviewDiscrepancySdrs = userDataResponse.review_discrepancies || [];

            const allSdrs: { email: string; id: number }[] = Array.from(
              new Set(discrepancySdrs.concat(reviewDiscrepancySdrs)),
            );

            let allAvailableSdrs = [];

            this.fullClientsInfo = clientsReponse
              .map((client) => {
                client.sdrs = client.sdrs.filter((sdr) => allSdrs.find((currSdr) => currSdr.email === sdr.email));
                allAvailableSdrs = Array.from(new Set(allAvailableSdrs.concat(client.sdrs)));
                return client;
              })
              .filter((client) => client.sdrs.length);

            this.clientNames = this.fullClientsInfo.map((client) => client.name);
            this.filteredClientNames = this.searchForm.get('client').valueChanges.pipe(
              startWith(''),
              map((val) => this._filterClients(val || '')),
            );

            this.filteredSdrs = this.searchForm.get('sdr').valueChanges.pipe(
              startWith(''),
              map((val) => this._filterSdrs(val || '')),
            );

            const messageSdr = allAvailableSdrs.find((sdr) => sdr.id === currentClassifierMessage?.sdrId);

            if (messageSdr) {
              this.searchForm.get('client').setValue(messageSdr?.clientId);
              this.onChangeClient(messageSdr?.clientId);
              this.searchForm.get('sdr').setValue(messageSdr?.id);
              this.searchForm.get('sdr').enable();
            }
          }
        }),
      );
    });

    this.susbcriptions.add(
      this.mailReviewService.clickedEmailToSearch$.subscribe((email) => {
        this.searchForm.get('email').setValue(email);
      }),
    );
  }

  ngOnDestroy(): void {
    this.susbcriptions.unsubscribe();
  }

  get isValid(): boolean {
    return this.searchForm.valid;
  }

  get email() {
    return this.searchForm.get('email');
  }

  get isValidEmail(): boolean {
    return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(this.email?.value?.trim());
  }

  private initForm() {
    this.searchForm = new FormGroup({
      client: new FormControl('', [Validators.required, Validators.minLength(2)]),
      sdr: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.minLength(2)]),
      email: new FormControl('', [Validators.required, Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)]),
    });
  }

  getSDREmail(sdrId: number) {
    if (!sdrId) return;
    return this.sdrs.find((sdr) => sdr.id === sdrId).email;
  }

  getClientName(clientId: number) {
    if (!clientId) return;
    return this.fullClientsInfo.find((client) => client.clientId === clientId).name;
  }

  onChangeClient(client: number) {
    const clientInfo = this.fullClientsInfo.find((c) => c.clientId === client);
    this.searchForm.get('sdr').setValue('');
    if (clientInfo) {
      this.sdrs = clientInfo.sdrs;
      this.searchForm.get('sdr').enable();
    }
  }

  onSubmitSearch() {
    if (this.isValid) {
      const form = this.searchForm.getRawValue();
      const leadSearchParams: LeadSearchParams = {
        clientId: form.client,
        sdrId: form.sdr,
        email: form.email?.trim(),
      };

      this.mailReviewService.setSearchParams(leadSearchParams);
    }
  }

  getClientIdByName(name: string) {
    return this.fullClientsInfo.find((client) => client.name === name)?.clientId;
  }

  onClickSelectInput(ctrlName: string) {
    const control = this.searchForm.get(ctrlName);

    if (control.value) {
      control.setValue('');
    }
  }

  onAdvanceSearch() {
    this.dialog
      .open(MailClassifierAdvanceSearchDialogComponent, {
        panelClass: 'discrepancies-dialog',
        height: '80%',
        width: '70%',
        data: {
          client: this.searchForm.get('client').value,
          sdr: this.searchForm.get('sdr').value,
        },
      })
      .afterClosed()
      .subscribe((response) => {
        if (response?.selectedLeadEmail) {
          this.searchForm.get('email').setValue(response.selectedLeadEmail);
        }
      });
  }

  private _filterClients(val: string): string[] {
    if (val) {
      return this.fullClientsInfo.filter((option) => {
        return option.name.toLowerCase().indexOf(val) !== -1 || option.clientId.toString().indexOf(val) !== -1;
      });
    }

    return this.fullClientsInfo;
  }

  private _filterSdrs(val: string): string[] {
    if (val) {
      return this.sdrs.filter((option) => {
        return option.email.toLowerCase().indexOf(val) !== -1 || option.id.toString().indexOf(val) !== -1;
      });
    }

    return this.sdrs;
  }
}

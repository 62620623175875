import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { CompleteCaseDto } from '../../../model/complete-case-dialog/complete-case.dto';
import { ClassifierMessageDto, StatusScreen } from '../../../model/mail-classifier/classifier-message.dto';
import { ClassifierMessage, ClassifierMessageResult } from '../../../model/mail-classifier/classifier-message.model';
import { LoadingStateService } from '../../../services/base/loading-state.service';
import { SdApiService } from '../../../services/base/sd-api.service';
import { ComposerMailService } from '../../../services/composer-mail.service';
import { MailReviewService } from '../../../services/mail-review.service';
import { SnackBarService } from '../../../services/snackbar/snackbar.service';
import { MailComposerMinComponent } from '../../home/mail-composer-min/mail-composer-min.component';
import { CompleteCaseDialogComponent } from '../complete-case-dialog/complete-case-dialog.component';

@Component({
  selector: 'app-mail-classifier',
  templateUrl: './mail-classifier.component.html',
  styleUrls: ['./mail-classifier.component.scss'],
})
export class MailClassifierComponent implements OnInit, AfterViewInit, OnDestroy {
  classifierMessages: ClassifierMessage[] = [];
  currentMessage: ClassifierMessage = null;
  currentMessageIdx = 0;
  totalCount = 0;
  pendingCount = 0;
  completedCases = 0;
  isLoading = true;
  classifierMessageDto: ClassifierMessageDto = {
    statusScreen: StatusScreen.Review,
    page: 1,
    pageSize: 5,
  };
  destroy$ = new Subject<boolean>();

  constructor(
    private composerMailService: ComposerMailService,
    private loadingStateService: LoadingStateService,
    private matDialog: MatDialog,
    private mailReviewService: MailReviewService,
    private sdApiService: SdApiService,
    private snackBarService: SnackBarService,
  ) {}

  ngOnInit() {
    this.loadManualReviewMessages();
    this.sdApiService.loadUserClientsSDRs();

    this.loadingStateService.isLoading.pipe(takeUntil(this.destroy$)).subscribe((value) => (this.isLoading = value));
  }

  ngAfterViewInit(): void {
    this.composerMailService.isMailComposerMediumOpen
      .pipe(takeUntil(this.destroy$))
      .subscribe((isOpen) => isOpen && this.openMediumDialog());

    this.composerMailService.isMailComposerMinimized
      .pipe(takeUntil(this.destroy$))
      .subscribe((isMinimized) => isMinimized && this.openMinimizedDialog());
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  get isPrevBtnDisabled(): boolean {
    return this.isLoading || this.currentMessageIdx === 0 || !this.classifierMessages.length;
  }

  get isNextBtnDisabled(): boolean {
    return (
      this.isLoading ||
      this.totalCount - this.completedCases === this.currentMessageIdx + 1 ||
      !this.classifierMessages.length
    );
  }

  get messageExits(): boolean {
    return this.currentMessageIdx < this.classifierMessages.length - 1;
  }

  onNavigateNextMessage() {
    if (this.messageExits) {
      this.currentMessageIdx += 1;
      this.currentMessage = this.classifierMessages[this.currentMessageIdx];
      this.setClassifierMessage(this.currentMessage);
    } else {
      this.classifierMessageDto.page += 1;
      this.loadManualReviewMessages();
    }
  }

  onNavigatePreviousMessage() {
    if (this.currentMessageIdx > 0) {
      this.currentMessageIdx -= 1;
      this.currentMessage = this.classifierMessages[this.currentMessageIdx];
      this.setClassifierMessage(this.currentMessage);
    }
  }

  loadManualReviewMessages() {
    this.isLoading = true;

    this.mailReviewService
      .loadManualReviewMessages(this.classifierMessageDto)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe({
        next: (response: ClassifierMessageResult) => {
          this.classifierMessages =
            this.classifierMessageDto.page === 1 ? response.items : this.classifierMessages.concat(...response.items);
          this.setData(response);
        },
        error: () => this.snackBarService.showError('An error occurred while trying to get the emails'),
      });
  }

  async onRefreshMails() {
    this.classifierMessageDto.page = 1;
    await this.loadManualReviewMessages();
  }

  onCompleteCase() {
    this.matDialog
      .open(CompleteCaseDialogComponent, {
        width: '30%',
        maxWidth: 'initial',
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.completeCase(result);
        }
      });
  }

  completeCase(ruleId: number) {
    const completeCaseDto: CompleteCaseDto = {
      classifierLogId: this.currentMessage?.classifierLogId,
      ruleId,
    };

    this.loadingStateService.setLoadingState(true);
    this.mailReviewService
      .completeCase(completeCaseDto)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.loadingStateService.setLoadingState(false);
        }),
      )
      .subscribe({
        next: () => {
          this.snackBarService.showSuccess('Case completed successfully');
          this.refreshCases();
        },
        error: () => {
          this.snackBarService.showError('An error occurred while completing the case');
        },
      });
  }

  refreshCases() {
    this.isLoading = false;
    this.classifierMessages = this.classifierMessages.filter(
      (m) => m.classifierLogId !== this.currentMessage.classifierLogId,
    );

    if (this.classifierMessages.length) {
      this.currentMessageIdx -= 1;
    }
    this.pendingCount -= 1;
    this.completedCases += 1;
    this.onNavigateNextMessage();
  }

  private setData(response: ClassifierMessageResult) {
    this.totalCount = response.totalResults;
    this.pendingCount = response.totalResults;
    this.completedCases = this.classifierMessageDto.page === 1 ? 0 : this.completedCases;
    this.currentMessageIdx = this.classifierMessageDto.page === 1 ? 0 : (this.currentMessageIdx += 1);

    this.currentMessage = this.classifierMessages?.length ? this.classifierMessages[this.currentMessageIdx] : null;
    this.setClassifierMessage(this.currentMessage);
    this.isLoading = false;
  }

  setClassifierMessage(classifierMessage: ClassifierMessage) {
    this.matDialog.closeAll();

    if (classifierMessage?.classifierLogId) {
      this.mailReviewService.setClassifierMessage(classifierMessage);
    }
  }

  onClickEmail(email: string) {
    this.mailReviewService.setClickedEmail(email);
  }

  openMediumDialog() {
    const dialogData = JSON.parse(window.localStorage.getItem('fwdEmailData') || '{}');
    const dialModalRef = this.matDialog.open(MailComposerMinComponent, {
      panelClass: 'mail-composer-container',
      hasBackdrop: false,
      data: dialogData,
      autoFocus: false,
      disableClose: true,
      maxHeight: '98vh',
    });
    dialModalRef.updatePosition({ bottom: '0', right: '20px' });
  }

  openMinimizedDialog() {
    const dialogData = JSON.parse(window.localStorage.getItem('fwdEmailData') || '{}');

    this.matDialog.open(MailComposerMinComponent, {
      panelClass: 'mail-composer-container',
      hasBackdrop: false,
      data: dialogData,
      autoFocus: false,
      disableClose: true,
      width: '400px',
      height: '36px',
      position: { bottom: '0', right: '20px' },
    });
  }
}

<div class="view-lead-toolbar">
  <div class="view-lead-toolbar__details-container">
    <span *ngIf="leadToCampaignValue"><strong>Lead to Campaign</strong> {{ leadToCampaignValue }}</span>
  </div>
  <div class="view-lead-toolbar__actions-container">
    <button
      class="view-lead-toolbar__new-lead-btn"
      mat-stroked-button
      (click)="onClickReassign()"
      [disabled]="!isReassignEnabled"
    >
      <span>Reassign</span>
    </button>
  </div>
</div>

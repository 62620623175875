<div class="contact-dialog">
  <div
    class="contact-dialog__top-bar"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
  >
    <span class="contact-dialog__title">{{
      isCreatingContact ? 'Create Contact' : parentData?.contactId ? 'View Contact' : 'Find Contact'
    }}</span>
    <mat-icon
      mat-dialog-close
      class="contact-dialog__close-btn"
      >close</mat-icon
    >
  </div>
  <div class="contact-dialog__content">
    <div
      class="contact-dialog__search-actions-container"
      [ngClass]="{ 'contact-dialog__search-actions-container--end': isCreatingContact }"
    >
      <app-contact-search
        *ngIf="!isCreatingContact"
        class="contact-dialog__search"
        [initialEmailAddress]="emailAddress"
        [customerId]="customerId"
      ></app-contact-search>
      <app-contact-toolbar></app-contact-toolbar>
    </div>
    <app-contact-form
      *ngIf="isCreatingContact"
      (addContact)="createContact($event)"
    ></app-contact-form>
    <app-contact-detail *ngIf="!isCreatingContact"></app-contact-detail>
  </div>
</div>

<div class="faq-operator">
  <div class="faq-operator__header">
    <span class="faq-operator__title">{{ title }}</span>
    <mat-icon
      mat-dialog-close
      class="faq-operator__close-btn"
      >close</mat-icon
    >
  </div>

  <iframe
    id="faqIframe"
    #faqIframe
    frameborder="0"
    sandbox="allow-same-origin"
  ></iframe>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { GenericResponse } from '../model/common/generic-response';

@Injectable({
  providedIn: 'root',
})
export class ClassifierLogService {
  constructor(private httpClient: HttpClient) {}

  checkBlacklistDomain(email: string, customerId: number): Observable<boolean> {
    return this.httpClient
      .get(`${environment.newApiBaseUrl}/classifier-log/check-blacklist-domain/${email}/${customerId}`)
      .pipe(map((response: GenericResponse<boolean>) => response.data));
  }

  checkBlacklistEmail(email: string, customerId: number): Observable<boolean> {
    return this.httpClient
      .get(`${environment.newApiBaseUrl}/classifier-log/check-blacklist-email/${email}/${customerId}`)
      .pipe(map((response: GenericResponse<boolean>) => response.data));
  }

  markEmailUpdateAsValid(classifierLogId: number): Observable<boolean> {
    return this.httpClient
      .patch(`${environment.newApiBaseUrl}/classifier-log/${classifierLogId}/update`, {})
      .pipe(map((response: GenericResponse<boolean>) => response.data));
  }

  checkEnableUpdate(classifierLogId: number): Observable<boolean> {
    return this.httpClient
      .get(`${environment.newApiBaseUrl}/classifier-log/${classifierLogId}/update`)
      .pipe(map((response: GenericResponse<boolean>) => response.data));
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FinalStatesService {
  apiBackEnd = '';
  constructor(private http: HttpClient) {
    this.apiBackEnd = environment.newApiBaseUrl;
  }

  public create(
    destination_folder_id: number,
    uid: string,
    current_folder: string,
    message_id: string,
    sdr: string,
    storage_id: string,
    returnDate?: string,
  ): Observable<any> {
    return this.http.post(`${this.apiBackEnd}/classification/classify-message/`, {
      destinationFolderId: destination_folder_id,
      uid,
      currentFolder: current_folder,
      messageId: message_id,
      sdr,
      messageHandlerId: storage_id,
      ...(returnDate && { returnDate }),
    });
  }
}

import { AfterViewInit, Component, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AssignOperatorRequest, IOperatorSDR, OperatorManagementTables } from '../../../../model/Operator';
import { ClientManagementModalCloseData, ClientManagementModalData, ICustomerSdr } from '../../../../model/Sdr';
import { BehaviorSubject } from 'rxjs';
import { OperatorService } from '../../../../services/operator.service.';
import { take } from 'rxjs/operators';
import { CommonLabelsEnum } from '../../../../model/Utils';

@Component({
    selector: 'app-assign-operator-to-client',
    templateUrl: './assign-operator.component.html',
    styleUrls: ['./assign-operator.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class AssignOperatorComponent implements AfterViewInit{
  @ViewChild(MatSort) public sort: MatSort;
  @ViewChild(MatPaginator) public paginator: MatPaginator;
  public OperatorManagementTables = OperatorManagementTables;
  public operatorDataSource: MatTableDataSource<IOperatorSDR> = new MatTableDataSource(null);
  public displayedOperatorColumns: string[] = ['select','operator_name','client_level','assigned'];
  public operatorsWithLevel: AssignOperatorRequest[] = [];
  public selectedOperatorIds: number[] = [];
  public selectedAllOperators$:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public selectedSdr: ICustomerSdr;
  public hasError = false;

  constructor(
    public dialog: MatDialogRef<AssignOperatorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ClientManagementModalData,
    private readonly operatorService: OperatorService,
  ) { }

  ngAfterViewInit(): void {
    if(this.data.selectedSdr){
      this.handleSdrChange(this.data.selectedSdr, true);
    }
  }

  public onDismiss(): void {
    this.dialog.close(null);
  }

  public onConfirm(): void {
    this.dialog.close(this.getSelectedOperators());
  }

  public setOperatorLevel(levelId: string, operatorId: string): void {
    const targetOperator = this.operatorDataSource.data.find(operator => operator.operator_id === operatorId);
    this.handleSelect(false, targetOperator.operator_id);
    this.operatorsWithLevel.push({
      customer_id: this.data.client.clientId,
      user_id: this.selectedSdr.user_id,
      operator_id: Number(operatorId),
      level_id: Number(levelId)
    })

    this.handleSelect(true, targetOperator.operator_id);
  }

  public handleSelect(
    selected: boolean,
    operatorId: string
  ): void {
    if(selected){
      this.selectedOperatorIds.push(Number(operatorId))
    } else {
      this.selectedOperatorIds = this.selectedOperatorIds.filter(data => data !== Number(operatorId))
    }

    if(!this.selectedOperatorIds.length){
      this.selectedAllOperators$.next(false);
      return
    }

    const pageSize = this.paginator.pageSize;
    const startIndex = this.paginator.pageIndex * pageSize;
    const avaliableOperators = this.operatorDataSource.data.slice(startIndex, startIndex + pageSize);
    const avaliableOperatorsWithLevel = avaliableOperators.filter(operator => this.checkIfOperatorHasLevel(operator.operator_id));
    const allOperatorsAreSelected = avaliableOperatorsWithLevel.every(operator => this.selectedOperatorIds.includes(Number(operator.operator_id)));

    this.selectedAllOperators$.next(allOperatorsAreSelected);
  }

  public handleSelectAllEvent(selectAll: boolean): void{
    if(selectAll){
      const pageSize = this.paginator.pageSize;
      const startIndex = this.paginator.pageIndex * pageSize;

      const avaliableOperators = this.operatorDataSource.data.slice(startIndex, startIndex + pageSize);
      const operatorsToSelect = avaliableOperators.filter(operator => this.checkIfOperatorHasLevel(operator.operator_id) && !this.selectedOperatorIds.includes(Number(operator.operator_id))).map(operator => Number(operator.operator_id));

      this.selectedOperatorIds.push(...operatorsToSelect);
    } else {
      this.selectedOperatorIds = [];
    }
    this.selectedAllOperators$.next(selectAll);
  }

  public checkIfOperatorIsSelected(operatorId: string): boolean {
    return this.selectedOperatorIds.includes(Number(operatorId));
  }

  public checkIfOperatorHasLevel(operatorId: string): boolean {
    return !!this.operatorsWithLevel.find(operator => operator.operator_id === Number(operatorId));
  }

  public handleOperatorFilterUserInputEvent(filter: any): void {
    this.applyFilter(filter);
  }

  public handleSelectAllActionEvent(): void {
    if(this.selectedOperatorIds.length){
      this.onConfirm();
    }
  }

  public handlePaginatorChange(): void {
    this.selectedAllOperators$.next(false);
  }

  public handleSdrChange(selectedSdr: ICustomerSdr, selectAll = false): void {
    this.selectedSdr = selectedSdr;
    this.operatorService.getAllUnsignedOperatorForSdr(selectedSdr.user_id, selectAll).pipe(take(1)).subscribe(res => {
      if(res.data.length) {
        this.hasError = false;
        this.createOperatorDataSource(res.data);
        this.checkAllSelectedOperators(res.data);
      }
    }, () => {
      this.hasError = true;
    })
  }

  public getOperatorLevel(operatorId: string): number | null {
    const foundOperator = this.operatorsWithLevel.find(operator => operator.operator_id === Number(operatorId));

    return foundOperator?.level_id ?? null
  }

  private checkAllSelectedOperators(operators: IOperatorSDR[]): void {
    const selectedOperators = operators.filter(operator => operator.assigned === CommonLabelsEnum.YES);
    selectedOperators.forEach(operator => {
      this.setOperatorLevel(operator.level_id, operator.operator_id);
    })
  }

  private getSelectedOperators(): ClientManagementModalCloseData {
    const selectedOperators =  this.operatorsWithLevel.filter(operator => this.selectedOperatorIds.includes(operator.operator_id));
    return {
      sdrId: this.selectedSdr.user_id,
      operators: selectedOperators
    }
  }

  private applyFilter(filterInput: string): void {
    if(!this.operatorDataSource.data){
      return
    }

    this.operatorDataSource.filter = filterInput.trim().toLowerCase();
  }

  private createOperatorDataSource(operatorList: IOperatorSDR[]): void {
    this.operatorDataSource = new MatTableDataSource(operatorList);
    this.operatorDataSource.sort = this.sort;
    this.operatorDataSource.paginator = this.paginator;
    this.operatorDataSource.filterPredicate = (data, filter) => {
      return data.operator_name && data.operator_name.toLowerCase().indexOf(filter) !== -1
    };
  }
}
